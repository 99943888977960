import React, { useState } from "react";

import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material";

import { ExpandLess, ExpandMore } from "@mui/icons-material";

// import { filterProducts } from "../../api/Queries";


import { useSelector } from "react-redux";

import { failureToastMessage, successToastMessage } from "../../common/Utils";

const FilterCategories = ({ setArrallproductget, closeDrawer }) => {
  const allProducts = useSelector((state) => state.cart.getAllProducts);
  const uniqueProductType = [...new Set(allProducts?.map((e) => e.productType))].sort();
  const UniqProductTypearr = uniqueProductType?.map((item, index) => ({
    id: index + 1,
    subheaderText: item,
  }));
  const uniqueProductStyle = [
    ...new Set(allProducts?.map((e) => e?.options[2]?.values[0]))].sort();
  const UniqProductStylearr = uniqueProductStyle.map((item, index) => ({
    id: index + 1,
    subheaderText: item,
  }));
  const uniqueProductColor = [
    ...new Set(allProducts?.map((e) => e?.options[1]?.values[0]))].sort();
  const UniqProductColorArr = uniqueProductColor?.map((item, index) => ({
    id: index + 1,
    subheaderText: item,
  }));
  const uniqueProductFit = [...new Set(allProducts?.map((e) => e?.fit?.value))].sort();
  const UniqProductFitArr = uniqueProductFit?.map((item, index) => ({
    id: index + 1,
    subheaderText: item,
  }));

  const [open, setOpen] = React.useState({
    price: false,

    gender: false,

    size: false,

    color: false,

    producttype: false,

    productstyle: false,

    fit: false,
  });

  const [value, setValue] = useState({
    price: [],

    gender: [],

    size: [], // Store selected sizes in an array

    color: [], // Store selected colors in an array

    producttype: [],

    productstyle: [],

    fit: [],
  });

  const handleApplyFilter = () => {
    const apiParams = {
      price: value.price,

      gender: value.gender,

      size: value.size, // Pass the selected size array

      color: value.color, // Pass the selected color array

      producttype: value.producttype,

      productstyle: value.productstyle,

      fit: value.fit,
    };

    const allfilteredProducts = allProducts?.filter((elm) => {
      const meetsMaxPriceCriteria =
      apiParams?.price?.length === 0 ||
      apiParams?.price?.some((priceRange) => {
        console.log(priceRange,"pricerange")
        const [min, max] = priceRange.split("-");
        const price = Number(elm?.variants?.nodes[0]?.compareAtPrice?.amount);
        return price >= Number(min) && price <= Number(max);
      });
      console.log(meetsMaxPriceCriteria,"sumitkumar")

      const meetsGenderCriteria =
        apiParams?.gender?.length === 0 ||
        apiParams?.gender.includes(elm?.gender?.value);

      const meetsSizeCriteria =
        apiParams?.size?.length === 0 ||
        elm.options[0]?.values?.filter((value) =>
          apiParams?.size.includes(value)
        ).length > 0;

      const meetsColorCriteria =
        apiParams?.color?.length === 0 ||
        apiParams.color.includes(elm?.options[1]?.values[0]);

      const meetsProductTypeCriteria =
        apiParams?.producttype.length === 0 ||
        apiParams?.producttype.includes(elm?.productType);

      const meetsProductStyleCriteria =
        apiParams?.productstyle.length === 0 ||
        apiParams?.productstyle.includes(elm?.options[2]?.values[0]);

      const meetsFitCriteria =
        apiParams?.fit?.length === 0 ||
        apiParams?.fit.includes(elm?.fit?.value);

      return (
        meetsMaxPriceCriteria &&
        meetsGenderCriteria &&
        meetsSizeCriteria &&
        meetsColorCriteria &&
        meetsProductTypeCriteria &&
        meetsProductStyleCriteria &&
        meetsFitCriteria
      );
    });

    // console.log(allfilteredProducts, "allfilteredProductsddddd");
    // setArrallproductget(allfilteredProducts);
    //   closeDrawer();
    if (allfilteredProducts?.length === 0) {
      failureToastMessage("No products found for the selected criteria.");
    } else {
      successToastMessage("Products found for the selected criteria");
      setArrallproductget(allfilteredProducts);
      closeDrawer();
    }
  };
  const productTypesWithSpecificSizes = [
    "Tees",
    "Polo",
    "Jacket",
    "Sweatshirt",
    "Hoodie",
    "Denim Jacket",
    "Shirt",
    "Placement Print",
  ];
  const productTypesWithJeansSizes = [
    "Shorts",
    "Jogger",
    "Woven Pants",
    "Denim Paints",
  ];

  let listOptions = [];

  if (value.producttype.length === 0) {
    listOptions = [
      { id: 3.1, subheaderText: "XS" },
      { id: 3.2, subheaderText: "S" },
      { id: 3.3, subheaderText: "M" },
      { id: 3.4, subheaderText: "L" },
      { id: 3.5, subheaderText: "XL" },
      { id: 3.6, subheaderText: "XXL" },
      { id: 3.6, subheaderText: "28" },
      { id: 3.6, subheaderText: "30" },
      { id: 3.6, subheaderText: "32" },
      { id: 3.6, subheaderText: "34" },
      { id: 3.6, subheaderText: "36" },
      { id: 3.6, subheaderText: "38" },
    ];
  } else if (
    productTypesWithSpecificSizes.some((type) =>
      value.producttype.includes(type)
    )
  ) {
    listOptions = [
      { id: 3.1, subheaderText: "XS" },
      { id: 3.2, subheaderText: "S" },
      { id: 3.3, subheaderText: "M" },
      { id: 3.4, subheaderText: "L" },
      { id: 3.5, subheaderText: "XL" },
      { id: 3.6, subheaderText: "XXL" },
    ];
  } else {
    listOptions = [
      { id: 3.6, subheaderText: "28" },
      { id: 3.6, subheaderText: "30" },
      { id: 3.6, subheaderText: "32" },
      { id: 3.6, subheaderText: "34" },
      { id: 3.6, subheaderText: "36" },
      { id: 3.6, subheaderText: "38" },
    ];
  }

  const filterOptionsArray = [
    {
      id: 5,

      header: "Product Type",

      isOpened: open.producttype,

      listOptions: UniqProductTypearr,
    },
    {
      id: 1,

      header: "Price",

      isOpened: open.price,

      listOptions: [
        {
          id: 1.1,

          subheaderText: "1000-1500",
        },

        {
          id: 1.2,

          subheaderText: "1500-2000",
        },

        {
          id: 1.3,

          subheaderText: "2000-3000",
        },

        {
          id: 1.4,

          subheaderText: "3000-4000",
        },

        {
          id: 1.5,

          subheaderText: "4000-5000",
        },

        // {
        //   id: 1.5,

        //   subheaderText: "5000-above",
        // },
      ],
    },

    // {
    //   id: 2,

    //   header: "Gender",

    //   isOpened: open.gender,

    //   listOptions: [
    //     {
    //       id: 2.1,

    //       subheaderText: "Unisex",
    //     },
    //   ],
    // },

    {
      id: 3,

      header: "Size",

      isOpened: open.size,

      listOptions,
    },

    {
      id: 4,

      header: "Color",

      isOpened: open.color,

      listOptions: UniqProductColorArr,
    },

    {
      id: 6,

      header: "Product Style",

      isOpened: open.productstyle,

      listOptions: UniqProductStylearr,
    },

    {
      id: 7,

      header: "Fit",

      isOpened: open.fit,

      listOptions: UniqProductFitArr,
    },
  ];

  //function to open the filter option dropdows

  const handleClick = (headerText, e) => {
    headerText = headerText.replace(/\s/g, "");

    headerText = headerText.toLowerCase();

    switch (headerText) {
      case "price":
        setOpen({ ...open, price: !open.price });

        break;

      case "gender":
        setOpen({ ...open, gender: !open.gender });

        break;

      case "size":
        setOpen({ ...open, size: !open.size });

        break;

      case "color":
        setOpen({ ...open, color: !open.color });

        break;

      case "producttype":
        setOpen({ ...open, producttype: !open.producttype });

        break;

      case "productstyle":
        setOpen({ ...open, productstyle: !open.productstyle });

        break;

      case "fit":
        setOpen({ ...open, fit: !open.fit });

        break;

      default:
        console.log(`Sorry, we are out of .`);
    }
  };

  const handleCheck = (text, headerText, subheaderText) => {
    headerText = headerText.replace(/\s/g, "");

    headerText = headerText.toLowerCase();

    switch (headerText) {
      case "price":
        setValue({
          ...value,

          price: value?.price?.includes(subheaderText)
            ? value?.price?.filter((price) => price !== subheaderText)
            : [...value?.price, subheaderText],
        });

        break;

      case "gender":
        setValue({
          ...value,

          gender: value.gender.includes(subheaderText)
            ? value.gender.filter((gender) => gender !== subheaderText)
            : [...value.gender, subheaderText],
        });

        break;

      case "size":
        // Toggle the selected size in the array

        setValue({
          ...value,

          size: value.size.includes(subheaderText)
            ? value.size.filter((size) => size !== subheaderText)
            : [...value.size, subheaderText],
        });

        break;

      case "color":
        setValue({
          ...value,

          color: value?.color?.includes(subheaderText)
            ? value?.color?.filter((color) => color !== subheaderText)
            : [...value?.color, subheaderText],
        });

        break;

      case "producttype":
        setValue({
          ...value,

          producttype: value?.producttype?.includes(subheaderText)
            ? value?.producttype?.filter(
                (producttype) => producttype !== subheaderText
              )
            : [...value?.producttype, subheaderText],
        });

        break;

      case "productstyle":
        setValue({
          ...value,

          productstyle: value?.productstyle.includes(subheaderText)
            ? value?.productstyle.filter(
                (productstyle) => productstyle !== subheaderText
              )
            : [...value?.productstyle, subheaderText],
        });

        break;

      case "fit":
        setValue({
          ...value,

          fit: value?.fit?.includes(subheaderText)
            ? value?.fit?.filter((fit) => fit !== subheaderText)
            : [...value?.fit, subheaderText],
        });

        break;

      default:
        console.log(`Sorry, we are out of .`);
    }
    setOpen(false);
  };

  return (
    <div>
      <Box
        sx={{mb:4,
          width: { lg: "85%", xs: "100%" },

          //   width: "18rem",

          overflowY: "hidden",

          overflowX: "hidden",

          boxShadow: "inherit",
        }}
      >
        <Box></Box>

        {filterOptionsArray.map((element) => (
          <>
            <ListItemButton
              // sx={{ marginY: "5%" }}
              onClick={(e) => handleClick(element.header, e)}
            >
              <ListItemText primary={element?.header} />

              {element.isOpened ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={element.isOpened} timeout="auto">
              {element.listOptions.map((sub) => (
                <List component="div" disablePadding>
                  <ListItemButton sx={{ padding: "0 1rem" }}>
                    <FormControl>
                      <FormGroup>
                        {sub.subheaderText && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{
                                  color: "black",
                                }}
                                onChange={(e) =>
                                  handleCheck(
                                    e.target.checked,

                                    element.header,

                                    sub.subheaderText
                                  )
                                }
                              />
                            }
                            label={sub.subheaderText}
                          />
                        )}
                      </FormGroup>
                    </FormControl>
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          </>
        ))}

        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            background: "black",
            justifyContent: "center",

            height: "3rem",
            // marginRight: "1.2rem",
          }}
        >
          {/* <Typography color="white" sx={{fontSize:"18px",fontWeight:"600",marginTop:"2.5%"}} onClick ={()=> handleReset()}>Reset Filter</Typography> */}

          <Typography
            color="white"
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              marginTop: { lg: "4.5%", xs: "3%", sm: "1.5%" },
            }}
            onClick={() => handleApplyFilter()}
          >
            Apply Filter
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default FilterCategories;
