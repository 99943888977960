import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  bannerImageId,
  bannerImgHome,
  bannerImgMarqueeId,
  bannerMarquee,
  bannerMarqueeId,
  bannerMarqueeImg,
  marqureeDetail,
  marqureeGetResourceId,
} from "../../api/Queries";
import Footer from "../../common/Footer";
import { client } from "../../common/Utils";
import CommonCard from "../Categories/CommonCard";

function MarqueeScreen() {
  const [marqueeCard, setMarqueeCard] = useState(true);
  const [mrDetails, setMrDetails] = useState([]);
  const [marqureeRsourId, setMarqureeRsourId] = useState("");
  const [marqueeBanner, setMarqueeBanner] = useState([]);
  const [marqueeBannerImg, setMarqueeBannerImg] = useState([]);
  const [marqueeDescription, setMarqueeDescription] = useState("");
  const [bannerImg, setBannerImg] = useState([]);

  // api for getting Banner of Marquee Screen
  const handleCommonCard = async (res) => {
    const responseData = await client
      .query({
        query: marqureeDetail,
        variables: { id: res },
      })
      .then((res) => {
        setMrDetails(res?.data?.collection?.products?.edges);
      });
  };

  // api for getting resource id for Marquee Image
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client
          .query({
            query: marqureeGetResourceId,
          })
          .then((res) => {
            handleCommonCard(res?.data?.menu?.items[0]?.resourceId);
            // res?.data?.menu?.items?.forEach((e) => handleCommonCard(e.resourceId));
          });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  // api for getting Banner of Marquee Screen
  const handleMarqueeBanner = async (res) => {
    const responseData = await client
      .query({
        query: bannerMarqueeId,
        variables: { collectionId: res },
      })
      .then((res) => {
        setMarqueeDescription(res.data.collection?.description);
        let image = res.data.collection.image.url;
        setMarqueeBanner((imageUrls) => [...imageUrls, image]);
      });
  };

  // api for getting resource id for Marquee Image
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client
          .query({
            query: bannerMarqueeImg,
          })
          .then((res) => {
            res?.data?.menu?.items?.forEach((e) =>
              handleMarqueeBanner(e.resourceId)
            );
          });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  // api for getting Banner of Marquee Screen
  const handleMarqueeImg = async (res) => {
    const responseData = await client
      .query({
        query: bannerImgMarqueeId,
        variables: { collectionId: res },
      })
      .then((res) => {
        // setMarqueeDescription(res.data.collection?.description)
        let image = res.data.collection.image.url;
        setMarqueeBannerImg((imageUrls) => [...imageUrls, image]);
      });
  };

  // api for getting resource id for  image
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client
          .query({
            query: bannerImgHome,
          })
          .then((res) => {
            res?.data?.menu?.items?.forEach((e) =>
              handleBannerImg(e.resourceId)
            );
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleBannerImg = async (res) => {
    const responseData = await client
      .query({
        query: bannerImageId,
        variables: { collectionId: res },
      })
      .then((res) => {
        let image = res?.data?.collection?.image?.url;
        setBannerImg((imageUrls) => [...imageUrls, image]);
      });
  };
  // api for getting resource id for Marquee Image
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client
          .query({
            query: bannerMarquee,
          })
          .then((res) => {
            res?.data?.menu?.items?.forEach((e) =>
              handleMarqueeImg(e.resourceId)
            );
          });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Box sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            mt: { xs: "8.5vh", sm: "10vh", md: "12vh" },
            height: { xs: "calc(91.5vh)", sm: "calc(90vh)", md: "calc(87vh)" },
            overflowY: "auto",
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <Box sx={{}}>
                <Box
                  sx={
                    {
                      // backgroundImage: `url("MarqueeBackground.png")`,
                      // backgroundRepeat: "no-repeat",
                      // width:{xs:"98vw",sm:"99.5vw"},
                      // display:"flex",justifyContent:"space-between"
                    }
                  }
                >
                  <Box
                    component="img"
                    sx={{
                      // width:{xs:"28vw",sm:"22vw"},overflow:"hidden"
                      objectFit: "cover",
                      width: "100%",
                    }}
                    alt="CartImage"
                    src={marqueeBanner[0]}
                  />
                  {/* <Box sx={{width:"28vw",display:"flex", alignItems: "center",}}>
                <Typography
                 sx={{textTransform:"uppercase", fontSize:"5vw", 
                 color:"#92E0EF",fontWeight:"bold" }} 
                 >{marqueeDescription}</Typography>
              </Box>
              <Box
                component="img"
                sx={{
                  width:{xs:"28vw",sm:"22vw"},overflow:"hidden"
                }}
                alt="CartImage"
                src={marqueeBanner[1]}
              />  */}
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={6}
              md={6}
              sx={{ p: 2, pl: { xs: "2vw", sm: 4, md: 5, lg: 6 } }}
            >
              <Box>
                <Box
                  component="img"
                  sx={{
                    width: { xs: "93vw", sm: "45vw" },
                  }}
                  alt="CartImage"
                  src={bannerImg[1]}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={6}
              md={6}
              sx={{
                p: { xs: 1, sm: 2 },
                pr: { xs: "2vw", sm: 4, md: 5, lg: 6 },
              }}
            >
              <Box>
                <Box
                  component="img"
                  sx={{
                    width: { xs: "93vw", sm: "45vw" },
                  }}
                  alt="CartImage"
                  src={bannerImg[0]}
                />
              </Box>
            </Grid>
            {mrDetails?.map((e) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  sx={{
                    backgroundColor: "#272330",
                    p: { xs: "1rem", sm: "1rem", md: "4rem" },
                    mb: 4,
                  }}
                >
                  <CommonCard
                    key={e?.node.id}
                    props={e?.node}
                    marqueeCard={marqueeCard}
                    setMarqueeCard={setMarqueeCard}
                    priceRange={e.node.priceRange.maxVariantPrice.amount}
                    discription={e.node.description}
                    imgUrl={e.node.images.edges[0].node.url}
                    titile={e.node.title}
                    availableForSale={e?.node?.availableForSale}
                    productVariantPrice={
                      e.node?.variants?.nodes[0].price.amount
                    }
                    productVariantId={e?.node.variants?.nodes[0].id}
                    productVariants={e?.node?.variants.nodes}
                    shippingCharges={e?.node?.variants?.nodes[0].weight}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Footer position="static" />
        </Box>
      </Box>
    </>
  );
}

export default MarqueeScreen;
