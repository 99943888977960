import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Zoom from "@mui/material/Zoom";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsEmail, setIsPhone } from "../../Redux/UserSlice";
import { SendEmailOtp, SendMobileOtp, ValidateMobileOtp } from "../../api/Api";
import { failureToastMessage, successToastMessage } from "../../common/Utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});
const OtpPopUp = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailValue = useSelector((state) => state?.user?.emailValue);
  const isPhone = useSelector((state) => state?.user?.isPhone);
  const isEmail = useSelector((state) => state?.user?.isEmail);
  const emailMobile = useSelector((state) => state?.user?.emailMobile);
  const [otp, setOtp] = useState("");
  const otpdigi = Math.floor(otp);
  const defaultValues = {
    otp: otp,
    // "phoneNumber": emailValue
    phoneNumber: isEmail == true ? emailValue : "+91" + emailMobile,
  };

  const {
    setOpenForgotPw,
    openForgotPw,
    setOpenSingUp,
    setOpenLog,
    showNewPass,
    setshowNewPass,
  } = props;

  const onSubmit = (data) => {
    data.otp = otp;
    ValidateMobileOtp(data)
      .then((res) => {
        if (res.responseCode == 200) {
          successToastMessage("You can change your Password");
          setOpenForgotPw(false);
          setOpenForgotPw(false);
          setshowNewPass(true);
        } else {
          failureToastMessage(res.message);
          setOtp("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { control, handleSubmit, reset } = useForm({ defaultValues });
  const [otpDigit, setOtpDigit] = useState([]);

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(60);
  // console.log(otpDigit,"otpDigit");
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOtp("");
    }, 60000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const requestBody = {
    phoneNumber: emailMobile,
    moblieNumberCode: "+91",
  };

  const resendOTP = () => {
    reset(otp);
    if (isEmail == true) {
      SendEmailOtp(emailValue).then((res) => {
        if (res.responseCode == 200) {
          successToastMessage(`OTP Resend Successfully on ${emailValue}`);
          setMinutes(0);
          setSeconds(60);
        }
      });
    } else if (isPhone == true) {
      SendMobileOtp(requestBody)
        .then((res) => {
          if (res.responseCode == 200) {
            successToastMessage(`OTP Send Successfully to Number`);
            setMinutes(0);
            setSeconds(60);
          } else {
            failureToastMessage(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const navigateToSignUp = () => {
    setOpenLog(true);
    setOpenForgotPw(false);
    setOpenSingUp(false);
    dispatch(setIsPhone(false));
    dispatch(setIsEmail(false));
  };

  const handleClose = () => {
    setOpenForgotPw(false);
    dispatch(setIsPhone(false));
    dispatch(setIsEmail(false));
  };

  return (
    <>
      <Dialog open={openForgotPw} TransitionComponent={Transition}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card
            sx={{
              boxShadow: "0px 0px 32px ",
              backgroundColor: "#1E1E1E",
              width: { xs: "100vw", sm: "45vw", md: "35vw", lg: "25rem" },
              height: { xs: "75vh", sm: "70vh", md: "70vh", lg: "70vh" },
              p: 1,
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <IconButton sx={{ float: "right" }}>
                    <CloseIcon
                      onClick={handleClose}
                      style={{ color: "white", fontSize: 27 }}
                    />
                  </IconButton>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      mt: 2,
                      ml: 3,
                    }}
                  >
                    <Box>
                      <img src="/LoginLog.png" alt="" height="130vh" />
                    </Box>
                    <Box sx={{ mb: -1 }}>
                      <img src="/LoginLog1.png" alt="" height="30vh" />
                    </Box>
                    <Box sx={{}}>
                      <img src="/LoginLog2.png" alt="" height="12vh" />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12} sx={{}}>
                  <Box
                    fullWidth
                    sx={{
                      height: "20vh",
                      backgroundColor: "#313140",
                      mt: 3,
                      pl: { xs: 1, sm: 1, md: 1, lg: 6 },
                    }}
                  >
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      placeholder="0000"
                      renderSeparator={<span style={{ margin: "8px" }}> </span>}
                      renderInput={(props) => (
                        <Box
                          sx={{
                            mt: 4,
                            ml: { xs: 0, sm: 1, md: 2, lg: 2 },
                            border: "2px soild white",
                          }}
                        >
                          <input
                            {...props}
                            style={{
                              height: "3rem",
                              width: "2.5rem",
                              fontSize: "1.5rem",
                              backgroundColor: "#313140",
                              color: "white",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                            onKeyPress={(e) => {
                              if (e.key.match(/[^0-9]/)) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {setOtpDigit(props.value)}{" "}
                          {console.log(props.value, "props")}
                        </Box>
                      )}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        ml: -3,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontSize: 10, color: "white", mt: 2 }}
                        >
                          Didn't receive code?
                        </Typography>
                      </Box>
                      <Box>
                        {seconds > 0 || minutes > 0 ? (
                          <Box sx={{ display: "flex" }}>
                            {" "}
                            <Typography
                              sx={{ color: "white", fontSize: 10, mt: 2 }}
                            >
                              Resend in :
                            </Typography>
                            <Typography
                              sx={{
                                color: "#004CE5",
                                fontSize: 13,
                                m: 1.7,
                                ml: 0.5,
                              }}
                            >
                              {" "}
                              {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </Typography>
                          </Box>
                        ) : (
                          <Button
                            variant="contained"
                            disabled={seconds > 0 || minutes > 0}
                            sx={{
                              fontSize: 10,
                              borderRadius: "40px",
                              border: "1px solid #006D82",
                              backgroundColor: "transparent",
                              "&:hover": { backgroundColor: "transparent" },
                              color:
                                seconds > 0 || minutes > 0
                                  ? "#DFE3E8"
                                  : "orange",
                              marginTop: "10px",
                            }}
                            onClick={resendOTP}
                          >
                            Resend OTP
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      mt: 4,
                      flexWrap: "wrap",
                    }}
                  >
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          width: "120px",
                          height: "4.3vh",
                          mb: { xs: 2 },
                          fontSize: 13,
                          border: "2px solid #006D82",
                          p: 0.2,
                          borderRadius: "40px",
                          backgroundColor: "transparent",
                          "&:hover": { backgroundColor: "transparent" },
                          textTransform: "none",
                        }}
                        onClick={navigateToSignUp}
                      >
                        Go Back
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          width: "120px",
                          height: "4.3vh",
                          fontSize: 13,
                          borderRadius: "40px",
                          backgroundColor: "#006D82",
                          textTransform: "none",
                          "&.Mui-disabled": { color: "#c0c0c0" },
                        }}
                        disabled={seconds == 0 || otp == "" ? true : false}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Box>
      </Dialog>
    </>
  );
};
export default OtpPopUp;
