import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { successToastDiscountMessage } from "./Utils";
import { Box, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";

const useStyles = makeStyles((theme) => ({
  paper: { borderRadius: "1rem" },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});

const AlertPopup = (props) => {
  const classes = useStyles();
  const { open, setOpen, title, buttonType1, buttonType2, displayTitle } =
    props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const handleDisagree = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    if (title === "logout") {
      setOpen(false);
      localStorage.clear();
      window.location.reload();
      window.location.href = "/";
    }
    successToastDiscountMessage("Logout Successfully")
  };

  return (
    <div>
      <Dialog
        open={open}
        classes={{
          paper: classes.paper,
        }}
        TransitionComponent={Transition}
      >
        <Box
          p={2}
          sx={{
            fontFamily: "Raleway",
            // backgroundImage: "url(background.png)",
            bgcolor:"black",
            boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.5)",
          }}
        >
          <DialogTitle textAlign="center">
            <img src="/newlogo.png" alt="" height="40vh" />
          </DialogTitle>
          <Typography
            sx={{ paddingX: "1rem", color: "#ffffff" }}
            textAlign="center"
          >
            {displayTitle}
          </Typography>
          <DialogActions>
            <div>
              {buttonType2 && (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "40px",
                    backgroundColor: "#006D82",
                    textTransform: "none",
                    "&.Mui-disabled": { color: "#c0c0c0" },
                  }}
                  onClick={handleAgree}
                  autoFocus
                >
                  {" "}
                  Logout{" "}
                </Button>
              )}
              {buttonType1 && (
                <Button
                  variant="contained"
                  sx={{
                    marginLeft: "0.5rem",
                    border: "1px solid #006D82",
                    borderRadius: "40px",
                    backgroundColor: "#006D82",
                    textTransform: "none",
                    "&.Mui-disabled": { color: "#c0c0c0" },
                  }}
                  onClick={handleDisagree}
                >
                  {title === "storeUnavailable" ||
                  title === "selectStore" ||
                  title === "pickUpLater" ||
                  title === "paymentCondition"
                    ? "OK"
                    : "Cancel"}
                </Button>
              )}
            </div>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};
export default AlertPopup;
