import axios from "axios";

let username = "nobie.prod.admin";
let password = "NNl%T9r2f%6pL@1w7*Jh!4k$8z^2k";
const instance = axios.create({
  baseURL: "https://api.nobiylifestyle.com",
  auth: {
    username: username,
    password: password,
  },
  // baseURL: "http://15.206.10.111:7777/neoMeta/Web",
});
instance.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8";
instance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default instance;
