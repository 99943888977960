import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductGetCollectionId, getCollectionId } from "../../api/Queries";
import { client } from "../../common/Utils";
import CommonCard from "../Categories/CommonCard";

function Collections() {
  const location = useLocation();
  const navigate = useNavigate();
  const [allProduct, setAllProduct] = useState([]);
  const [emptyProduct, setEmptyProduct] = useState([]);

  const title = location?.state?.title;

  const handleAllproduct = async (res) => {
    await client
      .query({
        query: ProductGetCollectionId,
        variables: { id: res },
      })
      .then((res) => {
        setEmptyProduct(res?.data?.collection?.products?.edges);
        const product = res?.data?.collection?.products?.edges;
        const sortedProducts = [...product].sort((a, b) => {
          const priceA = b?.node?.variants?.nodes[0]?.price?.amount;
          const priceB = a?.node?.variants?.nodes[0]?.price?.amount;
          return priceA - priceB;
        });
        setAllProduct(sortedProducts);
      });
  };

  useEffect(() => {
    if (!title) {
      navigate("/");
    } else {
      const fetch = async () => {
        try {
          const response = await client
            .query({
              query: getCollectionId,
            })
            .then((res) => {
              let resourIds = res?.data?.menu?.items
                ?.filter((e) => e.title == title)
                .map((e) => e.resourceId);
              handleAllproduct(resourIds[0]);
            });
        } catch (error) {
          console.error(error);
        }
      };
      fetch();
    }
  }, [title]);

  return (
    <>
      <Box
        sx={{
          paddingLeft: { lg: "14vw", xs: "5vw" },
          paddingRight: { lg: "8vw", xs: "1vw" },
          paddingBottom: { lg: "3vh", xs: "69px" },
          paddingTop: "15vh",
          display: "flex",
          height: { xs: "calc(100vh)", sm: "calc(100vh)", md: "calc(100vh)" },
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          spacing={{ lg: 8, md: 8, sm: 3, xs: 3 }}
          sx={{ pr: { lg: "5vw", xs: "5vw" } }}
        >
          {emptyProduct && emptyProduct.length > 0 ? (
            allProduct?.map((e) => (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <CommonCard
                  key={e?.id}
                  zoomScreenDetails={e}
                  imgUrl={e.node.images.edges[0].node.url}
                  props={e?.node}
                  catTitle={title}
                  // discription={e.node.description}
                  discription={
                    e?.node?.description.length > 60
                      ? `${e.node.description.substring(0, 150)}...`
                      : e.node.description
                  }
                  titile={e.node.title}
                  availableForSale={e.node.availableForSale}
                  priceRange={e.node.priceRange.maxVariantPrice.amount}
                  productVariantPrice={e.node.variants.nodes[0].price.amount}
                  productVariantId={e.node.variants.nodes[0].id}
                  productVariants={e.node.variants.nodes}
                />
              </Grid>
            ))
          ) : (
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                display: "block",
                marginTop: "-4rem",
              }}
              alt="CartImage"
              src="./Asset_4_3x.png"
            />
          )}
        </Grid>
      </Box>
    </>
  );
}

export default Collections;
