import { FormControl } from "@mui/base";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setOrderDetailsss } from "../../Redux/UserSlice";
import { cancelOrder } from "../../api/Api";
import {
  failureToastMessage,
  successToastMessage
} from "../../common/Utils";

const dialogStyles = {
  maxWidth: "80vw",
  width: { lg: "60vw", md: "70vw", sm: "70vw", xs: "80vw" },
  maxHeight: "80%",
  height: "auto",
  backgroundImage: `url('./BackgroundImageweb.png')`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};
const radioLabelStyles = {
  fontSize: { lg: 16, md: 16, sm: 16, xs: 13 },
  marginBottom: "8px",
  color: "white",
};

const radioStyles = {
  "& .MuiSvgIcon-root": {
    fontSize: "24px",
    color: "white",
  },
};
const buttonStyles = {
  display: "flex",
  justifyContent: "center", // Center the button horizontally
  marginTop: "0.5rem",
  marginBottom: "1rem",
  fontSize: { lg: 16, md: 16, sm: 16, xs: 12 },
};

const titleStyles = {
  textAlign: "center", // Center the title text
  color: "white",
  fontSize: { lg: 18, md: 18, sm: 16, xs: 16 },
};
function CancelOrderDialog({
  orderId,
  openDialog,
  handleDialogClose,
  selectedOrderDetails,
  handleData,
  setButtonDisabled,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");

  const handleClose = () => {
    setReason("");
    handleDialogClose();
  };

  const handleConfirm = () => {
    //cancel order logic.
    cancelOrder(orderId)
      .then((res) => {
        if (res.responseCode == 200) {
          setButtonDisabled(true);
          let slecteorder = { ...selectedOrderDetails };
          slecteorder.canceledAt = new Date();
          dispatch(setOrderDetailsss(slecteorder));
          successToastMessage(`Order Canceled Successfully`);
          navigate("/previousOrder");
        } else {
          failureToastMessage("Failed Cancelation request");
        }
      })
      .catch((e) => {
        console.log(e);
      });
    handleClose();
  };
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{ style: dialogStyles }}
    >
      <DialogTitle sx={titleStyles}>Why you want to Cancel Order?</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="cancel-reason"
            name="cancel-reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          >
            <FormControlLabel
              value="Changed my mind"
              control={<Radio sx={radioStyles} />}
              label="Changed my mind"
              sx={radioLabelStyles}
            />
            <FormControlLabel
              value="Shipping delay"
              control={<Radio sx={radioStyles} />}
              label="Shipping delay"
              sx={radioLabelStyles}
            />
            <FormControlLabel
              value="Other"
              control={<Radio sx={radioStyles} />}
              sx={radioLabelStyles}
              label="Other"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions sx={buttonStyles}>
        <Button
          variant="contained"
          type="submit"
          sx={{
            width: "9rem",
            height: "4.3vh",
            fontSize: 13,
            borderRadius: "10px",
            background: "linear-gradient(to right, #006D82, #006D82)",
            textTransform: "none",
            mt: "-2.8rem",
            marginRight: "3%",
          }}
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          sx={{
            width: "9rem",
            height: "4.3vh",
            fontSize: 13,
            borderRadius: "10px",
            background: "linear-gradient(to right, #006D82, #006D82)",
            textTransform: "none",
            mt: "-2.8rem",
            marginRight: "3%",
          }}
          onClick={handleConfirm}
          color="primary"
          disabled={!reason}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelOrderDialog;
