import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useNavigate } from "react-router-dom";

export const fontSize = {
  fontSize: { xs: 10, sm: 11, md: 13, lg: 14 },
  p: { xs: 0.2, sm: 1, md: 1, lg: 1 },
};
export const fontSize1 = {
  fontSize: { xs: 13, sm: 15, md: 17, lg: 18 },
  p: { xs: 0.2, sm: 1, md: 1, lg: 1 }, ml: { xs: "-1rem", sm: 0 }
};

const useStyles = makeStyles((theme) => ({
  textColor: {
    // height: "75px",
    transition: "transform .2s",
    overflow: "hidden",
    cursor: "pointer",
    color: "#006D82",
    "&:hover": { transform: "scale(1.3)", color: "#006D82", fontWeight: "bold" },
    "@media (hover: hover) and (pointer:fine)": {
      "&:hover": { transform: "scale(1.3)", color: "#006D82", fontWeight: "bold" }
    }
  },
}));

function Footer() {
  const navigate = useNavigate();
  const classes = useStyles();

  const handlePrivacyPolicy = () => {
    window.scrollTo({ top: 0, left: 0 });
    navigate("/privacyPolicy");
  };

  const handleReturnPolicy = () => {
    window.scrollTo({ top: 0, left: 0 });
    navigate("/returnPolicy");
  };

  const handleTermsAndConditions = () => {
    window.scrollTo({ top: 0, left: 0 });
    navigate("/termsAndConditions");
  };

  const handleShippingPolicy = () => {
    window.scrollTo({ top: 0, left: 0 });
    navigate("/shippingPolicy");
  };


  return (
    <>
      <Box sx={{ mt: "2rem", pb: 2 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={4} sm={4} md={4} lg={4} textAlign={"center"}>
            <Box
              component="img"
              sx={{
                height: { xs: "10vh", sm: "15vh", md: "20vh" },
              }}
              alt="CartImage"
              src="/LoginLog.png"
            />
            <Box>
              <Box
                component="img"
                sx={{
                  height: { xs: "2vh", sm: "3vh", md: "4vh" },
                }}
                alt="CartImage"
                src="/newlogo.png"
              />
            </Box>
            <Box sx={{ mt: { lg: "-2%", md: "-2%", xs: "-3%", sm: "-2%" } }}>
              {/* <img src="/LoginLog2.png" alt="" height="16vh" width="95vw" /> */}
              <Box
                component="img"
                sx={{
                  height: { xs: "1vh", sm: "1.2vh", md: "1.5vh" },
                  marginLeft: "-2.5%",
                }}
                alt="CartImage"
                src="/LoginLog2.png"
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4} textAlign={"center"}
          >
            <Box>
              <Typography style={{ color: "#006D82", }} sx={fontSize1}>
                <b>Company</b>
              </Typography>
              <Box sx={{ display: "flex", ml: { sm: "3rem", xs: 0 }, justifyContent: "center", textAlign: "left", flexWrap: "wrap" }}>
                <Box sx={{ color: "white", fontSize: 12 }}>
                  <Typography
                    sx={fontSize}
                    className={classes.textColor}
                    onClick={() => navigate("/ContactUs")}
                  >
                    Contact Us
                  </Typography>
                  <Typography
                    sx={fontSize}
                    className={classes.textColor}
                    onClick={() => handleReturnPolicy()}
                  >
                    Return Policy
                  </Typography>
                  <Typography
                    sx={fontSize}
                    className={classes.textColor}
                    onClick={() => handleShippingPolicy()}
                  >
                    Shipping Policy
                  </Typography>
                </Box>
                <Box sx={{ fontSize: 12, ml: { xs: "1.2rem", sm: 0 } }}>
                  <Typography
                    sx={fontSize}
                    className={classes.textColor}
                    onClick={() => handleTermsAndConditions()}
                  >
                    Terms & Conditions
                  </Typography>
                  <Typography
                    sx={fontSize}
                    className={classes.textColor}
                    onClick={() => handlePrivacyPolicy()}
                  >
                    Privacy Policy
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4} textAlign={"center"}
          >
            <Box sx={{ color: "white", }}>
              <Typography
                style={{ color: "#006D82", }}
                sx={fontSize1}
              >
                <b>Follow Us</b>
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", }}>
                <Box className={classes.textColor}>
                  <a
                    href="https://www.facebook.com/people/Nobiy/100095511012218/"
                    target="_blank"
                    style={{ textDecoration: "none", color: "#ffff" }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: { xs: "25px", sm: "30px", },
                        // marginLeft: "-2.5%",
                      }}
                      alt="CartImage"
                      src="./facebook.png"
                    />
                  </a>
                </Box>
                <Box className={classes.textColor} sx={{ ml: 2 }}>
                  <a
                    href="https://www.instagram.com/NoBiY_life/"
                    target="_blank"
                    style={{ textDecoration: "none", color: "#ffff" }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: { xs: "25px", sm: "30px", },
                        // marginLeft: "-2.5%",
                      }}
                      alt="CartImage"
                      src="./instagram.png"
                    />
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Footer;
