import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

const SizeChartPage = ({ opensizepage, setOpenSizepage, cat }) => {
  const handleClose = () => {
    setOpenSizepage(false);
  };

  const topSizeData = [
    { size: "XS", chest: "36.0", frontLength: "27.0", acrossShoulder: "16.0" },
    { size: "S", chest: "38.0", frontLength: "27.5", acrossShoulder: "16.3" },
    { size: "M", chest: "40.0", frontLength: "28.0", acrossShoulder: "17.0" },
    { size: "L", chest: "42.0", frontLength: "28.5", acrossShoulder: "17.8" },
    { size: "XL", chest: "44.5", frontLength: "29.0", acrossShoulder: "18.6" },
  ];

  const bottomSizeData = [
    {
      size: "28",
      waist: "28.0",
      insameLength: "34.0",
      rise: "16.3",
      Hips: "36.5",
    },
    {
      size: "30",
      waist: "30.0",
      insameLength: "34.0",
      rise: "16.3",
      Hips: "38.5",
    },
    {
      size: "32",
      waist: "32.0",
      insameLength: "34.0",
      rise: "16.3",
      Hips: "40.0",
    },
    {
      size: "34",
      waist: "34.0",
      insameLength: "34.0",
      rise: "16.3",
      Hips: "42.0",
    },
    {
      size: "36",
      waist: "36.0",
      insameLength: "34.0",
      rise: "16.3",
      Hips: "44.0",
    },
    {
      size: "38",
      waist: "38.0",
      insameLength: "34.0",
      rise: "16.3",
      Hips: "46.0",
    },
    {
      size: "40",
      waist: "40.0",
      insameLength: "34.0",
      rise: "16.3",
      Hips: "48.0",
    },
  ];

  const sizeData = cat === "Top" ? topSizeData : bottomSizeData;

  return (
    <>
      <div>
        <Dialog
          open={opensizepage}
          onClose={handleClose}
          PaperProps={{
            style: { borderRadius: 20, backgroundColor: "#1E1E1E" },
          }}
        >
          <DialogContent
            sx={{
              fontFamily: "Raleway",
              backgroundImage: "url(sizechartpage.png)",
              backgroundColor: "rgba(0, 0, 0, 1)",
              // width: "36rem",
            }}
          >
          <Box sx={{display:"flex",justifyContent:"space-between"}}>
            <DialogTitle
              sx={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: "20px",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              Size Chart
            </DialogTitle>
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <CloseIcon style={{ color: "white", fontSize: 27 }} />
            </IconButton>
            </Box>
            <DialogContentText>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {cat === "Top" ? (
                        <>
                          <TableCell
                            sx={{
                              color: "rgba(255, 255, 255, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Size
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "rgba(255, 255, 255, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Chest (in)
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "rgba(255, 255, 255, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Front Length (in)
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "rgba(255, 255, 255, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Across Shoulder (in)
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell
                            sx={{
                              color: "rgba(255, 255, 255, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Size
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "rgba(255, 255, 255, 1)",
                              fontSize: "15px",
                            }}
                          >
                            To Fit Waist (in)
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "rgba(255, 255, 255, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Inseam Length (in)
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "rgba(255, 255, 255, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Rise (in)
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "rgba(255, 255, 255, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Hips (in)
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sizeData.map((row) => (
                      <TableRow key={row.size}>
                        {cat === "Top" ? (
                          <>
                            <TableCell
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "15px",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {row.size}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "15px",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {row.chest}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "15px",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {row.frontLength}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "15px",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {row.acrossShoulder}
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "15px",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {row.waist}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "15px",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {row.waist}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "15px",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {row.insameLength}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "15px",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {row.rise}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "15px",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {row.Hips}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default SizeChartPage;
