import { useMutation } from "@apollo/client";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setCart,
  setProductVarientId,
  setSelectedSize,
  setWishList,
  setcreateCardValue,
  setproductVariantSize,
  setzoomDetails
} from "../../Redux/CartSlice";
import { setIsFavorite } from "../../Redux/UserSlice";
import { addWishlist, deleteWishListByIds } from "../../api/Api";
import { CREATE_CART_MUTATION } from "../../api/Mutations";
import {
  failureToastMessage,
  successToastMessage,
  warningToastMessage,
} from "../../common/Utils";
import LoginPage from "../Login/LoginPage";
import NewPassword from "../Singup/NewPassword";
import SingUp from "../Singup/SingUp";


const CommonCard = ({
  props,
  imgUrl,
  imgUrlTwo,
  discription,
  priceRange,
  titile,
  marqueeCard,
  wishListCard,
  productId,
  productVariantPrice,
  productVariantCompareAtPrice,
  productVariantId,
  zoomScreenDetails,
  productVariants,
  availableForSale,
  page,
  catTitle,
}) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state?.cart?.cart);
  const [expand, setExpand] = useState(false);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const isAuth = useSelector((state) => state?.user?.isAuth);
  const isFavorite = useSelector((state) => state?.user?.isFavorite);
  const wishList = useSelector((state) => state?.cart?.wishList);
  const [isSecondImageVisible, setIsSecondImageVisible] = useState(false);
  const [buttonText, setButtonText] = useState("Add to My Bag");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedproductVarientId, setSelectedproductVarientId] = useState("");
  const [selectedTitle, setSelectedTitile] = useState("");
  const [showvarient, setShowvarient] = useState();
  const loaction = useLocation();
  const [loading, setLoading] = useState(false);
  const [openLog, setOpenLog] = useState(false);
  const [openForgotPw, setOpenForgotPw] = useState(false);
  const [openSingUp, setOpenSingUp] = useState(false);
  const [showNewPass, setshowNewPass] = useState(false);
  const [checkoutNavigate, setCheckoutNavigate] = useState(false);

  let cartDetail = {
    id: props?.id,
    quantity: 1,
    price: productVariantPrice,
    image: imgUrl,
    title: titile,
    description: discription,
    merchandiseId: productVariantId,
    quantityAvailable: productVariants,
    shippingCharges: productVariants,
    catTitle: catTitle,
    cartItemDetails: props,
    remove: "add",
    page: "wishlist",
  };

  const handleMouseEnter = () => {
    setIsSecondImageVisible(true);
  };

  const handleMouseLeave = () => {
    setIsSecondImageVisible(false);
  };

  const handleClick = (e) => {
    if (isAuth == true) {
      setLoading(true);
      const addFav = {
        customerIdShopify: userDetails?.id,
        productVariantId: props?.id,
        createdAt: "2023-07-02T10:52:24.605Z",
        updatedAt: "2023-07-02T10:52:24.605Z",
      };

      addWishlist(addFav).then((res) => {
        if (res.responseCode == 201) {
          setLoading(false);
          dispatch(setWishList([...wishList, props?.id]));
          successToastMessage(`${titile} Added  in Wishlist`);
          if (isFavorite) {
            dispatch(setIsFavorite(false));
          } else {
            dispatch(setIsFavorite(true));
          }
        } else if (res.responseCode == 400) {
          deleteWishListByIds(props?.id, userDetails?.id).then((res) => {
            setLoading(false);
            if (res.responseCode == 204) {
              if (isFavorite) {
                dispatch(setIsFavorite(false));
              } else {
                dispatch(setIsFavorite(true));
              }
              failureToastMessage(`${titile} Removed  from Wishlist`);

              let index = wishList?.findIndex((e) => e == props?.id);
              let favlist = [...wishList];
              favlist.splice(index, 1);
              dispatch(setWishList(favlist));
            }
          });
        }
      });
    } else {
      setOpenLog(true);
    }
  };

  useEffect(() => {
    setShowvarient(props?.variants);
  }, [showvarient]);

  const handlezoomCard = (e) => {
    let zoomdetaislpros = { ...props };
    zoomdetaislpros.catTitle = catTitle;
    zoomdetaislpros.merchandiseId = selectedproductVarientId;
    zoomdetaislpros.productVarientIndex = selectedIndex;
    dispatch(setzoomDetails(zoomdetaislpros));
    dispatch(setProductVarientId(zoomdetaislpros.variants.nodes[0].id));
    dispatch(
      setSelectedSize(
        String(zoomdetaislpros.variants.nodes[0].title).split("/")[0]
      )
    );
    const idWithoutPrefix = props?.id.replace("gid://shopify/Product/", "");
    const encodedTitle = encodeURIComponent(titile);
    const styleNo = props?.Style_code?.value;
    let path = `/itemDetail/${encodedTitle}`;
    let search = `?itemId=${idWithoutPrefix}&style=${styleNo}&category=${catTitle}&productVarient=${selectedIndex}`;
    navigate({
      pathname: path,
      search: search,
      state: { wishlistCard: page },
    });
    // navigate(`/itemDetail/${titleWithoutPrefix}/${encodedTitle}`, { state: { wishlistCard: page } });
    e.stopPropagation();
  };

  const [createCart, { data }] = useMutation(CREATE_CART_MUTATION);

  const handleCreateCart = async (cart) => {
    const lineItems = cart.map(({ quantity, merchandiseId }) => ({
      quantity,
      merchandiseId,
    }));

    try {
      const response = await createCart({
        variables: {
          cartInput: {
            lines: lineItems,
          },
        },
      });
      dispatch(setproductVariantSize(productVariants));

      dispatch(setcreateCardValue(response.data.cartCreate.cart));
    } catch (error) {
      console.error(error);
    }
  };

  const idWithoutPrefix = props?.id.replace("gid://shopify/Product/", "");
  const encodedTitle = encodeURIComponent(titile);
  const styleNo = props?.Style_code?.value;
  let path = `/itemDetail/${encodedTitle}`;
  let search = `?itemId=${idWithoutPrefix}&style=${styleNo}&category=${catTitle}&productVarient=${selectedIndex}`;
  let dynamicURL = `${path}${search}`;

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <>
        <Box
          sx={{
            background:
              "white",
            borderRadius: "1.2rem",
            boxShadow: "0 0.5px 4px rgba(0, 0, 0, 0.1)",
            border: "1.3px solid #E6E6E6",
          }}
        >
          <Box >
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    textAlign: "center",
                    // boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.5)",
                    marginBottom: "0.5rem",
                    position: "relative",
                    backgroundColor: "",
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  // style={{ display: "flex", justifyContent: "center" }}
                >
                  <a href={dynamicURL}>
                    <Box
                      onClick={handlezoomCard}
                      component="img"
                      sx={{
                        objectFit: "contain",
                        height: {
                          lg: "26rem",
                          md: "24rem",
                          sm: "20rem",
                          xs: "14rem",
                        },
                        cursor: "pointer",
                        opacity: isSecondImageVisible ? 0 : 1,
                        transition: "opacity 0.3s ease",
                      }}
                      alt="CatImage"
                      src={imgUrl}
                    />
                  </a>
                  <a href={dynamicURL}>
                    <Box
                      onClick={handlezoomCard}
                      component="img"
                      sx={{
                        // objectFit: "contain",
                        height: {
                          lg: "26rem",
                          md: "24rem",
                          sm: "20rem",
                          xs: "15rem",
                        },
                        cursor: "pointer",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        opacity: isSecondImageVisible ? 1 : 0,
                        transition: "opacity 0.3s ease",
                      }}
                      alt="CatImage2"
                      src={imgUrlTwo}
                    />
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: { lg: "0 1rem", xs: "0 0" },
              }}
            >
              <Typography
                sx={{
                  color: "#107081",
                  marginTop: "0.5rem",
                  marginLeft: { lg: 0, xs: "0.3rem" },
                  fontSize: { lg: 16, sm: 14, xs: 13.5 },
                  fontStyle: "bold",
                }}
              >
                <b>{titile}</b>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: { lg: "0 1rem", xs: "0 .5rem" },
              }}
            >
              <Typography
                variant="subtitle"
                sx={{
                  color: "#006D82",
                  marginBottom: "1rem",
                  fontSize: { lg: 16, sm: 14, xs: 13 },
                }}
              >
                <b>₹{parseInt(productVariantCompareAtPrice, 10)}</b>
              </Typography>
              {productVariantCompareAtPrice && (
                <Typography
                  variant="subtitle"
                  sx={{
                    color: "#808080",
                    marginRight: { lg: "9.5rem", sm: "15rem", xs: "5rem" },
                    textDecoration: "line-through",
                    ml:"1rem",
                    marginBottom: "1rem",
                    fontSize: { lg: 16, sm: 14, xs: 13 },
                  }}
                >
                  ₹{Math.round(Number(productVariantPrice))}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                cursor: "pointer",
                float: "right",
                marginTop: { lg: "-2.3rem", xs: "-2rem" },
                marginRight: "5%",
                color: wishList?.find((e) => e == props?.id)
                  ? "#006D82"
                  : "#CCCCCC",
              }}
              onClick={(e) => handleClick(e)}
            >
              <FavoriteIcon fontSize="1rem" sx={{}} />
            </Box>
            <Box
              sx={{
                marginTop: { lg: "-0.3rem", xs: "-0.5rem" },
                ml: { lg: "1.2rem", xs: "1rem" },
                mb: ".5rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: 8,
                  pl: 1,
                  pr: 1,
                  pt: 0.3,
                  pb: 0.2,
                  display: "inline",
                  backgroundColor: "#006D82",
                  color: "white",
                }}
              >
                20% OFF{" "}
              </Typography>
            </Box>
          </Grid>
        </Box>
        <LoginPage
          // checkoutNavigate={checkoutNavigate}
          setCheckoutNavigate={setCheckoutNavigate}
          openLog={openLog}
          setOpenLog={setOpenLog}
          openForgotPw={openForgotPw}
          setOpenForgotPw={setOpenForgotPw}
          openSingUp={openSingUp}
          setOpenSingUp={setOpenSingUp}
          showNewPass={showNewPass}
          setshowNewPass={setshowNewPass}
        />
        {openSingUp && (
          <SingUp
            openLog={openLog}
            setOpenLog={setOpenLog}
            openForgotPw={openForgotPw}
            setOpenForgotPw={setOpenForgotPw}
            openSingUp={openSingUp}
            setOpenSingUp={setOpenSingUp}
          />
        )}

        {showNewPass && (
          <NewPassword
            openLog={openLog}
            setOpenLog={setOpenLog}
            openForgotPw={openForgotPw}
            setOpenForgotPw={setOpenForgotPw}
            openSingUp={openSingUp}
            setOpenSingUp={setOpenSingUp}
            showNewPass={showNewPass}
            setshowNewPass={setshowNewPass}
          />
        )}
      </>
    </>
  );
};

export default CommonCard;
