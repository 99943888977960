import { getWrapper, postWrapper, putWrapper } from "./Wrapper";

export const order = (data) => {
  let url = `/order/order`;
  return postWrapper(url, data);
};

export const verifyOtp = (data) => {
  let url = `/common/validateOtp`;
  return postWrapper(url, data);
};

export const resendOtp = (data) => {
  let url = `/common/sendOtp`;
  return postWrapper(data);
};

export const Register = (data) => {
  let url = `/customers/addCustomer`;
  return postWrapper(url, data);
};

export const Log_in = (data) => {
  let url = `/login/login`;
  return postWrapper(url, data);
};

export const SocialLogin = (data) => {
  let url = `/login/socialLogin`;
  return postWrapper(url, data);
};

export const SendEmailOtp = (data) => {
  let url = `/login/forgetPasswordEmailOtp?email=${data}`;
  return postWrapper(url, data);
};

export const cancelOrder = (data) => {
  let url = `/order/cancelOrder?orderId=${data}`;
  return postWrapper(url, data);
};

export const SendMobileOtp = (data) => {
  let url = `/common/sendOtp`;
  return postWrapper(url, data);
};

export const ValidateMobileOtp = (data) => {
  let url = `/common/validateOtp`;
  return postWrapper(url, data);
};

export const changePassword = (data) => {
  let url = `/login/changePassword`;
  return putWrapper(url, data);
};

export const addWishlist = (data) => {
  let url = `/wishlist/addWishlist`;
  return postWrapper(url, data);
};

export const deleteWishListByIds = (varientId, userId) => {
  let url = `/wishlist/deleteWishListByIds?id=${varientId}&customerIdShopify=${userId}`;
  return putWrapper(url);
};

export const findAllWishlistByCustomerId = (data) => {
  let url = `/wishlist/findAllWishlistByCustomerId?customerIdShopify=${data}`;
  return getWrapper(url, data);
};
export const findAllDiscountCoupan = () => {
  let url = `discount/getAllPriceRules`;
  return getWrapper(url);
};

export const createOrder = (data) => {
  let url = `/instaMojo/createOrder`;
  return postWrapper(url, data);
};

export const updateOrder = (data) => {
  let url = `instaMojo/updateOrder`;
  return postWrapper(url, data);
};

// added by sobhit
export const getOrderByOrderId = (data) => {
  let url = `/order/getOrderById?orderId=${data}`;
  return getWrapper(url, data);
};

export const returnRequest = (data) => {
  let url = `/return/requestReturn`;
  return postWrapper(url, data);
};
export const sendSms = (data) => {
  let url = `/order/sendSmsForOrder`;
  return postWrapper(url, data);
};
export const getAllOrder = (data) => {
  let url = `/order/getAllOrderByCustomerToken?customerToken=${data}`;
  return getWrapper(url, data);
};

export const getCustomerDetails = (data) => {
  let url = `/order/getCustomerByToken?customerToken=${data}`;
  return getWrapper(url, data);
};

export const sendMailTemplates = (data) => {
  let url = `mail/mailSender`;
  return postWrapper(url, data);
};
export const getTrakingDetails = (data) => {
  let url = `xpress/findByAwbId?id=${data}`;
  return getWrapper(url, data);
};
export const getFullfilmentItemId = (data) => {
  let url = `return/returnableFulfillment`;
  return postWrapper(url, data);
};
export const saveReturnId = (data) => {
  let url = `/wishlist/addReturn`;
  return postWrapper(url, data);
};

export const getReturnId = (customerToken, orderId, varientId) => {
  let url = `/wishlist/findAllReturnIdsByCustomerIdShopify?customerIdShopify=${customerToken}&orderId=${orderId}&productVariant=${varientId}`;
  return getWrapper(url);
};

export const getReturnStatus = (data) => {
  let url = `return/returnStatus?returnId=${data}`;
  return postWrapper(url, data);
};

export const savePaymentRefId = (data) => {
  let url = `/order/createCartMetaData`;
  return postWrapper(url, data);
};

//Phone pay payment
export const createOnlineOrder = (data) => {
  let url = `/phonePay/createOrderPhonePay`;
  return postWrapper(url, data);
};

export const getPaymentDetails = (data) => {
  let url = `/phonePay/getPaymentDetails?merchantTransactionId=${data}`;
  return getWrapper(url, data);
};
export const createTrackNumber = async (channelId, orderReferences) => {
  const url = `/return/browntapeOrdersReturns`;
  const data = {
    channelId: channelId,
    orderReferences: orderReferences,
  };

  return postWrapper(url, data);
};
