import { gql } from "@apollo/client";
import SocialLogin from "react-social-login";

export const CREATE_USER_MUTATION = gql`
  mutation RegisterAccount(
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
  ) {
    customerCreate(
      input: {
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
      }
    ) {
      customer {
        id
        email
        phone
      }
      customerUserErrors {
        code
        message
      }
    }
  }
`;

export const CREATE_USER_MUTATION_SocialLogin = gql`
  mutation RegisterAccount(
    $email: String!
    $password: String!
    $firstName: String!
  ) {
    customerCreate(
      input: { email: $email, password: $password, firstName: $firstName }
    ) {
      customer {
        id
        email
      }
      customerUserErrors {
        code
        message
      }
    }
  }
`;
export const LoginUserMutation = gql`
  mutation SignInWithEmailAndPassword($email: String!, $password: String!) {
    customerAccessTokenCreate(input: { email: $email, password: $password }) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const customerAccessTokenCreateMutation = gql`
  mutation customerAccessTokenCreate($email: String!, $password: String!) {
    customerAccessTokenCreate(input: { email: $email, password: $password }) {
      customerAccessToken {
        accessToken
      }
      customerUserErrors {
        message
      }
    }
  }
`;

export const updateCustomerInfoMutation = gql`
  mutation UpdateCustomerInfo(
    $customerAccessToken: String!
    $password: String
  ) {
    customerUpdate(
      customerAccessToken: $customerAccessToken
      customer: { password: $password }
    ) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
      }
      customerUserErrors {
        code
        message
      }
      userErrors {
        message
      }
    }
  }
`;

export const updateCustomerInfoDetails = gql`
  mutation UpdateCustomerInfo(
    $customerAccessToken: String!
    $email: String
    $firstName: String
    $lastName: String
    $password: String
  ) {
    customerUpdate(
      customerAccessToken: $customerAccessToken
      customer: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        password: $password
      }
    ) {
      customer {
        id
        firstName
        lastName
        email
      }
      customerUserErrors {
        code
        message
      }
      userErrors {
        message
      }
    }
  }
`;

export const createAddress = gql`
  mutation CreateAddress(
    $customerAccessToken: String!
    $firstName: String!
    $phone: String!
    $address1: String!
    $address2: String!
    $country: String!
    $province: String!
    $city: String!
    $zip: String!
  ) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: {
        firstName: $firstName
        phone: $phone
        address1: $address1
        address2: $address2
        country: $country
        province: $province
        city: $city
        zip: $zip
      }
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        message
      }
    }
  }
`;

export const defaultAddress = gql`
  mutation SetDefaultAddress($customerAccessToken: String!, $addressId: ID!) {
    customerDefaultAddressUpdate(
      customerAccessToken: $customerAccessToken
      addressId: $addressId
    ) {
      customer {
        defaultAddress {
          id
          firstName
          phone
          address1
          address2
          province
          city
          country
          zip
          firstName
        }
      }
      customerUserErrors {
        code
        message
      }
    }
  }
`;

export const updateAddress = gql`
  mutation UpdateAddress(
    $addressId: ID!
    $customerAccessToken: String!
    $firstName: String!
    $phone: String!
    $address1: String!
    $address2: String!
    $country: String!
    $province: String!
    $city: String!
    $zip: String!
  ) {
    customerAddressUpdate(
      id: $addressId
      customerAccessToken: $customerAccessToken
      address: {
        firstName: $firstName
        phone: $phone
        address1: $address1
        address2: $address2
        country: $country
        province: $province
        city: $city
        zip: $zip
      }
    ) {
      customerAddress {
        id
        firstName
        phone
        address1
        address2
        province
        city
        country
        zip
      }
      customerUserErrors {
        code
        message
      }
      userErrors {
        message
      }
    }
  }
`;

export const deleteAddress = gql`
  mutation DeleteAddress($customerAccessToken: String!, $addressId: ID!) {
    customerAddressDelete(
      customerAccessToken: $customerAccessToken
      id: $addressId
    ) {
      deletedCustomerAddressId
      customerUserErrors {
        code
        message
      }
      userErrors {
        message
      }
    }
  }
`;

export const CREATE_CART_MUTATION = gql`
  mutation createCart($cartInput: CartInput) {
    cartCreate(input: $cartInput) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          edges {
            node {
              id
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  image {
                    url
                  }
                }
              }
            }
          }
        }
        attributes {
          key
          value
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;


export const UPDATE_CART_MUTATION = gql`
  mutation UpdateCart($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
        lines(first: 50) {
          edges {
            node {
              id
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  priceV2 {
                    amount
                    currencyCode
                  }
                  image {
                    url
                  }
                  product {
                    title
                  }
                }
              }
              quantity
            }
          }
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;

export const checkoutCreateMutation = gql`
  mutation CheckoutCreate(
    $lineItems: [CheckoutLineItemInput!]!
    $email: String!
    $shippingAddress: MailingAddressInput!
  ) {
    checkoutCreate(
      input: {
        lineItems: $lineItems
        email: $email
        shippingAddress: $shippingAddress
      }
    ) {
      checkout {
        id
        webUrl
        shippingAddress {
          firstName
          lastName
          address1
          city
          province
          country
          zip
        }
        totalPriceV2 {
          amount
          currencyCode
        }
        totalTaxV2 {
          amount
          currencyCode
        }
        availableShippingRates {
          ready
          shippingRates {
            handle
            priceV2 {
              amount
              currencyCode
            }
          }
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

// api for updated checkout for shipping charge
export const checkoutUpdateMutation = gql`
  mutation checkoutShippingAddressUpdateV2(
    $shippingAddress: MailingAddressInput!
    $checkoutId: ID!
  ) {
    checkoutShippingAddressUpdateV2(
      shippingAddress: $shippingAddress
      checkoutId: $checkoutId
    ) {
      checkoutUserErrors {
        code
        field
        message
      }
      checkout {
        id
        shippingAddress {
          firstName
          lastName
          address1
          province
          country
          zip
        }
        availableShippingRates {
          ready
          shippingRates {
            handle
            title
            price {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;
// Discount api for coupan code based on card id
export const discountCoupanMutation = gql`
  mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        id
        discountCodes {
          applicable
          code
        }
        discountAllocations {
          discountedAmount {
            amount
            currencyCode
          }
        }
        lines(first: 10) {
          nodes {
            cost {
              totalAmount {
                amount
              }
            }
          }
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
        }
      }
      userErrors {
        field
        message
      }
    }
  }
`;
export const Coupan_Discount_CheckOut_Id = gql`
  mutation checkoutDiscountCodeApplyV2(
    $checkoutId: ID!
    $discountCode: String!
  ) {
    checkoutDiscountCodeApplyV2(
      checkoutId: $checkoutId
      discountCode: $discountCode
    ) {
      checkout {
        discountApplications(first: 10) {
          nodes {
            targetType
            targetSelection
            allocationMethod
            value {
              ... on MoneyV2 {
                __typename
                amount
                currencyCode
              }
              ... on PricingPercentageValue {
                __typename
                percentage
              }
            }
            ... on DiscountCodeApplication {
              __typename
              allocationMethod
              applicable
              code
              targetSelection
              targetType
            }
          }
        }

        totalPrice {
          amount
          currencyCode
        }
        totalTax {
          amount
          currencyCode
        }
        totalDuties {
          amount
          currencyCode
        }
        subtotalPrice {
          amount
          currencyCode
        }
        lineItems(first: 10) {
          nodes {
            id
            quantity
            title
            unitPrice {
              amount
              currencyCode
            }
            variant {
              title
              price {
                amount
                currencyCode
              }
              id
              availableForSale
            }
          }
        }
        availableShippingRates {
          ready
          shippingRates {
            handle
            priceV2 {
              amount
              currencyCode
            }
          }
        }
        requiresShipping
      }
    }
  }
`;

//Remove coupaCode api
export const Remove_Coupan_Code = gql`
  mutation ($checkoutId: ID!) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        subtotalPrice {
          amount
          currencyCode
        }
        totalDuties {
          amount
          currencyCode
        }
        totalPrice {
          amount
          currencyCode
        }
        totalTax {
          amount
          currencyCode
        }

        lineItems(first: 10) {
          nodes {
            title
            id
            discountAllocations {
              discountApplication {
                allocationMethod
                targetSelection
                targetType
                value {
                  ... on MoneyV2 {
                    __typename
                    amount
                    currencyCode
                  }
                }
              }
            }
            quantity
            unitPrice {
              amount
            }
            variant {
              title
              price {
                amount
                currencyCode
              }
              availableForSale
            }
          }
        }
      }
    }
  }
`;
