import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
  knitSeries: "",
  denimSeries: "",
  wovenSeries: "",
  outwearSeries: "",
  getAllProducts: [],
  allProductcardValue: [],
  wishList: [],
  commonWishList: [],
  zoomDetails: {},
  createCardValue: {},
  productVariantSize: [],
  yourBagCartValue: [],
  selectProductSize: "",
  selectProductQuantity: "",
  selectCoupanCode: "",
  checkoutDetailes: {},
  checkoutVariants: {},
  discountdata: {},
  shippingValue: {},
  quantity: 1,
  totalPrice: 0,
  totalPriceWithoutRound: 0,
  serachValue: "",
  productVarientId: "",
  selectedSize: "",
  searchProductValue: [],
  allProducts: [],
  coupantextValue: false,
  requestId: "123",
  phonpayOrderId: "",
  DetailsSizeChanage:"",
  DetailsQuantityChange:""
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setUpdateCart: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.cart = payload;
    },
    setCart: (state, { payload }) => {
      if (payload == null) {
        state.cart = initialState.cart;
      } else {
        payload.forEach((product) => {
          const existingProductIndex = state.cart.findIndex(
            (item) => item.merchandiseId === product.merchandiseId
          );
          if (existingProductIndex !== -1 && product.remove == "remove") {
            state.cart.splice(existingProductIndex, 1);
          } else if (existingProductIndex !== -1) {
            //   state.cart[existingProductIndex].quantity += product.quantity;
          } else {
            state.cart.push(product);
          }
        });
      }
    },
    setWishList: (state, { payload }) => {
      if (payload.length == 0) {
        state.wishList = initialState.wishList;
      } else {
        state.wishList = payload;
      }
    },
    setCheckoutDetailes: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.checkoutDetailes = payload;
    },
    setCheckoutVariants: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.checkoutVariants = payload;
    },
    setshippingValue: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.shippingValue = payload;
    },
    setDiscountData: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.discountdata = payload;
    },
    setQuantity: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.quantity = payload;
    },
    setTotalPrice: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.totalPrice = payload;
    },
    setTotalPriceWithoutRound: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.totalPriceWithoutRound = payload;
    },

    setCommonWishList: (state, { payload }) => {
      if (payload.length == 0) {
        state.wishList = initialState.wishList;
      } else {
        state.commonWishList = payload;
      }
    },
    setknitSeries: (state, { payload }) => {
      if (payload.length == 0) {
        state.knitSeries = initialState;
      } else {
        state.knitSeries = payload;
      }
      //  state.knitSeries = payload
    },
    setdenimSeries: (state, { payload }) => {
      state.denimSeries = payload;
    },
    setwovenSeries: (state, { payload }) => {
      state.wovenSeries = payload;
    },
    setoutwearSeries: (state, { payload }) => {
      state.outwearSeries = payload;
    },
    setGetAllProducts: (state, { payload }) => {
      state.getAllProducts = payload;
    },
    setallProductcardValue: (state, { payload }) => {
      if (payload.length == 0) {
        state.allProductcardValue = initialState;
      } else {
        state.allProductcardValue = payload;
      }
    },
    setzoomDetails: (state, { payload }) => {
      state.zoomDetails = payload;
    },

    setcreateCardValue: (state, { payload }) => {
      state.createCardValue = payload;
    },
    setproductVariantSize: (state, { payload }) => {
      state.productVariantSize = payload;
    },
    removeFronmCart: (state, action) => {
      const removeItem = state.cart.filter(
        (item) => item.id !== action.payload.id
      );
      state.cart = removeItem;
    },
    setyourBagCartValue: (state, { payload }) => {
      state.yourBagCartValue = payload;
    },
    setselectProductSize: (state, { payload }) => {
      state.selectProductSize = payload;
    },
    setselectProductQuantity: (state, { payload }) => {
      state.selectProductQuantity = payload;
    },
    setselectCoupanCode: (state, { payload }) => {
      state.selectCoupanCode = payload;
    },

    setcoupantextValue: (state, { payload }) => {
      state.coupantextValue = payload;
    },
    setserachValue: (state, { payload }) => {
      state.serachValue = payload;
    },
    setProductVarientId: (state, { payload }) => {
      state.productVarientId = payload;
    },
    setSelectedSize: (state, { payload }) => {
      state.selectedSize = payload;
    },
    setsearchProductValue: (state, { payload }) => {
      state.searchProductValue = payload;
    },
    setAllProducts: (state, { payload }) => {
      state.allProducts = payload;
    },
    setRequestId: (state, { payload }) => {
      state.requestId = payload;
    },
    setPhonpayOrderId: (state, { payload }) => {
      state.phonpayOrderId = payload;
    },
    setDetailsSizeChanage:(state,{payload})=>{
      state.DetailsSizeChanage=payload
    },
    setDetailsQuantityChange:(state,{payload})=>{
      state.DetailsQuantityChange=payload
    }
  },
});

export const {
  setRequestId,
  setPhonpayOrderId,
  setGetAllProducts,
  setcoupantextValue,
  setTotalPrice,
  setTotalPriceWithoutRound,
  setUpdateCart,
  setCart,
  setQuantity,
  setWishList,
  setCheckoutDetailes,
  setCheckoutVariants,
  setshippingValue,
  setDiscountData,
  setknitSeries,
  setdenimSeries,
  setwovenSeries,
  setCommonWishList,
  setoutwearSeries,
  setallProductcardValue,
  setzoomDetails,
  setcreateCardValue,
  setproductVariantSize,
  removeFronmCart,
  setyourBagCartValue,
  setselectProductSize,
  setselectProductQuantity,
  setselectCoupanCode,
  setserachValue,
  setProductVarientId,
  setSelectedSize,
  setAllProducts,
  setsearchProductValue,
  setDetailsSizeChanage,
  setDetailsQuantityChange
} = cartSlice.actions;

export default cartSlice.reducer;
