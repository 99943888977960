import { useMutation } from "@apollo/client";
import { ErrorMessage } from "@hookform/error-message";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Zoom from "@mui/material/Zoom";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { changePassword } from "../../api/Api";
import {
  customerAccessTokenCreateMutation,
  updateCustomerInfoMutation,
} from "../../api/Mutations";
import {
  ErrorText,
  failureToastMessage,
  successToastMessage,
} from "../../common/Utils";

const useStyles = makeStyles((theme) => ({
  paper: { borderRadius: 15 },
  errorP: {
    color: "#bf1650",
    marginLeft: "11.8rem",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});
const NewPassword = (props) => {
  const { showNewPass, setshowNewPass, setshowPopup, setTitle, setOpenLog } =
    props;
  const emailValue = useSelector((state) => state?.user?.emailValue);
  const emailMobile = useSelector((state) => state?.user?.emailMobile);
  const isPhone = useSelector((state) => state?.user?.isPhone);
  const isEmail = useSelector((state) => state?.user?.isEmail);
  const emaiValueIncrep = window.btoa(emailValue);
  const mobileValueIncrep = window.btoa(emailMobile);
  const defaultValues = {
    email: isPhone == true ? mobileValueIncrep : emaiValueIncrep,
    isOldPasswordRequired: true,
    newPassword: "",
    // oldPassword: "",
    // customerId: "",
  };
  const { control, handleSubmit, formState, register } = useForm({
    defaultValues,
  });
  const { errors } = formState;
  const classes = useStyles();
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passValue, setPassValue] = useState("");
  const [accessToken, setAccessToken] = useState();
  const [passwordValue, setPasswordValue] = useState();
  const [loadingg, setLoading] = useState(false);

  const [changepasswordToken, { error }] = useMutation(
    customerAccessTokenCreateMutation
  );
  const [changepasswordMutation] = useMutation(updateCustomerInfoMutation);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [valuesCN, setValuesCN] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPasswordCN = () => {
    setValuesCN({ ...valuesCN, showPassword: !valuesCN.showPassword });
  };

  const handlePasswordChangeCN = (prop) => (event) => {
    setValuesCN({ ...valuesCN, [prop]: event.target.value });
  };
  const onSubmit = (data) => {
    if (valuesCN.password == values.password) {
      setLoading(true);
      changePassword(data)
        .then((res) => {
          if (res.responseCode == 200) {
            setLoading(false);
            const decryptedPassword = window.atob(res.data.oldPassword);
            changepasswordToken({
              variables: {
                email: res.data.email,
                password: decryptedPassword,
              },
            }).then((res) => {
              setAccessToken(
                res?.data?.customerAccessTokenCreate?.customerAccessToken
                  ?.accessToken
              );
              changepasswordMutation({
                variables: {
                  customerAccessToken:
                    res?.data?.customerAccessTokenCreate?.customerAccessToken
                      ?.accessToken,
                  password: data?.newPassword,
                },
              });
            });

            successToastMessage("Password Change Successfully");
            setshowNewPass(false);
            setOpenLog(true);
          } else {
            failureToastMessage(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      data.newPassword = values.password;
      delete data.confirmPassword;
    } else {
      failureToastMessage("Confirm Password Not match");
    }
  };

  const handleClose = () => {
    setshowNewPass(false);
  };
  useEffect(() => {
    setPassValue(values.password);
  });

  return (
    <>
      <Dialog
        open={showNewPass}
        classes={{
          paper: classes.paper,
        }}
        TransitionComponent={Transition}
      >
        <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loadingg}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box p={3} sx={{ bgcolor: "#18161D", color: "#FFFFFF" }}>
          <IconButton sx={{ float: "right" }}>
            <CloseIcon
              onClick={handleClose}
              style={{ color: "white", fontSize: 27 }}
            />
          </IconButton>
          <Typography sx={{ fontSize: 20 }}> Password Reset</Typography>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box
              display="flex"
              sx={{ mt: 8, flexWrap: { xs: "wrap", sm: "nowrap" } }}
            >
              <Typography
                style={{ width: "18rem" }}
                variant="subtitle1"
                gutterBottom
              >
                New Password :
              </Typography>
              <FormControl fullWidth>
                <Controller
                  name="newPassword"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ bgcolor: "#595A79", borderRadius: "5px" }}
                      id="newPassword"
                      size="small"
                      placeholder="Enter 8-16 digit password"
                      type={values.showPassword ? "text" : "password"}
                      onInput={handlePasswordChange("password")}
                      value={values.password}
                      onChange={(e) => {
                        setPasswordValue(e.target.value);
                      }}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        maxLength: 16,
                        minLength: 6,
                      }}
                      InputProps={{
                        sx: {
                          height: "30px",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}>
                              {values.showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      {...register("newPassword", {
                        required: "Password is mandatory",
                        minLength: {
                          value: 6,
                          message: "Minimum 6 character long.",
                        },
                        maxLength: {
                          value: 15,
                          message: "Maximum 15 character long.",
                        },
                      })}
                    />
                  )}
                  control={control}
                />
                <ErrorMessage
                  errors={errors}
                  name="newPassword"
                  render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
              </FormControl>
            </Box>
            <Box
              display="flex"
              sx={{
                mt: { xs: 7, sm: 5 },
                flexWrap: { xs: "wrap", sm: "nowrap" },
              }}
            >
              <Typography
                style={{ width: "18rem" }}
                variant="subtitle1"
                gutterBottom
              >
                Confirm Password :
              </Typography>
              <FormControl fullWidth>
                <Controller
                  name="confirmPassword"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ bgcolor: "#595A79", borderRadius: "5px" }}
                      id="confirmPassword"
                      size="small"
                      placeholder="Re-enter password"
                      type={valuesCN.showPassword ? "text" : "password"}
                      onInput={handlePasswordChangeCN("password")}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      value={valuesCN.password}
                      variant="outlined"
                      InputProps={{
                        sx: {
                          height: "30px",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPasswordCN}>
                              {valuesCN.showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        autocomplete: "new-password",
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      {...register("confirmPassword", {
                        required: {
                          value: true,
                          message: "Confirm Password is required",
                        },
                        validate: (value, passwordValue) => {
                          if (value != values.password) {
                            return "Confirm password do not match.";
                          }
                        },
                      })}
                    />
                  )}
                  control={control}
                />
                <ErrorMessage
                  errors={errors}
                  name="confirmPassword"
                  render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
              </FormControl>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  fontSize: 13,
                  borderRadius: "40px",
                  bgcolor: "#006D82",
                  textTransform: "none",
                }}
              >
                Save Change
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </>
  );
};

export default NewPassword;
