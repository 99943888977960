import { useMutation } from "@apollo/client";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCart,
  setProductVarientId,
  setQuantity,
  setWishList,
  setcreateCardValue,
  setzoomDetails,
} from "../../Redux/CartSlice";
import { setIsFavorite } from "../../Redux/UserSlice";
import {
  addWishlist,
  deleteWishListByIds,
  getCustomerDetails,
} from "../../api/Api";
import {
  CREATE_CART_MUTATION,
  UPDATE_CART_MUTATION,
} from "../../api/Mutations";
import {
  MenuProps,
  failureToastMessage,
  successToastMessage,
} from "../../common/Utils";

export const fontSizedUpperText = {
  fontSize: { xs: 15, sm: 13, md: 15, lg: 15 },
  // ml: { xs: 3, sm: 0, md: 0, lg: 0 },
  fontWeight: "bold",
};
export const fontSizeDownText = {
  fontSize: { xs: 10, sm: 11, md: 12, lg: 13 },
  ml: { xs: 3, sm: 0, md: 0, lg: 0 },
};

const CartItem = ({
  keyIndex,
  Detail,
  showproductSize,
  handleCartSizeQuantityChange,
}) => {
  const [customValue, setCustomValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [varientIndex, setVarientIndex] = useState("");
  const [availableQuantitys, setAvailableQuantitys] = useState("");
  const [avaqunatity, setAvaQuantity] = useState([]);
  const createCardValues = useSelector((state) => state?.cart?.createCardValue);
  const cartItems = useSelector((state) => state?.cart?.cart);
  const isAuth = useSelector((state) => state?.user?.isAuth);
  const userDetails = useSelector((state) => state?.user?.userDetails);
  showproductSize?.map((e) => e.title);
  const quantityCart = useSelector((state) => state?.cart?.quantity);
  const wishList = useSelector((state) => state?.cart?.wishList);
  const isFavorite = useSelector((state) => state?.user?.isFavorite);
  const commonWishList = useSelector((state) => state?.cart?.commonWishList);
  const [updateCartMutation] = useMutation(UPDATE_CART_MUTATION);
  const zoomDetailsCards = useSelector((state) => state?.cart?.zoomDetails);
  const [loading, setLoading] = useState(false);
  const detailsizechange= useSelector((state)=>state.cart.DetailsSizeChanage)
  const detailQuantitychange=useSelector((state)=>state.cart.DetailsQuantityChange)
  const dispatch = useDispatch();
  let cartDetail = {
    id: Detail?.id,
    merchandiseId: Detail.merchandiseId,
    quantity: Detail?.quantity,
    price: Detail?.price,
    image: Detail?.image,
    title: Detail?.title,
    description: Detail?.description,
    remove: "remove",
  };
  // age is = quantity and size represented
  const changeSize = Detail.merchandiseId;
  const [age, setAge] = useState(detailQuantitychange);
  const [size, setSize] = useState(Detail.merchandiseId);
  const [updateCartValeshow, setUpdateCartValueshow] = useState();

  const [createCart] = useMutation(CREATE_CART_MUTATION);
  useEffect(() => {
    setAvaQuantity([]);
    let AvailableQuantity = showproductSize?.filter(
      (v) => v.id === size && v.availableForSale === true
    );

    if (AvailableQuantity?.length > 0) {
      setAvailableQuantitys(AvailableQuantity[0].quantityAvailable);
      for (let i = 1; i <= AvailableQuantity[0].quantityAvailable; i++) {
        if (i <= 10) setAvaQuantity((avaqunatity) => [...avaqunatity, i]);
      }
    } else {
      console.log("selected variant not found");
    }
  }, [size]);

  const hadleRemove = async () => {
    setLoading(true);
    let item = [];
    item.push(cartDetail);
    dispatch(setCart(item));
    const ItemIndex = cartItems?.findIndex(
      (e) => e.merchandiseId == Detail.merchandiseId
    );
    let updateCartItems = [...cartItems];
    updateCartItems?.splice(ItemIndex, 1);
    const lineItems = updateCartItems?.map(({ quantity, merchandiseId }) => ({
      quantity,
      merchandiseId,
    }));
    successToastMessage("Removed from Your Bag");
    try {
      const response = await createCart({
        variables: {
          cartInput: {
            lines: lineItems,
          },
        },
      });
      dispatch(setcreateCardValue(response.data.cartCreate.cart));

      setTimeout(() => {
        setLoading(false);
      }, 3000);

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  let changeValueafterQuantity = Detail?.price * age;
  let ssiz =
    createCardValues?.lines?.edges[keyIndex]?.node?.merchandise?.priceV2
      ?.amount;

  let cartDetails = { ...Detail };
  cartDetails.quantity = age;
  cartDetails.merchandiseId = size;

  const handleUpdateCart = async (size, age) => {
    console.log(age,"ageValue")
    setLoading(true);
    try {
      const { data } = await updateCartMutation({
        variables: {
          cartId: createCardValues?.id,
          lines: [
            {
              id: createCardValues?.lines.edges[keyIndex]?.node.id,
              merchandiseId: size,
              quantity: age,
            },
          ],
        },
      });
      setUpdateCartValueshow(data);
      handleCartSizeQuantityChange(size, age, Detail.id);
      dispatch(setcreateCardValue(data?.cartLinesUpdate?.cart));
      dispatch(
        setQuantity(
          data?.cartLinesUpdate?.cart?.lines?.edges[0]?.node?.quantity
        )
      );
      setLoading(false);
    } catch (error) {
      console.error(error, "Error while calling mutation");
    }
  };
  
  useEffect(()=>{
    handleUpdateCart(size,detailQuantitychange)
  },[])

  const handleChange = (event) => {
    setAge(event.target.value);
    handleUpdateCart(size, event.target.value);
  };
  

  // const handleSizeChange = (size, index) => {
  //   handleUpdateCart(size, Detail.quantity);
  //   setSize(size);
  //   setVarientIndex(String(index.key).split("")[1]);
  //   dispatch(setProductVarientId(size));
  // };
  const handleSizeChange = (size, index) => {
    const isSizeAlreadySelected = cartItems.some((item) => item.merchandiseId === size);
   
    if (isSizeAlreadySelected) {
      hadleRemove();
      failureToastMessage("Already Size is Selected in Cart ")
    
    } else {
      handleUpdateCart(size, Detail.quantity);
      setSize(size);
      setVarientIndex(String(index.key).split("")[1])
      setVarientIndex(String(index.key).split('')[1]);
      dispatch(setProductVarientId(size));
    }
  }

  const accessToken = useSelector((state) => state?.user?.accessToken);
  const [defAddress, setDefAddress] = useState({});

  useEffect(() => {
    // const handleData = async () => {
    // const responseData = await client
    //   .query({
    //     query: addressDetails,
    //     variables: { customerAccessToken: accessToken },
    //   })
    //   .then((res) => {
    //     let customer = res;
    //     let addressInArray = customer?.data?.customer?.addresses?.edges;
    //     setDefAddress(customer?.data?.customer?.defaultAddress);
    //     // setDefAddressID(customer?.data?.customer?.defaultAddress?.id);
    //     // setDefNameMob(customer?.data?.customer);
    //   });
    // };
    // handleData();
    getCustomerDetails(accessToken)
      .then((res) => {
        let customer = res.data;
        setDefAddress(customer?.data?.customer?.defaultAddress);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleClick = (e) => {
    // setTimeout(() => {
    setLoading(true);
    const addFav = {
      customerIdShopify: userDetails?.id,
      productVariantId: Detail?.id,
      createdAt: "2023-07-02T10:52:24.605Z",
      updatedAt: "2023-07-02T10:52:24.605Z",
    };

    addWishlist(addFav).then((res) => {
      if (res.responseCode == 201) {
        setLoading(false);
        dispatch(setWishList([...wishList, Detail?.id]));
        successToastMessage(`${Detail?.title} Added  in Wishlist`);
        if (isFavorite) {
          dispatch(setIsFavorite(false));
        } else {
          dispatch(setIsFavorite(true));
        }
      } else if (res.responseCode == 400) {
        deleteWishListByIds(Detail?.id, userDetails?.id).then((res) => {
          setLoading(false);
          if (res.responseCode == 204) {
            if (isFavorite) {
              dispatch(setIsFavorite(false));
            } else {
              dispatch(setIsFavorite(true));
            }
            failureToastMessage(`${Detail?.title} Removed  from Wishlist`);

            let index = wishList?.findIndex((e) => e == Detail?.id);
            let favlist = [...wishList];
            favlist.splice(index, 1);
            dispatch(setWishList(favlist));
          }
        });
      }
    });
  };
  let navigate = useNavigate();
  const handleClickimage = () => {
    // const imageUrl = Detail?.image;
    // window.location.href = imageUrl;
    dispatch(setzoomDetails(Detail.cartItemDetails));
    const idWithoutPrefix = Detail?.id.replace("gid://shopify/Product/", "");
    const encodedTitle = encodeURIComponent(Detail?.title);
    const styleNo = Detail.cartItemDetails?.Style_code?.value;
    let path = `/itemDetail/${encodedTitle}`;
    const abc =
      varientIndex == ""
        ? Detail.productVarientIndex
          ? Detail.productVarientIndex
          : 1
        : Number(varientIndex) + 1;
    let search = `?itemId=${idWithoutPrefix}&style=${styleNo}&varId=${abc}`;
    navigate({
      pathname: path,
      search: search,
      state: {
        productVariantIdIndex:
          varientIndex == ""
            ? Detail.productVarientIndex
            : Number(varientIndex) + 1,
      },
    });
    //  navigate("/itemDetail",{state:{productVariantIdIndex:varientIndex==""?Detail.productVarientIndex:Number(varientIndex)+1}})
  };
  // useEffect(() => {
  //   if (detailsizechange) {
  //     setSize(detailsizechange);
  //   } else {
  //     setSize(Detail.merchandiseId);
  //   }
  // }, [Detail.merchandiseId, detailsizechange]);
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        style={{
          borderRadius: "0.5rem",
          boxShadow: "0px 0px 6px rgba(255, 255, 255, 0.5)",
          paddingBottom: "0.8rem",
          marginBottom: "1rem",
        }}
      >
        <Grid container>
          <Grid item md={5} xs={4} sm={5} lg={4}>
            <div style={{ textAlign: "center", alignSelf: "center" }}>
              <Box
                component="img"
                sx={{
                  height: { xs: "8rem", sm: "8rem", md: "9rem", lg: "9rem" },
                  mt: 1,
                  cursor: "pointer",
                }}
                alt="CartImage"
                src={Detail?.image}
                onClick={handleClickimage}
              />
            </div>
          </Grid>

          <Grid
            item
            md={7}
            xs={8}
            sm={7}
            lg={7}
            display="flex"
            direction="column"
            justifyContent="flex-start"
            style={{ marginTop: "0.4rem" }}
          >
            <Typography
              variant="subtitle1"
              color="#FFFFFF"
              sx={fontSizedUpperText}
            >
              {Detail?.title}
            </Typography>
            <Typography
              sx={{
                maxHeight: "3.6em", // Adjust this to control the maximum height (2 lines)
                cursor: "pointer",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2, // Limit to 2 lines
                lineHeight: "1em",
                fontSize: { xs: 12, sm: 11, md: 12, lg: 13 },
                //  ml: { xs: 2, sm: 0, md: 0, lg: 0 },
                color: "white",
              }}
            >
              {Detail?.description}
            </Typography>
            {/* <Typography variant="subtitle" color="#FFFFFF" sx={fontSizeDownText}>
            {Detail?.description.length>60?`${Detail.description.substring(0, 60)}...`:Detail.description}
          </Typography> */}
            <div style={{ display: "flex" }}></div>
            <Grid
              sx={{ marginTop: -1.7, marginBottom: 1 }}
              container
              spacing={3}
            >
              <Grid item lg={6}>
                <Box
                  sx={{
                    width: 89,
                    height: 25,
                    border: "1px solid white",
                    borderRadius: "5px",
                    // ml:{lg:0,xs:3 ,sm:-0.9},
                    // mt:{xs:1}
                  }}
                >
                  <Typography style={{ color: "#ffffff", marginLeft: "7px" }}>
                    size:
                  </Typography>
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 20, marginLeft: "45px" }}
                  >
                    <Select
                      disableUnderline
                      sx={{
                        marginTop: "-35px",
                        color: "white",
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "white",
                        },
                      }}
                      value={size}
                      MenuProps={MenuProps}
                      defaultValue={1}
                      onChange={(e, index) =>
                        handleSizeChange(e.target.value, index)
                      }
                      inputProps={{
                        "aria-label": "Without label",
                        MenuProps: {
                          MenuListProps: {
                            sx: {
                              backgroundColor: "#18161D",
                              color: "white",
                              "& .MuiMenuItem-root:hover": {
                                backgroundColor: "#006D82",
                              },
                            },
                          },
                        },
                      }}
                    >
                      {showproductSize?.map((e) => (
                        <MenuItem
                          value={e.id}
                          disabled={e.quantityAvailable == 0}
                        >
                          {e.title.split("/")[0]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item lg={6}>
                <Box
                  sx={{
                    width: 89,
                    height: 25,
                    border: "1px solid white",
                    borderRadius: "5px",
                  }}
                >
                  <Typography style={{ color: "#ffffff", marginLeft: "7px" }}>
                    Qty:
                  </Typography>
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 20, marginLeft: "45px" }}
                  >
                    <Select
                      disableUnderline
                      sx={{
                        marginTop: "-35px",
                        color: "white",

                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                      //  MenuProps={MenuProps}
                      value={age}
                      //  defaultValue={1}
                      onChange={handleChange}
                      inputProps={{
                        "aria-label": "Without label",
                        MenuProps: {
                          MenuListProps: {
                            sx: {
                              backgroundColor: "#18161D",
                              color: "white",
                              "& .MuiMenuItem-root:hover": {
                                backgroundColor: "#006D82",
                              },
                              // "& .MuiMenuItem-root.Mui-selected:hover": {
                              //   backgroundColor: "red"
                              // }
                            },
                          },
                        },
                      }}
                    >
                      {avaqunatity?.map((e, index) => (
                        <MenuItem key={index} value={e}>
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="subtitle1"
                color="#FFFFFF"
                sx={fontSizedUpperText}
                marginTop="0.3rem"
              >
                {/* MRP : ₹ { ssiz || Detail?.price} */}
                MRP : ₹ {Detail?.quantityAvailable[0]?.compareAtPrice?.amount}
                <Typography
                variant="subtitle"
                sx={{...fontSizedUpperText,
                  color: "lightblue",
                  ml: 2,
                  // marginTop: {sm:"2.6%",xs:".4rem"},
                  textDecoration: "line-through",
                  // fontSize: 15,
                }}
              >
                ₹ {ssiz || Detail?.price}{" "}
              </Typography>
              </Typography>
              
            </Box>
          </Grid>
        </Grid>

        {isAuth ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "fit-content",
              marginLeft: "2rem",
            }}
          >
            <Box sx={{ alignItems: "center", justifyContent: "center" }}>
              <Button
                onClick={hadleRemove}
                sx={{
                  background: "rgba(255, 255, 255, 0.05)",
                  color: "#FFFFFF",
                  width: { lg: "7rem", xs: "3rem" },

                  textTransform: "none",
                }}
                variant="contained"
              >
                Remove
              </Button>
            </Box>
            <Button
              onClick={() => handleClick()}
              sx={{
                background: "rgba(255, 255, 255, 0.05)",
                color: "#FFFFFF",
                width: { lg: "14rem", sm: "9rem" },
                marginLeft: "1rem",
                textTransform: "none",
              }}
              variant="contained"
            >
              {wishList?.find((e) => e == Detail.id)
                ? "Remove from Wishlist"
                : "Move to Wishlist"}
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              ml: { lg: 18, xs: 10 },
              border: "1px solid #4F4F4F",
              width: "9rem",
            }}
          >
            <Button
              onClick={hadleRemove}
              sx={{
                background: "rgba(255, 255, 255, 0.05)",
                color: "#FFFFFF",
                width: "9rem",

                textTransform: "none",
              }}
              variant="contained"
            >
              Remove
            </Button>
          </Box>
        )}
      </div>
    </>
  );
};

export default CartItem;
