import {
  Box,
  Tab,
  Tabs
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AllCategories from "../page/Categories/AllCategories";
import DenimSeries from "../page/Categories/DenimSeries";
import KnitSeries from "../page/Categories/KnitSeries";
import OutwearSeries from "../page/Categories/OutwearSeries";
import WovenSeries from "../page/Categories/WovenSeries";
import "./MovingBox.css";

const useStyles = makeStyles({
  tabSeparator: {
    "& .MuiTabScrollButton-root": {
      height: "25px",
    },
    "& button.Mui-selected": {
      color: "#006D82",
    },
    "& .MuiTab-root": {
      borderRight: "1px solid lightgray",
      height: "5px",
      marginTop: "7px",
      marginBottom: "10px",
      // paddingBottom:"10px"
    },
    "& .MuiTabs-indicator": {
      marginBottom: "9px", // Adjust the value as needed
    },

    "& .MuiTab-textColorInherit": {
      minWidth: "0", // Allow tabs to shrink as needed
    },
    "& button": {
      padding: "7px 3px",
      minHeight: "25px",
      paddingRight: "1.2rem",
      paddingLeft: "1.2rem",
    },
  },
});

const TabBar = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const location = useLocation();
  let value1 = location.state;

  const [value, setValue] = React.useState("All");
  useEffect(() => {
    if (value1) {
      setValue(value1);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box>
        <Box sx={{ mt: { xs: 8, sm: 8, md: 12 } }}>
          <Box
            sx={{
              position: "fixed",
              top: { lg: 92, xs: 63 },
              marginTop:"-0.2rem",
              left: 0,
              right: 0,
              zIndex: 1000,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              height: "2.3rem",
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", sm: "none" },
                justifyContent: "center",
                // borderBottom: 1,
                borderColor: "#c6c6c6",
                mt: -0.2,
                minHeight: "10px",
                backgroundColor: "#F1F1F1",
                width: "100%",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                // className={classes.tabSeparator}
                variant="scrollable"
                // scrollButtons="auto"
                // allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                TabIndicatorProps={{
                  sx: {
                    minHeight: "1px",
                    color: "#006D82",
                    backgroundColor: "#006D82",
                    marginBottom:"1rem"
                  },
                }}
              >
                <Tab
                  label="All"
                  value={"All"}
                  sx={{
                     color: "#777676",
                    fontWeight: "550",
                    paddingTop: "0.2rem",
                    fontSize: "1.1rem",
                    fontFamily:"hiragino Mincho ProN",
                    '&.Mui-selected': {
                      color: '#777676', 
                    },
                    
                  }}
                />
                <Tab
                  label="Topwear"
                  value={"TOPS"}
                  sx={{
                    color: "#777676",
                    fontWeight: "550",
                    paddingTop: "0.2rem",
                    fontSize: "1.1rem",
                    fontFamily:"hiragino Mincho ProN",
                    '&.Mui-selected': {
                      color: '#777676', 
                    },
                  }}
                />
                <Tab
                  label="Bottomwear"
                  value={"BOTTOMS"}
                  sx={{
                    color: "#777676",
                    fontWeight: "550",
                    paddingTop: "0.2rem",
                    fontSize: "1.1rem",
                    fontFamily:"hiragino Mincho ProN",
                    '&.Mui-selected': {
                      color: '#777676', 
                    },
                  }}
                />
                <Tab
                  label="Denim"
                  value={"DENIM"}
                  sx={{
                    color: "#777676",
                    fontWeight: "550",
                    paddingTop: "0.2rem",
                    fontSize: "1.1rem",
                    fontFamily:"hiragino Mincho ProN",
                    '&.Mui-selected': {
                      color: '#777676', 
                    },
                  }}
                />
                <Tab
                  label="Outerwearwear"
                  value={"OUTERWEAR"}
                  sx={{
                    color: "#777676",
                    fontWeight: "550",
                    paddingTop: "0.2rem",
                    fontSize: "1.1rem",
                    fontFamily:"hiragino Mincho ProN",
                    '&.Mui-selected': {
                      color: '#777676', 
                    },
                  }}
                />
              </Tabs>
            </Box>
            <Box
              sx={{
                backgroundColor: "#1D1D1D",
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Tabs
                variant="scrollable"
                allowScrollButtonsMobile
                value={value}
                onChange={handleChange}
                textColor="primary"
                scrollButtons="auto"
                sx={{
                  "& button.Mui-selected": {
                    backgroundColor: "#006D82",
                    borderRadius: "1.5rem",
                    color: "white",
                  },
                  "& button": { padding: "2px 3px", minHeight: "25px" },
                  "& .css-ptiqhd-MuiSvgIcon-root": { marginTop: "-15px" },
                  color: "white",
                  pb: 2,
                }}
                TabIndicatorProps={{
                  sx: {
                    display: "none",
                  },
                }}
              >
                {/* <Tab
                      label="Categories"
                      style={{ color: "#FFFFFF",background: "rgba(255, 255, 255, 0.13)",p:.5,pr:3,ml:8 }} 
                      value={0}
                    /> */}
                <Tab
                  label="All"
                  sx={{
                    textTransform: "none",
                    color: "white",
                    m: { sm: "0.2rem 1rem", xs: "0.2rem 0rem" },
                    "&:hover": {
                      background: "rgba(255, 255, 255, 0.13)",
                      // background:"rgba(13, 129, 128, 0.35)",
                      borderRadius: "1.5rem",
                    },
                  }}
                  value={"All"}
                />
                <Tab
                  label="Tops"
                  sx={{
                    textTransform: "none",
                    color: "white",
                    m: { sm: "0.2rem 3rem", xs: "0.2rem 0rem" },
                    "&:hover": {
                      background: "rgba(255, 255, 255, 0.13)",
                      // background:"rgba(13, 129, 128, 0.35)",
                      borderRadius: "1.5rem",
                    },
                  }}
                  value={"TOPS"}
                />

                <Tab
                  label="Denim"
                  sx={{
                    textTransform: "none",
                    color: "white",
                    m: { sm: "0.2rem 3rem", xs: "0.2rem 0rem" },
                    "&:hover": {
                      background: "rgba(255, 255, 255, 0.13)",
                      // background:"rgba(13, 129, 128, 0.35)",
                      borderRadius: "1.5rem",
                    },
                  }}
                  value={"DENIM"}
                />
                <Tab
                  label="Bottoms"
                  sx={{
                    textTransform: "none",
                    color: "white",
                    m: { sm: "0.2rem 3rem", xs: "0.2rem 0.rem" },
                    "&:hover": {
                      background: "rgba(255, 255, 255, 0.13)",
                      // background:"rgba(13, 129, 128, 0.35)",
                      borderRadius: "1.5rem",
                    },
                  }}
                  value={"BOTTOMS"}
                />
                <Tab
                  label="Outerwear"
                  sx={{
                    textTransform: "none",
                    color: "white",
                    m: { sm: "0.2rem 3rem", xs: "0.2rem 0.rem" },
                    "&:hover": {
                      background: "rgba(255, 255, 255, 0.13)",
                      // background:"rgba(13, 129, 128, 0.35)",
                      borderRadius: "1.5rem",
                    },
                  }}
                  value={"OUTERWEAR"}
                />
              </Tabs>
            </Box>
          </Box>

          {value === "All" && <AllCategories />}
          {value === "TOPS" && <KnitSeries />}
          {value === "DENIM" && <DenimSeries />}
          {value === "BOTTOMS" && <WovenSeries />}
          {value === "OUTERWEAR" && <OutwearSeries />}
        </Box>
        {/* <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' }, }} >
          <Box className="movingBox" >
            <Button sx={{ color: "white", textAlign: "center", fontSize: { xs: 8, sm: 10, md: 13, lg: 15 }, p: 2, pl: 4 }}
            onClick={() => navigate('/pairingScreen')}>Turn on Pairing Mode</Button>
          </Box>
        </Box>
          <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' },mb:1,mt:1, position:"absolute", alignItems:"center", bottom:0 }} >
          <Box className="stopBox" sx={{position:"static"}}>
            <Button sx={{ color: "white", alignItems: "center", fontSize: 10 , }}
            onClick={() => navigate('/pairingScreen')}>Turn on Pairing Mode</Button>
          </Box>
        </Box>  */}
      </Box>
    </>
  );
};
export default TabBar;
