import { Box, Typography, Zoom } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { useNavigate } from "react-router-dom";
import { fontColor } from "../../Search/SearchBar";
import { fontSize } from "../../common/Footer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});

function Shop({ openShop, setOpenShop }) {
  const navigate = useNavigate();

  // const classes = useStyles();

  const handleClickOpen = () => {
    setOpenShop(true);
  };

  const handleClose = () => {
    setOpenShop(false);
  };

  const handleNavigation1 = () => {
    navigate("/collections", { state: { title: "Tees" } });
    setOpenShop(false);
  };
  const handleNavigation2 = () => {
    navigate("/collections", { state: { title: "Shirts" } });
    setOpenShop(false);
  };
  const handleNavigation3 = () => {
    navigate("/collections", { state: { title: "Jackets" } });
    setOpenShop(false);
  };
  const handleNavigation4 = () => {
    navigate("/collections", { state: { title: "Polos" } });
    setOpenShop(false);
  };
  const handleNavigation5 = () => {
    navigate("/collections", { state: { title: "Hoodies" } });
    setOpenShop(false);
  };
  const handleNavigation6 = () => {
    navigate("/collections", { state: { title: "Sweatshirts" } });
    setOpenShop(false);
  };
  const handleNavigation7 = () => {
    navigate("/collections", { state: { title: "Denim Jackets" } });
    setOpenShop(false);
  };
  const handleNavigation8 = () => {
    navigate("/collections", { state: { title: "Shorts" } });
    setOpenShop(false);
  };
  const handleNavigation9 = () => {
    navigate("/collections", { state: { title: "Joggers" } });
    setOpenShop(false);
  };
  const handleNavigation10 = () => {
    navigate("/collections", { state: { title: "Woven Pants" } });
    setOpenShop(false);
  };
  const handleNavigation11 = () => {
    navigate("/collections", { state: { title: "Denim Pants" } });
    setOpenShop(false);
  };
  return (
    <>
      <Dialog
        open={openShop}
        TransitionComponent={Transition}
        onClose={handleClose}
        sx={{ mb: { md: "25%", lg: "19%" }, ml: { xs: 0, md: "45%" } }}
      >
        <DialogContent
          sx={{
            fontFamily: "Raleway",
            backgroundColor: "black",
            overflow: "hidden",
          }}
        >
          <Box sx={{ backgroundColor: "black" }}>
            <Typography
              sx={{ color: "#E6A82E", ml: { xs: "1.8rem", sm: "3rem" } }}
            >
              Top Wears
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Box sx={{}}>
                <Typography
                  style={{ color: "#ffffff", cursor: "pointer" }}
                  sx={fontSize}
                  onClick={handleNavigation1}
                >
                  Tees
                </Typography>
                <Typography
                  style={{ color: "#ffffff", cursor: "pointer" }}
                  sx={fontSize}
                  onClick={handleNavigation2}
                >
                  Shirt
                </Typography>
                <Typography
                  style={{ color: "#ffffff", cursor: "pointer" }}
                  sx={fontSize}
                  onClick={handleNavigation3}
                >
                  Jacket
                </Typography>
                <Typography
                  style={{ color: "#ffffff", cursor: "pointer" }}
                  sx={fontSize}
                  onClick={handleNavigation4}
                >
                  Polo
                </Typography>
              </Box>
              <Box sx={{}}>
                <Typography
                  style={fontColor}
                  sx={fontSize}
                  onClick={handleNavigation5}
                >
                  Hoodies
                </Typography>
                <Typography
                  style={fontColor}
                  sx={fontSize}
                  onClick={handleNavigation6}
                >
                  Sweatshirt
                </Typography>
                <Typography
                  style={fontColor}
                  sx={fontSize}
                  onClick={handleNavigation7}
                >
                  Denim Jacket
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: "-1.4rem",
                  ml: { xs: "0rem", sm: "3rem" },
                }}
              >
                <Box>
                  {/* <Typography sx={{ color: "#E6A82E", mt: "2rem" }}>Top Wears</Typography> */}
                  <Typography sx={{ color: "#E6A82E", textAlign: "center" }}>
                    Bottom Wears
                  </Typography>
                  <Typography
                    style={fontColor}
                    sx={fontSize}
                    onClick={handleNavigation8}
                  >
                    Shorts
                  </Typography>
                  <Typography
                    style={fontColor}
                    sx={fontSize}
                    onClick={handleNavigation9}
                  >
                    Jogger
                  </Typography>
                  <Typography
                    style={fontColor}
                    sx={fontSize}
                    onClick={handleNavigation10}
                  >
                    Woven Pants
                  </Typography>
                  <Typography
                    style={fontColor}
                    sx={fontSize}
                    onClick={handleNavigation11}
                  >
                    Denim Pants
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Shop;
