import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography, Zoom } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});
function TrackDetails({
  trackDetailsOpen,
  setTrackDetailsOpen,
  trackingNo,
  trackingDetails,
}) {
  const [reversedTrackingDetails, setReversedTrackingDetails] = useState([]);
  const handleClose = () => {
    setTrackDetailsOpen(false);
  };

  const dividerStyle = {
    backgroundColor: "lightgrey",
    height: "25px",
    width: "0.5px",
    marginLeft: "10px",
    marginRight: "10px",
  };

  useEffect(() => {
    const reversedTrackingDetailsArr = trackingDetails.map((item, index) => {
      const reversedIndex = trackingDetails.length - 1 - index;
      return trackingDetails[reversedIndex];
    });
    setReversedTrackingDetails(reversedTrackingDetailsArr);
  }, []);

  return (
    <>
      <Dialog
        open={trackDetailsOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pl: 1,
              pr: 1,
              backgroundColor: "lightgrey",
              borderBottom: "1px solid grey",
            }}
          >
            <Typography sx={{ mt: ".5rem" }}>Tracking Details</Typography>
            <IconButton>
              <CloseIcon
                onClick={handleClose}
                style={{ backgroundColor: "black", color: "white" }}
              />
            </IconButton>
          </Box>
          <Box sx={{ borderBottom: "1px solid grey", p: 1 }}>
            <Typography>
              Courier Name: <b>Xpressbees</b>
            </Typography>
            <Typography>
              Tracking No: <b>{trackingNo}</b>
            </Typography>
          </Box>
          {reversedTrackingDetails?.map((e) => {
            return (
              <>
                <Typography sx={{ color: "#088F8F", pl: 1 }}>
                  {e?.statusDate}
                </Typography>
                <Typography sx={{ color: "#088F8F", pl: 1 }}>
                  {e?.statusCode == "DLVD"
                    ? "Delivered"
                    : e?.statusCode == "OFD"
                    ? "Out For Delivery"
                    : e?.statusCode == "UD"
                    ? "Undeliverd"
                    : e?.statusCode == "RAD"
                    ? "Reached At City Hub"
                    : e?.statusCode == "PUD"
                    ? "Pickup Done"
                    : e?.statusCode == "PKD"
                    ? "Picked"
                    : e?.statusCode == "IT"
                    ? "In Transit"
                    : e?.statusCode == "DRC"
                    ? "Shipment Data Recieved"
                    : e?.statusCode == "OFP"
                    ? "Out For Pickup"
                    : e?.statusCode == "PND"
                    ? "Pickup Not Done"
                    : e?.statusCode == "STD"
                    ? "ShipmentTransit"
                    : ""}
                </Typography>
                <Box sx={{ display: "flex", p: 1 }}>
                  <Typography>{`${String(e?.statusTime).split("")[0]}${
                    String(e?.statusTime).split("")[1]
                  }:${String(e?.statusTime).split("")[2]}${
                    String(e?.statusTime).split("")[3]
                  }`}</Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={dividerStyle}
                  />
                  <Typography>{e?.currentLocation}</Typography>
                </Box>
              </>
            );
          })}
        </Box>
      </Dialog>
    </>
  );
}

export default TrackDetails;
