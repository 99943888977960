
import React,{useEffect, useState} from 'react'
import { Grid, Box, Typography, Button,Drawer ,CircularProgress,Backdrop,RadioGroup, FormControlLabel,Radio,Divider} from '@mui/material';
import CommonCard from './CommonCard';
import { useNavigate, useLocation } from "react-router-dom";
import { positions } from '@mui/system';
import { useSelector, useDispatch } from "react-redux";
import { setName } from '../../Redux/UserSlice';
import { setAllProducts, setGetAllProducts } from '../../Redux/CartSlice';
import FilterCategories from './FilterCategories';
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
// import useScrollPosition from "../../common/useScrollPosition";
const WovenSeries = () => {
  const dispatch = useDispatch();
  dispatch(setName("abc"));
    const wovenSeries = useSelector((state)=>state?.cart?.denimSeries)
    const woven = wovenSeries?.filter((e)=>e?.data?.collection.title =='BOTTOMS');
     const wovenValue = woven[0]?.data?.collection?.products?.edges;
     const[wovenProduct,setWovenProduct]=useState([])
     const [loading, setLoading] = useState(true);
     const [openFilter, setOpenFilter] = useState(false);
     const [sortOrder, setSortOrder] = useState('')
     const [sortDrawer,setSortDrawer]=useState()
     const [selectedSort, setSelectedSort] = useState('');
    //  const checkScroll =useSelector((state) => state.user.checkScroll);
      // const scrollPosition = useScrollPosition();



     const sortedProducts = [...wovenValue]?.sort((a, b) => {
      const priceA =(a?.node.variants?.nodes[0]?.price?.amount) 
      const priceB = (b?.node.variants?.nodes[0]?.price?.amount) 
    
      return priceA - priceB;
    });
    const afternodeWoven = sortedProducts?.map((e)=>e?.node)
    useEffect(()=>{
      dispatch(setGetAllProducts(afternodeWoven))
     setWovenProduct(afternodeWoven)
    },[])

    const handleSortChange = (event) => {
      const sortOrder = event.target.value;
      setSelectedSort(sortOrder);
    
      const sortedProduct = [...wovenValue]?.sort((a, b) => {
        const priceA = a?.node?.variants?.nodes[0]?.price?.amount || 0;
        const priceB = b?.node?.variants?.nodes[0]?.price?.amount || 0;
    
        if (sortOrder === 'lowToHigh') {
          return priceA - priceB; 
        } else if (sortOrder === 'highToLow') {
          return priceB - priceA; 
        }
    
        return 0;
      });
    
      const afternodeValueDenim1 = sortedProduct?.map((e) => e?.node);
      setWovenProduct(afternodeValueDenim1);
      closeDrawer1();
    };

    const handleOpnesort=()=>{
      setSortDrawer(true)
    
    }
    const closeDrawer1 = () => {
      setSortDrawer(false);
    };
    useEffect(() => {
      setTimeout(() => {
        setLoading(false); 
      }, 1000); 
    }, [])
    
    const handleOpne = () => {
      setOpenFilter(true);
    };
    const closeDrawer = () => {
      setOpenFilter(false);
    };
    const totalProducts = wovenProduct?.filter((e) => e?.availableForSale === true)?.length;
  return (
    <>
    {loading?<Backdrop sx={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>:
    <Box
        sx={{
          paddingLeft: { lg: "1vw", xs: "1vw" },
          paddingRight: {lg:"1vw", xs:"1vw"},
          paddingBottom: { lg: "2vh", xs: "69px" },
          paddingTop: "2vh",
          display: "flex",
          mt:{lg:-1,xs:-1},
          // marginRight:"0.4rem"
          backgroundColor:"#f1f1f1"
        }}
      >
        <Grid
  container
  spacing={2}
  columnSpacing={{ lg: 1, md: 8, sm: 3, xs: 3 }}
  rowSpacing={3}
   sx={{ mt: ".3rem" }}
  style={{ position: 'relative' }} // Add this line
>
<Grid
    item
    xs={6}
    sm={6}
    md={4}
    lg={2.4}
    sx={{
      display: {
        lg: "block",
        md: "block",
        sm: "none",
        xs: "none",
        position: "static",
        left: 0,
      },
      position: "sticky", // Add this line
      top: "22vh", // Add this line
      // height: "100vh", // Add this line
      overflowY: "auto",
      height: "calc(100vh - 18vh)"
    }}
>
<Box sx={{marginTop:"-1rem",marginLeft:"1rem"}} >
             <Typography variant="body2" color="textSecondary" component="p">
              Total Products: {totalProducts}
        </Typography>
        </Box>

        <FilterCategories
      setArrallproductget={setWovenProduct}
      closeDrawer={closeDrawer}
     
    />
    </Grid>
    <Grid
    container
    spacing={0.8}
    item
    xs={12}
    sm={12}
    md={8}
    lg={9.6}
    sx={{  paddingRight: {lg:"0.8rem",xs:0} }}
>
          {wovenProduct?.filter((e)=>e?.availableForSale==true)?.map((e) => {
            return (
              <Grid item xs={6} sm={6} md={4} lg={3} sx={{marginBottom:""}}>
            <CommonCard
                  key={e?.id}
                  zoomScreenDetails={e}
                  imgUrl={e?.images?.edges[0]?.node?.url}
                  imgUrlTwo={e?.images?.edges[1]?.node?.url}
                  props={e}
                  catTitle = 'BOTTOMS'
                  // discription={e.node.description}
                  discription={e?.description?.length>60?`${e?.description.substring(0, 150)}...`:e?.description}
                  titile={e?.title}
                  availableForSale={e?.availableForSale}
                  priceRange={e?.priceRange?.maxVariantPrice?.amount}
                  productVariantPrice={e?.variants?.nodes[0]?.price?.amount}
                  productVariantCompareAtPrice={e?.variants?.nodes[0]?.compareAtPrice?.amount}
                  productVariantId={e?.variants?.nodes[0]?.id}
                  productVariants ={e?.variants?.nodes}
                />
              </Grid>
            );
          })}
        </Grid>  
      </Grid>
      </Box>
}
      <Box
        sx={{
          display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
          background: "white",
          border:"0.3px solid #999999",
          height: "3rem",
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
          alignItems: "center",
          padding: "0 7px",
          zIndex: 999,
          justifyContent: "center",
        }}
      >
        {/* <Typography color="white" sx={{ fontSize: '18px', fontWeight: '600', cursor: 'pointer' }}>
          Sort
        </Typography> */}
        <Box
          sx={{ display: "flex", justifyContent: "center",marginRight: "5rem" }}
          onClick={() => handleOpne()}
        >
          <FilterAltOutlinedIcon
            fontSize="medium"
            sx={{ color: "#107081", marginTop: "0.4rem" }}
          />
          <Typography
            color="#454545"
            sx={{
              fontSize: "15px",
              fontWeight: "600",
              marginTop: "0.4rem",
              cursor: "pointer",
              fontFamily:"arial"
            }}
          >
             FILTER
          </Typography>
        </Box>
        <Divider sx={{borderColor: "#999999",marginRight:"3.5rem",height:"2rem"}} orientation="vertical"  />
        <Box
          sx={{ display: "flex", justifyContent: "center",marginRight:'3rem' }}
          onClick={() => handleOpnesort()}
        >
          <SortOutlinedIcon
            fontSize="medium"
            sx={{ color: "#107081", marginTop: "0.4rem", }}
          />
          <Typography
            color="#454545"
            sx={{
              fontSize: "15px",
              fontWeight: "600",
              marginTop: "0.5rem",
              cursor: "pointer",
              fontFamily:"arial"
            }}
          >
            SORT
          </Typography>
        </Box>
      </Box>
      <Drawer
        anchor="bottom"
        open={openFilter}
        onClose={closeDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
          },
        }}
      >
        <FilterCategories
          setArrallproductget={setWovenProduct}
          closeDrawer={closeDrawer}
          
        />
      </Drawer>
      <Drawer
        anchor="bottom"
        open={sortDrawer}
        onClose={closeDrawer1}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
          },
        }}
      >
        <RadioGroup value={selectedSort} onChange={handleSortChange} >
          <FormControlLabel sx={{marginLeft:'1.5rem'}}

            value="lowToHigh"
            control={<Radio sx={{ marginRight: "3rem" }} />}
            label="Price-Low to High"
          />
           <Divider light />
         <FormControlLabel
            value="highToLow"
            label="Price-High to Low"
            control={<Radio sx={{ marginRight: "3rem" }} />}
           
            sx={{marginLeft:'1.5rem'}}
            
          />
        </RadioGroup>
      </Drawer>
    
    </>
  )
}

export default WovenSeries