import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCart,
  setCheckoutDetailes,
  setCheckoutVariants,
} from "../../Redux/CartSlice";
import {
  getPaymentDetails,
  order,
  savePaymentRefId,
  sendSms,
  updateOrder,
} from "../../api/Api";
import { productByProductIdPay } from "../../api/Queries";
import { client } from "../../common/Utils";

const Success = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [payOrderId, setPayOrderId] = useState(false);
  const cartItems = useSelector((state) => state?.cart?.cart);
  const createCardValues = useSelector((state) => state?.cart?.createCardValue);
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const discountdata = useSelector((state) => state?.cart?.discountdata);
  const shippingValue = useSelector((state) => state?.cart?.shippingValue);
  const totalPrice = useSelector((state) => state?.cart?.totalPrice);
  const coupantextValue = useSelector((state) => state.cart.coupantextValue);
  const tempAddress = useSelector((state) => state?.user?.tempAddress);
  const coupanValue = useSelector((state) => state?.user?.coupanValue);
  const emailValue = useSelector((state) => state?.user?.emailValue);

  const freeChargeValue =
    shippingValue?.data?.checkoutCreate?.checkout?.availableShippingRates
      ?.shippingRates[0]?.priceV2?.amount === 0;
  let custId = parseInt(userDetails?.id.split("/").pop(), 10);
  const defAddress = useSelector((state) => state?.user?.defualtUserAddress);
  const [value, setValue] = React.useState(" ");
  const [varientId, setVarientId] = React.useState();
  const shipping = freeChargeValue
    ? 0
    : shippingValue?.data?.checkoutCreate?.checkout?.availableShippingRates
        ?.shippingRates[0]?.priceV2?.amount;
  const dicountTotalPrice =
    parseFloat(
      (20 / 100) * createCardValues?.estimatedCost?.totalAmount.amount
    ) + parseFloat(coupanValue);
  const compareTotalPrice =
    parseFloat(createCardValues?.estimatedCost?.totalAmount.amount) -
    dicountTotalPrice;
  let CoupandiscountPrice = dicountTotalPrice;
  let discountAmount =
    discountdata?.checkoutDiscountCodeApplyV2?.checkout?.discountApplications
      ?.nodes[0]?.value?.amount;
  let discountCode =
    discountdata?.checkoutDiscountCodeApplyV2?.checkout?.discountApplications
      ?.nodes[0]?.code;
  let discountType =
    discountdata?.checkoutDiscountCodeApplyV2?.checkout?.discountApplications
      ?.nodes[0]?.targetType;
  // console.log(cartItems,"carttt")

  const result = cartItems.map(({ merchandiseId, quantity, title, price }) => ({
    title,
    price,
    variant_id: merchandiseId.substring(
      merchandiseId.indexOf("ProductVariant/") + "ProductVariant/".length
    ),
    quantity: quantity,
    requires_shipping: true,
  }));

  const dataOnlinePay = {
    order: {
      line_items: result,
      shipping_address: {
        first_name:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.firstName
            : defAddress?.firstName,
        last_name:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.firstName
            : defAddress?.firstName,
        address1:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.address1
            : defAddress?.address1,
        phone:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.phone
            : defAddress?.phone,
        city:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.city
            : defAddress?.city,
        province:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.province
            : defAddress?.province,
        country:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.country
            : defAddress?.country,
        zip:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.zip
            : defAddress?.zip,
      },
      billing_address: {
        first_name:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.firstName
            : defAddress?.firstName,
        last_name:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.firstName
            : defAddress?.firstName,
        address1:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.address1
            : defAddress?.address1,
        phone:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.phone
            : defAddress?.phone,
        city:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.city
            : defAddress?.city,
        province:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.province
            : defAddress?.province,
        country:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.country
            : defAddress?.country,
        zip:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.zip
            : defAddress?.zip,
      },
      shipping_lines: [
        {
          price: shipping,
          title: "Standard Shipping",
        },
      ],
      //   "tax_lines": [
      //     {
      //         "price": coupantextValue ? discountdata?.checkoutDiscountCodeApplyV2?.checkout?.totalTax.amount : createCardValues?.estimatedCost?.totalTaxAmount?.amount,
      //         "rate": (Number(totalPrice)-Number(createCardValues?.estimatedCost?.totalTaxAmount?.amount))>1199 ? 0.12 : 0.12,
      //         "title": "GST"
      //     }
      // ],
      // total_tax: coupantextValue ? discountdata?.checkoutDiscountCodeApplyV2?.checkout?.totalTax.amount : createCardValues?.estimatedCost?.totalTaxAmount?.amount,
      total_price: compareTotalPrice,
      // currency: createCardValues?.estimatedCost?.totalAmount?.currencyCode,
      transactions: [
        {
          kind: "sale",
          status: "success",
          amount: compareTotalPrice,
          gateway: "Online",
          payment_method: "Online",
          source_name: userDetails?.firstName,
          currency: "INR",
        },
      ],
      // financial_status: "pending",
      customer: {
        id: custId,
      },
      discount_codes: [
        {
          code: coupantextValue ? discountCode : "" ? discountCode : "20% Off",
          amount: coupantextValue
            ? CoupandiscountPrice
            : ""
            ? CoupandiscountPrice
            : dicountTotalPrice,
          type: coupantextValue
            ? discountType
            : ""
            ? discountType
            : "LINE_ITEM",
        },
      ],
    },
  };

  const requestId = useSelector((state) => state?.cart?.requestId);
  const phonpayOrderId = useSelector((state) => state?.cart?.phonpayOrderId);

  useEffect(() => {
    getPaymentDetails(requestId).then((response) => {
      if (response.data.data.responseCode === "SUCCESS") {
        order(dataOnlinePay).then((res) => {
          const parsedData = res.data;
          const updatOrderData = {
            customerIdentifier: userDetails?.email,
            instaPayStatus: response.data.data.responseCode,
            instaPayPaymentId: response.data.data?.transactionId,
            instaPayOrderId: phonpayOrderId,
            shopifyOrderId: parsedData.order.id,
          };
          updateOrder(updatOrderData).then((res) => {
            console.log(res, "shilpa");
          });
          let data = {
            smsType: 2,
            name: parsedData.order.shipping_address.first_name,
            mobileNumberWithCode: `+91${String(
              parsedData.order.shipping_address.phone
            )
              .split("-")
              .join("")}`,
            orderNo: String(parsedData.order.id),
            shipment: "",
            shipmentUrl: "",
          };
          sendSms(data)
            .then((res) => {})
            .catch((e) => {
              console.log(e);
            });
          let variant = parsedData?.order?.line_items.map(
            (item) => item.variant_id
          );
          let variantIds = variant.map(
            (id) => `gid://shopify/ProductVariant/${id}`
          );
          dispatch(setCheckoutDetailes(parsedData?.order?.line_items));
          client
            .query({
              query: productByProductIdPay,
              variables: { ids: variantIds },
            })
            .then((res) => {
              console.log(res,res?.data,"sssshhhhhh")
              dispatch(setCheckoutVariants(res?.data?.nodes));
              let path = `/oderPlace`;
              navigate(path);
              dispatch(setCart());
            });

          let metaData = {
            id: parsedData?.order?.admin_graphql_api_id,
            namespace: "payment",
            key: "PaymentRefrenceId",
            value: String(response.data.data?.transactionId),
            type: "single_line_text_field",
          };
          savePaymentRefId(metaData)
            .then((res) => {
              // console.log(res,"meta Data response")
            })
            .catch((e) => {
              console.log(e);
            });
        });
      }
    });
  }, []);
  return (
    <div style={{ textAlign: "center", marginTop: "2rem" }}>
      <Box component="img" src="./success_payment.gif" />
    </div>
  );
};

export default Success;
