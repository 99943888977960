import { useMutation } from "@apollo/client";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  failureToastMessage,
  successToastDiscountMessage,
} from "../../common/Utils";
// import { addressDetails } from "../../api/Queries";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  setDiscountData,
  setTotalPrice,
  setcoupantextValue,
  setselectCoupanCode,
  setshippingValue,
} from "../../Redux/CartSlice";
import { setCoupanValue, setDefaultUserAddress } from "../../Redux/UserSlice";
import { getCustomerDetails } from "../../api/Api";
import {
  Coupan_Discount_CheckOut_Id,
  Remove_Coupan_Code,
  checkoutCreateMutation,
  checkoutUpdateMutation,
  defaultAddress,
  discountCoupanMutation,
} from "../../api/Mutations";
import CouponCode from "../CouponCode/CouponCode";
import AddressPopup from "./AddressPopup";

function YourBag() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const defualtUserAddress = useSelector(
    (state) => state?.user?.defualtUserAddress
  );
  const addressID = useSelector((state) => state?.user?.addressID);
  const coupanValue = useSelector((state) => state?.user?.coupanValue);
  const tempAddress = useSelector((state) => state?.user?.tempAddress);
  const createAddressId = useSelector((state) => state?.user?.createAddressId);
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const createCardValues = useSelector((state) => state?.cart?.createCardValue);
  const discountdata = useSelector((state) => state?.cart?.discountdata);
  const shippingValue = useSelector((state) => state?.cart?.shippingValue);
  const quantityCart = useSelector((state) => state?.cart?.quantity);
  const cartItems = useSelector((state) => state?.cart?.cart);
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const coupanCodeValue = useSelector((state) => state.cart.selectCoupanCode);
  const coupantextValue = useSelector((state) => state.cart.coupantextValue);
  const [makeId, setMakeId] = useState("");
  const [defAddress, setDefAddress] = useState({});
  const [defAddressID, setDefAddressID] = useState("");
  const [defNameMob, setDefNameMob] = useState({});
  const [setDefaultAddress] = useMutation(defaultAddress);
  const [isLoading, setIsLoading] = useState(false);
  // const [shippingValue,setshippingValue]=useState([])
  const [opencoupancode, setopenCoupancode] = useState(false);
  // const[coupantextValue,setcoupantextValue]=useState(false)
  // const[discountdata,setDiscountData]=useState()
  const [showAlternateImage, setShowAlternateImage] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);
  // const [tempAddress, setTempAddress] = useState({});
  const getCounpanCode = useSelector((state) => state?.user?.coupneCode);
  const [radioValue, setRadioValue] = useState("");

  const coupanCode = () => {
    setopenCoupancode(true);
  };

    const [checkoutShippingAddressUpdateV2] = useMutation(checkoutUpdateMutation);

  const hanldeAddressDefault = (address) => {
    checkoutShippingAddressUpdateV2({
      variables: {
        checkoutId: shippingValue?.data?.checkoutCreate?.checkout?.id,
        shippingAddress: {
          firstName: addressID?.firstName,
          lastName: addressID?.firstName,
          address1: addressID?.address1,
          city: addressID?.city,
          province: addressID?.province,
          country: addressID?.country,
          zip: addressID?.zip,
        },
      },
    })
      .then((res) => {})
      .catch((error) => {});
    setMakeId(address?.id);
    navigate("/editaddress", {
      state: { Id: address, yourBagId: 1, defaultAddId: 2 },
    });
  };

  useEffect(() => {
    setLoading(true);
    // client.query({
    //             query: addressDetails,
    //             variables: { customerAccessToken: accessToken },
    //         })
    //         .then((res) => {

    //         });

    getCustomerDetails(accessToken).then((res) => {
      setLoading(false);
      let customer = res.data;
      dispatch(setDefaultUserAddress(customer?.data?.customer?.defaultAddress));
      let addressInArray = customer?.data?.customer?.addresses?.edges;
      setDefAddress(customer?.data?.customer?.defaultAddress);
      setDefAddressID(customer?.data?.customer?.defaultAddress?.id);
      setRadioValue(customer?.data?.customer?.defaultAddress?.id)
      setDefNameMob(customer?.data?.customer);
      const result = cartItems.map(({ merchandiseId, quantity }) => ({
        variantId: merchandiseId,
        quantity: quantityCart,
      }));
      checkoutCreate({
        variables: {
          email: userDetails?.email,
          lineItems: result,
          shippingAddress: {
            firstName:
              (tempAddress && Object.keys(tempAddress).length != 0) ||
              tempAddress === null
                ? tempAddress?.firstName
                : customer?.data?.customer?.defaultAddress?.firstName,
            lastName:
              (tempAddress && Object.keys(tempAddress).length != 0) ||
              tempAddress === null
                ? tempAddress?.firstName
                : customer?.data?.customer?.defaultAddress?.firstName,
            address1:
              (tempAddress && Object.keys(tempAddress).length != 0) ||
              tempAddress === null
                ? tempAddress?.address1
                : customer?.data?.customer?.defaultAddress?.address1,
            city:
              (tempAddress && Object.keys(tempAddress).length != 0) ||
              tempAddress === null
                ? tempAddress?.city
                : customer?.data?.customer?.defaultAddress?.city,
            province:
              (tempAddress && Object.keys(tempAddress).length != 0) ||
              tempAddress === null
                ? tempAddress?.province
                : customer?.data?.customer?.defaultAddress?.province,
            country:
              (tempAddress && Object.keys(tempAddress).length != 0) ||
              tempAddress === null
                ? tempAddress?.country
                : customer?.data?.customer?.defaultAddress?.country,
            zip:
              (tempAddress && Object.keys(tempAddress).length != 0) ||
              tempAddress === null
                ? tempAddress?.zip
                : customer?.data?.customer?.defaultAddress?.zip,
          },
        },
      })
        .then((res) => {
          dispatch(setshippingValue(res));
        })
        .catch((error) => {
          console.error(error, "checkouterror");
        });
    });
  }, []);

  const [checkoutCreate] = useMutation(checkoutCreateMutation);

  if (
    shippingValue?.data?.checkoutCreate?.checkout?.availableShippingRates
      ?.shippingRates[0]?.priceV2?.amount === 0
  ) {
  } else {
    // console.log(
    //   shippingValue?.data?.checkoutCreate?.checkout?.availableShippingRates
    //     ?.shippingRates[0]?.priceV2?.amount,
    //   "shipppingamount"
    // );
  }

  let freeChargeValue =
    shippingValue?.data?.checkoutCreate?.checkout?.availableShippingRates
      ?.shippingRates[0]?.priceV2?.amount === 0;
  let totalPriceAmount =
    shippingValue?.data?.checkoutCreate?.checkout?.totalPriceV2?.amount;
  let discountAmountValue =
    discountdata?.checkoutDiscountCodeApplyV2?.checkout?.discountApplications
      ?.nodes[0]?.value?.amount;
  let shippingAmopunt =
    shippingValue?.data?.checkoutCreate?.checkout?.availableShippingRates
      ?.shippingRates[0]?.priceV2?.amount;
  let totalPriceAfterdiscount =
    discountdata?.checkoutDiscountCodeApplyV2?.checkout?.totalPrice.amount;
  let num1 = Number(createCardValues?.estimatedCost?.totalAmount.amount);
  let num2 = Number(shippingAmopunt);

  let totalAmoutProduct = Number(num1 + num2);
  let totalAmountAfterDiscount = Number(totalPriceAfterdiscount);

  const [cartDiscountCodesUpdate] = useMutation(discountCoupanMutation);

  const coupanCondition =
    discountdata?.cartDiscountCodesUpdate?.cart?.discountCodes[0]
      ?.applicable === true;
  const [coupanCodeMessage, setCoupanCodeMessage] = useState("");
  const [discountcodeMessage, setdiscountcodeMessage] = useState();

  const [checkoutDiscountCodeApplyV2] = useMutation(
    Coupan_Discount_CheckOut_Id
  );

  const handleDiscountCoupanUpdate = async () => {
    if (
      (defAddress && Object.keys(defAddress).length == 0) ||
      defAddress == null
    ) {
      failureToastMessage("First Add Delivery Address");
    } else {
      const formattedCouponCodeValue = coupanCodeValue.replace(/\s/g);
      const formattedCouponCodes = getCounpanCode.map((code) =>
        code.replace(/\s/g, "")
      );
      if (formattedCouponCodes.includes(formattedCouponCodeValue)) {
        // successToastMessage("Coupon code is valid!")

        if (coupanCodeValue) {
          setIsLoading(true);
          try {
            const checkoutId =
              shippingValue?.data?.checkoutCreate?.checkout?.id;
            //    const checkoutId = "gid://shopify/Checkout/9f9ba2502cc7462ea0889f7759336203?key=b0bcef9a3529f397a4974c5baefb9f72"
            const discountCode = coupanCodeValue;
            const { data } = await checkoutDiscountCodeApplyV2({
              variables: { checkoutId, discountCode },
            });
            dispatch(setDiscountData(data));
            if (
              data?.checkoutDiscountCodeApplyV2?.checkout?.discountApplications
                ?.nodes[0]?.applicable == true
            ) {
              dispatch(setcoupantextValue(true));
              if (
                data?.checkoutDiscountCodeApplyV2?.checkout
                  ?.discountApplications?.nodes[0].targetType == "SHIPPING_LINE"
              ) {
                if (
                  data?.checkoutDiscountCodeApplyV2?.checkout
                    ?.discountApplications?.nodes[0].value.percentage == 100
                ) {
                  freeChargeValue = true;
                }
              } else {
                totalAmountAfterDiscount =
                  Number(totalPriceAfterdiscount) + Number(shippingAmopunt);
              }
              successToastDiscountMessage(
                `Congratulations! Coupon has been applied to your bag`
              );
              // setCoupanCodeMessage(`Congratulations! Rs-${discountAmount} discount has been applied to your bag!`)
              //  setCoupanCodeMessage("Congratulations!Coupon has been applied to your bag!")
            } else if (
              data?.checkoutDiscountCodeApplyV2?.checkout?.discountApplications
                ?.nodes[0]?.applicable == false
            ) {
              setCoupanCodeMessage("Not Applicable for this Item");
            }
            setIsLoading(false);
          } catch (error) {
            dispatch(setcoupantextValue(false));
            setIsLoading(false);
            console.error("Error updating discount codes:", error);
          }
        } else {
          setdiscountcodeMessage("Enter A Coupon Code");
        }
      }
      else if(!coupanCodeValue){
        failureToastMessage("Please Enter Coupon Code");
      }
       else {
        failureToastMessage("Coupon code is not valid!");
      }
    }
  };

  useEffect(() => {
    if (!coupantextValue) {
      setCoupanCodeMessage("");
    }
    dispatch(setcoupantextValue(""));
    dispatch(setselectCoupanCode(""));
  }, []);
  const currenyValue =
    discountdata?.checkoutDiscountCodeApplyV2?.checkout?.discountApplications
      ?.nodes[0]?.value?.currencyCode;
  let discountAmount =
    discountdata?.checkoutDiscountCodeApplyV2?.checkout?.discountApplications
      ?.nodes[0]?.value?.amount;

  const [checkoutDiscountCodeRemove] = useMutation(Remove_Coupan_Code);

  const handleRemoveDiscountCoupan = async () => {
    try {
      const checkoutId = shippingValue?.data?.checkoutCreate?.checkout?.id;
      const { data } = await checkoutDiscountCodeRemove({
        variables: { checkoutId },
      });

      dispatch(setcoupantextValue(false));
      setdiscountcodeMessage("");
    } catch (error) {
      dispatch(setcoupantextValue(true));
      console.error("Error updating discount codes:", error);
    }
  };

  let totalAmount = coupantextValue
    ? totalAmountAfterDiscount
    : shippingValue?.data?.checkoutCreate?.checkoutUserErrors[0]?.code ===
      "BLANK"
    ? createCardValues.estimatedCost?.totalAmount.amount
    : totalAmoutProduct;

  const handleCheckout = () => {
    dispatch(setTotalPrice(totalAmount));
    // dispatch(setTotalPriceWithoutRound(totalAmount));
    navigate("/payment");
  };

  // const [coupanValue, setCoupanValue] = useState("");

  useEffect(() => {
    const code =
      discountdata?.checkoutDiscountCodeApplyV2?.checkout?.discountApplications
        ?.nodes[0]?.code;
    if (code === "NOBIY10") {
      let CoupandiscountPrice =
        //  createCardValues?.estimatedCost?.subtotalAmount?.amount-discountdata?.checkoutDiscountCodeApplyV2?.checkout?.subtotalPrice?.amount;
        parseFloat(
          (10 / 100) * createCardValues?.estimatedCost?.subtotalAmount?.amount
        );
      let CoupandiscountPriceAfterFixed = CoupandiscountPrice.toFixed(2);
      if (coupantextValue) {
        dispatch(setCoupanValue(CoupandiscountPriceAfterFixed));
      } else {
        dispatch(setCoupanValue("0"));
      }
    } else if (
      code === "N0001" ||
      code === "N0002" ||
      code === "N0003" ||
      code === "N0004" ||
      code === "N0005" ||
      code === "N0006" ||
      code === "N0007" ||
      code === "N0008" ||
      code === "N0009" ||
      code === "N0010" ||
      code === "N0011" ||
      code === "N0012" ||
      code === "N0013" ||
      code === "N0014" ||
      code === "N0015" ||
      code === "N0016" ||
      code === "N0017" ||
      code === "N0018" ||
      code === "N0019" ||
      code === "N0020" ||
      code === "N0021" ||
      code === "N0022" ||
      code === "N0023" ||
      code === "N0024" ||
      code === "NN01"
    ) {
      let CoupandiscountPrice =
        //  createCardValues?.estimatedCost?.subtotalAmount?.amount-discountdata?.checkoutDiscountCodeApplyV2?.checkout?.subtotalPrice?.amount;
        parseFloat(
          (16 / 100) * createCardValues?.estimatedCost?.subtotalAmount?.amount
        );
      let CoupandiscountPriceAfterFixed = CoupandiscountPrice.toFixed(2);
      if (coupantextValue) {
        dispatch(setCoupanValue(CoupandiscountPriceAfterFixed));
      } else {
        dispatch(setCoupanValue("0"));
      }
    } else {
      dispatch(setCoupanValue("0"));
    }
  }, [coupantextValue]);

  const dicountPre =
    (20 / 100) * createCardValues?.estimatedCost?.totalAmount.amount;
  const dicountTotalPrice =
    parseFloat(
      (20 / 100) * createCardValues?.estimatedCost?.totalAmount.amount
    ) + parseFloat(coupanValue);
  const compareTotalPrice =
    parseFloat(createCardValues?.estimatedCost?.totalAmount.amount) -
    dicountTotalPrice;

  const handleAddPopup = () => {
    setAddressPopup(true);
  };

  return (
    <>
      {loading ? (
        <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box sx={{ mt: { xs: "8vh", md: "13.5vh", sm: "8vh" } }}>
          <Container
            sx={{ mt: { lg: "3vh", xs: "", sm: "4vh", md: -"2vh" }, mb: "1vh" }}
          >
            <Typography
              color="#006D82"
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                marginBottom: "1rem",
                mt:"4rem"
              }}
              onClick={() => navigate("/cart")}
            >
              <ArrowBackIcon sx={{ fontSize: 30, mb: -1, cursor: "pointer" }} />
              Your Bag
            </Typography>
            <Card
              sx={{
                borderRadius: "20px",
                backgroundColor: "#18161D",
                width: "100%",
                boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.5)",
              }}
            >
              <Grid container>
                <Grid item md={6} xs={12} sm={12} lg={6}>
                  <Container
                    sx={{
                      // padding: {
                      //   lg: "2rem 4rem",
                      //   md: "2rem 3rem ",
                      //   xs: "1rem 0.5rem",
                      //   sm: " 2rem",
                      // },
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        height: "calc(11rem)",
                        overflowX: "auto",
                        color: "white",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      {cartItems?.map((e) => {
                        return (
                          <Box
                            sx={{
                              padding: {
                                xs: "0 1rem",
                                sm: "0 1rem",
                                md: "0 2rem",
                                lg: "0 1rem",
                              },
                            }}
                          >
                            <Box
                              component="img"
                              sx={{
                                height: "9.5rem",
                                // background: "white",
                              }}
                              alt="CartImage"
                              src={e.image}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        mt: 1,
                        alignItems: "center",
                        ml: { xs: 1, sm: 2, md: 6, lg: 6 },
                      }}
                    >
                      <TextField
                        sx={{
                          bgcolor: "rgba(255, 255, 255, 0.1)",
                          color: "white",
                        }}
                        onChange={(e) => {
                          dispatch(setselectCoupanCode(e.target.value));
                          if (e.target.value == "") {
                            setdiscountcodeMessage("Enter A coupon Code");
                          } else {
                            setdiscountcodeMessage("");
                          }
                        }}
                        id="coupenCode"
                        height="10px"
                        type="text"
                        size="small"
                        variant="outlined"
                        placeholder="Enter Code Here"
                        inputProps={{
                          maxLength: 50,
                          style: {
                            color: "white",
                            height: "1rem",
                            width: "16rem",
                          },
                        }}
                        value={coupanCodeValue}
                      />
                      {coupantextValue ? (
                        <Button
                          onClick={() => {
                            handleRemoveDiscountCoupan();
                            dispatch(setselectCoupanCode(""));
                          }}
                          sx={{
                            bgcolor: "#EA9A23",
                            color: "#FFFFFF",
                            p: "0.5rem 0rem",
                            textTransform: "none",
                            height: "2.2rem",
                            width: "5rem",
                          }}
                          variant="contained"
                        >
                          Remove
                        </Button>
                      ) : (
                        <Button
                          onClick={handleDiscountCoupanUpdate}
                          sx={{
                            bgcolor: "#006D82",
                            color: "#FFFFFF",
                            p: "0.5rem 0rem",
                            textTransform: "none",
                            height: "2.2rem",
                            width: "5rem",
                            position: "relative",
                          }}
                          variant="contained"
                        >
                          {isLoading && (
                            <CircularProgress
                              size={24}
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: -12,
                                marginLeft: -12,
                                color: "white",
                              }}
                            />
                          )}
                          Apply
                        </Button>
                      )}
                    </Box>
                    <Typography
                      sx={{
                        color: "white",
                        // textAlign: "center",
                        ml:"10%",
                        fontFamily: "Raleway",
                      }}
                    >
                      {" "}
                      {discountcodeMessage}
                    </Typography>
                    <Box sx={{ ml: { xs: 0, sm: 2, md: 4, lg: 5 } }}>
                      <Button
                        variant="text"
                        onClick={coupanCode}
                        sx={{
                          textTransform: "none",
                          color: "white",
                          fontSize: 11,
                          mr: 1.5,
                          textDecoration: "underline",
                        }}
                      >
                        {" "}
                        Get Coupon Codes
                      </Button>
                    </Box>
                    <Divider
                      sx={{
                        bgcolor: "#F2F2F2",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                    />
                    {coupantextValue ? (
                      <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                        <Typography style={{ color: "white", flex: 2 }}>
                          Cart Total :
                        </Typography>
                        <Typography style={{ color: "white", flex: 1 }}>
                          {" "}
                          <CurrencyRupeeIcon sx={{ fontSize: 15, mb: -0.2 }} />
                          {
                            createCardValues?.estimatedCost?.subtotalAmount
                              ?.amount
                          }
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                        <Typography style={{ color: "white", flex: 2 }}>
                          Cart Total :
                        </Typography>
                        <Typography style={{ color: "white", flex: 1 }}>
                          <CurrencyRupeeIcon sx={{ fontSize: 15, mb: -0.2 }} />
                          {
                            createCardValues?.estimatedCost?.subtotalAmount
                              ?.amount
                          }
                        </Typography>
                      </Box>
                    )}
                    <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                      <Typography style={{ color: "white", flex: 2 }}>
                        Discount Price :
                      </Typography>
                      <Typography style={{ color: "white", flex: 1 }}>
                       <CurrencyRupeeIcon sx={{ fontSize: 15, mb: -0.2 }} />
                        {dicountPre.toFixed(2)}
                      </Typography>
                    </Box>
                    {coupantextValue ? (
                      <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                        <Typography style={{ color: "white", flex: 2 }}>
                          {" "}
                          Apply Coupon:
                        </Typography>
                        <Typography style={{ color: "white", flex: 1 }}>
                          {" "}
                          -<CurrencyRupeeIcon sx={{ fontSize: 15, mb: -0.2 }} />
                          {coupanValue}
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                    {/* {coupantextValue ? (
                      <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                        <Typography style={{ color: "white", flex: 1 }}>
                          GST :
                        </Typography>
                        <Typography style={{ color: "white", flex: 1 }}>
                          {" "}
                          <CurrencyRupeeIcon sx={{ fontSize: 15, mb: -0.2 }} />
                          {
                            discountdata?.checkoutDiscountCodeApplyV2?.checkout
                              ?.totalTax.amount
                          }
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                        <Typography style={{ color: "white", flex: 1 }}>
                          GST :
                        </Typography>
                        <Typography style={{ color: "white", flex: 1 }}>
                          {" "}
                          <CurrencyRupeeIcon sx={{ fontSize: 15, mb: -0.2 }} />
                          {
                            createCardValues?.estimatedCost?.totalTaxAmount
                              ?.amount
                          }
                        </Typography>
                      </Box>
                    )} */}

                    {coupantextValue &&
                    discountdata?.checkoutDiscountCodeApplyV2?.checkout
                      ?.discountApplications?.nodes[0]?.targetType ===
                      "SHIPPING_LINE" ? (
                      <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                        <Typography style={{ color: "white", flex: 2 }}>
                          Shipping Charges :
                        </Typography>
                        <Typography style={{ color: "white", flex: 1 }}>
                          Free
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                        <Typography style={{ color: "white", flex: 2 }}>
                          Shipping Charges :
                        </Typography>
                        <Typography style={{ color: "white", flex: 1 }}>
                          Free
                        </Typography>
                      </Box>
                    )}

                    <Divider
                      sx={{
                        bgcolor: "#F2F2F2",
                        marginTop: "1rem",
                        marginBottom: "0.5rem",
                      }}
                    />

                    {/* <Box style={{ display: 'flex', }}>
                                        <Typography variant='subtitle1' sx={{
                                            color: "#F2F2F2", fontSize: 13, marginLeft: { xs: "1rem", sm: "2rem", md: "3rem", lg: "3rem" },
                                            width: { xs: "7rem", sm: "7rem", md: "8rem", lg: "8rem" }
                                        }} gutterBottom>Total Amount</Typography>
                                        
                                        <Typography variant='subtitle1' sx={{ color: "#F2F2F2", fontSize: 13, marginLeft: "4.6rem", width: "4rem", }} gutterBottom><CurrencyRupeeIcon sx={{ fontSize: 15, mb: -.2,ml:{lg:-2.1,xs:-1.2} }} />{totalAmount}/-</Typography>
                                    </Box> */}
                    <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                      <Typography style={{ color: "white", flex: 2 }}>
                        Total Amount :
                      </Typography>
                      <Typography style={{ color: "white", flex: 1 }}>
                        {" "}
                        <CurrencyRupeeIcon sx={{ fontSize: 15, mb: -0.2 }} />
                        {compareTotalPrice.toFixed(2)}
                      </Typography>
                    </Box>
                  </Container>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  sm={12}
                  lg={6}
                  sx={{
                    borderStyle: "solid",
                    borderColor: "#EA9A23",
                    mt: { xs: 1, sm: 0, md: 3, lg: 3 },
                    mb: { xs: 0, sm: 0, md: 3, lg: 3 },
                    mr: { xs: 2, sm: 2, md: 0, lg: 0 },
                    ml: { xs: 2, sm: 2, md: 0, lg: 0 },
                    height: "29rem",
                    borderWidth: {
                      xs: "2px 0 0 0",
                      sm: "2px 0 0 0",
                      md: "0 0 0 2px",
                      lg: "0 0 0 2px",
                    },
                  }}
                >
                  <Container sx={{}}>
                    <Box
                      sx={{
                        p: { xs: 0, sm: 2, md: 4, lg: 4 },
                        pt: { xs: 4, sm: 5, md: 8, lg: 8 },
                      }}
                    >
                      {/* <Box sx={{ display: 'flex', ml: { xs: -1, sm: 1, md: 0, lg: 0 } }}>
                                            <Typography variant='subtitle1' sx={{
                                                color: "#F2F2F2", fontSize: { xs: 11, sm: 12, md: 13, lg: 14 },
                                                width: { xs: "10rem", sm: "11rem", md: "12rem", lg: "14rem" }
                                            }} >Select Delivery Address</Typography>
                                            <Box sx={{ ml: { xs: 3, sm: 4, md: 5, lg: 5 } }}>
                                                <Button variant="text" onClick={() => { navigate("/editaddress", { state: { yourBagId: 1 } }) }}
                                                    sx={{ textTransform: "none", color: "#83CAD8", fontSize: { xs: 11, sm: 12, md: 13, lg: 14 }, mt: -.8 }}
                                                > +Add Address</Button>
                                            </Box>
                                        </Box> */}
                      <Box
                        sx={{ display: "flex", justifyContent: "space-around" }}
                      >
                        <Typography style={{ color: "white" }}>
                          Select Delivery Address{" "}
                        </Typography>
                        <Typography style={{ color: "white" }}>
                          {" "}
                          <Button
                            variant="text"
                            onClick={() => {
                              navigate("/editaddress", {
                                state: { yourBagId: 1 },
                              });
                            }}
                            sx={{
                              textTransform: "none",
                              color: "#83CAD8",
                              mt: -0.8,
                            }}
                          >
                            {" "}
                            + Add Address
                          </Button>
                        </Typography>
                      </Box>
                      <Divider
                        sx={{
                          bgcolor: "#F2F2F2",
                          mb: { xs: 2, sm: 3, md: 4, lg: 5 },
                        }}
                      />
                      {(defAddress && Object.keys(defAddress).length === 0) ||
                      defAddress == null ? (
                        ""
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            ml: { xs: 0, sm: 6 },
                            mr: { xs: 0, sm: 5 },
                          }}
                        >
                          <Box sx={{ color: "white" }}>
                            <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                              {tempAddress &&
                              Object.keys(tempAddress).length != 0
                                ? tempAddress?.firstName
                                : defAddress?.firstName}
                            </Typography>
                            <Typography sx={{ mt: 1 }}>
                              {tempAddress &&
                              Object.keys(tempAddress).length != 0
                                ? tempAddress?.address1
                                : defAddress?.address1}
                              ,{" "}
                              {tempAddress &&
                              Object.keys(tempAddress).length != 0
                                ? tempAddress?.address2
                                : defAddress?.address2}{" "}
                              {tempAddress &&
                              Object.keys(tempAddress).length != 0
                                ? tempAddress?.city
                                : defAddress?.city}{" "}
                              {tempAddress &&
                              Object.keys(tempAddress).length != 0
                                ? tempAddress?.province
                                : defAddress?.province}
                            </Typography>
                            <Typography sx={{}}>
                              {tempAddress &&
                              Object.keys(tempAddress).length != 0
                                ? tempAddress?.country
                                : defAddress?.country}{" "}
                              {tempAddress &&
                              Object.keys(tempAddress).length != 0
                                ? tempAddress?.zip
                                : defAddress?.zip}
                            </Typography>
                            <Typography sx={{ mt: ".1rem" }}>
                              <b>
                                Phone -{" "}
                                {tempAddress &&
                                Object.keys(tempAddress).length != 0
                                  ? tempAddress?.phone
                                  : defAddress?.phone}
                              </b>
                            </Typography>
                          </Box>
                          <Box>
                            <Button
                              variant="text"
                              onClick={() =>
                                hanldeAddressDefault(
                                  tempAddress &&
                                    Object.keys(tempAddress).length != 0
                                    ? tempAddress
                                    : defAddress
                                )
                              }
                              sx={{ textTransform: "none", color: "#83CAD8" }}
                            >
                              {" "}
                              Edit
                            </Button>
                          </Box>
                        </Box>
                      )}
                      {(defAddress && Object.keys(defAddress).length == 0) ||
                      defAddress === null ? (
                        ""
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              mt: "2rem",
                            }}
                          >
                            <Button
                              sx={{
                                bgcolor: "#006D82",
                                color: "#FFFFFF",
                                p: "0.5rem 0rem",
                                textTransform: "none",
                                height: "2.2rem",
                                width: "13rem",
                                mr: { sm: "1rem", xs: 0 },
                              }}
                              variant="contained"
                              onClick={handleCheckout}
                            >
                              Deliver Here
                            </Button>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              mt: "2rem",
                            }}
                          >
                            <Button
                              sx={{
                                bgcolor: "#006D82",
                                color: "#FFFFFF",
                                p: "0.5rem 0rem",
                                textTransform: "none",
                                height: "2.2rem",
                                width: "13rem",
                              }}
                              variant="contained"
                              onClick={handleAddPopup}
                            >
                              Change Address
                            </Button>
                          </Box>
                        </Box>
                      )}
                      {(defAddress && Object.keys(defAddress).length == 0) ||
                      defAddress === null ? (
                        ""
                      ) : (
                        <Divider sx={{ bgcolor: "#F2F2F2", mt:{sm:5,xs:2} }} />
                      )}
                      {(defAddress && Object.keys(defAddress).length == 0) ||
                      defAddress === null ? (
                        ""
                      ) : (
                        <Box sx={{ }}>
                          <Button
                            variant="text"
                            onClick={() => {
                              navigate("/myaddress", {
                                state: { yourBagId: 1 },
                              });
                            }}
                            sx={{
                              textTransform: "none",
                              color: "#83CAD8",
                              // mt: -0.8,
                            }}
                          >
                            My All Address
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Container>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Box>
      )}
      <CouponCode
        opencoupancode={opencoupancode}
        setopenCoupancode={setopenCoupancode}
        setdiscountcodeMessage={setdiscountcodeMessage}
        handleRemoveDiscountCoupan={handleRemoveDiscountCoupan}
      />
      {addressPopup && 
      <AddressPopup 
      addressPopup={addressPopup} 
      setDefAddress={setDefAddress}
      setRadioValue={setRadioValue}
      radioValue={radioValue}
      // tempAddress ={tempAddress}
      // setTempAddress={setTempAddress}
      defAddress={defAddress} 
      setAddressPopup={setAddressPopup}/>}
    </>
  );
}

export default YourBag;
