import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { setallProductcardValue, setdenimSeries } from "../../Redux/CartSlice";
import { setCheckScroll } from "../../Redux/UserSlice";
import {
  ProductCollectionId,
  allProductCollectionId,
  bannerImageId,
  bannerImgHome,
  bannerMarqueeId,
  bannerMarqueeImg,
  getProductCollectionId,
  slickImagePart1,
  slickImagePart2,
} from "../../api/Queries";
import Footer from "../../common/Footer";
import { client } from "../../common/Utils";
import "./Home.css";
import imagebanner from "../../images/imagebanner.png"

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imageUrls, setImageUrls] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [bannerImg, setBannerImg] = useState([]);
  const [marqueeBanner, setMarqueeBanner] = useState([]);
  const [aim, setaim] = useState([]);
  const [marqueeDescription, setMarqueeDescription] = useState("");


  const handleData = async (res) => {
    const responseData = await client
      .query({
        query: slickImagePart2,
        variables: { collectionId: res },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        let image = res?.data?.collection?.image?.url;
        let title = res?.data?.collection?.title;
        let object = { title: title, image: image };
        setImageUrls((imageUrls) => [...imageUrls, object]);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client
          .query({
            query: slickImagePart1,
          })
          .then((res) => {
            res?.data?.menu?.items?.forEach((e) => handleData(e.resourceId));
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  // api for getting Banner image
  const handleBannerImg = async (res) => {
    const responseData = await client
      .query({
        query: bannerImageId,
        variables: { collectionId: res },
      })
      .then((res) => {
        let image = res?.data?.collection?.image?.url;
        setBannerImg((imageUrls) => [...imageUrls, image]);
      });
  };

  // api for getting resource id for  image
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client
          .query({
            query: bannerImgHome,
          })
          .then((res) => {
            res?.data?.menu?.items?.forEach((e) =>
              handleBannerImg(e.resourceId)
            );
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  // api for getting Banner of Marquee Screen
  const handleMarqueeBanner = async (res) => {
    const responseData = await client
      .query({
        query: bannerMarqueeId,
        variables: { collectionId: res },
      })
      .then((res) => {
        setMarqueeDescription(res?.data?.collection?.description);
        let image = res?.data?.collection?.image?.url;
        setMarqueeBanner((imageUrls) => [...imageUrls, image]);
      });
  };

  // api for getting resource id for Marquee Image
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client
          .query({
            query: bannerMarqueeImg,
          })
          .then((res) => {
            res?.data?.menu?.items?.forEach((e) =>
              handleMarqueeBanner(e.resourceId)
            );
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  // api for getting all product value

  const handleAllproduct = async (res, cursor = null) => {
    await client
      .query({
        query: allProductCollectionId,
        variables: { id: res, cursor },
      })
      .then((res) => {
        let products = res?.data?.collection?.products;

        setAllProduct((allProduct) => [...allProduct, products]);
      });
  };

  useEffect(() => {
    dispatch(setallProductcardValue(allProduct));
  }, [allProduct]);

  // code for getting resourceId for product for 4 series
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await client
          .query({
            query: getProductCollectionId,
          })
          .then((resd) => {
            resd?.data?.menu?.items?.forEach((e) =>
              handleAllproduct(e.resourceId)
            );
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetch();
  }, []);

  const ProductCollectiongetId = async (res) => {
    await client
      .query({
        query: ProductCollectionId,
        variables: { id: res },
      })
      .then((response) => {
        let products = response;
        let seriesImage = products.data.collection.image.url;
        let seriesTitle = products.data.collection.title;
        let seriesObject = { seriesImage: seriesImage, image: seriesTitle };
        setaim((aim) => [...aim, products]);
      });
  };
  
  useEffect(() => {
    if (aim && aim.length === 4) {
      const desiredOrder = ["TOPS", "BOTTOMS", "DENIM", "OUTERWEAR"];
      const sortedAim = aim.slice().sort((a, b) => {
        return (
          desiredOrder.indexOf(a.data.collection.title) -
          desiredOrder.indexOf(b.data.collection.title)
        );
      });

      // Ensure the sorting worked and aim is updated with the desired order
      if (JSON.stringify(aim) !== JSON.stringify(sortedAim)) {
        // Update aim with the sorted order
        setaim(sortedAim);
      }
    }
  }, [aim]);

  useEffect(() => {
    const fetch = async () => {
      try {
        await client
          .query({
            query: getProductCollectionId,
          })
          .then((resd) => {
            const items = resd.data.menu.items;
            items.forEach((item, index) => {
              const resourceId = item.resourceId;
              ProductCollectiongetId(resourceId);
            });
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    dispatch(setdenimSeries(aim));
  }, [aim]);
  const getValueByCollectionId = (collection) => {
    dispatch(setdenimSeries(aim));
    dispatch(setCheckScroll(0));
    navigate("/cat", { state: collection?.title });
  };

  return (
    <>
      <Box
        sx={{
          mt: { xs: "7.2vh", sm: "5.8vh", md: "11vh" },
          backgroundColor: "white",
        }}
      >
        <Box>
          <div>
            <Box>
              <Box
                component="img"
                onClick={() => navigate("/cat")}
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  objectFit: "contain",
                  display: {
                    xs: "none",
                    sm: "block",
                    lg: "block",
                    md: "block",
                  },
                }}
                src="./Banner_DTversion.jpg"
              />
            </Box>
          </div>
        </Box>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ display: { xs: "none", sm: "none", md: "none" } }}
        >
          <Grid item lg={12} xs={12} onClick={() => navigate("/cat")}>
            <Box
              component="img"
              sx={{
                cursor: "pointer",
                width: "100%",
                objectFit: "contain",
              }}
              src="./Web-Banner.jpg"
            />
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ display: { xs: "block", sm: "block", md: "none" } }}
        >
          <Grid
            item
            lg={12}
            xs={12}
            sx={{ position: "relative" }}
            onClick={() => navigate("/cat")}
          >
            <Box
              component="img"
              sx={{
                cursor: "pointer",
                width: "100%",
                objectFit: "contain",
                marginTop: {md:"-1rem",xs:".1rem"},
              }}
              src="./NewImagePhone.png"
            />
            <Button
              variant="contained"
              sx={{
                position: "absolute",
                // textTransform: "none",
                // border:"2px solid white",
                borderRadius: "0.7rem",
                padding: "0.4rem 0.6rem",
                backgroundColor: "rgb(250, 249, 246)",
                opacity: "0.9",
                fontStyle: "normal",
                fontWeight: "800",
                fontSize: "1.1rem",
                color: "#107081",
                bottom: "4%",
                left: "48%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <strong> SHOP NOW </strong>
            </Button>
          </Grid>
        </Grid>
        <Box
          component="img"
          onClick={() => navigate("/cat")}
          sx={{
            borderRadius: "1rem",
            padding: "0.5rem 0.6rem",
            mt: "-.2rem",
            cursor: "pointer",
            width: "95%",
            objectFit: "contain",
            display: { xs: "block", sm: "none", lg: "none", md: "none" },
          }}
          src="./IntroductoryBanner1.jpg"
        />
        <Box
          sx={{
            marginTop: { lg: "0.6rem", md: "0.6rem", sm: "1rem", xs: "1rem" },
            paddingTop: "1.3rem",
            backgroundColor: "white",
          }}
        >
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              item
              lg={2.4}
              md={2.4}
              textAlign="center"
              sx={{
                cursor: "pointer",
                mt: "-.7rem",
                display: { lg: "block", md: "block", sm: "none", xs: "none" },
              }}
            >
              <Box
                onClick={() => {
                  navigate("/cat");
                }}
                component="img"
                sx={{
                  height: {
                    lg: "15rem",
                    xs: "11rem",
                    sm: "11rem",
                    md: "11rem",
                  },
                  objectFit: "cover",
                  border: "1px solid white",
                  padding: { lg: "0 3rem", xs: "0 0.3rem" },
                  transition: "transform 0.15s ease-in-out",
                  borderRadius: "1rem",
                  //  boxShadow: "0px 0px 8px rgba(0, 0, 0.2)",
                  backgroundColor: "#F8F8FF",
                  "&:hover": { transform: "scale3d(1.15, 1.15, 1)" },
                }}
                alt="CartImage"
                src="./All.png"
              />
              <Typography
                onClick={() => {
                  navigate("/cat");
                }}
                sx={{
                  position: "relative",
                  color: "#006D82",
                  fontFamily: "Arial",
                  marginTop: { lg: "1rem", xs: "-0.2rem" },
                  fontSize: {
                    lg: "1.3rem",
                    md: "1.6rem",
                    sm: "1.2rem",
                    xs: "1rem",
                  },
                  fontWeight: "bold",
                }}
              >
                ALL
              </Typography>
            </Grid>

            {aim?.map((e, index) => (
              <Grid
                item
                lg={2.4}
                md={2}
                sm={3}
                xs={5.8}
                textAlign="center"
                key={index}
                sx={{ cursor: "pointer", mt: "-2.3rem" }}
              >
                <Box
                  onClick={() => {
                    getValueByCollectionId(e?.data?.collection);
                  }}
                  component="img"
                  sx={{
                    height: {
                      lg: "15rem",
                      xs: "16rem",
                      sm: "17rem",
                      md: "11rem",
                    },

                    objectFit: "cover",
                    border: "1px solid white",
                    padding: { lg: "0 3rem", xs: 0, sm: 0 },
                    transition: "transform 0.15s ease-in-out",
                    borderRadius: { lg: "1rem", sm: "0.5rem", xs: "0.5rem" },
                    //  boxShadow: "0px 0px 8px rgba(0, 0, 0.2)",
                    backgroundColor: "#F8F8FF",
                    "&:hover": { transform: "scale3d(1.15, 1.15, 1)" },
                  }}
                  alt="CartImage"
                  src={e?.data?.collection?.image?.url}
                  loading="lazy"
                />
                <Typography
                  onClick={() => {
                    getValueByCollectionId(e?.data?.collection);
                  }}
                  sx={{
                    position: "relative",
                    color: "#107081",
                    fontFamily: "Arial",
                    marginTop: { lg: "1rem", xs: "0.4rem" },
                    marginBottom: { lg: 0, xs: "3rem" },
                    fontSize: {
                      lg: "1.2rem",
                      md: "1.6rem",
                      sm: "1.2rem",
                      xs: "0.7rem",
                    },
                    fontWeight: "bold",
                    // width:"8rem",
                  }}
                >
                  {e?.data?.collection?.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Box
            component="img"
            onClick={() => navigate("/cat")}
            sx={{
              borderRadius: "1rem",
              margin: 0,
              padding: "0.5rem 0.6rem",
              mt: "-2.4rem",
              cursor: "pointer",
              width: "95%",
              objectFit: "contain",
              display: { xs: "block", sm: "none", lg: "none", md: "none" },
            }}
            src="/offerImage.jpeg"
          />
        </Box>

        {/* <Box
          sx={{
            marginTop: { xs: ".1rem", md: "1.3rem" },
          }}
        >
          <Box
            component="img"
            sx={{
              overflow: "hidden",
              objectFit: "cover",
              width: "100%",
              // height:"5rem"
            }}
            alt="CartImage"
            src="./Picture2.jpg"
          />
        </Box> */}

        <Box
          sx={{
            marginTop: { xs: "2rem", md: "2.5rem" },
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} paddingBottom={2}>
            <Grid item lg={6} md={6} sm={12} xs={12} textAlign="center">
              <Box
                component="img"
                sx={{
                  height: { md: "71vh", xs: "46vh" },
                  width: { xs: "90vw", md: "42vw" },
                  p: { md: 3 },
                  ml: { md: 4 },
                  mt: { xs: "-1.2rem", md: "0rem" },
                  cursor: "pointer",
                }}
                alt="imge"
                src={bannerImg[1]}
                onClick={() => navigate("/cat")}
              />
            </Grid>

            <Grid Grid item lg={6} md={6} sm={12} xs={12} textAlign="center">
              <Box
                component="img"
                sx={{
                  height: { md: "71vh", xs: "46vh" },
                  // maxWidth: "75vw",
                  mt: "-0.5rem",
                  width: { xs: "90vw", md: "42vw" },
                  p: { md: 3 },
                  mr: { md: 4 },
                  cursor: "pointer",
                }}
                alt="imge"
                src={bannerImg[0]}
                onClick={() => navigate("/cat")}
              />
            </Grid>
          </Grid>
        </Box>
        {/* for Desktop animation  */}
        <Box
          sx={{
            backgroundImage: `url(${imagebanner})`, 
            marginTop: { sm: "1rem", md: "2rem" },
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            display: { xs: "none", sm: "none", lg: "block" },
          }}
          height="18vh"
        >
          <Box class="container">
            <Box
              sx={{ height: { lg: "12rem", md: "10rem", sm: "10rem" } }}
              class="animation"
            >
              <div class="first">
                <div>
                  <Box
                    component="img"
                    // sx={{width:{xs:"5rem"}}}
                    sx={{
                      height: { lg: "14vh", md: "10vh", sm: "10vh" },
                      ml: -5,
                    }}
                    src="deliveryIcon.png"
                    alt=""
                  />
                  <Box
                    component="img"
                    src="SecurePayments.png"
                    alt=""
                    // sx={{width:{xs:"2rem"}}}
                    sx={{
                      height: { lg: "14vh", md: "10vh", sm: "10vh" },
                      ml: 8,
                    }}
                  />
                  <Box
                    component="img"
                    src="UniqueProducts.png"
                    alt=""
                    // sx={{width:{xs:"2rem"}}}
                    sx={{
                      height: { lg: "14vh", md: "10vh", sm: "10vh" },
                      ml: 14,
                    }}
                  />
                  <Box
                    component="img"
                    src="ChangingFashion.png"
                    alt=""
                    sx={{
                      height: { lg: "14vh", md: "10vh", sm: "10vh" },
                      ml: 13,
                    }}
                  />
                </div>
              </div>
              <div class="second">
                <div>
                  <Box
                    component="img"
                    sx={{
                      height: { lg: "11vh", md: "10vh", sm: "10vh" },
                      ml: 5,
                    }}
                    src="Be Your Truth .png"
                  />
                </div>
              </div>
            </Box>
          </Box>
        </Box>

        {/* for mobile animation code  */}
        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "block", lg: "none" },
          }}
        >
          <Box
            className="mainbody"
            sx={{
              backgroundImage: `url(${imagebanner})`,
              marginTop: "1rem",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              // height: "10vh",
              height: "12vh",
            }}
          >
            <Box class="item-1" sx={{}}>
              <Grid container>
                <Grid item lg={3} xs={3}>
                  <Box
                    component="img"
                    sx={{
                      width: { xs: "100px", sm: "160px", md: "120px" },
                      height: { xs: "60px", sm: "100px" },
                      mt: "1rem",
                    }}
                    alt="Delivery Image."
                    src="deliveryIcon.png"
                  />
                </Grid>
                <Grid item lg={3} xs={3}>
                  <Box
                    component="img"
                    sx={{
                      width: { xs: "60px", sm: "120px", md: "120px" },
                      height: { xs: "60px", sm: "100px" },
                      mt: "1rem",
                    }}
                    alt="Delivery Image."
                    src="SecurePayments.png"
                  />
                </Grid>

                <Grid item lg={3} xs={3}>
                  <Box
                    component="img"
                    sx={{
                      width: { xs: "60px", sm: "120px", md: "120px" },
                      height: { xs: "60px", sm: "100px" },
                      mt: "1rem",
                    }}
                    alt="Delivery Image."
                    src="UniqueProducts.png"
                  />
                </Grid>

                <Grid item lg={3} xs={3}>
                  <Box
                    component="img"
                    sx={{
                      width: { xs: "60px", sm: "120px", md: "120px" },
                      height: { xs: "60px", sm: "100px" },
                      mt: "1.5rem",
                    }}
                    alt="Delivery Image."
                    src="ChangingFashion.png"
                  />
                </Grid>
              </Grid>
            </Box>
            <p class="item-2">
              <Box
                component="img"
                sx={{
                  width: { xs: "15rem", sm: "30rem" },
                  height: { xs: "2rem", sm: "6rem" },
                  mt: { xs: -12.5, sm: -16 },
                  // marginTop:"1rem",
                  position: "absolute",
                }}
                src="Be Your Truth .png"
              />
            </p>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: { xs: "1rem", md: "1.5rem" },
          }}
        >
          <Box >
            <Box
              component="img"
              sx={{
                overflow: "hidden",
                objectFit: "contain",
                width: "100%",
                cursor: "pointer",
              }}
              alt="CartImage"
              src="./Footer_banner.jpg"
              onClick={() => navigate("/cat")}
            />
         
          </Box>
        </Box>

        <Box>
          <Footer position="static" />
        </Box>
      </Box>
    </>
  );
};

export default Home;
