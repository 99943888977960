import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export const fontSize = {
  fontSize: { xs: 12, sm: 14, md: 16, lg: 18 },
  mt: 2.5,
  color: "black",
  //   fontFamily:"cursive"
};

export const htag = {
  fontSize: { xs: 14, sm: 16, md: 18 },
  color: "black",
  display: "flex",
  alignItems: "center",
  backgroundColor: "rgba(13, 129, 128, 0.25)",
  paddingLeft: "20px",
  marginTop: "30px",
  p: "20px",
};

export const mHead = {
  fontSize: { xs: 22, sm: 25, md: 35 },
  mt: { xs: 0, md: "2rem" },
  textAlign: "center",
  color: "#0D8180",
  borderBottom: "4px solid #EA9A23",
  // fontFamily: "cursive",
};

function ReturnPolicy() {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          mt: "13vh",
          pr: { xs: 2, sm: 10, md: 25 },
          pl: { xs: 2, sm: 10, md: 25 },
          pb: 5,
        }}
      >
        <Typography sx={mHead}>
          <b>NOBIY: RETURN & EXCHANGE POLICY</b>
        </Typography>
        <Typography sx={{ fontSize: 15, color: "white", mt: "1rem" }}>
          Last Updated: August 04, 2023{" "}
        </Typography>
        <Typography sx={htag}>
          <b> 1. TIMELINE TO RETURN THE PRODUCTS</b>
        </Typography>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}>
              The return of any product should be made within 15 days from the
              delivery date (i.e. 15 days including holidays, Saturday & Sunday
              also).
            </Typography>
          </Box>{" "}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}>
              {" "}
              Delivery date is the date on which the customer receives the order
              at the address for the delivery as mentioned on the purchase
              order. It is expected that the products will be returned in the
              same condition as it is delivered i.e. unused and unwashed, tags
              are intact to the packaging used for delivery.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              Products such as face masks, socks, undergarments/bodywear for
              men, women, boys and girls are not eligible for return.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            <Typography sx={fontSize}>
              {" "}
              We do not accept returns of online orders at our
              Exclusive/Franchised/Large Format Stores(LFS)/ Retail stores
              (‘Stores’).
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            <Typography sx={fontSize}>
              {" "}
              There are different return methods depending on where you
              purchased your product. Products purchased at our online official
              site can only be returned to us. Products purchased at our Stores
              can only be returned to the Stores from which the products is/are
              originally purchased.
            </Typography>
          </Box>
        </Box>
        <Typography sx={htag}>
          <b>2. COST TO RETURN</b>
        </Typography>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              For all online return requests, shipping (delivery) is offered for
              free.{" "}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              An estimated pick-up date will be provided to you post
              confirmation via SMS/email.
            </Typography>
          </Box>
        </Box>
        <Typography sx={htag}>
          <b>3. RETURNED PRODUCT CONDITION</b>
        </Typography>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              All products to be returned must be unused and unwashed and in
              their original condition with all original tags and packaging
              intact (for e.g. shoes must be packed in the original shoe box as
              well and returned), and should not be broken or tampered with.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />{" "}
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              Request you to reach to customer service as details mentioned on
              the website to arrange for returns in case of any issues.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />{" "}
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              Ensure your invoice of purchase order is available with you
              (digital form is acceptable) while returning the product.
            </Typography>
          </Box>
        </Box>
        <Typography sx={htag}>
          <b>4. REFUND TIMELINES AFTER RETURNING THE PRODUCT</b>
        </Typography>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              Once the products reaches back to our warehouse and completes the
              quality check, refunds, if any, shall be initiated in accordance
              with the discretion of the Company, as per the below procedure:
            </Typography>
          </Box>
        </Box>
        <Typography sx={fontSize} style={{ marginLeft: "22px" }}>
          If your payment method is -{" "}
        </Typography>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />{" "}
          </Box>
          <Box>
            <Typography sx={fontSize}>
              <b>Prepaid - </b> Refunds will be initiated in 7 days and amount
              may take up to 5-15 business days depending upon your bank to
              reflect in your account.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />{" "}
          </Box>
          <Box>
            <Typography sx={fontSize}>
              <b>Cash on delivery (COD) - </b>Refunds will be initiated in 7
              days on the account details provided while raising returns request
              and amount may take up to 5-15 business days depending upon your
              bank to reflect in your account.
            </Typography>
          </Box>
        </Box>
        {/* <Typography color="#EA9A23" sx={{ fontWeight: 'bold', fontSize: '1.5rem', position: "absolute",bottom:0,left:0,p:"10px", cursor: "pointer" }}  onClick={() => navigate("/home")}>
          <ArrowBackIcon sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }} />Back</Typography> */}
      </Box>
      <Box sx={{ position: "fixed", bottom: 0 }}>
        <Typography
          color="#EA9A23"
          sx={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            left: 0,
            p: "10px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          <ArrowBackIcon
            sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }}
          />
          Back
        </Typography>
      </Box>
      {/* </Box> */}
    </>
  );
}

export default ReturnPolicy;
