import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { fontSize, htag, mHead } from "./ReturnPolicy";

function TermsAndConditions() {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          backgroundColor: "transparent",
          mt: "13vh",
          pr: { xs: 2, sm: 10, md: 25 },
          pl: { xs: 2, sm: 10, md: 25 },
          pb: 5,
        }}
      >
        <Typography sx={mHead}>
          <b>NOBIY: TERMS & CONDITIONS</b>
        </Typography>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>1.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              The Website,{" "}
              <a
                href="http://www.nobiylifestyle.com/"
                target="_blank"
                style={{ color: "#EA9A23" }}
              >
                www.nobiylifestyle.com
              </a>{" "}
              (“Platform/Website”) is owned and operated by M/s. Neometa
              Lifestyle Studio Private Limited lifestyle studio Pvt Ltd and its
              affiliates/Group Companies (Hereinafter collectively referred as
              “the Company”). The trade name, the word mark and all intellectual
              property rights associated with NoBiY & Gloria Vanderbilt Jeans
              (“Products”) and the Platform is managed solely by the Company.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>2.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              All orders shall be subject to terms and conditions as mentioned
              below. Please read these Purchase Terms carefully before ordering
              Products online from the Platform. Any person placing an orders on
              the Website ordering the product (‘Customer/You’) shall be capable
              of entering into this contract (competent Parties), their consent
              being free (without any force or coercion) and the object and
              consideration being for a lawful purpose.{" "}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>3.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              All products shown on the Platform are subject to availability.
              This means that, although we strive to ensure our Platform
              reflects the availability of stock, a Product shown on the
              Platform may no longer be available for purchase.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>4.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              Minor differences in colour and other variations in Products are
              possible as a result of different image acquisition, display
              technologies or other technical reasons, the Company is not liable
              for these variations and deviations. No rights can be derived from
              typing errors, product description or manifest errors on the
              Platform.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>5.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              All Products will remain the property of the Company until you
              have paid all amounts owed to us in full under any agreement,
              including the payment of costs, earlier or later deliveries or
              partial deliveries. You may not sell, dispose of, or encumber any
              Product before full tittle thereof as has passed to you.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>6.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              We draw your attention to the washing and maintenance instructions
              printed on the labels of the Products. We are not liable for any
              damage resulting from incorrect handling of Products, including
              handling contrary to the instructions.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>7.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              The Product should be delivered only in India and can be ordered
              only by persons having a valid address in India. The delivery
              shall be subject to the Customer complying with all the legal
              requirements, rules and regulations and having all statutory
              documents in place (whether local or national). Any third party
              courier agency shall not be responsible for any delivery not being
              done because of the non-existence or the non-production of the
              statutory documents or the address being incomplete or incorrect.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>8.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              These Purchase Terms apply to all offers and contracts relating to
              the sale and delivery of Products by us. You agree to these
              Purchase Terms, when you (i) order anything from the Website, (ii)
              order anything at any web page directly connected to the Website
              or (iii) when you accept an offer from us. It is only possible to
              deviate from these Purchase Terms if agreed in writing by us.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>9.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              We will deliver to the address indicated by you within India. We
              can only deliver to a home or office address or a carrier pickup
              location. If a delivery attempt is unsuccessful, you agree that
              the carrier we have engaged for the delivery may also deliver the
              Products to a neighbouring/nearby address or pick-up point after
              which the Company is deemed to have met its obligation to deliver
              the Products. The carrier will notify you of the delivery to the
              neighbouring/nearby address or pick-up point. Shipment of orders
              will take place in business days, not being a public holiday in
              the area in which our warehouse is located. Therefore, please,
              consider any public holidays including any national and/ or
              regional holidays. Delivery periods are indicative and are
              therefore not regarded as strict deadlines.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>10.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              We do not exchange Products. If you wish to exchange a Product,
              you will need to return your purchase for a refund and place a new
              order. It is only possible to get refund on returns when the
              return is in line with our Return Policy.{" "}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>11.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              Your purchase is warranted against manufacturing defects for up to
              3 months from the date of purchase.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>12.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              Notwithstanding your right of withdrawal, it is possible, under
              limited circumstances, to cancel your order for Products. Please
              check the Platform for more information.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>13.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              The stated prices include GST. Prices are quoted in India Rupees
              (INR), the Company reserves the right to make price changes prior
              to an order placed by you. We reserve the right to change, limit
              or terminate nay special offers or discounts at any time. We may
              charge delivery costs. The delivery costs vary for each Product
              and type of delivery. Any delivery costs will be charged
              separately, itemised and added to the total amount of the order.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>14.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              Where we elect or are required by applicable law, to issue or make
              available an invoice, we reserve the right to issue or make
              available electronic invoices and you agree to such form of
              invoicing.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>15.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              The Company shall have a right to share the information of the
              customer with such third party in order for the fulfilment of the
              order placed on the website. The Customer/ user confirms and
              acknowledges the same. The Customer also gives the right to
              Neometa Lifestyle studio Pvt Ltd use his / her information for
              sharing marketing and product update.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>16.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              The taxes on the product shall be borne by the Customer.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>17.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              The Company does not give any additional warranties or guarantee
              except for the same being mentioned on the Website. The Customer
              agrees to indemnify the Company for any breach and or
              misrepresentation made herein.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>18.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              This Website may contain typographical errors or technical
              inaccuracies. The Company reserves the right to modify the content
              of this Website at any time without prior notice.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>19.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              We reserve the right to make changes to these Terms and Conditions
              and any content contained in other parts of the Platform or in
              links, the Company Terms and Conditions contained in this document
              prevail.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>20.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              In case of contradiction between the Company’s Terms and
              Conditions and any content contained in Platform or in links, the
              Company’s Terms and Conditions contained in this document prevail.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>21.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              The Company will not be held responsible for any delay or failure
              to perform or comply with our obligations under the Company’s
              terms and conditions when the delay or failure arises from any
              cause which is beyond the Company reasonable control.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>22.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              The Company’s Terms and Conditions shall be governed by the laws
              of India. The Company shall bring any of dispute which may arise
              under these Terms and Conditions to the competent court of India
              to settle such dispute.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>23.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              Before you may use the Website, you must agree read and accept all
              of the terms and conditions in and linked to, this Terms &
              Conditions and the linked Privacy Policy. This is effective upon
              acceptance and if it conflicts with any other document, the Terms
              & Conditions will prevail for the purposes of usage of the
              Website. If you do not agree to be bound by this Terms &
              Conditions and the Privacy Policy, you may not use the Site for
              purchases.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>24.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              The Company grants you a limited license to access and make
              personal use of the Website and the Service. This license does not
              include any downloading or copying of account information for the
              benefit of another vendor or any other third party; caching,
              unauthorized hypertext links to the Website and the framing of any
              Content available through the Website uploading, posting, or
              transmitting any content that you do not have a right to make
              available (such as the intellectual property of another party);
              uploading, posting, or transmitting any material that contains
              software viruses or any other computer code, files or programs
              designed to interrupt, destroy or limit the functionality of any
              computer software or hardware or telecommunications equipment; any
              action that imposes or may impose (in The Company’s sole
              discretion) an unreasonable or disproportionately large load on
              The Company’s infrastructure; or any use of data mining, robots,
              or similar data gathering and extraction tools. You may not bypass
              any measures used by The Company to prevent or restrict access to
              the Website. Any unauthorized use by you shall terminate the
              permission or license granted to you by the Company.
            </Typography>
            <Typography sx={fontSize}>
              The Website is not available to minors under the age of 18 or to
              any users suspended or removed from the system by The Company for
              any reason.
            </Typography>
            <Typography sx={fontSize}>
              In consideration of your use of the Website, you represent that
              you are of legal age to form a binding contract and are not a
              person barred from receiving services under the laws as applicable
              in India. You also agree to provide true, accurate, current and
              complete information about yourself as prompted by the Website's
              registration form. If you provide any information that is untrue,
              inaccurate, not current or incomplete (or becomes untrue,
              inaccurate, not current or incomplete), or The Company has
              reasonable grounds to suspect that such information is untrue,
              inaccurate, not current or incomplete, The Company has the right
              to suspend or terminate your account and refuse any and all
              current or future use of the Website (or any portion thereof). If
              you use the Website, you are responsible for maintaining the
              confidentiality of your account and password, and for restricting
              access to your computer. You agree to accept responsibility for
              all activities that occur under your account or password. Because
              of this, we strongly recommend that you exit from your account at
              the end of each session. You agree to notify The Company
              immediately of any unauthorized use of your account or any other
              breach of security. The Company reserves the right to refuse
              service, terminate accounts, or remove or edit content in its sole
              discretion.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>26.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              Please note that there may be certain orders that we are unable to
              accept and must cancel. We reserve the right, at our sole
              discretion, to refuse or cancel any order for any reason. Some
              situations that may result in your order being cancelled include
              limitations on quantities available for purchase, inaccuracies or
              errors in product or pricing information, or problems identified
              by our credit and fraud avoidance department. We may also require
              additional verifications or information before accepting any
              order. We will contact you if all or any portion of your order is
              cancelled or if additional information is required to accept your
              order. If your order is cancelled after your account has been
              charged, it will be refunded back in accordance with the Refund
              Policy of the Company.
            </Typography>
            <Typography sx={fontSize}>
              In case of requests for order cancellations the customer, The
              Company reserves the right to accept or reject requests for order
              cancellations for any reason. As part of usual business practice,
              if we receive a cancellation notice and the order has not been
              processed / approved by us, we shall cancel the order and refund
              shall be processed basis the refund policy. We will not be able to
              cancel orders that have already been processed. The Company has
              the full right to decide whether an order has been processed or
              not. The customer agrees not to dispute the decision made by The
              Company and accept The Company's decision regarding the
              cancellation.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>27.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              The Company reserves the right to recover the cost of goods,
              collection charges and lawyers’ fees from persons using the
              Website fraudulently. The Company reserves the right to initiate
              legal proceedings against such persons for fraudulent use of the
              Website and any other unlawful acts or omissions in breach of
              these terms and conditions.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px", color: "white" }}>
            <Typography
              sx={{ fontSize: { xs: 12, sm: 14, md: 16, lg: 18 }, mr: 1 }}
            >
              <b>28.</b>
            </Typography>
          </Box>
          <Box>
            <Typography sx={fontSize}>
              {" "}
              You agree, understand and confirm that the credit/debit/internet
              banking details provided by you for availing of services on The
              Company will be correct and accurate and you shall not use the
              personal details which is not lawfully owned by you. You further
              agree and undertake to provide the correct details to The Company.
              Further the said information will not be utilized and shared by
              The Company with any of the third parties unless required for
              fraud verifications or by law, regulation or court order. The
              Company will not be liable for any credit/debit card/internet
              banking fraud. The liability for use of a card fraudulently will
              be on you and the onus to 'prove otherwise' shall be exclusively
              on you.
            </Typography>
          </Box>
        </Box>
        <Typography sx={htag}>
          <b>Disclaimer:</b>
        </Typography>
        <Typography sx={fontSize}>
          All the information on this Website is published in good faith. Any
          action you take upon the information on our Website is strictly at
          your own risk. We are not liable for any losses and damages in
          connection with the use of our Website. We do not warrant that our
          products/Website or its functioning or the content and material of the
          products/services made available thereby will be timely, secure,
          uninterrupted or error-free, that defects will be corrected, or that
          our Websites or the servers that make our Website available are free
          of viruses or other harmful components. The use of our Website is at
          your sole risk and you assume full responsibility for any costs
          associated with your use of our Website. We will not be liable for any
          damages of any kind related to the use of our Website. In force
          majeure events (acts of god), we shall not be responsible for the
          delivery of the Products in time and we shall not be liable for any
          losses/damages caused to you with respect to the same. In no event
          will we, or our affiliates, our or their respective content or service
          providers, or any of our or their respective directors, officers,
          agents, contractors, suppliers or employees be liable to you for any
          direct, indirect, special, incidental, consequential, exemplary or
          punitive damages, losses or causes of action, or lost revenue, lost
          profits, lost business or sales, or any other type of damage, whether
          based in contract or tort (including negligence), strict liability or
          otherwise, arising from your use of, or the inability to use, or the
          performance of, our Website or the content or material or
          functionality through our Website, even if we are advised of the
          possibility of such damages.
        </Typography>
        <Typography sx={htag}>
          <b>Disclaimer of Warranty</b>
        </Typography>
        <Typography sx={fontSize}>
          ALL CONTENT, PRODUCTS, AND SERVICES ON THE WEBSITE ARE PROVIDED TO YOU
          "AS IS" WITHOUT WARRANTY OF ANY KIND EITHER EXPRESS OR IMPLIED
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TITLE,
          NON-INFRINGEMENT, SECURITY OR ACCURACY.
        </Typography>
        <Typography sx={fontSize}>
          OTHER THAN AS REQUIRED UNDER APPLICABLE CONSUMER PROTECTION LAW, UNDER
          NO CIRCUMSTANCE WILL THE COMPANY BE LIABLE FOR ANY LOSS OR DAMAGE
          CAUSED BY A USER'S RELIANCE ON INFORMATION OBTAINED THROUGH THE
          WEBSITE, IT IS THE RESPONSIBILITY OF THE USER TO EVALUATE THE
          ACCURACY, COMPLETENESS OR USEFULNESS OF ANY OPINION, ADVICE OR OTHER
          CONTENT AVAILABLE THROUGH THE WEBSITE. PLEASE SEEK THE ADVICE OF
          PROFESSIONALS, AS APPROPRIATE, REGARDING THE EVALUATION OF ANY
          SPECIFIC OPINION, ADVICE, PRODUCT, SERVICE, OR OTHER CONTENT.
        </Typography>
        <Typography sx={htag}>
          <b>Limitation of Liability</b>
        </Typography>
        <Typography sx={fontSize}>
          The Company will not be liable in any way for loss, damage, costs or
          expenses (including loss of profit) arising directly or indirectly
          from any failure or delay in performing any obligation under this
          Contract by reason of any event or circumstance outside the reasonable
          control of The Company, including but not limited to), any strikes,
          industrial action, failure of power supplies or equipment, government
          action or Act of God. You expressly understand and agree that The
          Company and its subsidiaries, affiliates, officers, employees, agents,
          partners and licensors shall not be liable to you for any direct,
          indirect, incidental, special, consequential or exemplary damages,
          including, but not limited to, damages for loss of profits, goodwill,
          use, data or other intangible losses (even if The Company has been
          advised of the possibility of such damages), resulting from use of the
          Website, sale and supply of goods content or any related services.
        </Typography>
        <Typography sx={fontSize}>
          This limitation of liability section shall prevail over any
          conflicting or inconsistent provision contained in any of the
          documents comprising this Agreement. It is up to you to take
          precautions to ensure that whatever you select for your use is free of
          such items as viruses, worms, Trojan horses and other items of a
          destructive nature.
        </Typography>
        <Typography sx={htag}>
          <b>Indemnity</b>
        </Typography>
        <Typography sx={fontSize}>
          You agree to indemnify and hold The Company (and its officers,
          directors, agents, subsidiaries, joint ventures, and employees)
          harmless from any claim or demand, including reasonable attorneys’
          fees, or arising out of or related to your breach of this Terms &
          Conditions, or your violation of any law or the rights of a third
          party.
        </Typography>
        <Typography sx={htag}>
          <b>Electronic Communication</b>
        </Typography>
        <Typography sx={fontSize}>
          When you use the Website or send emails to The Company, you are
          communicating with The Company electronically. You consent to receive
          communications from The Company electronically. The Company will
          communicate with you by email, SMS or by posting notices on the
          Website. You agree that all agreements, notices, disclosures and other
          communications that we provide to you electronically satisfy any legal
          requirement that such communications be in writing.
        </Typography>
        <Typography sx={htag}>
          <b>Modifications and Notification of Changes</b>
        </Typography>
        <Typography sx={fontSize}>
          The Company reserves the right to make changes to the Website, related
          policies and agreements, this Terms & Conditions and the Privacy
          Policy at any time which may or may not be updated on the Website.{" "}
        </Typography>
        <Typography sx={htag}>
          <b>Trademarks</b>
        </Typography>
        <Typography sx={fontSize}>
          The trademarks, logos and service marks ("Marks") displayed on the
          Website are the property of The Company and other respective persons.
          Customers are prohibited from using any Marks for any purpose
          including, but not limited to use as metatags on other pages or
          Websites on the World Wide Web without the written permission of The
          Company or such third party which may own the Marks. All information
          and content including any software programs available on or through
          the Website ("Content") is protected by copyright. Customers are
          prohibited from modifying, copying, distributing, transmitting,
          displaying, publishing, selling, licensing, creating derivative works
          or using any Content available on or through the Website for
          commercial or public purposes.
        </Typography>
        <Typography sx={htag}>
          <b>Survival of Terms after Agreement Ends</b>
        </Typography>
        <Typography sx={fontSize}>
          Notwithstanding any other provisions of this Terms & Conditions, or
          any general legal principles to the contrary, any provision of this
          Terms & Conditions that imposes or contemplates continuing obligations
          on a party will survive the expiration or termination of this Terms &
          Conditions.
        </Typography>
        <Typography sx={htag}>
          <b>General</b>
        </Typography>
        <Typography sx={fontSize}>
          If any of these conditions are deemed invalid, void, or for any reason
          unenforceable, the parties agree that the court should endeavour to
          give effect to the parties’ intentions as reflected in the provision,
          and the unenforceable condition shall be deemed severable and shall
          not affect the validity and enforceability of any remaining condition.
          From time to time The Company may offer special promotional offers
          which may or may not be applicable to you. To avail of such
          promotional offers, you agree to be bound by any additional terms and
          conditions for these special offers which are laid down by The
          Company. This Terms & Conditions and the relationship between you and
          The Company will be governed by the laws as applicable in India. The
          failure of The Company to act with respect to a breach by you or
          others does not waive its right to act with respect to subsequent or
          similar breaches. The Company does not guarantee it will take action
          against all breaches of this Terms & Conditions. Except as otherwise,
          expressly provided in this Terms & Conditions, there shall be no
          third-party beneficiaries to this Terms & Conditions. The Courts in
          Bangalore shall have exclusive jurisdiction on any matter arising out
          of this Agreement. This Terms & Conditions constitutes the entire
          agreement between you and The Company and governs your use of the
          Website, superseding any prior agreements between you and The Company
          with respect to the Website.
        </Typography>
        {/* <Typography color="#EA9A23" sx={{ fontWeight: 'bold', fontSize: '1.5rem', position: "absolute",bottom:0,left:0,p:"10px",cursor: "pointer" }} onClick={() => navigate("/")}>
              <ArrowBackIcon sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }} />Back</Typography> */}
      </Box>
      <Box sx={{ position: "fixed", bottom: 0 }}>
        <Typography
          color="#EA9A23"
          sx={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            left: 0,
            p: "10px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          <ArrowBackIcon
            sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }}
          />
          Back
        </Typography>
      </Box>
      {/* </Box> */}
    </>
  );
}

export default TermsAndConditions;
