import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./TrackOrder.css";
// import DownloadButton from "./DownloadButton";
// import InvoicePage from "./InvoicePage";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { setOrderDetailsss, setfullfilmentsIndex } from "../../Redux/UserSlice";
import { getAllOrder, getTrakingDetails } from "../../api/Api";
import CancelOrderDialog from "./CancelOrderDialog";

function TrackActiveOrder({ orderID }) {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isDelivered, setIsdelivered] = useState(false);

  const accessToken = useSelector((state) => state?.user?.accessToken);

  useEffect(() => {
    setLoading(true);
    getAllOrder(accessToken)
      .then((res) => {
        if (Object.keys(orderDetails)?.length == 0) {
          setLoading(false);
        }
        let customer = res.data;
        customer?.data?.customer?.orders?.edges.map((e) => {
          e.deliveredStatus = "not delevired";
          if (e?.node?.successfulFulfillments?.length > 0) {
            getTrakingDetails(
              e?.node?.successfulFulfillments[0]?.trackingInfo[0]?.number
            ).then((res) => {
              if (res?.data && res?.data?.length > 0) {
                res?.data?.map((elm) => {
                  if (elm?.statusCode == "DLVD") {
                    e.deliveredStatus = "delevired";
                    if (isDelivered) {
                      setIsdelivered(false);
                      setLoading(false);
                    } else {
                      setIsdelivered(true);
                      setLoading(false);
                    }
                  }
                });
              } else {
                setLoading(false);
              }
            });
          } else {
            setLoading(false);
          }
        });
        setOrderDetails(customer?.data?.customer?.orders?.edges);
        //  dispatch(setOrderDetailsss(customer?.data?.customer?.orders?.edges));
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, [isButtonDisabled]);

  useEffect(() => {
    if (orderDetails?.length > 0) {
      setOrderDetails(orderDetails);
    }
  }, [isDelivered]);

  const cancelOrderFun = (data) => {
    console.log(data,"55555");
    let orderId = data.node.id;
    const regex = /\/Order\/(\d+)\?/;
    const match = orderId.match(regex);

    // Check if a match was found and extract the order number
    if (match) {
      const orderNumber = match[1]; // Output: 5479163625768
      setSelectedOrderDetails(data.node);
      setOrderId(orderNumber);
      setOpenDialog(true);
    } else {
      console.log("Order number not found in the input string.");
    }
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {loading ? (
        <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box>
          <Box
            sx={{
              mt: { xs: 8, sm: 10, md: 11, lg: 12 },
            }}
          >
            <Typography
              color="#006D82"
              sx={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                marginTop: "1rem",
                marginBottom: "0.5rem",
                ml: { xs: "2vw", sm: "4vw", md: "12vw", lg: "-0.1vw" },
              }}
            >
              <ArrowBackIcon
                onClick={() => navigate("/")}
                sx={{ fontSize: 30, mb: -1, cursor: "pointer" }}
              />
              Your Orders
            </Typography>
            {(orderDetails && Object.keys(orderDetails)?.length == 0) ||
              orderDetails == null ? (
              <div style={{ textAlign: "center", marginTop: "2rem" }}>
                <Typography variant="h5" fontWeight="600" color="#EA9A23">
                  No Active Orders Yet
                </Typography>
                <Button
                  sx={{
                    borderRadius: 20,
                    padding: "0.2rem 3rem",
                    backgroundColor: "#006D82",
                    color: "white",
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={() => {
                    navigate("/cat");
                  }}
                >
                  Go to Shopping
                </Button>
              </div>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  m: 1,
                  ml: { xs: 1, sm: 10, md: 22, lg: 28 },
                }}
              >
                {/* <Box className="arrow-pointer"> <Typography sx={{ color: "white", pl: 2, pt: 1, fontSize: { xs: 11, sm: 15 } }}>Active Orders</Typography></Box> */}
                {/* <Box sx={{ width: { xs: 1, sm: "56vw", md: "50vw", lg: "60vw" }, height: "10px", marginTop: ".78rem", background: "#006d82" }}></Box> */}
              </Box>
            )}
            {(orderDetails && Object.keys(orderDetails)?.length == 0) ||
              orderDetails == null ? (
              ""
            ) : (
              <Container sx={{ }}>
                {orderDetails?.map((e, index) => {
                  return (
                    <>
                      <Card
                      onClick={() => {
                        dispatch(
                          setOrderDetailsss(e.node)
                        );
                        dispatch(
                          setfullfilmentsIndex(
                            Number(index)
                          )
                        );
                        navigate("/previousOrder");
                      }} 
                        sx={{
                          borderRadius: "10px",
                          // backgroundColor: "rgba(255, 255, 255, 0.9)",
                          // backdropFilter: "blur(1.5px)",
                          mb: 2,
                          cursor:"pointer",
                          border:"1px solid #dadada",
                          transition: 'box-shadow 0.3s ease-in-out',
                          
                                '&:hover': {
                                  transform: 'translateY(-5px)',
                             boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                              },
                          
                          

                        }}
                      >
                        <Box display="flex"  sx={{ justifyContent: "space-between",backgroundColor:"#F5F5F5",height:"3rem" ,pr:"1rem"}}  >
                          
                            <Typography
                              sx={{
                                marginLeft: "1%",
                                marginTop:"0.6rem",
                                
                                fontSize: { lg: 16, md: 18, xs: 13 },
                              }}
                            >
                              Order Id: {e?.node.id.match(/\/Order\/(\d+)\?/)[1]}
                              <br />
                            </Typography>
                            
                           
                           
                            <Typography
                            
                              sx={{ fontSize: { lg: 14, md: 18, xs: 13 },marginTop:"0.6rem" ,}}
                            >
                              {e?.deliveredStatus == "delevired"
                                ? "Delivered"
                                : e?.node?.canceledAt == null
                                  ? `Confirmed`
                                  : `Canceled `}
                              {/* <Box
                                component="img"
                                sx={{
                                  marginLeft: {lg:"2rem"},
                                  height: {
                                    xs: "1rem",
                                    sm: "1rem",
                                    md: "1.5rem",
                                    lg: "1.5rem",
                                  },
                                }}
                                alt="CartImage"
                                src={
                                  e?.node?.canceledAt == null
                                    ? ""
                                    : ""
                                }
                              /> */}
                            </Typography>
                          </Box>
                         
                    

                        {e?.node?.canceledAt == null && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {e?.node?.canceledAt == null &&
                              e?.deliveredStatus !== "delevired" && (
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              width: {
                                lg: "9rem",
                                md: "9rem",
                                sm: "8rem",
                                xs: "6rem",
                              },
                              height: {
                                lg: "4.3vh",
                                md: "4.3vh",
                                sm: "3vh",
                              },
                              fontSize: {
                                lg: 13,
                                md: 13,
                                sm: 13,
                                xs: 10,
                              },
                              borderRadius: "10px",
                              background:
                                "linear-gradient(to right, #006D82, #006D82)",
                              textTransform: "none",
                              // mt: {sm:"-2.6rem",xs:"-1rem" },
                              marginRight: { xs: "3%", sm: "1%" },
                            }}
                            onClick={() => cancelOrderFun(e)}
                          >
                            Cancel Order
                          </Button>

                          )}
                        </div>
                        )}

                        <Grid container spacing={0} sx={{ marginTop: { xs: 0, md: "-3rem" } }}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Container sx={{ display: "flex", width: "100%" }}>
                              <Box
                                sx={{
                                  color: "white",
                                  overflowX: "auto",
                                  mb: { xs: 2, sm: 2, md: 0, lg: 0 },
                                  width: "100%",
                                }}
                              >

                                {/* {e?.node?.lineItems?.edges>2&&<Divider />} */}
                                {e?.node?.lineItems?.edges?.map(
                                  (ele, index) => {
                                    return (
                                      <>
                                        <div
                                          style={{
                                            borderRadius: "0.5rem",
                                            boxShadow:
                                              "0px 0px 6px rgba(255, 255, 255, 0.5)",
                                            paddingBottom: "0.8rem",
                                            marginBottom: "1rem",
                                            // marginTop:{lg:"0%",md:"0%",sm:"3%",xs:"1&"},
                                            width: "100%",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              mt: {
                                                md:
                                                  e?.node?.lineItems?.edges
                                                    .length == 1
                                                    ? 5
                                                    : 5,
                                              },
                                              width: "100%",
                                            }}

                                          >
                                            <Box sx={{ ml: { sm: 3 }, mt: 2 }}>
                                              <Box sx={{ border:"1px solid #dadada", borderRadius:"12px",paddingLeft:"1rem",marginLeft:{lg:"-1.5rem"}}}>
                                              <Box
                                                component="img"
                                                sx={{
                                                  height: {
                                                    xs: "7rem",
                                                    sm: "8rem",
                                                    md: "9rem",
                                                    lg: "9rem",
                                                    
                                                  },
                                                  
                                                  mr: "1rem",
                                                 
                                                }}
                                                alt="CartImage"
                                                src={
                                                  ele?.node?.variant?.image?.url
                                                }
                                              />
                                              </Box>
                                            </Box>
                                            <Box sx={{ mt: { lg:2, sm: 8, xs: 2 } }}>
                                              <Box sx={{display:'flex' ,gap:{lg:"15rem"}}}>
                                              <Typography
                                                sx={{
                                                  fontSize: {
                                                    lg: 18,
                                                    md: 18,
                                                    sm: 13,
                                                    xs: 13,
                                                  },
                                                  color: "black",
                                                  marginLeft:"2rem",
                                                  width: {
                                                    xs: "40vw",
                                                    sm: "30vw",
                                                    md: "18vw",
                                                    lg: "15vw",
                                                  },
                                                }}
                                              >
                                                {ele?.node?.title}
                                              </Typography>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                
                                                }}
                                              >
                                                {/* <Typography
                                                  variant="subtitle1"
                                                  sx={{ fontSize: { lg: 18, md: 18, sm: 13, xs: 13, }, color: "black" , display: { xs: 'none', sm: 'block',md:"block",lg:"block" } }}
                                                >
                                                  Paid : ₹ {e?.node?.totalPrice?.amount}
                                                </Typography> */}
                                               
                                              </Box>
                                              </Box>
                                              <Box
                                                sx={{ display: "flex", gap:"1rem" ,marginTop:"0.5rem" }}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontSize: {
                                                      lg: 13,
                                                      md: 18,
                                                      sm: 13,
                                                      xs: 13,
                                                    },
                                                    color: "black",
                                                    marginLeft:"2rem",
                                                    
                                                  }}
                                                >
                                                  Size:
                                                  {
                                                    String(
                                                      ele?.node?.variant?.title
                                                    ).split("/")[0]
                                                  }
                                                </Typography>
                                                <Divider style={{background:"black"}} orientation="vertical" flexItem />
                                                <Typography
                                                  sx={{
                                                    fontSize: {
                                                      lg: 13,
                                                      md: 18,
                                                      sm: 13,
                                                      xs: 13,
                                                    },
                                                    color: "black",
                                                
                                                  }}
                                                >
                                                  Qty:{ele?.node?.quantity}
                                                </Typography>
                                                <Divider style={{background:"black"}} orientation="vertical" flexItem />
                                                <Typography
                                                  variant="subtitle1"
                                                  sx={{ fontSize: { lg: 13, md: 18, sm: 13, xs: 13, }, color: "black" , display: { xs: 'none', sm: 'block',md:"block",lg:"block" } }}
                                                >
                                                  Paid : ₹ {e?.node?.totalPrice?.amount}
                                                </Typography>
                                              </Box>
                                              <Box sx={{display:"flex",flexWrap:"wrap" }}>
                        <Button  onClick={() => {
                          dispatch(
                            setOrderDetailsss(e.node)
                          );
                          dispatch(
                            setfullfilmentsIndex(
                              Number(index)
                            )
                          );
                          navigate("/previousOrder");
                        }} sx={{cursor:"pointer",marginLeft:"3rem",marginTop:"1rem", marginRight:"1rem",color:"#006D82" ,border:"1px solid #006D82",width:{xs:"10rem"}}}>Track Now</Button>
                         <Button  onClick={() => {
                          dispatch(
                            setOrderDetailsss(e.node)
                          );
                          dispatch(
                            setfullfilmentsIndex(
                              Number(index)
                            )
                          );
                          navigate("/previousOrder");
                        }} sx={{cursor:"pointer",marginLeft:"3rem",marginTop:"1rem", marginRight:"1rem",color:"#006D82" ,border:"1px solid #006D82"}}>Return/Exchange</Button>
                        {/* <IconButton>
                        <ArrowForwardIosIcon
                          sx={{ color: "black", fontSize: 18 }}
                        />{" "}
                      </IconButton> */}
                        </Box>
                        
                                              <Divider style={{background:"black",marginLeft:"1rem",marginTop:"1rem"}}/>
                                              <Typography
                                                  variant="subtitle1"
                                                  sx={{ fontSize: { lg: 18, md: 18, sm: 13, xs: 13, }, marginLeft:"2rem",color: "black" , display: { xs: 'block', sm: 'none',md:"none",lg:"none" } }}
                                                >
                                                  Paid : <b>₹ {e?.node?.totalPrice?.amount}</b>
                                                </Typography>
                                              {/* <Box
                                                sx={{
                                                  display: "flex",
                                                  mt: "0.5rem",
                                                }}
                                              >
                                                <Typography
                                                  variant="subtitle1"
                                                  sx={{ fontSize: { lg: 18, md: 18, sm: 13, xs: 13, }, color: "black" }}
                                                >
                                                  MRP : ₹ {ele?.node?.variant?.compareAtPrice?.amount}
                                                </Typography>
                                                <Typography variant='subtitle' sx={{ color: "gray", textDecoration: "line-through", fontSize: { lg: 18, md: 18, sm: 13, xs: 13, }, ml: "1rem", mt: { xs: ".15rem", sm: ".2rem" } }}
                                                >₹ {ele?.node?.variant?.priceV2.amount}/-</Typography>
                                              </Box> */}
                                            </Box>
 
                                          </Box>
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </Box>
                            </Container>
                          </Grid>
                          
                        </Grid>
                       
                      </Card>
                    </>
                  );
                })}
              </Container>
            )}
            {/* <PreviousOrder/> */}
            <CancelOrderDialog
              orderId={orderId}
              openDialog={openDialog}
              // handleData={handleData}
              setButtonDisabled={setButtonDisabled}
              handleDialogClose={handleDialogClose}
              selectedOrderDetails={selectedOrderDetails}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default TrackActiveOrder;
