import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from
} from "@apollo/client";
import styled from "@emotion/styled";
import { Buffer } from "buffer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const link = from([
  new HttpLink({
    uri: "https://037f1e-2.myshopify.com/api/2023-04/graphql.json",
    // uri: "https://ecf6e5-2.myshopify.com/api/2023-04/graphql.json",
  }),
]);
export const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "X-Shopify-Storefront-Access-Token": "17110ee06e7ef672acce823728bbb2f5",
      // "X-Shopify-Storefront-Access-Token": "3863f630edcb0a594b65984dc192e295",
    },
  });
  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});

export const successToastMessage = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    pauseOnFocusLoss: false,
    autoClose: 1500,
    pauseOnHover: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    theme: "dark",
  });
};

export const warningToastMessage = (message) => {
  toast.warning(message, {
    position: toast.POSITION.TOP_RIGHT,
    pauseOnFocusLoss: false,
    autoClose: 1500,
    pauseOnHover: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    theme: "dark",
  });
};

export const failureToastMessage = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    pauseOnFocusLoss: false,
    autoClose: 2500,
    pauseOnHover: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    theme: "dark",
  });
};

export const ErrorText = styled("p")`
  color: red;
  font-size: 0.9rem;
  position: absolute;
  top: 1.5rem;
`;

const crypto = require("crypto");
const IV = Buffer.from([
  22, 13, 74, 14, 92, 112, 95, 63, 112, 26, 18, 37, 75, 9, 29, 65,
]);

export const generateKey = () => {
  const ivWithSaltAndName = combineIVWithSaltAndName(IV, "Ashutosh");
  const derivedKey = crypto.pbkdf2Sync(
    Buffer.from(ivWithSaltAndName, "utf8"),
    Buffer.from("AES", "utf8"),
    65536,
    16, // Use a 128-bit key length (16 bytes)
    "sha256"
  );
  return crypto.createSecretKey(derivedKey);
};

export const encrypt = (plainText, secretKey) => {
  const cipher = crypto.createCipheriv("aes-128-cbc", secretKey, IV);
  let encrypted = cipher.update(plainText, "utf8", "base64");
  encrypted += cipher.final("base64");
  return encrypted;
};

export const decrypt = (encryptedText, secretKey) => {
  const decipher = crypto.createDecipheriv("aes-128-cbc", secretKey, IV);
  let decrypted = decipher.update(encryptedText, "base64", "utf8");
  decrypted += decipher.final("utf8");
  return decrypted;
};

function combineIVWithSaltAndName(iv, name) {
  const nameBytes = Buffer.from(name, "utf8");
  const salt = Buffer.alloc(16);
  for (let i = 0; i < salt.length; i++) {
    salt[i] = iv[i] ^ nameBytes[i % nameBytes.length];
  }
  const ivWithSaltAndName = Buffer.concat([iv, salt, nameBytes]);
  return ivWithSaltAndName;
}

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 10;
export const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",

    horizontal: "left",
  },

  transformOrigin: {
    vertical: "top",

    horizontal: "left",
  },

  getContentAnchorEl: null,

  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 2 + ITEM_PADDING_TOP,

      // width: 250,
    },
  },
};

export const successToastDiscountMessage = (message, type) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    pauseOnFocusLoss: false,
    autoClose: 9500,
    pauseOnHover: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    theme: "dark",
  });
};
