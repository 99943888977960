import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setName } from "../../Redux/UserSlice";
import CommonCard from "./CommonCard";

const SerachValue = () => {
  const dispatch = useDispatch();
  dispatch(setName("abc"));
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const allProductVale = useSelector((state) => state.cart.allProductcardValue);
  const showSearchProductValue = useSelector(
    (state) => state.cart.searchProductValue
  );

  const [arrallproductget, setArrallproductget] = useState([]);

  useEffect(() => {
    const allProducts = showSearchProductValue?.map((e) => e.edges);
    let arrallproductget = [];
    allProducts?.forEach((e) => {
      let product = e?.map((e) => e?.node);
      let priceofProduct = e.map((e) => e);
      arrallproductget.push(...product);
    });
    setArrallproductget(arrallproductget);
    setLoading(false);
  }, [showSearchProductValue]);
  const productTypeTitle = arrallproductget.map((e) => e.productType);
  const oneproductTypeTitel = productTypeTitle[0];

  return (
    <>
      <Box onClick={() => navigate("/cat")} sx={{cursor: "pointer", margin: { lg: "7rem ", xs: "4rem 0.3rem" } }}>
        <Typography
          color="#006D82"
          sx={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            marginBottom: "1rem",
          }}
        >
          <ArrowBackIcon
            sx={{ fontSize: 30, mr: 2, mb: -1,}}
            
          />
          {/* {zoomDetailsCards?.title} */}
          Back
        </Typography>
        {/* <Typography>{oneproductTypeTitel}</Typography> */}
      </Box>

      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          paddingLeft: { lg: "2vw", xs: "3vw" },
          paddingRight: { lg: "2vw", xs: "5vw" },
          paddingBottom: { lg: "2vh", xs: "69px" },

          display: "flex",
          mt: { lg: -10, xs: -3 },
          // background: "rgba(0, 0, 0, 0.4)",
          //  height: "calc(71vh)",  overflowY: "auto",overflowX: "hidden"
        }}
      >
       <Grid
  container
  columnSpacing={{ lg: 1, md: 8, sm: 3, xs: 3 }}
  rowSpacing={3}
  sx={{ mt: ".3rem" }}
>
  {arrallproductget.length === 0 ? (
   <Box
   sx={{
     display: "flex",
     alignItems: "center",
     justifyContent: "center",
     
   }}
 >
   <Typography variant="h6" sx={{fontSize:"2rem", fontWeight: "bold",textAlign:"center", marginLeft:{lg:'34rem',xs:"3rem"},color:"#006D82" }}>
     No products found.
   </Typography>
 </Box>
  ) : (
    arrallproductget
      ?.filter((e) => e.availableForSale == true)
      .map((e) => (
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={2.4}
          key={e.id}
          sx={{ mt: "-1.2rem" }}
        >
          <CommonCard
            props={e}
            imgUrl={e.images.edges[0].node.url}
            imgUrlTwo={e.images.edges[1].node.url}
            discription={e.description}
            catTitle="All"
            titile={e.title}
            availableForSale={e?.availableForSale}
            productVariantPrice={e?.variants?.nodes[0].price.amount}
            productVariantCompareAtPrice={
              e?.variants?.nodes[0]?.compareAtPrice?.amount
            }
            productVariantId={e?.variants?.nodes[0].id}
            productVariants={e?.variants?.nodes}
            shippingCharges={e?.variants?.nodes[0].weight}
          />
        </Grid>
      ))
  )}
</Grid>

      </Box>
      
    </>
  );
};

export default SerachValue;
