import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";






const InvoicePage = ({invoiceDetails,orderDetail, emailValue,index}) => {
  // let invoiceDetails = useSelector((state) => state?.user?.invoiceDetails);
  // const orderDetail = useSelector((state) => state?.user?.orderDetailsss);
  console.log(orderDetail,"orderDetails1233")
  // const emailValue = useSelector((state) => state?.user?.emailValue);
  const [quantity, setQuantity] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  useEffect(() => {
    setQuantity(orderDetail?.lineItems?.edges[index]?.node?.quantity)
    console.log(quantity,"quantity")
    setItemCode(orderDetail?.lineItems?.edges[index]?.node?.variant?.sku)
    setUnitPrice(orderDetail?.lineItems?.edges[index]?.node?.variant?.compareAtPrice?.amount)
  }, [])
  const inputDate = orderDetail?.processedAt;

  // Create a new Date object from the input string
  const dateObject = new Date(inputDate);
  
  // Extract day, month, and year
  const day = dateObject.getUTCDate();
  const month = dateObject.getUTCMonth() + 1; // Note: Months are zero-indexed
  const year = dateObject.getUTCFullYear();
  
  // Format the date as "DD-MM-YYYY"
  const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
  console.log(formattedDate,"formatteddate")

 let compareAtPriceValue = orderDetail?.lineItems?.edges[index]?.node?.variant?.compareAtPrice?.amount
  let perOfPrice = 20;
  let iGst = 12;
  let totalTax= 12;
  const igstPrice = ((iGst / 100) * orderDetail?.lineItems?.edges[index]?.node?.variant?.compareAtPrice?.amount)
  const discountPrice = ((perOfPrice / 100) * orderDetail?.lineItems?.edges[0]?.node?.variant?.priceV2?.amount)
  const amountTax = ((totalTax /100) *orderDetail?.lineItems?.edges[index]?.node?.variant?.compareAtPrice?.amount)
  console.log(discountPrice, "invoiceDetails");
  const taxableValue = orderDetail?.lineItems?.edges[index]?.node?.variant?.compareAtPrice?.amount - amountTax;
  const totalAmt = igstPrice + taxableValue;


  let prodcutDetails = {
    Sno: "",
    ItemCode: "",
    HSN: "",
    qty: "",
    unitPrice: "",
    discount: "",
    taxableValue: "",
    Gst: { rate: "", amount: "" },
    Sgst: { rate: "", amount: "" },
    igst: { rate: "", amount: "" },
    total: "",
  };
  let date = Date(invoiceDetails?.created_at)?.split(" ")
  let orderDate = `${date[2]} ${date[1]},${date[3]}`

  console.log(date, "date45");

  const invoiceItems = [
    {
      sno: 1,
      itemCode: itemCode,
      hsn: '12345',
      qty: orderDetail?.lineItems?.edges[0]?.node?.quantity,
      unitPrice: orderDetail?.lineItems?.edges[index]?.node?.variant?.priceV2?.amount,
      discount: discountPrice,
      taxableValue: taxableValue,
      gstn1: '6%',
      gstn2: '6%',
      gstn3: iGst,
      igstAmt: igstPrice,
      totalAmount: totalAmt,
    },
  ];
  console.log( (invoiceItems[0].discount),"invoiceItem121")

  const QrCodeData = JSON.stringify(invoiceItems);
  const barCodeData = "521546278521";


  const tableStyle = {
    borderCollapse: 'collapse',
    width: "auto",
     marginLeft:"3rem"
  };

  const cellStyle = {
    border: '0.5px solid black', // Add your desired border style here
    padding: '8px',
    textAlign: 'center',
    
  };

  const thStyle = {
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
    padding: '2px',
    border: '0.5px solid black',
    textAlign: "center"
  };


  return (
    <Box id="invoice-container" >
      <Paper style={{ padding: "2rem", }}>
        <Typography sx={{ marginRight:"20rem",textAlign: "center", fontSize: "2rem", fontWeight: 700, color: "#006D82" }}>TAX INVOICE</Typography>
        <Typography sx={{ marginRight:"20rem",textAlign: "center", fontSize: 12, mb: "1rem" }}>ORIGINAL FOR CUSTOMER</Typography>
        {/* <Grid container spacing={2} marginBottom="1.6rem">
          <Grid item xs={3}> */}

        <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
          <Box
            component="img"
            sx={{
              height: "3rem",
            }}
            alt="Left Logo"
            src="./lifestyle.png"
          />

          <Box sx={{}}>
            {/* </Grid> */}
            {/* <Grid item xs={6}> */}
            <Typography style={{ fontWeight: "bold" }}>
              NEOMETA LIFESTYLE STUDIO PRIVATE LIMITED
            </Typography>

            <Typography style={{ fontSize: "0.6rem",  }}>
              <span style={{ fontWeight: 600,  }}>
                CIN:U14101ka2023PTC17194
              </span>
              <br />
              918, 3rd A Cross Rd, HRBR Layout 1st Block, HRBR Layout, Kalyan
              Nagar, Bengaluru, Karnataka 560043 <br />
              <Typography style={{ fontSize: "0.6rem" }}>
                GSTN:
                <span style={{ fontWeight: 600,  }}>
                  29AAICN7886Q1Z4
                </span>
                <span style={{ fontWeight: 600, marginRight: "2rem" }}>
                  PAN:AAICN7886Q
                </span>
              </Typography>
              Contact: info@neometalifestyle.com {"              "}
              <span style={{ fontWeight: 600,  marginRight: "2rem" }}>
                Phone:+91 8031210094
              </span>
              <br />
              Website: www.neometalifestyle.com
            </Typography>
          </Box>
          <Box sx={{
            height: "11rem",
            mt: "-5rem"
          }}>
            {/* <QRCode value={QrCodeData} size={200} /> */}
          </Box>
        </Box>
      
{/* 
         <div style={{ width: "80%" }}>
          <Grid container spacing={30} marginBottom="1rem">
            <Grid item xs={6}>
              <div>
                <strong style={{ fontSize: "0.8rem", marginRight: "2rem" }}>
                  Invoice No.{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}></span>
                <br />
               
                <strong style={{ fontSize: "0.8rem", marginRight: "2.5rem" }}>
                  Order No.{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}>
                  {orderDetail?.orderNumber}
                </span>
                <br />
              </div>
              

              
            </Grid>

            <Grid item xs={6}>
              <div>
                <strong style={{ fontSize: "0.8rem", marginRight: "2rem" }}>
                  Transporter Name{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}>XPRESS BEES</span>
                <br />
                <strong style={{ fontSize: "0.8rem", marginRight: "7.1rem" }}>
                  Date{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}>{orderDate}</span>
                <br />
               
                <span style={{ fontSize: "0.8rem" }}>{invoiceDetails ? invoiceDetails?.id : ""}</span>
                <br />
              </div>
             
            </Grid>
          </Grid>
          <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div>
                <strong style={{ fontSize: "0.8rem", marginRight: "5rem" }}>
                  City{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}>Bangalore</span>
                <br />
                <strong style={{ fontSize: "0.8rem", marginRight: "3.2rem" }}>
                  Pin code{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}>560068</span>
                <br />
                <strong style={{ fontSize: "0.8rem", marginRight: "2.5rem" }}>
                  State code{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}>17</span>
                <br />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <strong style={{ fontSize: "0.8rem", marginRight: "4rem" }}>
                  State{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}>Karnataka</span>
                <br />
                <strong style={{ fontSize: "0.8rem", marginRight: "3rem" }}>
                  GS/CTY{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}>0</span>
                <br />
               
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <strong style={{ fontSize: "0.8rem", marginRight: "6rem" }}>
                  Weight{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}>1</span>
                <br />
                <strong style={{ fontSize: "0.8rem", marginRight: "3rem" }}>
                  Payment mode{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}>
                  {invoiceDetails?.financial_status == "pending"
                    ? "COD"
                    : "Prepaid"}
                </span>
                <br />
                <strong style={{ fontSize: "0.8rem", marginRight: "0.8rem" }}>
                  Collect COD amount{" "}
                </strong>{" "}
                <span style={{ fontSize: "0.8rem" }}>
                  {invoiceDetails
                    ? invoiceDetails.financial_status == "pending"
                      ? invoiceDetails.total_price
                      : 0
                    : 0}
                </span>
                <br />
              </div>
            </Grid>
          </Grid>
        </div>  */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "1rem",
            background: "#EEEEEE",
            fontWeight: 500,
            fontSize: 14,
            padding: "0.3rem",
          }}
        >
          <strong>TAX INVOICE</strong>
        </div> */}
        <br></br>
        <Grid container >
          <Grid item xs={4}>
            <div>
              <strong style={{ fontSize: "0.8rem" }}>Sold by </strong>
              <br /> <br />
              <Typography style={{ fontSize: "0.8rem" }}>
                Neometa lifestyle Pvt. Ltd.
                <br />
                co/ Busybees Logistics Solution Pvt. Ltd.
                <br />
                Survey No 26, Kalkunte Agrahara Village
                <br />
                Bengaluru 560066
                <br />
                GST-29AAICN7886Q1Z4
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={6} >
              <Grid item xs={6}>
                <div>
                  <strong style={{ fontSize: "0.8rem", marginRight: "2.79rem" }}>
                    Order No.{" "}
                  </strong>
                  {" "}
                  <span style={{ fontSize: "0.8rem" }}>
                    {orderDetail?.orderNumber}
                  </span>
                  {/* <strong style={{ fontSize: "0.8rem", marginRight: "2.1rem" }}>
                    Invoice No.{" "}
                  </strong> */}
                  {" "}
                  <span style={{ fontSize: "0.8rem" }}></span>
                  <br />
                  <strong style={{ fontSize: "0.8rem", marginRight: "0.8rem" }}>
                    Payment mode{" "}
                  </strong>{" "}
                  <span style={{ fontSize: "0.8rem", marginRight: "0.2rem" }}>
                    {invoiceDetails?.financial_status == "pending"
                      ? "COD"
                      : "Prepaid"}
                  </span>
                  <br />
                  <strong style={{ fontSize: "0.8rem", marginRight: "0.8rem" }}>
                    Type of supply{" "}
                  </strong>{"             "}
                  <span style={{ fontSize: "0.8rem", width: "1rem" }}>
                    Garments and accessories
                  </span>
                  <br />
                  <strong style={{ fontSize: "0.8rem", marginRight: "0.8rem" }}>
                    Place of supply{" "}
                  </strong>{" "}
                  <span style={{ fontSize: "0.8rem" }}>Karnataka-29</span>
                  <br />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <strong style={{ fontSize: "0.8rem", marginRight: "2rem" }}>
                    Order Date{" "}
                  </strong>{" "}
                  <span style={{ fontSize: "0.8rem" }}>{formattedDate}</span>
                  <br />
                  <strong style={{ fontSize: "0.8rem", marginRight: "1.5rem" }}>
                    Invoice Date{" "}
                  </strong>{" "}
                  <span style={{ fontSize: "0.8rem" }}>{orderDate}</span>
                  <br />
                  {/* <strong style={{ fontSize: "0.8rem", marginRight: "0.7rem" }}>
                    Order currency
                  </strong> */}
                  <span style={{ fontSize: "0.8rem" }}>{invoiceDetails?.currency}</span>
                  <br />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginTop: "1rem",
            marginBottom: "1rem",
            background: "#EEEEEE",
            fontWeight: 500,
            fontSize: 14,
            padding: "0.3rem",
            gap:"40rem"
          }}
        >
          <strong style={{ fontSize: "0.8rem" }}>BILING ADDRESS </strong>
          <strong style={{ fontSize: "0.8rem" }}>SHIPPING ADDRESS </strong>
        </div>
        <Grid container spacing={2} marginBottom="1rem">
          <Grid item xs={10} style={{ fontSize: 12, }}>
            <div>
              {orderDetail?.shippingAddress?.firstName}
              <br />
              {orderDetail?.shippingAddress?.address1},
              {orderDetail?.shippingAddress?.address2}
              {orderDetail?.shippingAddress?.city}
              <br />
              {orderDetail?.shippingAddress?.province},
              {orderDetail?.shippingAddress?.zip}
              <br />
              {emailValue}
              <br />
              Contact: {orderDetail?.shippingAddress?.phone}
            </div>
          </Grid>
          <Grid item xs={2} style={{ fontSize: 12 }}>
            <div>
              {orderDetail?.shippingAddress?.firstName}
              <br />
              {orderDetail?.shippingAddress?.address1},
              {orderDetail?.shippingAddress?.address2}
              {orderDetail?.shippingAddress?.city}
              <br />
              {orderDetail?.shippingAddress?.province},
              {orderDetail?.shippingAddress?.zip}
              <br />
              {emailValue}
              <br />
              Contact: {orderDetail?.shippingAddress?.phone}
            </div>
          </Grid>
        </Grid>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th rowSpan="2" style={thStyle} >S.No.</th>
              <th rowSpan="2" style={thStyle}>Item Code</th>
              <th rowSpan="2" style={thStyle}>HSN</th>
              <th rowSpan="2" style={thStyle}>Qty</th>
              <th rowSpan="2" style={thStyle}>Unit Price</th>
              <th rowSpan="2" style={thStyle}>Discount</th>
              <th rowSpan="2" style={thStyle}>Taxable <br />Value</th>
              <th style={thStyle} colSpan="2" >CGST</th>
              <th style={thStyle} colSpan="2" >SGST/UGST</th>
              <th style={thStyle} colSpan="2">IGST</th>
              <th rowSpan="2" style={thStyle}>Total</th>
            </tr>
            <tr>
              <th style={thStyle}>Rate</th>
              <th style={thStyle}>Amt</th>
              <th style={thStyle}>Rate</th>
              <th style={thStyle}>Amt</th>
              <th style={thStyle}>Rate</th>
              <th style={thStyle}>Amt</th>
            </tr>
          </thead>
          
          <tbody>
            {invoiceItems.map((item, index) => (
              <tr key={index}>
                <td style={cellStyle}>1</td>
                <td style={cellStyle}>{item.itemCode}</td>
                <td style={cellStyle}>{item.hsn}</td>
                <td style={cellStyle}>{item.qty}</td>
                <td style={cellStyle}>₹{item.unitPrice}</td>
                <td style={cellStyle}>-₹{item.discount.toFixed(2)}</td>
                <td style={cellStyle}>₹{item.taxableValue.toFixed(2)}</td>
                <td style={cellStyle}>{item.gstn1}</td>
                <td style={cellStyle}>₹{((6 * parseFloat(compareAtPriceValue)) / 100).toFixed(2)}</td>
                <td style={cellStyle}>{item.gstn2}</td>
                <td style={cellStyle}>₹{((6 * parseFloat(compareAtPriceValue)) / 100).toFixed(2)}</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                {/* <td style={cellStyle}>{item.gstn4}</td> */}
                <td style={cellStyle}>₹{item.totalAmount.toFixed(2)}</td>

              </tr>
            ))}
            {invoiceItems.map((item, index) => (
              <tr key={index}>
                <td style={cellStyle}>2</td>
                <td style={cellStyle}><b>COD Charge</b></td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>

              </tr>
            ))}
            {invoiceItems.map((item, index) => (
              <tr key={index}>
                <td style={cellStyle}>3</td>
                <td style={cellStyle}><b>Shipping Charge</b></td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                <td style={cellStyle}>0.00</td>
                {/* <td style={cellStyle}>{item.gstn4}</td> */}
                <td style={cellStyle}>Free</td>

              </tr>
            ))}
            {invoiceItems.map((item, index) => (
            <tr>
              <th colspan="3" style={cellStyle}>Total</th>
              <td style={cellStyle} >{item.qty}</td>
              <td colspan="2" style={cellStyle} ></td>
              <td style={cellStyle}>₹{item.taxableValue.toFixed(2)}</td>
              <td colspan="2" style={cellStyle} >₹{((6 * parseFloat(item.unitPrice)) / 100).toFixed(2)}</td>
              <td colspan="2" style={cellStyle} >₹{((6 * parseFloat(item.unitPrice)) / 100).toFixed(2)}</td>
              <td colspan="2" style={cellStyle} >0.00</td>
              <td style={cellStyle} >₹{item.totalAmount.toFixed(2)}</td>
            </tr>
            ))}
          </tbody>
        </table>
        <Typography
          variant="body1"
          align="left"
          sx={{ mt:2,fontWeight: "bold", fontSize: "0.8rem" }}
        >
          Disclaimer : This is a system generated invoice, signature not
          required.
        </Typography>
        <Typography
          variant="body1"
          align="left"
          style={{ fontWeight: "bold", fontSize: "0.8rem" }}
        >
          Thank you for shopping with us.
        </Typography>
      </Paper>
    </Box>
  );
};

export default InvoicePage;
