import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Backdrop,
  CircularProgress,
  Typography,
  Drawer,
  RadioGroup, FormControlLabel,Radio,Divider 
} from "@mui/material";
import CommonCard from "./CommonCard";
import { client } from "../../common/Utils";
import { ProductAllCollection,getAllProductCollectionId } from "../../api/Queries";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LazyLoad from "react-lazyload";
import Footer from "../../common/Footer";
import { setName } from "../../Redux/UserSlice";
import { positions } from "@mui/system";
import FilterCategories from "./FilterCategories";
import { setAllProducts } from "../../Redux/CartSlice";
import FilterListIcon from "@mui/icons-material/FilterList";
import { setGetAllProducts } from "../../Redux/CartSlice";
import InfiniteScroll from "react-infinite-scroll-component"
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
// import useScrollPosition from "../../common/useScrollPosition";


const AllCategories = () => {
  const dispatch = useDispatch();
  dispatch(setName("abc"));
  let navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const allProductVale = useSelector((state) => state.cart.allProductcardValue);
  const getAllProducts = useSelector((state) => state.cart.getAllProducts);
  const [arrallproductget, setArrallproductget] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
 const[hasMore,setHasMore]=useState(true)
 const[isLoadingMore,setIsLoadingMore]=useState(false)
 const [loadedProducts, setLoadedProducts] = useState(8);
 const [sortOrder, setSortOrder] = useState('')
const [sortDrawer,setSortDrawer]=useState()
const [selectedSort, setSelectedSort] = useState('');
const[allproductValue,setAllProductValue]=useState()

 
  const handleOpne = () => {
    setOpenFilter(true);
  };
  const closeDrawer = () => {
    setOpenFilter(false);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await client
          .query({
            query: getAllProductCollectionId,
          })
          .then((resd) => {
            handleAllproduct(resd?.data?.menu?.items[0]?.resourceId)
          });
      } catch (error) {
        console.error(error);
      }
    };
    fetch();
  }, [arrallproductget]);

const handleAllproduct = async (res) => {
  setLoading(true)
  const responseData = await client
    .query({
      query: ProductAllCollection,
      variables: { id: res},
    })
    .then((res) => {
      const nodeData = res?.data?.collection?.products?.edges?.map((e) => e?.node)
      dispatch(setGetAllProducts(nodeData))
       setAllProductValue(nodeData)
      setFilterProducts(nodeData)
      setLoading(false)
    });
};

const loadMoreProducts = () => {
  setIsLoadingMore(true);
  setTimeout(() => {
    setLoadedProducts(loadedProducts + 8);
    setIsLoadingMore(false);
  }, 1000); 
}; 

const handleSortChange = (event) => {
  const sortOrder = event.target.value;
  setSelectedSort(sortOrder);

  const sortedProduct = [...allproductValue]?.sort((a, b) => {
    const priceA = a?.variants?.nodes[0]?.price?.amount || 0;
    const priceB = b?.variants?.nodes[0]?.price?.amount || 0;

    if (sortOrder === 'lowToHigh') {
      return priceA - priceB; 
    } else if (sortOrder === 'highToLow') {
      return priceB - priceA; 
    }

    return 0;
    
  });
  setFilterProducts(sortedProduct);
  closeDrawer1();
};
const handleOpnesort=()=>{
  setSortDrawer(true)

}
const closeDrawer1 = () => {
  setSortDrawer(false);
};
const totalProducts = filterProducts?.filter((e) => e?.availableForSale === true)?.length;
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          paddingLeft: { lg: "1vw", xs: "1vw" },
          paddingRight: {lg:"1vw", xs:"1vw"},
          paddingBottom: { lg: "2vh", xs: "65px" },
          paddingTop: "2vh",
          display: "flex",
          mt:{lg:-1,xs:-1},
          backgroundColor:"#f1f1f1"
        }}
      >
        <Grid
          container
          spacing={2}
          columnSpacing={{ lg: 1, md: 8, sm: 3, xs: 1 }}
          rowSpacing={3}
          sx={{ mt: ".3rem" , }}
          style={{ position: "relative" }} // Add this line
        >
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={2.4}
            sx={{
              display: {
                lg: "block",
                md: "block",
                sm: "none",
                xs: "none",
                position: "static",
                left: 0,
              },
              position: "sticky", // Add this line
              top: "22vh", // Add this line
              // height: "100vh", // Add this line
              overflowY: "auto",
              height: "calc(100vh - 18vh)", 
            }}
          >
             <Box sx={{marginTop:"-1rem",marginLeft:"1rem"}} >
             <Typography variant="body2" color="textSecondary" component="p">
              Total Products: {totalProducts}
        </Typography>
             </Box>
            <FilterCategories
              setArrallproductget={setFilterProducts}
              closeDrawer={closeDrawer}
              filterProducts={filterProducts}
            />
          </Grid>
          <Grid
            container
            spacing={0.8}
            item
            xs={12}
            sm={12}
            md={8}
            lg={9.6}
            sx={{ paddingRight: { lg: "0.8rem", xs: 0 } }}
          >
           {filterProducts?.filter((e)=>e?.availableForSale==true)?.map((e) => (
            
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                key={e.id}
                sx={{ marginBottom:"",}}
              >
                <InfiniteScroll
             dataLength={loadedProducts}
              next={loadMoreProducts}
               hasMore={hasMore}
                // loader={isLoadingMore && <CircularProgress />}
               >
                <CommonCard
                  props={e}
                  imgUrl={e?.images?.edges[0]?.node?.url}
                  imgUrlTwo={e?.images?.edges[1]?.node?.url}
                  discription={e?.description}
                  catTitle = 'All'
                  // discription={e?.description.length>60?`${e.description.substring(0, 150)}...`:e.description}
                  titile={e?.title}
                  availableForSale={e?.availableForSale}
                  productVariantPrice={e?.variants?.nodes[0].price.amount}
                  productVariantCompareAtPrice={e?.variants?.nodes[0]?.compareAtPrice?.amount}
                  productVariantId={e?.variants?.nodes[0].id}
                  productVariants={e?.variants?.nodes}
                  shippingCharges={e?.variants?.nodes[0].weight}
                />
                </InfiniteScroll>
              </Grid>
            ))}
             {isLoadingMore && <CircularProgress sx={{ml:{lg:"35rem",md:"35rem",sm:"23rem",xs:"10rem"}}} />}
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: { lg: "none", md: "none", sm: "flex", xs: "flex" },
          background: "white",
          border:"0.3px solid #999999",
          height: "3rem",
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
          alignItems: "center",
          padding: "0 7px",
          zIndex: 999,
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "center", marginRight: "5rem" }}
          onClick={() => handleOpne()}
        >
          <FilterAltOutlinedIcon
            fontSize="medium"
            sx={{ color: "#107081", marginTop: "0.4rem" }}
          />
          <Typography
            color="#454545"
            sx={{
              fontSize: "15px",
              fontWeight: "600",
              marginTop: "0.4rem",
              cursor: "pointer",
              fontFamily:"arial"
            }}
          >
              FILTER
          </Typography>
        </Box>
        <Divider sx={{borderColor: "#999999",marginRight:"3.5rem",height:"2rem"}} orientation="vertical"  />
        <Box
          sx={{ display: "flex", justifyContent: "center",marginRight:'3rem' }}
          onClick={() => handleOpnesort()}
        >
          <SortOutlinedIcon
            fontSize="medium"
            sx={{ color: "#107081", marginTop: "0.4rem", }}
          />
          <Typography
            color="#454545"
            sx={{
              fontSize: "15px",
              fontWeight: "600",
              marginTop: "0.5rem",
              cursor: "pointer",
              fontFamily:"arial"
            }}
          >
            SORT
          </Typography>
        </Box>
      </Box>

      <Drawer
        anchor="bottom"
        open={openFilter}
        onClose={closeDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
          },
        }}
      >
        <FilterCategories
          setArrallproductget={setFilterProducts}
          closeDrawer={closeDrawer}
          filterProducts={filterProducts}
        />
      </Drawer>
         <Drawer
        anchor="bottom"
        open={sortDrawer}
        onClose={closeDrawer1}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
          },
        }}
      >
        <RadioGroup value={selectedSort} onChange={handleSortChange} >
          <FormControlLabel sx={{marginLeft:'1.5rem'}}

            value="lowToHigh"
            control={<Radio sx={{ marginRight: "3rem" }} />}
            label="Price-Low to High"
          />
           <Divider light />
         <FormControlLabel
            value="highToLow"
            label="Price-High to Low"
            control={<Radio sx={{ marginRight: "3rem" }} />}
           
            sx={{marginLeft:'1.5rem'}}
            
          />
        </RadioGroup>
      </Drawer>
    </>
  );
};

export default AllCategories;
