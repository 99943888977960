import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCart,
  setCheckoutDetailes,
  setCheckoutVariants,
  setPhonpayOrderId,
  setRequestId,
} from "../../Redux/CartSlice";
import { setTempAddress } from "../../Redux/UserSlice";
import {
  createOnlineOrder,
  order,
  sendMailTemplates,
  sendSms,
} from "../../api/Api";
import { productByProductIdPay } from "../../api/Queries";
import { client } from "../../common/Utils";

const Payment = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [payOrderId, setPayOrderId] = useState(false);
  const cartItems = useSelector((state) => state?.cart?.cart);
  const createCardValues = useSelector((state) => state?.cart?.createCardValue);
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const discountdata = useSelector((state) => state?.cart?.discountdata);
  const shippingValue = useSelector((state) => state?.cart?.shippingValue);
  const totalPrice = useSelector((state) => state?.cart?.totalPrice);
  // const totalPriceWithoutRound = useSelector((state) => state?.cart?.totalPriceWithoutRound);
  const coupantextValue = useSelector((state) => state.cart.coupantextValue);
  const [showAlternateImage, setShowAlternateImage] = useState(false);
  const emailValue = useSelector((state) => state?.user?.emailValue);
  const coupanValue = useSelector((state) => state?.user?.coupanValue);
  const tempAddress = useSelector((state) => state?.user?.tempAddress);
  const requestId = useSelector((state) => state?.cart?.requestId);

  let newDate = new Date();
  let requestIdK = (
    newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
  )
    .toString()
    .replace(/[T.Z:_-]/g, "");
  useEffect(() => {
    dispatch(setRequestId(requestIdK));
  }, []);

  // useEffect(() => {
  //   const beforeUnloadHandler = (e) => {
  //     // Customize the confirmation message for the browser dialog
  //     e.returnValue = "Changes you made may not be saved. Are you sure you want to leave?";
  //   };
  //   window.addEventListener("beforeunload", beforeUnloadHandler);
  //   return () => {
  //     window.removeEventListener("beforeunload", beforeUnloadHandler);
  //   };
  // }, []);

  const dicountPre =
    (20 / 100) * createCardValues?.estimatedCost?.totalAmount.amount;
  const dicountTotalPrice =
    parseFloat(
      (20 / 100) * createCardValues?.estimatedCost?.totalAmount.amount
    ) + parseFloat(coupanValue);
  const compareTotalPrice =
    parseFloat(createCardValues?.estimatedCost?.totalAmount.amount) -
    dicountTotalPrice;

  const hs = toString(dicountTotalPrice);

  const freeChargeValue =
    shippingValue?.data?.checkoutCreate?.checkout?.availableShippingRates
      ?.shippingRates[0]?.priceV2?.amount === 0;
  let custId = parseInt(userDetails?.id.split("/").pop(), 10);
  const defAddress = useSelector((state) => state?.user?.defualtUserAddress);
  const [value, setValue] = React.useState(" ");
  const [varientId, setVarientId] = React.useState();
  const shipping = freeChargeValue
    ? 0
    : shippingValue?.data?.checkoutCreate?.checkout?.availableShippingRates
        ?.shippingRates[0]?.priceV2?.amount;
  //  let CoupandiscountPrice = (compareTotalPrice.toString())-(discountdata?.checkoutDiscountCodeApplyV2?.checkout?.subtotalPrice?.amount);
  let CoupandiscountPrice = dicountTotalPrice;
  let discountCode =
    discountdata?.checkoutDiscountCodeApplyV2?.checkout?.discountApplications
      ?.nodes[0]?.code;
  let discountType =
    discountdata?.checkoutDiscountCodeApplyV2?.checkout?.discountApplications
      ?.nodes[0]?.targetType;

  const result = cartItems.map(({ merchandiseId, quantity, title, price }) => ({
    title,
    price,
    variant_id: merchandiseId.substring(
      merchandiseId.indexOf("ProductVariant/") + "ProductVariant/".length
    ),
    quantity: quantity,
    requires_shipping: true,
  }));

  // const decimalValue = (totalPriceWithoutRound%1).toFixed(2);
  // const deductedCoupandiscountPrice = (Number(CoupandiscountPrice) + Number(decimalValue)).toFixed(2);

  const data = {
    order: {
      line_items: result,
      shipping_address: {
        first_name:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.firstName
            : defAddress?.firstName,
        last_name:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.firstName
            : defAddress?.firstName,
        address1:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.address1
            : defAddress?.address1,
        phone:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.phone
            : defAddress?.phone,
        city:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.city
            : defAddress?.city,
        province:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.province
            : defAddress?.province,
        country:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.country
            : defAddress?.country,
        zip:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.zip
            : defAddress?.zip,
      },
      billing_address: {
        first_name:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.firstName
            : defAddress?.firstName,
        last_name:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.firstName
            : defAddress?.firstName,
        address1:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.address1
            : defAddress?.address1,
        phone:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.phone
            : defAddress?.phone,
        city:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.city
            : defAddress?.city,
        province:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.province
            : defAddress?.province,
        country:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.country
            : defAddress?.country,
        zip:
          (tempAddress && Object.keys(tempAddress).length != 0) ||
          tempAddress == null
            ? tempAddress?.zip
            : defAddress?.zip,
      },
      shipping_lines: [
        {
          price: shipping,
          title: "Standard Shipping",
        },
      ],
      //   "tax_lines": [
      //     {
      //         "price": coupantextValue ?  discountdata?.checkoutDiscountCodeApplyV2?.checkout?.totalTax.amount :  createCardValues?.estimatedCost?.totalTaxAmount?.amount,
      //         "rate": (Number(totalPrice)-Number(createCardValues?.estimatedCost?.totalTaxAmount?.amount))> 1199 ? 0.12: 0.12,
      //         "title": "GST"
      //     }
      // ],
      // total_tax: coupantextValue ?  discountdata?.checkoutDiscountCodeApplyV2?.checkout?.totalTax.amount :  createCardValues?.estimatedCost?.totalTaxAmount?.amount,
      total_price: compareTotalPrice,
      currency: createCardValues?.estimatedCost?.totalAmount?.currencyCode,
      transactions: [
        {
          kind: "sale",
          status: "pending",
          amount: compareTotalPrice,
          gateway: "Cash on Delivery",
          payment_method: "cash",
          source_name: userDetails?.firstName,
          currency: "INR",
        },
      ],
      financial_status: "pending",

      customer: {
        id: custId,
      },

      // fulfillments: [
      //   {
      //     location_id: 90485096744,
      //     tracking_number: "ABC123XYZ",
      //     tracking_url: "https://example.com/tracking/ABC123XYZ",
      //     status: "shipped",
      //   },
      // ],

      discount_codes: [
        {
          code: coupantextValue ? discountCode : "" ? discountCode : "20% Off",
          amount: coupantextValue
            ? CoupandiscountPrice
            : ""
            ? CoupandiscountPrice
            : dicountTotalPrice,
          type: coupantextValue
            ? discountType
            : ""
            ? discountType
            : "LINE_ITEM",
        },
      ],
      // discount_codes: [
      //   {
      //     code: coupantextValue ?  discountCode : "20% Discount",
      //     amount: coupantextValue ? CoupandiscountPrice : "150",
      //     type: coupantextValue ? discountType : "LINE_ITEM",
      //   },
      // ],
    },
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleCheckout = () => {
    setLoading(true);
    if (value == "Online") {
      // window.Instamojo.open('https://instamojo.com/@prashant_jindal',options);
      let newDate = new Date();
      let dataOnline = {
        amount: compareTotalPrice,
        surcharge: 0,
        purpose: "shopping",
        name: userDetails?.firstName,
        email: userDetails?.email,
        phone: userDetails?.phone,
        requestId: requestId,
        currency: "INR",
        paymentMode: "online",
        clientId: "Aiprus",
        redirectUrl: "https://www.nobiylifestyle.com/success",
      };

      createOnlineOrder(dataOnline)
        .then((response) => {
          // setPayOrderId(response?.data?.orderId);
          window.location.href = response.data;
          dispatch(setPhonpayOrderId(response.data));
          // setPayOrderId(response?.data?.orderId)
          // setLoading(true);
          // console.log(response);
          // Redirect the user to the custom payment link
          // window.Instamojo.open(response.data.longurl);
          // window.location.href = response.data.longurl;
        })
        .catch((error) => {
          console.error("Error creating payment link:", error);
        });
    } else if (value == "COD") {
      order(data).then((res) => {
        const parsedData = res.data;
        let data = {
          smsType: 2,
          name: parsedData?.order?.shipping_address?.first_name,
          mobileNumberWithCode: `+91${String(
            parsedData?.order?.shipping_address.phone
          )
            .split("-")
            .join("")}`,
          orderNo: String(parsedData?.order?.id),
          shipment: "",
          shipmentUrl: "",
        };
        sendSms(data)
          .then((res) => {})
          .catch((e) => {
            console.log(e);
          });
        // Date Format
        const dateTimeString = parsedData?.order?.created_at;
        const splitResult = dateTimeString.split("T");
        const datePart = splitResult[0]; // "2023-09-15"
        const [year, month, day] = datePart.split("-");
        const outputDateString = `${day}-${month}-${year}`;

        let orderPlaced = {
          mailType: "3",
          email: emailValue,
          order: data.orderNo,
          address: `${parsedData?.order?.shipping_address?.address1} ${parsedData?.order.shipping_address.address2} ${parsedData.order.shipping_address.city} ${parsedData.order.shipping_address.province} ${parsedData.order.shipping_address.country}`,
          orderTotal: `${parsedData.order.total_price}`,
          orderDate: outputDateString,
        };
        sendMailTemplates(orderPlaced)
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          });

        let variant = parsedData?.order?.line_items.map(
          (item) => item.variant_id
        );
        let variantIds = variant?.map(
          (id) => `gid://shopify/ProductVariant/${id}`
        );
        dispatch(setCheckoutDetailes(parsedData?.order?.line_items));
        client
          .query({
            query: productByProductIdPay,
            variables: { ids: variantIds },
          })
          .then((res) => {
            setLoading(false);
            dispatch(setCheckoutVariants(res?.data?.nodes));
            let path = `/oderPlace`;
            navigate(path);
            dispatch(setCart());
            dispatch(setTempAddress(""));
            //  console.log(res?.data?.nodes,"looop")
          });
      });
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 10000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container sx={{ paddingTop: { xs: "10vh", lg: "16vh" ,sm:"8vh"} }}>
        <Typography
              color="#006D82"
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                marginBottom: "1rem",
                mt:"4rem"
              }}
              onClick={() => navigate("/yourBag")}
            >
              <ArrowBackIcon sx={{ fontSize: 30, mb: -1, cursor: "pointer" }} />
              Back
            </Typography>
        <Card
          sx={{
            borderRadius: "20px",
            backgroundColor: "#18161D",
            boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.5)",
          }}
        >
          {/* <Grid container> */}
          <h1 style={{ textAlign: "center", color: "white" }}>Payment</h1>
          <Divider
            sx={{
              bgcolor: "#EA9A23",
              height: "2px",
              margin: {
                lg: "0 15vw",
                md: "0 15vw",
                sm: "0 5vw",
                xs: "0 2vw",
              },
            }}
          />
          <div
            style={{
              textAlign: "center",
              color: "white",
              marginTop: "5rem",
            }}
          >
            <FormControl>
              <RadioGroup value={value} onChange={handleChange}>
                <FormControlLabel
                  value="COD"
                  control={
                    <Radio
                      size="large"
                      sx={{
                        color: "#006D82",

                        "&.Mui-checked": {
                          color: "#006D82",
                        },
                      }}
                    />
                  }
                  label="Cash on Delivery (COD)"
                />
                <FormControlLabel
                  value="Online"
                  control={
                    <Radio
                      size="large"
                      sx={{
                        color: "#006D82",
                        "&.Mui-checked": {
                          color: "#006D82",
                        },
                      }}
                    />
                  }
                  label="Online Payments"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "2.5rem",
              marginBottom: "8rem",
            }}
          >
            <Button
              disabled={value == " " ? true : false}
              onClick={() => {
                handleCheckout();
              }}
              sx={{
                "&.Mui-disabled": {
                  // background: "#eaeaea",
                  border: "1px solid white",
                  color: "#ffffff",
                },
                bgcolor: "#006D82",
                color: "#FFFFFF",
                p: "0.5rem 0rem",
                textTransform: "none",
                height: "2.2rem",
                width: "13rem",
                fontSize: { xs: 14, sm: 12, md: 13, lg: 14 },
              }}
              variant="contained"
            >
              Place Order
            </Button>
          </div>
          {/* </Grid> */}
        </Card>
      </Container>
    </>
  );
};

export default Payment;
