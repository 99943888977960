import { Route, Routes } from "react-router-dom";
import "./App.css";
import SearchBar from "./Search/SearchBar";
import Headerbar from "./common/Headerbar";
import SizeChartPage from "./common/SizeChartPage";
import TabBar from "./common/TabBar";
import AboutUs from "./page/AboutUs/AboutUs";
import Cart from "./page/Cart/Cart";
import SearchValue from "./page/Categories/SearchValue";
import CouponCode from "./page/CouponCode/CouponCode";
import Home from "./page/Home/Home";
import ForgotPassword from "./page/Login/ForgotPassword";
import LoginPage from "./page/Login/LoginPage";
import MarqueeScreen from "./page/Marquee/MarqueeScreen";
import PairingScreen from "./page/PairingScreen/PairingScreen";
import PreviewComb from "./page/PairingScreen/PreviewComb";
import Payment from "./page/Payment/Payment";
import ChangePassword from "./page/Profile/ChangePassword";
import EditAddress from "./page/Profile/EditAddress";
import EditProfile from "./page/Profile/EditProfile";
import MyAddress from "./page/Profile/MyAddress";
import Profile from "./page/Profile/Profile";
import Shop from "./page/Shop/Shop";
import NewPassword from "./page/Singup/NewPassword";
import OtpPopUp from "./page/Singup/OtpPopUp";
import SingUp from "./page/Singup/SingUp";
import PreviousOrder from "./page/TrackOrder/PreviousOrder";
import TrackActiveOrder from "./page/TrackOrder/TrackActiveOrder";
import TrackOrder from "./page/TrackOrder/TrackOrder";
import TrackOrderScreen from "./page/TrackOrder/TrackOrderScreen";
import Wishlist from "./page/WishlistScreen/Wishlist";
import ItemDetail from "./page/YourBag/ItemDetail";
import OderPlace from "./page/YourBag/OderPlace";
import YourBag from "./page/YourBag/YourBag";
import ContactUs from "./policy/ContactUs";
import PrivacyPolicy from "./policy/PrivacyPolicy";
import ReturnPolicy from "./policy/ReturnPolicy";
import TermsAndConditions from "./policy/TermsAndConditions";
// import Hoodies from './page/Collections/Collections';
import Collections from "./page/Collections/Collections";
import Success from "./page/Payment/Success";
import FundReturn from "./page/TrackOrder/FundReturn";
import AddressPopup from "./page/YourBag/AddressPopup";
import ShippingPolicy from "./policy/ShippingPolicy";
import InvoicePage from "./page/TrackOrder/InvoicePage";

function App() {
  return (
    <div className="root1">
      {/* {showVideo ?
        " " : */}
      <Headerbar
        position="static"
        style={{ background: "transparent", boxShadow: "none" }}
      />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/collections" element={<Collections />} />
        <Route exact path="/cat" element={<TabBar />} />
        <Route exact path="/aboutUs" element={<AboutUs />} />
        <Route exact path="/trackOrder" element={<TrackOrder />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/searchBar" element={<SearchBar />} />
        <Route exact path="/yourBag" element={<YourBag />} />
        <Route exact path="/logIn" element={<LoginPage />} />
        <Route exact path="/oderPlace" element={<OderPlace />} />
        <Route exact path="/singUp" element={<SingUp />} />
        <Route exact path="/success" element={<Success />} />
        <Route exact path="/trackActiveOrder" element={<TrackActiveOrder />} />
        <Route exact path="/trackOrderScreen" element={<TrackOrderScreen />} />
        <Route exact path="/forgotPassword" element={<ForgotPassword />} />
        <Route exact path="/itemDetail/:name" element={<ItemDetail />} />
        <Route exact path="/shop" element={<Shop />} />
        <Route exact path="/pairingScreen" element={<PairingScreen />} />
        <Route exact path="/wishlist" element={<Wishlist />} />
        <Route exact path="/marqueeScreen" element={<MarqueeScreen />} />
        <Route exact path="/otpPopUp" element={<OtpPopUp />} />
        <Route exact path="/newPassword" element={<NewPassword />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/editAddress" element={<EditAddress />} />
        <Route exact path="/editProfile" element={<EditProfile />} />
        <Route exact path="/myaddress" element={<MyAddress />} />
        <Route exact path="/changepassword" element={<ChangePassword />} />
        <Route exact path="/previousOrder" element={<PreviousOrder />} />
        <Route exact path="/previewComb" element={<PreviewComb />} />
        <Route exact path="/payment" element={<Payment />} />
        <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/returnPolicy" element={<ReturnPolicy />} />
        <Route exact path="/shippingPolicy" element={<ShippingPolicy />} />
        <Route
          exact
          path="/termsAndConditions"
          element={<TermsAndConditions />}
        />
        <Route exact path="/contactus" element={<ContactUs />} />
        <Route exact path="/couponCode" element={<CouponCode />} />
        <Route exact path="/sizechartpage" element={<SizeChartPage />} />
        <Route exact path="/searchValue" element={<SearchValue />} />
        <Route exact path="/fundReturn" element={<FundReturn />} />
        <Route exact path="/addressPopup" element={<AddressPopup />} />
        <Route exact path="/invoice" element={<InvoicePage/>}/>
      </Routes>

      {/* <Footer position="static" /> */}
    </div>
  );
}

export default App;
