import { useMutation } from "@apollo/client";
import { ErrorMessage } from "@hookform/error-message";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCommonWishList, setWishList } from "../../Redux/CartSlice";
import {
  loginSuccess,
  setAccessToken,
  setEmailMobile,
  setEmailValue,
  setGoLoginKey,
  setIsEmail,
  setIsFavorite,
  setIsPhone,
  setOldPasswordEnter,
  setUserDetails,
  setUserGoogleData,
} from "../../Redux/UserSlice";
import {
  Log_in,
  SendEmailOtp,
  SendMobileOtp,
  SocialLogin,
  findAllWishlistByCustomerId,
} from "../../api/Api";
import {
  CREATE_USER_MUTATION_SocialLogin,
  LoginUserMutation,
} from "../../api/Mutations";
import { loginDetailes, productByProductId1 } from "../../api/Queries";
import {
  ErrorText,
  client,
  failureToastMessage,
  successToastMessage,
} from "../../common/Utils";
import SocialButton from "./SocialButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});
const useStyles = makeStyles((theme) => ({
  dialogBox: {
    // height:"100vh",
    // width:"100vw",
  },
  cardCont: {
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
    // position: "relative",
    backgroundColor: "#1E1E1E",
  },
  griditems: {
    // position: "absolute",
    top: 0,
    left: 0,
    overflow: "scroll",
  },
}));

function LoginPage({
  setOpenLog,
  openLog,
  setOpenForgotPw,
  openForgotPw,
  setOpenSingUp,
  checkoutNavigate,
}) {
  const defaultValues = {
    email: "",
    password: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state?.user?.isAuth);
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const emailValue = useSelector((state) => state?.user?.emailValue);
  const isPhone = useSelector((state) => state?.user?.isPhone);
  const isEmail = useSelector((state) => state?.user?.isEmail);
  const emailMobile = useSelector((state) => state?.user?.emailMobile);
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const goLoginKey = useSelector((state) => state?.user?.goLoginKey);
  // const[accessToken, setAccessToken] = useState();
  const [loginUser] = useMutation(LoginUserMutation);
  const customerAccessToken = accessToken;
  const [createUser, { error }] = useMutation(CREATE_USER_MUTATION_SocialLogin);

  // const { loading, data } = useQuery(loginDetailes,
  //   {
  //     variables: { customerAccessToken }
  //   }
  // );

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm({ defaultValues });

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [loadingg, setLoading] = useState(false);
  const phonePattern = /^\d{10}$/; // Matches 10 digits
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email pattern

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    const user = jwt_decode(credentialResponse.credential);
    const loginData = {
      email: user?.email,
      firstName: user?.given_name,
      lastName: user?.family_name,
      imageurl: user?.picture,
      deviceToken: "",
      deviceType: 0,
      socialType: 2,
      socialToken: user?.sub,
    };
    dispatch(setUserGoogleData(user));
    dispatch(setGoLoginKey(user?.sub));
    handleLoginThroughGoogle(loginData);
  };

  const handleGoogleLoginError = (e) => {
    console.log("Login Failed", e);
  };

  // const handleSocialLogin = (user) => {
  //   const loginData = {
  //     deviceToken: "",
  //     email: user?.profile?.email,
  //     socialToken: user?.profile?.id,
  //     lastName: user?.profile?.lastName,
  //     deviceType: 0,
  //     firstName: user?.profile?.firstName,
  //     imageurl: user?.profile?.profilePicURL,
  //     socialType: 2,
  //   };
  //   dispatch(setUserGoogleData(user?.profile));
  //   dispatch(setGoLoginKey(user?.profile?.id))
  //   handleLoginThroughGoogle(loginData);
  // }

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  const handleSocialFacebookLogin = (user) => {
    console.log(user,"userDetails")
    const loginData = {
      email: user._profile?.email,
      firstName: user._profile?.firstName,
      lastName: user._profile?.lastName,
      imageurl: user._profile?.profilePicURL,
      deviceToken: "",
      deviceType: 0,
      socialType: 1,
      socialToken: user?._profile.id,
    };
    handleLoginThroughGoogle(loginData);
    dispatch(setUserGoogleData(user));
    dispatch(setGoLoginKey(loginData.socialToken));
  };

  const handleSocialFacebookLoginFailure = (err) => {
    console.error(err);
  };

  const handleLoginThroughGoogle = (loginData) => {
    setLoading(true);
    SocialLogin(loginData)
      .then((res) => {
        if (res?.data?.passwordChange == true) {
          dispatch(setUserDetails(res.data.customerData));
          createUser({
            variables: {
              firstName: res.data.customerData.firstName,
              email: res.data.customerData.email,
              password: res.data.customerData.socialPassword,
              rePassword: res.data.customerData.socialPassword,
            },
          }).then((res) => {
            if (res?.data?.customerCreate?.customer?.__typename == "Customer") {
              // setLoading(false);
              // setOpenLog(false);
              // reset(defaultValues);
              // dispatch(loginSuccess(true));
              // successToastMessage("Logged in Successfully");
              loginUser({
                variables: {
                  email: loginData.email,
                  password: loginData.socialToken,
                },
              }).then((res) => {
                setLoading(false);
                dispatch(setIsFavorite(true));
                dispatch(
                  setAccessToken(
                    res?.data.customerAccessTokenCreate.customerAccessToken
                      .accessToken
                  )
                );
                let abc =
                  res?.data.customerAccessTokenCreate.customerAccessToken
                    .accessToken;
                client
                  .query({
                    query: loginDetailes,
                    variables: { customerAccessToken: abc },
                  })
                  .then((response) => {
                    if (checkoutNavigate == false) {
                      navigate("/yourBag");
                    }
                    dispatch(setUserDetails(response?.data.customer));
                  });
                if (
                  res?.data?.customerAccessTokenCreate?.customerAccessToken
                    ?.__typename == "CustomerAccessToken"
                ) {
                  setOpenLog(false);
                  reset(defaultValues);
                  dispatch(loginSuccess(true));
                  // successToastMessage("Login Successful")
                } else if (
                  res?.data?.customerAccessTokenCreate?.customerUserErrors
                    ?.length > 0
                ) {
                  failureToastMessage(
                    res?.data?.customerAccessTokenCreate?.customerUserErrors[0]
                      ?.message
                  );
                }
              });
              successToastMessage("Logged in Successfully");
              // dispatch(loginSuccess(true));
              // dispatch(setUserDetails(res.data.customerData));
              setOpenLog(false);
            } else if (
              res?.data?.customerCreate?.customerUserErrors?.length > 0
            ) {
              setLoading(false);
              failureToastMessage(
                res?.data?.customerCreate?.customerUserErrors[0]?.message
              );
            }
          });
          if (error) {
            setLoading(false);
          }
        } else if (res.responseCode == 200) {
          const decryptedPassword = window.atob(
            res?.data?.customerData?.password
          );
          loginUser({
            variables: {
              email: loginData.email,
              password:
                res?.data?.customerData?.password == null
                  ? loginData.socialToken
                  : decryptedPassword,
            },
          }).then((res) => {
            setLoading(false);
            dispatch(setIsFavorite(true));
            dispatch(
              setAccessToken(
                res?.data.customerAccessTokenCreate.customerAccessToken
                  .accessToken
              )
            );
            let abc =
              res?.data.customerAccessTokenCreate.customerAccessToken
                .accessToken;
            client
              .query({
                query: loginDetailes,
                variables: { customerAccessToken: abc },
              })
              .then((response) => {
                if (checkoutNavigate == false) {
                  navigate("/yourBag");
                }
                dispatch(setUserDetails(response?.data.customer));
              });
            if (
              res?.data?.customerAccessTokenCreate?.customerAccessToken
                ?.__typename == "CustomerAccessToken"
            ) {
              setOpenLog(false);
              reset(defaultValues);
              dispatch(loginSuccess(true));
              // successToastMessage("Login Successful")
            } else if (
              res?.data?.customerAccessTokenCreate?.customerUserErrors?.length >
              0
            ) {
              failureToastMessage(
                res?.data?.customerAccessTokenCreate?.customerUserErrors[0]
                  ?.message
              );
            }
          });
          successToastMessage("Logged in Successfully");
          // dispatch(loginSuccess(true));
          // dispatch(setUserDetails(res.data.customerData));
          setOpenLog(false);
        } else if (res.responseCode == 0) {
          setLoading(false);
          failureToastMessage(res.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onSubmit = (detail) => {
    setLoading(true);
    const loginData = {
      email: detail.email,
      password: detail.password,
    };

    isPhone
      ? Log_in(loginData).then((res) => {
          setLoading(false);
          loginUser({
            variables: {
              email: res?.data?.email,
              password: detail.password,
            },
          })
            .then((res) => {
              dispatch(
                setAccessToken(
                  res?.data.customerAccessTokenCreate.customerAccessToken
                    .accessToken
                )
              );
              let abc =
                res?.data.customerAccessTokenCreate.customerAccessToken
                  .accessToken;
              client
                .query({
                  query: loginDetailes,
                  variables: { customerAccessToken: abc },
                })
                .then((response) => {
                  dispatch(setUserDetails(response?.data.customer));
                  findAllWishlistByCustomerId(response?.data.customer?.id).then(
                    (res) => {
                      let wishListId = res.data;
                      let productvarientIds = wishListId.map(
                        (e) => e.productVariantId
                      );
                      if (res?.data?.length == 0) {
                        setLoading(false);
                        dispatch(setCommonWishList([]));
                        dispatch(setWishList([]));
                      } else {
                        setTimeout(() => {
                          client
                            .query({
                              query: productByProductId1,
                              variables: { ids: productvarientIds },
                            })
                            .then((res) => {
                              dispatch(setWishList(productvarientIds));
                              dispatch(setCommonWishList(res.data.nodes));
                              setLoading(false);
                            });
                        }, 1000);
                      }
                    }
                  );
                });
              if (
                res?.data?.customerAccessTokenCreate?.customerAccessToken
                  ?.__typename == "CustomerAccessToken"
              ) {
                setLoading(false);
                setOpenLog(false);
                reset(defaultValues);
                dispatch(loginSuccess(true));
                successToastMessage("Login Successful");
              } else if (
                res?.data?.customerAccessTokenCreate?.customerUserErrors
                  ?.length > 0
              ) {
                setLoading(false);
                failureToastMessage(res.message);
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log(error, "phoneError");
              failureToastMessage(res.message);
            });
        })
      : loginUser({
          variables: {
            email: detail.email,
            password: detail.password,
          },
        })
          .then((res) => {
            setLoading(false);
            dispatch(
              setAccessToken(
                res?.data.customerAccessTokenCreate.customerAccessToken
                  .accessToken
              )
            );
            let abc =
              res?.data.customerAccessTokenCreate.customerAccessToken
                .accessToken;
            client
              .query({
                query: loginDetailes,
                variables: { customerAccessToken: abc },
              })
              .then((response) => {
                dispatch(setUserDetails(response?.data.customer));
                findAllWishlistByCustomerId(response?.data.customer?.id).then(
                  (res) => {
                    let wishListId = res.data;
                    let productvarientIds = wishListId.map(
                      (e) => e.productVariantId
                    );
                    if (res?.data?.length == 0) {
                      setLoading(false);
                      dispatch(setCommonWishList([]));
                      dispatch(setWishList([]));
                    } else {
                      setTimeout(() => {
                        client
                          .query({
                            query: productByProductId1,
                            variables: { ids: productvarientIds },
                          })
                          .then((res) => {
                            dispatch(setWishList(productvarientIds));
                            dispatch(setCommonWishList(res.data.nodes));
                            setLoading(false);
                          });
                      }, 1000);
                    }
                  }
                );
              });
            if (
              res?.data?.customerAccessTokenCreate?.customerAccessToken
                ?.__typename == "CustomerAccessToken"
            ) {
              setOpenLog(false);
              reset(defaultValues);
              dispatch(loginSuccess(true));
              successToastMessage("Login Successfully");
              if (checkoutNavigate == false) {
                navigate("/cart");
              }
            } else if (
              res?.data?.customerAccessTokenCreate?.customerUserErrors?.length >
              0
            ) {
              failureToastMessage(
                res?.data?.customerAccessTokenCreate?.customerUserErrors[0]
                  ?.message
              );
            } else {
              failureToastMessage(
                "Invalid User!Please check email and password"
              );
            }
          })
          .catch((error) => {
            setLoading(false);
            failureToastMessage(
              "Unidentified user! Please check email and password"
            );
          });
    if (error) {
      setLoading(false);
    }
  };

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpenLog(true);
  };

  const handleClose = () => {
    setOpenLog(false);
    reset();
    
  };

  const requestBody = {
    phoneNumber: emailMobile,
    moblieNumberCode: "+91",
  };
  const navigateToForgotPassword = (data) => {
    if (isPhone == true) {
      setLoading(true);
      SendMobileOtp(requestBody)
        .then((res) => {
          if (res.responseCode == 200) {
            setLoading(false);
            successToastMessage(`OTP Send Successfully to Number`);
            setOpenForgotPw(true);
            setOpenLog(false);
          } else {
            //  failureToastMessage(res.message);
            failureToastMessage("Phone Number is not register");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (isEmail == true) {
      setLoading(true);
      SendEmailOtp(emailValue)
        .then((res) => {
          if (res.responseCode == 200) {
            setLoading(false);
            successToastMessage(`OTP Send Successfully on ${emailValue}`);
            setOpenForgotPw(true);
            setOpenLog(false);
          } else {
            //  failureToastMessage(res.message);
            failureToastMessage("Email id is not register");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const navigateToSingUp = () => {
    setOpenLog(false);
    setOpenForgotPw(false);
    setOpenSingUp(true);
  };

  let emailArr = emailMobile?.split("").length;

  const componentClicked = (data) => {
    // console.log(data,"data")
  };
  const responseFacebook = (response) => {};

  return (
    <Box>
      <Dialog open={openLog} TransitionComponent={Transition}>
        <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loadingg}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card
            sx={{
              boxShadow: "0px 0px 32px ",
              backgroundColor: "#1E1E1E",
              width: { xs: "110vw", sm: "60vw", md: "40vw", lg: "30vw" },
              // height: "82vh",
              pb:2
            }}
          >
            {/* <CardHeader sx={{ textAlign: 'center'}} title="Checkout Form" /> */}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Grid container spacing={5} sx={{}}>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <IconButton sx={{ float: "right" }}>
                    <CloseIcon
                      onClick={handleClose}
                      style={{ color: "white", fontSize: 27 }}
                    />
                  </IconButton>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      mt: 2,
                      ml: 3,
                    }}
                  >
                    <Box>
                      <img src="/LoginLog.png" alt="" height="130vh" />
                    </Box>
                    <Box>
                      <img src="/newlogo.png" alt="" height="32vh" />
                    </Box>
                    <Box sx={{ mt: "-2%" }}>
                      <img src="/LoginLog2.png" alt="" height="12vh" />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  {/* <Box sx={{}}> */}
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: -3 }}
                  >
                    <FormControl
                      fullWidth
                      sx={{
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                    >
                      <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ bgcolor: "#595A79", borderRadius: "5px" }}
                            id="email"
                            height="10px"
                            type="text"
                            size="small"
                            variant="outlined"
                            placeholder="Enter phone number or email"
                            autoComplete="off"
                            onInput={(e) => {
                              let value = e.target.value;
                              dispatch(setEmailMobile(value));
                              if (!value) {
                                dispatch(setIsPhone(false));
                                dispatch(setIsEmail(false));
                              } else if (phonePattern.test(e.target.value)) {
                                dispatch(setIsPhone(true));
                              } else if (emailPattern.test(e.target.value)) {
                                dispatch(setEmailValue(e.target.value));
                                dispatch(setIsEmail(true));
                              }
                            }}
                            inputProps={{
                              autocomplete: "email",
                              
                              maxLength: isPhone ? 10 : 50,
                              style: {
                                height: "15px",
                              },
                            }}
                            {...register("email", {
                              required: "Email/Phone no. is mandatory",

                              pattern: {
                                // value: /^(?:\d{10}|[^\s@]+@[^\s@]+\.[^\s@]+)$/,
                                // value:/^\d{10}|^[a-z0-9.!#$%&'*+=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/,
                                value:
                                  /^\d{10}|^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "Enter vaild Email/Phone no.",
                              },
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => (
                          <ErrorText style={{fontWeight:500}}>{message}</ErrorText>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {/* </Box> */}
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  {/* <Box sx={{}}> */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FormControl
                      fullWidth
                      sx={{
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                    >
                      <Controller
                        control={control}
                        name="password"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{
                              bgcolor: "#595A79",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                            id="password"
                            type="password"
                            // onChange={handleChangePassword}
                            // value={values.password}
                            size="small"
                            variant="outlined"
                            placeholder="Password"
                            autoComplete="off"
                            onInput={(e) => {
                              let value = e.target.value;
                              dispatch(setOldPasswordEnter(value));
                            }}
                            inputProps={{
                              
                              
                              maxLength: 50,
                              style: {
                                height: "15px",
                              },
                            }}
                            {...register("password", {
                              required: "Password is mandatory",
                              // minLength: {
                              //   value: 6,
                              //   message:
                              //     "Minimum 6 character long.",
                              // },
                              // maxLength: {
                              //   value: 15,
                              //   message:
                              //     "Maximum 15 character long.",
                              // },
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="password"
                        render={({ message }) => (
                          <Typography style={{color:'red', fontWeight:600, fontSize:14}}>{message}</Typography>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {/* </Box> */}
                  <Box style={{ float: "right" }}>
                    {emailArr >= 10 && (
                      <Button
                        variant="text"
                        disabled={isPhone || isEmail == true ? false : true}
                        sx={{
                          textTransform: "none",
                          color: "white",
                          fontSize: 9.5,
                          mr: 1.5,
                        }}
                        onClick={navigateToForgotPassword}
                      >
                        {" "}
                        Forgot Password
                      </Button>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: -4,
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        width: "8vw",
                        height: "4.3vh",
                        fontSize: 13,
                        borderRadius: "40px",
                        background:
                          "linear-gradient(to right, #006D82, #006D82)",
                        textTransform: "none",
                      }}
                    >
                      Login
                    </Button>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: "1rem",
                      ml: 5,
                    }}
                  >
                    <Divider
                      sx={{
                        width: "45%",
                        height: "1.5px",
                        backgroundColor: "white",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: -3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{ color: "white", fontSize: 13 }}
                      align="center"
                    >
                      Connect With
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      pr: 1,
                      pl: 1,
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        width: "110px",
                        height: "5.2vh",
                        fontSize: 13,
                        border: "2px solid white",
                        p: 0.2,
                        borderRadius: "40px",
                        backgroundColor: "transparent",
                        "&:hover": { backgroundColor: "transparent" },
                        textTransform: "none",
                      }}
                    >
                      <GoogleOAuthProvider clientId="407573242864-l65eqnpgolqsv45ku6jg4g2qknnrc609.apps.googleusercontent.com">
                        <GoogleLogin
                          onSuccess={handleGoogleLoginSuccess}
                          onError={handleGoogleLoginError}
                          //  type="icon"
                          shape="pill"
                          size="medium"
                          text="signin"
                          theme="filled_black"
                        />

                        {/* <Box sx={{display:"flex"}}>
                         <img src="/googleLogo.png" height="23px" style={{ marginRight: "15px" }} /> 
                        <Typography sx={{color:"white",ml:1}}>Google</Typography> 
                    </Box> */}
                      </GoogleOAuthProvider>
                    </Button>
                    <Divider
                      orientation="vertical"
                      sx={{
                        bgcolor: "white",
                        width: ".5px",
                        height: "5vh",
                        ml: 1,
                        mr: 1,
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        width: "125px",
                        height: "5.2vh",
                        fontSize: 9,
                        border: "2px solid white",
                        p: 0.2,
                        borderRadius: "40px",
                        backgroundColor: "transparent",
                        "&:hover": { backgroundColor: "transparent" },
                        textTransform: "none",
                      }}
                    >
                      <SocialButton
                        provider="facebook"
                        appId="263337336529652"
                        onLoginSuccess={handleSocialFacebookLogin}
                        onLoginFailure={handleSocialFacebookLoginFailure}
                      >
                        <Box display={"flex"}>
                          <img
                            src="/fblogo.png"
                            height="27px"
                            style={{ marginRight: "10px" }}
                          />
                          <Typography
                            style={{
                              color: "white",
                              marginTop: "0.15rem",
                              fontSize: 15,
                            }}
                          >
                            Facebook
                          </Typography>
                        </Box>
                      </SocialButton>
                    </Button>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      ml: "4rem",
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: { xs: 12, sm: 12, md: 13, lg: 13 },
                      }}
                    >
                      Not a member?
                      <Button
                        variant="text"
                        sx={{
                          textTransform: "none",
                          color: "white",
                          fontSize: { xs: 12, sm: 12, md: 13, lg: 13 },
                          mt: "-3px",
                          textDecoration: "underline",
                        }}
                        onClick={navigateToSingUp}
                      >
                        {" "}
                        Sign Up
                      </Button>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </form>
            {/* <GoogleOAuthProvider clientId="407573242864-l65eqnpgolqsv45ku6jg4g2qknnrc609.apps.googleusercontent.com">
            <GoogleLogin
             onSuccess={credentialResponse => {
              let user = jwt_decode(credentialResponse.credential)
               console.log(user);
               }}
             onError={() => {
               console.log('Login Failed');
              }}
                 />
               </GoogleOAuthProvider> */}

            {/* <FacebookLogin
    appId="1378689863043613"
    autoLoad={true}
    fields="name,email,picture"
    // onClick={componentClicked}
    callback={responseFacebook}
    type="icon"
    shape="circle"
    size="small"
     />, */}
          </Card>
        </Box>
      </Dialog>
    </Box>
  );
}

export default LoginPage;
