import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import AodIcon from "@mui/icons-material/Aod";
import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from "@mui/icons-material/Key";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/Person";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setEmailValue, setIsEmail } from "../../Redux/UserSlice";
import { Register, SendEmailOtp, sendMailTemplates } from "../../api/Api";
import { CREATE_USER_MUTATION } from "../../api/Mutations";
import { failureToastMessage, successToastMessage } from "../../common/Utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});
function SingUp({
  setOpenForgotPw,
  openForgotPw,
  setOpenSingUp,
  setOpenLog,
  openSingUp,
}) {
  const ErrorText = styled("p")`
    color: red;
    font-size: 0.9rem;
    position: absolute;
    top: 1.3em;
  `;
  const dispatch = useDispatch();
  const defaultValues = {
    firstName: "",
    phoneNumber: "",
    email: "",
    password: "",
  };

  const [createUser, { error }] = useMutation(CREATE_USER_MUTATION);
  const [signUpDetail, setSignUpDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [fieldEmpty, setFieldEmpty] = useState("");
  const [reapeatPassword, setReapeatPassword] = useState("");
  const [inputEmail, setInputEmail] = useState("");

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm({ defaultValues });
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  let valueOldPassword = values.password;

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setFieldEmpty(values.password);
  };

  let singUpTemp = {
    mailType: "2",
    email: inputEmail,
  };

  const onSubmit = (data) => {
    data.password = fieldEmpty;
    setLoading(true);
    const detailes = {
      customer: {
        id: 0,
        firstName: data.firstName,
        lastName: "",
        email: data.email,
        password: data.rePassword,
        profilePic: "string",
        dateOfBirth: "2023-06-07T05:01:51.908Z",
        gender: 0,
        isDeleted: true,
        createdAt: "2023-06-07T05:01:51.908Z",
        updatedAt: "2023-06-07T05:01:51.908Z",
        deviceType: 0,
        socialPassword: "string",
        deviceToken: "string",
        phoneNumber: data.phoneNumber,
        timezoneId: "string",
        roleId: 0,
        facebookId: "string",
        facebookToken: "string",
        googleId: "string",
        googleToken: "string",
        appleId: "string",
        appleToken: "string",
        socialType: 0,
        status: "string",
        acceptsMarketing: true,
        currency: "string",
        lastOrderId: 0,
        lastOrderName: "string",
        ordersCount: 0,
        totalSpent: 0,
        storeId: 0,
      },
    };

    Register(detailes).then((res) => {
      if (res.responseCode == 200) {
        setFieldEmpty("");
        dispatch(setEmailValue(data.email));
        setLoading(false);
        failureToastMessage(res.message);
        SendEmailOtp(data.email).then((res) => {
          if (res.responseCode == 200) {
            successToastMessage(res.message);
          }
        });
        dispatch(setIsEmail(true));
        setOpenForgotPw(true);
      } else if (res.responseCode == 400) {
        setLoading(false);
        failureToastMessage(res.message);
      } else if (res.responseCode == 201) {
        createUser({
          variables: {
            firstName: data.firstName,
            email: data.email,
            phone: "+91" + data.phoneNumber,
            password: data.rePassword,
            rePassword: data.rePassword,
          },
        })
          .then((res) => {
            setLoading(false);
            setSignUpDetail(res.data.customerCreate);
            if (res?.data?.customerCreate?.customer?.__typename == "Customer") {
              successToastMessage("Registration Successful");
              setOpenSingUp(false);
              reset(defaultValues);
              setOpenLog(true);
              sendMailTemplates(singUpTemp).then((res) => {
                console.log(res);
              });
            } else if (
              res?.data?.customerCreate?.customerUserErrors?.length > 0
            ) {
              failureToastMessage(
                res?.data?.customerCreate?.customerUserErrors[0]?.message
              );
            }
          })
          .catch((e) => {
            setLoading(false);
            console.log(e);
          });
        if (error) {
        }
      }
    });
  };

  const navigateToLogin = () => {
    setOpenLog(true);
    setOpenForgotPw(false);
    setOpenSingUp(false);
  };

  const handleClose = () => {
    setOpenSingUp(false);
    reset(defaultValues);
  };

  return (
    <>
      <Dialog open={openSingUp} TransitionComponent={Transition}>
        <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card
            sx={{
              boxShadow: "0px 0px 32px ",
              backgroundColor: "#1E1E1E",
              width: { xs: "110vw", sm: "60vw", md: "40vw", lg: "30vw" },
              // height: "82vh",
              pb:2
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <IconButton sx={{ float: "right" }}>
                      <CloseIcon
                        onClick={handleClose}
                        style={{ color: "white", fontSize: 27 }}
                      />
                    </IconButton>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0.5rem",
                      }}
                    >
                      <Typography fontSize={22} sx={{ color: "white" }}>
                        Create an Account
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <Box sx={{ mb: -1 }}>
                      <img src="/newlogo.png" alt="" height="30vh" />
                    </Box>
                    <Box sx={{ mb: -3 }}>
                      <img src="/LoginLog2.png" alt="" height="12vh" />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FormControl
                      fullWidth
                      sx={{
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                    >
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field: { name } }) => (
                          <TextField
                            sx={{ bgcolor: "#595A79", borderRadius: "5px" }}
                            id="firstName"
                            height="10px"
                            type="text"
                            size="small"
                            variant="outlined"
                            placeholder="Name"
                            InputProps={{
                              maxLength: 50,
                              sx: {
                                height: "30px",
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon />
                                </InputAdornment>
                              ),
                            }}
                            {...register("firstName", {
                              required: {
                                value: true,
                                message: "Name is required",
                              },
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="firstName"
                        render={({ message }) => (
                          <ErrorText>{message}</ErrorText>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {/* </Box> */}
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FormControl
                      fullWidth
                      sx={{
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                    >
                      <Controller
                        control={control}
                        name="phoneNumber"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ bgcolor: "#595A79", borderRadius: "5px" }}
                            id="phoneNumber"
                            height="10px"
                            type="text"
                            size="small"
                            variant="outlined"
                            placeholder="Phone Number"
                            inputProps={{
                              maxLength: 10,
                            }}
                            InputProps={{
                              maxLength: 10,
                              sx: {
                                height: "30px",
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AodIcon />
                                </InputAdornment>
                              ),
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            {...register("phoneNumber", {
                              required: "Phone is mandatory",
                              minLength: {
                                value: 10,
                                message: "Phone should be 10 digits",
                              },
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="phoneNumber"
                        render={({ message }) => (
                          <ErrorText>{message}</ErrorText>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {/* </Box> */}
                </Grid>

                <Grid item md={12} xs={12} sm={12} lg={12}>
                  {/* <Box sx={{}}> */}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FormControl
                      fullWidth
                      sx={{
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                    >
                      <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ bgcolor: "#595A79", borderRadius: "5px" }}
                            id="email"
                            height="10px"
                            type="text"
                            size="small"
                            variant="outlined"
                            placeholder="E-mail"
                            autoComplete="off"
                            onInput={(e) => {
                              setInputEmail(e.target.value);
                            }}
                            InputProps={{
                              maxLength: 50,
                              sx: {
                                height: "30px",
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <MailIcon />
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              //  autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            {...register("email", {
                              required: "Email is mandatory",
                              pattern: {
                                // value:/^\d{10}|^[a-z0-9]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/,
                                value:
                                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "Enter vaild Email",
                              },
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => (
                          <ErrorText>{message}</ErrorText>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {/* </Box> */}
                </Grid>

                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FormControl
                      fullWidth
                      sx={{
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                    >
                      <Controller
                        control={control}
                        name="password"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ bgcolor: "#595A79", borderRadius: "5px" }}
                            id="password"
                            size="small"
                            placeholder="Enter 8-16 digit password"
                            type={values.showPassword ? "text" : "password"}
                            onInput={handlePasswordChange("password")}
                            value={values.password}
                            variant="outlined"
                            autoComplete="off"
                           
                            fullWidth
                            inputProps={{
                              autoComplete:'new-password',
                              maxLength: 16,
                              minLength: 6,
                            }}
                            InputProps={{
                              sx: {
                                height: "30px",
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleClickShowPassword}>
                                    {values.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              startAdornment: (
                                <InputAdornment position="start">
                                  <KeyIcon />
                                </InputAdornment>
                              ),
                              // autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            {...register("newPassword", {
                              required: "Password is mandatory",
                              minLength: {
                                value: 6,
                                message:
                                  "Password should be minimum 6 character long.",
                              },
                              maxLength: {
                                value: 16,
                                message:
                                  "Password should be maximum 16 character long.",
                              },
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="newPassword"
                        render={({ message }) => (
                          <ErrorText>{message}</ErrorText>
                        )}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  {/* <Box sx={{}}> */}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FormControl
                      fullWidth
                      sx={{
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                    >
                      <Controller
                        control={control}
                        name="rePassword"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ bgcolor: "#595A79", borderRadius: "5px" }}
                            id="rePassword"
                            height="10px"
                            type="password"
                            size="small"
                            variant="outlined"
                            placeholder="Repeat Password"
                            onInput={(e) => setReapeatPassword(e.target.value)}
                            inputProps={{
                              maxLength: 16,
                              minLength: 6,
                            }}
                            InputProps={{
                              maxLength: 50,
                              sx: {
                                height: "30px",
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SyncLockIcon />
                                </InputAdornment>
                              ),
                            }}
                            {...register("rePassword", {
                              required: {
                                value: true,
                                message: "Re-Password is required",
                              },
                              validate: (value, passwordValue) => {
                                if (value != values.password) {
                                  return "Confirm password do not match.";
                                }
                              },
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="rePassword"
                        render={({ message }) => (
                          <ErrorText>{message}</ErrorText>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {/* </Box> */}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        width: "8rem",
                        height: "4.3vh",
                        fontSize: 13,
                        borderRadius: "40px",
                        background:
                          "linear-gradient(to right, #006D82, #006D82)",
                        textTransform: "none",
                      }}
                    >
                      Sign Up
                    </Button>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      ml: 5,
                    }}
                  >
                    <Divider
                      sx={{
                        width: "45%",
                        height: "1.5px",
                        backgroundColor: "white",
                        mt: 2,
                        mb: 1,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      ml: "4rem",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: { xs: 12, sm: 12, md: 13, lg: 13 },
                      }}
                    >
                      Already a member?
                      <Button
                        variant="text"
                        sx={{
                          textTransform: "none",
                          color: "white",
                          fontSize: { xs: 12, sm: 12, md: 13, lg: 13 },
                          textDecoration: "underline",
                        }}
                        onClick={navigateToLogin}
                      >
                        {" "}
                        Sign In
                      </Button>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Box>
      </Dialog>
    </>
  );
}

export default SingUp;
