import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import { addressDetails } from "../../api/Queries";
import {
  useMutation
} from "@apollo/client";
import { setAddressID } from "../../Redux/UserSlice";
import { getCustomerDetails } from "../../api/Api";
import { defaultAddress, deleteAddress } from "../../api/Mutations";
import {
  successToastMessage
} from "../../common/Utils";

const MyAddress = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const addressID = useSelector((state) => state?.user?.addressID);
  const dispatch = useDispatch();
  const [sendAddId, setSendAddId] = useState("");
  const [allAddress, setAllAddress] = useState([]);
  const [defAddress, setDefAddress] = useState({});
  const [makeId, setMakeId] = useState("");
  const [defAddressID, setDefAddressID] = useState("");
  const [loading, setLoading] = useState(false);
  const yourBagDetail = location?.state?.yourBagId;

  useEffect(() => {
    setLoading(true);
    getCustomerDetails(accessToken)
      .then((res) => {
        setLoading(false);
        let customer = res.data;
        let addressInArray = customer?.data?.customer?.addresses?.edges;
        setDefAddress(customer?.data?.customer.defaultAddress);
        setAllAddress(addressInArray);
        setDefAddressID(customer?.data?.customer?.defaultAddress?.id);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  }, [sendAddId]);
  
  const [setDefaultAddress] = useMutation(defaultAddress);

  // Delete Address
  const [removeAddress] = useMutation(deleteAddress);

  const hanldeMakeDefault = (node) => {
    // setSendAddId(ids)
    dispatch(setAddressID(node));
    setDefaultAddress({
      variables: {
        customerAccessToken: accessToken,
        addressId: node,
      },
    }).then((res) => {
      let defaultAddressData =
        res?.data?.customerDefaultAddressUpdate?.customer?.defaultAddress;
      let defaultAddressId =
        res?.data?.customerDefaultAddressUpdate?.customer?.defaultAddress?.id;
      // setSendAddId(defaultAddressId)
      dispatch(setAddressID(defaultAddressData));
      successToastMessage("This Address Set in deafault Address");
      yourBagDetail == 1 ? navigate("/yourBag") : navigate("/profile");
    });
  };

  const hanldeRemoveAddress = (node) => {
    removeAddress({
      variables: {
        customerAccessToken: accessToken,
        addressId: node,
      },
    }).then((res) => {
      setSendAddId(res);
      successToastMessage("Your Address Deleted!!");
    });
  };

  const hanldeAddressDefault = (address) => {
    setMakeId(address?.id);
    navigate("/editaddress", { state: { Id: address } });
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ paddingTop: { lg: "17vh", sm: "13vh", xs: "12vh" } }}>
        <Box>
          <form autoComplete="off">
            <Card
              sx={{
                backgroundColor: "#F0EAFE",
                overflowY: "auto",
                height: {xs:"80vh", sm:"50vh",md:"65vh"},
              }}
            >
              <Typography sx={{ fontSize: 30, fontFamily: "Raleway", ml: 6 }}>
                My Addresses
              </Typography>
              <Box>
                <Button
                  sx={{ color: "black", mt: 3, ml: 4 }}
                  onClick={() =>
                    yourBagDetail == 1
                      ? navigate("/yourBag")
                      : navigate("/profile")
                  }
                >
                  <ArrowBackIosIcon sx={{ fontSize: "small" }} />
                  Back to My Account
                </Button>
                <Divider
                  sx={{
                    background: "black",
                    width: "11rem",
                    marginLeft: { lg: "4%", sm: "5.7%", xs: "12%" },
                    marginTop: "-0.5%",
                  }}
                />
              </Box>

              <Grid sx={{ mt: 6, mb: 5 }} container spacing={2}>
                {(defAddress && Object.keys(defAddress).length == 0) ||
                defAddress == null ? (
                  " "
                ) : (
                  <Grid item lg={6}>
                    <Box sx={{ ml: 5 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Default{" "}
                      </Typography>
                      <Typography>{defAddress?.firstName}</Typography>
                      <Typography sx={{ mt: 1 }}>
                        {defAddress?.address1}
                      </Typography>
                      <Typography>{defAddress?.address2}</Typography>
                      <Typography>{defAddress?.city}</Typography>
                      <Typography>{defAddress?.province}</Typography>
                      <Typography>
                        {defAddress?.country},{defAddress?.zip}
                      </Typography>
                      {/* <Typography>{defAddress?.zip}</Typography> */}
                      <Typography>{defAddress?.phone}</Typography>
                    </Box>
                    <Button
                      onClick={() => hanldeAddressDefault(defAddress)}
                      variant="contained"
                      sx={{
                        mt: 2,
                        mb: 2,
                        ml: 5,
                        // width: "120px",
                        borderRadius: "22px",
                        color: "white",
                        fontSize: "13px",
                        fontFamily: "Raleway",
                        backgroundColor: "#006D82",
                        "&:hover": { backgroundColor: "#006D82" },
                        textTransform: "none",
                      }}
                    >
                      Edit
                    </Button>
                  </Grid>
                )}

                {allAddress?.map(
                  (text, index) =>
                    text?.node?.id != defAddress.id && (
                      <Grid item lg={6}>
                        <Box sx={{ ml: 5, lg: { mt: 2, sm: 3 }, mt: 3 }}>
                          <Typography>{text?.node?.firstName}</Typography>
                          <Typography sx={{ mt: 2 }}>
                            {text?.node?.address1}
                          </Typography>
                          <Typography>{text?.node?.address2}</Typography>
                          <Typography>{text?.node?.city}</Typography>
                          <Typography>{text?.node?.province}</Typography>
                          <Typography>
                            {text?.node?.country},{text?.node?.zip}
                          </Typography>
                          {/* <Typography>{text?.node?.zip}</Typography> */}
                          <Typography>{text?.node?.phone}</Typography>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "14px",
                              flexWrap: "wrap",
                              mt: 2,
                              textAlign: "center",
                            }}
                          >
                            <Button
                              onClick={() => hanldeAddressDefault(text?.node)}
                              variant="contained"
                              sx={{
                                // mt: 2,
                                // mb: 2,
                                // width: "120px",
                                borderRadius: "22px",
                                color: "white",
                                fontSize: "13px",
                                fontFamily: "Raleway",
                                backgroundColor: "#006D82",
                                "&:hover": { backgroundColor: "#006D82" },
                                textTransform: "none",
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{
                                // mt: 2,
                                // mb: 2,
                                // width: "160px",
                                borderColor: "#006D82",
                                borderRadius: "23px",
                                color: "#006D82",
                                fontSize: "13px",
                                fontFamily: "Raleway",
                              }}
                              onClick={() => hanldeRemoveAddress(text?.node.id)}
                            >
                              {" "}
                              Remove{" "}
                            </Button>
                            {text?.node?.id == defAddress.id ? (
                              ""
                            ) : (
                              <Button
                                onClick={() => hanldeMakeDefault(text?.node.id)}
                                variant="outlined"
                                sx={{
                                  // mt: 2,
                                  // mb: 2,
                                  width: "160px",
                                  borderColor: "#006D82",
                                  borderRadius: "23px",
                                  color: "#006D82",
                                  fontSize: "13px",
                                  fontFamily: "Raleway",
                                }}
                              >
                                Make as Default
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    )
                )}
              </Grid>
              <Divider />
            </Card>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default MyAddress;
