import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { store } from "./Redux/Store.js";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  ApolloLink
} from "@apollo/client";
import TagManager from 'react-gtm-module'



const link = from([
  new HttpLink({ uri: "https://037f1e-2.myshopify.com/api/2023-04/graphql.json" }),
  // new HttpLink({ uri: "https://ecf6e5-2.myshopify.com/api/2023-04/graphql.json" }),
]);

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "X-Shopify-Storefront-Access-Token": "17110ee06e7ef672acce823728bbb2f5"
      // "X-Shopify-Storefront-Access-Token": "3863f630edcb0a594b65984dc192e295"
    }
  });

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});

// const tagManagerArgs = {
//   gtmId: 'GTM-MG68BK2C'
// }

// TagManager.initialize(tagManagerArgs)


let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
        <ApolloProvider client={client}>
          <App />
          </ApolloProvider>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
