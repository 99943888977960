import ReportIcon from "@mui/icons-material/Report";
import {
    Box,
    Button,
    Card,
    Divider,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

function FundReturn() {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();
  const [bankData, setBankData] = useState(null);
  const [error, setError] = useState(null);
  const [ifscCode, setIfscCode] = useState("");
  const [branchName, setBranchName] = useState(null);
  const [bankName, setBankName] = useState(null);

  const onSubmit = (data) => {
    // console.log(data, "5555555");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://ifsc.razorpay.com/${ifscCode}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setBankData(jsonData);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, [ifscCode]);
  useEffect(() => {
    if (bankData != null) {
      setBankName(bankData?.BANK);
      setBranchName(bankData?.BRANCH);
    }
  }, [bankData]);

  return (
    <>
      <Box>
        <Card sx={{ backgroundColor: "#F0EAFE", mt: "15vh" }}>
          <Typography sx={{ fontSize: 30, fontFamily: "Raleway", ml: 6 }}>
            Bank Details
          </Typography>
          <Divider sx={{ background: "black" }} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container sx={{ p: 5, ml: { sm: "5rem" } }} spacing={3}>
              <Grid item sm={6} xs={12}>
                <Typography sx={{ mb: 1 }}>
                  Bank Account Number *
                  <Tooltip title="Enter Account Number">
                    <IconButton>
                      <ReportIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>

                <Box sx={{}}>
                  <FormControl>
                    <Controller
                      control={control}
                      name="bankAccountNumber"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="bankAccountNumber"
                          // defaultValue={userAddressAllDetails?.firstName}
                          variant="outlined"
                          // value={zipName}
                          size="small"
                          type="text"
                          InputProps={{
                            sx: { width: { sm: "35vw", xs: "80vw" } },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography sx={{ mb: 1 }}>
                  Confirm Bank Account Number *
                  <Tooltip title="Enter Account Confirm Number">
                    <IconButton>
                      <ReportIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Box sx={{}}>
                  <FormControl>
                    <Controller
                      control={control}
                      name="confirmBankAccountNumber"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="confirmBankAccountNumber"
                          // defaultValue={userAddressAllDetails?.firstName}
                          variant="outlined"
                          // value={zipName}
                          size="small"
                          type="text"
                          // placeholder="Name"
                          // onChange={onChange}
                          // onChange={(e) => { setZipName(e.target.value) }}
                          // height="50px"
                          InputProps={{
                            sx: { width: { sm: "35vw", xs: "80vw" } },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography sx={{ mb: 1 }}>
                  Name *
                  <Tooltip title="Enter Account Holder Name">
                    <IconButton>
                      <ReportIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Box sx={{}}>
                  <FormControl>
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="name"
                          variant="outlined"
                          // value={zipName}
                          size="small"
                          type="text"
                          InputProps={{
                            sx: { width: { sm: "35vw", xs: "80vw" } },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography sx={{ mb: 1 }}>
                  IFSC *
                  <Tooltip title="Enter IFSC Number">
                    <IconButton>
                      <ReportIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Box sx={{}}>
                  <FormControl>
                    <Controller
                      control={control}
                      name="ifsc"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="ifsc"
                          variant="outlined"
                          value={ifscCode}
                          size="small"
                          type="text"
                          // placeholder="Name"
                          // onChange={onChange}
                          onChange={(e) => {
                            setIfscCode(e.target.value);
                          }}
                          // height="50px"
                          InputProps={{
                            sx: { width: { sm: "35vw", xs: "80vw" } },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography sx={{ mb: 1 }}>
                  Branch Name *
                  <Tooltip title="Enter Branch Name">
                    <IconButton>
                      <ReportIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Box sx={{}}>
                  <FormControl>
                    <Controller
                      control={control}
                      name="branchName"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="branchName"
                          // defaultValue={userAddressAllDetails?.firstName}
                          variant="outlined"
                          value={branchName}
                          size="small"
                          type="text"
                          // placeholder="Name"
                          // onChange={onChange}
                          onChange={(e) => {
                            setBranchName(e.target.value);
                          }}
                          // height="50px"
                          InputProps={{
                            sx: { width: { sm: "35vw", xs: "80vw" } },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography sx={{ mb: 1 }}>
                  Bank Name *
                  <Tooltip title="Enter Bank Name">
                    <IconButton>
                      <ReportIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Box sx={{}}>
                  <FormControl>
                    <Controller
                      control={control}
                      name="bankName"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="bankName"
                          variant="outlined"
                          value={bankName}
                          size="small"
                          type="text"
                          onChange={(e) => {
                            setBankName(e.target.value);
                          }}
                          InputProps={{
                            sx: { width: { sm: "35vw", xs: "80vw" } },
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="outlined"
                    sx={{
                      borderColor: "#006D82",
                      borderRadius: "23px",
                      color: "#006D82",
                      width: "15rem",
                    }}
                  >
                    {" "}
                    Save{" "}
                  </Button>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/trackActiveOrder");
                    }}
                    sx={{
                      borderColor: "#006D82",
                      borderRadius: "23px",
                      color: "#006D82",
                      fontSize: "13px",
                      width: "15rem",
                    }}
                  >
                    {" "}
                    Back{" "}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Box>
    </>
  );
}

export default FundReturn;
