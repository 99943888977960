import { Box, Typography } from "@mui/material";
import React from "react";
// import "./style.css";
import Footer from "../../common/Footer";

function AboutUs() {
  return (
    <>
      <Box
        sx={{
          margin: { lg: "7rem 8rem", xs: "5rem 0.3rem" },
          paddingBottom: "1rem",
        }}
      >
        <Typography
          sx={{
            color: "#006D82",
            opacity: "0.9",
            fontSize: { lg: "1.5rem", md: "1.6rem", sm: "1.2rem", xs: "1rem" },
            fontWeight: "bold",
            fontFamily: "Arial",
            marginBottom: "1rem",
          }}
        >
          ABOUT NoBiY
        </Typography>
        <Box
          sx={{
            padding: "1rem",
            borderRadius: "1rem",
            boxShadow: "0px 0px 15px rgba(0, 0, 0.5)",
            color: "#333333",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Arial",
              fontSize: {
                xs: "0.8rem",
                sm: "1.2rem",
                md: "1.4rem",
                lg: "1rem",
              },
            }}
          >
            NoBiY is a genderless lifestyle brand that embraces versatility and
            breaks free from traditional constraints. With no legacy baggage, it
            offers a unique premise that sets it apart from others in the
            industry. It offers a lifestyle wardrobe solution for Gen M. Its
            product range covers various categories, including knits, woven
            garments, denim pieces, and outerwear. By catering to the fashion
            needs of these forward-thinking generations, NoBiY aligns with their
            dynamic and diverse lifestyles.
            <br />
            <br />
            NoBiY's brand premise revolves around the sci-fi and fantasy genres,
            intertwined with a subculture of gaming, Comicon, anime, music, and
            digital growth. It creates a vibrant and immersive world for
            storytelling, where metaverse and multiverse concepts come to life.
            <br />
            <br />
            NoBiY is designed for the next generation of consumers, including
            Gen M. These generations have embraced a mindset that rejects biases
            and segregation. They have "Awakened" to a new era of inclusivity
            and unity. The generation meta, a term coined to describe multiverse
            travelers transcending ages, represents a combined force of Gen M.
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "#006D82",
            opacity: "0.9",
            fontSize: { lg: "1.5rem", md: "1.6rem", sm: "1.2rem", xs: "1rem" },
            fontWeight: "bold",
            fontFamily: "Arial",
            marginBottom: "1rem",
            marginTop: "3rem",
          }}
        >
          ABOUT NEOMETA
        </Typography>
        <Box
          sx={{
            padding: "1rem",
            borderRadius: "1rem",
            boxShadow: "0px 0px 15px rgba(0, 0, 0.5)",
            color: "#333333",
            mb: "5rem",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Arial",
              fontSize: {
                xs: "0.8rem",
                sm: "1.2rem",
                md: "1.4rem",
                lg: "1rem",
              },
            }}
          >
            Neometa is a global alliance of fashion innovators, storytellers,
            and retail masterminds. We are passionate about designing,
            storytelling, and retail, and we believe that the three can be used
            to create powerful experiences that connect with consumers on a
            deeper level. We believe that brands should use storytelling to
            create experiences that are both memorable and meaningful.
            <br />
            <br />
            We also believe that retail can be used to create these experiences.
            We believe that the future of retail is about storytelling, and we
            are committed to helping brands tell their stories in a way that is
            both memorable and meaningful.
          </Typography>
        </Box>
        <Box>
          <Footer position="static" />
        </Box>
      </Box>
    </>
  );
}

export default AboutUs;
