import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "./UserSlice";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import CartSlice from "./CartSlice";

const reducers = combineReducers({
  user: UserSlice,
  cart: CartSlice
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});








