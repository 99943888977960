import { useMutation } from "@apollo/client";
import { ExpandLess } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  Backdrop,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Skeleton,
  Snackbar
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ReactSlickSlider from "react-slick";
import Slider from "@mui/material/Slider";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles, styled } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  setCart,
  setProductVarientId,
  setcreateCardValue,
  setproductVariantSize,
  setzoomDetails,
  setDetailsSizeChanage,
  setDetailsQuantityChange
} from "../../Redux/CartSlice";
import { CREATE_CART_MUTATION } from "../../api/Mutations";
import {
  GET_PRODUCT_DETAILS,
  productRecommendationsQuery,
} from "../../api/Queries";
import TATneometa from "../../api/TATneometa.json";
import SizeChartPage from "../../common/SizeChartPage";
import {
  client,
  failureToastMessage,
  successToastMessage,
  warningToastMessage,
} from "../../common/Utils";
import CommonCard from "../Categories/CommonCard";
import LoginPage from "../Login/LoginPage";
import ZoomView from "./ZoomView";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import MuiAlert from '@mui/material/Alert';
const marks = [
  {
    value: 1,
    label: "XS",
  },
  {
    value: 2,
    label: "S",
  },
  {
    value: 3,
    label: "M",
  },
  {
    value: 4,
    label: " L",
  },
  {
    value: 5,
    label: " XL",
  },
];

const marksBottom = [
  {
    value: 1,
    label: "28",
  },
  {
    value: 2,
    label: "30",
  },
  {
    value: 3,
    label: "32",
  },
  {
    value: 4,
    label: "34",
  },
  {
    value: 5,
    label: "36",
  },
  {
    value: 6,
    label: "38",
  },
  {
    value: 7,
    label: "40",
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  textField: {
    flex: 2,
    "& .MuiInputBase-input": {
      color: "white", // Input text color
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#006D82", // Border color
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#006D82", // Placeholder color when focused
    },
    "& .MuiInputBase-root": {
      height: "40px",
      // Adjust the height as needed
    },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "white", // Disabled background color
    },
    "& .MuiInputBase-input.Mui-disabled": {
      // color: 'white', // Disabled input text color
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottom: "none", // Remove underline for disabled state
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "40px",
      "& fieldset": {
        borderColor: "#006D82", // Border color
      },
      "&:hover fieldset": {
        borderColor: "#006D82", // Border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#006D82", // Border color when focused
      },
    },
  },
  button: {
    backgroundColor: "#006D82",
    color: "white",
    height: "40px",
    "&:hover": {
      backgroundColor: "#006D82",
    },
  },
  checkIcon: {
    color: "green",
  },
  message: {
    display: "flex",
    alignItems: "center",
    color: "white",
  },
  errorText: {
    color: "red",
  },
}));

const Sliders = styled(Slider)(({ theme }) => ({
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 10,
    width: 10,
    backgroundColor: "white",
    //   boxShadow: iOSBoxShadow,
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "&.css-1eoe787-MuiSlider-markLabel": {
    color: "white",
  },
  "& .MuiSlider-markLabel": {
    color: "white",
  },

  "&.css-187mznn-MuiSlider-root": {
    height: "1px",
    color: "#bfbfbf",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },

  "& .MuiSlider-mark": {
    backgroundColor: "white",
    height: 10,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "#006D82",
    },
  },
}));

function ItemDetail() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  let location = useLocation();
  let search = useLocation().search;
  const itemId = new URLSearchParams(search).get("itemId");
  const catTitle = new URLSearchParams(search).get("category");
  const style = new URLSearchParams(search).get("style");
  const varientIndex = new URLSearchParams(search).get("varId");
  const productVarientIndex = new URLSearchParams(search).get("productVarient");
  const idWithPrefix = `gid://shopify/Product/${itemId}`;
  const [openLog, setOpenLog] = useState(false);
  const [loadingg, setLoading] = useState(true);
  const [openForgotPw, setOpenForgotPw] = useState(false);
  const [openSingUp, setOpenSingUp] = useState(false);
  const [showNewPass, setshowNewPass] = useState(false);
  const [varientCartDetils, setVarientCartDetails] = useState({});
  const [opensizepage, setOpenSizepage] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [itemCode, setItemCode] = useState("");
  const [productInfo, setProductInfo] = useState();
  const [data, setData] = useState();
  const [sortedRecommendations, SetsortedRecommendations] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const productVarientId = useSelector(
    (state) => state?.cart?.productVarientId);
 
  const selectedSize = useSelector((state) => state?.cart?.selectedSize);


  useEffect(() => {
    setLoading(true);
    client
      .query({
        query: GET_PRODUCT_DETAILS,
        variables: {
          // id: zoomDetailsCards?.id,
          id: idWithPrefix,
        },
      })
      .then((res) => {
        setProductInfo(res?.data?.node);
        setLoading(false);
        client
          .query({
            query: productRecommendationsQuery,
            variables: {
              // id: zoomDetailsCards?.id,
              productId: idWithPrefix,
            },
          })
          .then((res) => {
            setData(res?.data);
          });
      })
      .catch((error) => {
        console.log(error.message, "error fetching product details");
      });
  }, []);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [pincode, setPincode] = useState("");
  const [inValidPinCode, setInValidpinCode] = useState("");
  const [isValidPincode, setIsValidpincode] = useState(false);
  const cartItems = useSelector((state) => state?.cart?.cart);

  const wishListData = location?.state?.wishlistCard;
  const [VarientPrice, setVarientPrice] = useState();
  const [cat, setCat] = useState();
  const [sizeValue, setSizeValue] = useState();

  useEffect(() => {
    const priceAmount = productInfo?.variants?.nodes[0]?.price?.amount;
    if (priceAmount !== undefined) {
      setVarientPrice(
        wishListData === "wishlist"
          ? productInfo?.priceRange?.maxVariantPrice?.amount
          : productInfo?.variants?.nodes[0]?.price?.amount
      );
    }
    setCat(productInfo?.cat?.value);
    setSizeValue(
      productInfo?.variants?.nodes[
        varientIndex ? varientIndex - 1 : productVarientIndex - 1
      ]?.title.split("/")[0]
    );
  }, [productInfo]);

  useEffect(() => {
    SetsortedRecommendations(data?.productRecommendations);
  }, [data]);

  const isAuth = useSelector((state) => state?.user?.isAuth);
  let mark = [];
  for (let i = 1; i <= productInfo?.variants?.nodes?.length; i++) {
    mark.push(i);
  }

  const imageUrls = [
    productInfo?.images?.edges[0]?.node?.url,
    productInfo?.images?.edges[1]?.node?.url,
    productInfo?.images?.edges[2]?.node?.url,
    productInfo?.images?.edges[3]?.node?.url,
    productInfo?.images?.edges[4]?.node?.url,
    productInfo?.images?.edges[5]?.node?.url,
  ];


  const cartDetail = {
    id: productInfo?.id,
    quantity: 1,
    price: productInfo?.variants?.nodes[0]?.price?.amount,
    image: productInfo?.images?.edges[0]?.node?.url,
    title: productInfo?.title,
    description: productInfo?.description,
    merchandiseId: productInfo?.variants?.nodes[0]?.id,
    quantityAvailable: productInfo?.variants?.nodes,
    cartItemDetails: productInfo,
    catTitle: catTitle,
    productVarientIndex: productVarientIndex,
    remove: "Add",
  };

  const [isremvebutton, setIsRemoveButton] = useState(false);
  const [varientid, setVarientId] = useState("");

  useEffect(() => {
    if (
      productInfo?.variants?.nodes[0].availableForSale == false ||
      productInfo?.variants?.nodes[0].quantityAvailable == 0
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, []);

  const changeSizeValue = (event, sizecheck) => {
    let index = sizecheck - 1;
    let currentProductVarient = productInfo.variants.nodes.filter(
      (vari) => vari.title == productInfo.variants.nodes[index].title
    );
    if (currentProductVarient.length > 0) {
      if (
        currentProductVarient[0].availableForSale === false ||
        currentProductVarient[0].quantityAvailable === 0
      ) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
      setSizeValue(currentProductVarient[0].title.split("/")[0]);
      dispatch(setProductVarientId(String(currentProductVarient[0].id)));
      setVarientPrice(currentProductVarient[0]?.price?.amount);
      setVarientId(currentProductVarient[0]?.id);
      setItemCode(currentProductVarient[0].sku);

      cartDetail.id = productInfo?.id;
      cartDetail.quantity = 1;
      cartDetail.price = currentProductVarient[0]?.price?.amount;
      cartDetail.image = productInfo?.images?.edges[0]?.node?.url;
      cartDetail.title = productInfo?.title;
      cartDetail.description = productInfo?.description;
      cartDetail.merchandiseId = currentProductVarient[0]?.id;
      cartDetail.quantityAvailable = productInfo?.variants?.nodes;
      cartDetail.productVarientIndex = sizecheck;
      cartDetail.remove = "Add";
      setVarientCartDetails(cartDetail);
    } else {
      setVarientPrice();
    }
  };

  useEffect(() => {
    cartItems.map((x) => {
      if (
        x?.merchandiseId == (varientid == "")
          ? productInfo?.variants?.nodes[0]?.id
          : varientid
      ) {
        setIsRemoveButton(true);
      }
    });
  }, [sizeValue]);

  const AddToBag = () => {
    if (isButtonDisabled) {
      failureToastMessage("Selected product size not available");
    } else {
      if (Object.keys(varientCartDetils).length === 0) {
        let item = [];
        item.push(cartDetail);
        dispatch(setCart(item));
        handleCreateCart([...cartItems, ...item]);
        successToastMessage(
          `${productInfo?.title} Add Sucessfully in Your Bag`
        );
      } else {
        let item = [];
        item.push(varientCartDetils);
        dispatch(setCart(item));
        handleCreateCart([...cartItems, ...item]);
        successToastMessage(
          `${productInfo?.title} Add Sucessfully in Your Bag`
        );
      }
    }
  };

  const hadleRemove = (e) => {
    let index = cartItems?.findIndex((e) => e.id === productVarientId);
    let cartReomve = [...cartItems];
    cartReomve.splice(index, 1);
    dispatch(setCart());
    dispatch(setCart(cartReomve));
    warningToastMessage(`${productInfo?.title} Remove Sucessfully in Your Bag`);
    setIsButtonDisabled(false);
    e.stopPropagation();
  };

  const buyNowBag = () => {
    if (isButtonDisabled) {
      failureToastMessage("Selected product size not available");
    } else {
      if (Object.keys(varientCartDetils).length === 0) {
        let item = [];
        item.push(cartDetail);
        dispatch(setCart(item));
        handleCreateCart([...cartItems, ...item]);
        successToastMessage(
          `${productInfo?.title} Add Sucessfully in Your Bag`
        );
      } else {
        let item = [];
        item.push(varientCartDetils);
        dispatch(setCart(item));
        handleCreateCart([...cartItems, ...item]);
        successToastMessage(
          `${productInfo?.title} Add Sucessfully in Your Bag`
        );
      }
    navigate("/yourBag");
    }
  };
  const handleSizeChart = () => {
    setOpenSizepage(true);
  };

  const [createCart, { zoomDetailsCardCreateValue }] =
    useMutation(CREATE_CART_MUTATION);
  const handleCreateCart = async (cart) => {
    const lineItems = cart.map(({ quantity, merchandiseId }) => ({
      quantity,
      merchandiseId,
    }));

    try {
      const response = await createCart({
        variables: {
          cartInput: {
            lines: lineItems,
          },
        },
      });
      dispatch(setproductVariantSize(productInfo.variants.nodes));
      dispatch(setcreateCardValue(response.data.cartCreate.cart));
      dispatch(setcreateCardValue(response.data.createCart.zoomDetails));

      // navigate("/cart");
    } catch (error) {
      console.error(error);
    }
  };

  const keyValuePairs = [
    {
      key: "Item Code",
      value:
        itemCode == ""
          ? productInfo?.variants?.nodes[
              productVarientIndex ? productVarientIndex : 0
            ]?.sku
          : itemCode,
    },
    {
      key: "Style No.",
      value:
        productInfo?.Style_code == null ? "N/A" : productInfo?.Style_code.value,
    },
    { key: "Sleeve Length", value: productInfo?.sleeve_length?.value },
    { key: "Color", value: productInfo?.options[1]?.values },
    { key: "Fit", value: productInfo?.fit?.value },
  ];

  const filteredKeyValuePairs = keyValuePairs.filter(
    (item) => item.value !== "N/A" && item.value !== "NA"
  );

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handlePincodeValidate = () => {
    if (pincode.length != 6) {
      // failureToastMessage("Please enter a valid pin code")
      toast.error("Please enter a valid pin code", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 800,
        pauseOnHover: true,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "dark",
      });
    } else {
      let isDeliveredZipcode = TATneometa.filter(
        (e) => e.Pincode == Number(pincode)
      );
      if (isDeliveredZipcode.length != 0) {
        setIsValidpincode(true);
      } else {
        setIsValidpincode(false);
        setInValidpinCode(pincode);
      }
    }
  };
  const handleChangeButton = () => {
    setPincode("");
    setInValidpinCode("");
    setIsValidpincode(false);
  };

  const [isDescription, setIsDescription] = useState(false);
  const [isDetails, setIsDetails] = useState(true);
  const toggleDescription = () => {
    setIsDescription(!isDescription);
  };
  const toggleDetails = () => {
    setIsDetails(!isDetails);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    //  autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: true,
    caseEase: "linear",
     arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // const [sizeChnage, setSizeChange] = useState(productVarientId?productVarientId:"");
  const [sizeChnage, setSizeChange] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const variantWithId = productInfo?.variants?.nodes.find(variant => variant.id === productVarientId);
  const quantityAvailable = variantWithId ? variantWithId.quantityAvailable : null;
    const handleChange = (event) => {
      setSizeChange(event.target.value);
      let currentProductVarient = productInfo.variants.nodes.filter(
       (vari) => vari.id == event.target.value
       );
      if (currentProductVarient.length > 0) {
        if (
          currentProductVarient[0].availableForSale === false ||
          currentProductVarient[0].quantityAvailable === 0
        ) {
          setIsButtonDisabled(true);
        } else {
          setIsButtonDisabled(false);
        }
         setSizeValue(currentProductVarient[0].title.split("/")[0]);
        dispatch(setProductVarientId(String(currentProductVarient[0].id)));
        setVarientPrice(currentProductVarient[0]?.price?.amount);
        setVarientId(currentProductVarient[0]?.id);
        setItemCode(currentProductVarient[0].sku);
  
        cartDetail.id = productInfo?.id;
        cartDetail.quantity = 1;
        cartDetail.price = currentProductVarient[0]?.price?.amount;
        cartDetail.image = productInfo?.images?.edges[0]?.node?.url;
        cartDetail.title = productInfo?.title;
        cartDetail.description = productInfo?.description;
        cartDetail.merchandiseId = currentProductVarient[0]?.id;
        cartDetail.quantityAvailable = productInfo?.variants?.nodes;
        cartDetail.remove = "Add";
        setVarientCartDetails(cartDetail);
      } else {
        setVarientPrice();
      }

      
    };

    
    const increaseQuantity = () => {
      if (variantWithId && quantityAvailable !== null) {
        setQuantity((prevQuantity) => {
          return prevQuantity < quantityAvailable ? prevQuantity + 1 : prevQuantity;
        });
      }
    };
    const decreaseQuantity = () => {
      if (quantity > 1) {
        setQuantity((prevQuantity) => prevQuantity - 1);
      }
    };
    dispatch((setDetailsQuantityChange(quantity)))

    const handleCloseNotification = () => {
      setNotificationOpen(false);
    };
    const AddToBagMobileView = () => {
      if (sizeChnage) {
        // Add the item to the bag logic here
        console.log('Item added to the bag:', sizeChnage);
    
        if (isButtonDisabled) {
          failureToastMessage("Selected product size not available");
        } else {
          if (Object.keys(varientCartDetils).length === 0) {
            let item = [];
            item.push(cartDetail);
            dispatch(setCart(item));
            handleCreateCart([...cartItems, ...item]);
            successToastMessage(`${productInfo?.title} Added Successfully to Your Bag`);
          } else {
            let item = [];
            item.push(varientCartDetils);
            dispatch(setCart(item));
            handleCreateCart([...cartItems, ...item]);
            successToastMessage(`${productInfo?.title} Added Successfully to Your Bag`);
          }
        }
      } else {
        // Show the notification if size is not selected
        // setNotificationOpen(true);
        failureToastMessage("Please Select Size First");
      }
    };
    const buyNowBagMobileView = () => {
      if (sizeChnage) {
      if (isButtonDisabled) {
        failureToastMessage("Selected product size not available");
      } else {
        if (isAuth === true) {
          if (Object.keys(varientCartDetils).length === 0) {
            let item = [];
            item.push(cartDetail);
            dispatch(setzoomDetails(cartItems));
            dispatch(setCart(item));
            handleCreateCart([...item, ...cartItems]);
          } else {
  
            let item = [];
            item.push(varientCartDetils);
            dispatch(setCart(item));
            handleCreateCart([...item, ...cartItems]);
          }
  
          navigate("/yourBag");
        } else {
          setOpenLog(true);
        }
      }
    }else{
      failureToastMessage("Please Select Size First");
    }
    };
  return (
    <>
    <Box sx={{ display: { xs: 'none', sm: 'block',md:"block",lg:"block" }}}>
      {loadingg ? (
        <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loadingg}>
          <CircularProgress sx={{ color: "white" }} />
        </Backdrop>
      ) : (
        <Box>
          <Box>
            <Box
              sx={{
                mt: { lg: "12vh", xs: "9vh", md: "12vh" },
                // height: { lg: "calc(87vh)", xs: "calc(100vh)" },
                // overflowY: "auto",
              }}
            >
              <Box sx={{}}>
                <Box
                  onClick={() => navigate("/cat", { state: catTitle })}
                  sx={{
                    paddingTop: "0.8rem",
                    marginLeft: "0.8rem",
                    cursor: "pointer",
                    width: "6.5rem",
                  }}
                >
                  <Typography
                    color="#006D82"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <ArrowBackIcon sx={{ fontSize: 30, mr: 2, mb: -1 }} />
                    {/* {zoomDetailsCards?.title} */}
                    Back
                  </Typography>
                </Box>
                <Card
                  sx={{
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    backgroundColor: "#18161D",
                    width: "100%",
                    // boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <Grid container>
                    <Grid item md={6} xs={12} sm={12} lg={6}>
                      <Container sx={{ paddingTop: "3rem" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Box
                            sx={{
                              display: {
                                xs: "none",
                                sm: "none",
                                md: "block",
                                lg: "block",
                              },
                            }}
                          >
                            <Box>
                              {imageUrls.map((imageUrl, index) => (
                                <Box key={index}>
                                  <Box
                                    component="img"
                                    onClick={() => setSelectedImageIndex(index)}
                                    sx={{
                                      border: "1px solid white",
                                      cursor: "pointer",
                                      backgroundColor:
                                        selectedImageIndex === index
                                          ? "#006D82"
                                          : "white",
                                      padding: "0 1.5rem",
                                      borderRadius: "0.5rem",
                                      height: {
                                        xs: "7rem",
                                        sm: "10rem",
                                        md: "7rem",
                                      },
                                    }}
                                    src={imageUrl ? imageUrl : "./All.png"}
                                    alt="Image"
                                  />
                                </Box>
                              ))}
                            </Box>
                          </Box>

                          <Box
                            onClick={openDialog}
                            component="img"
                            src={
                              productInfo?.images?.edges[selectedImageIndex]
                                ?.node?.url
                            }
                            sx={{
                              width: {
                                lg: "22rem",
                                md: "22rem",
                                sm: "13rem",
                                xs: "13rem",
                              },
                              height: {
                                lg: "44rem",
                                md: "44rem",
                                sm: "26rem",
                                xs: "26rem",
                              },
                              cursor: "pointer",
                              background: "white",
                              borderRadius: "0.7rem",
                              boxShadow:
                                "0px 0px 20px rgba(255, 255, 255, 0.5)",
                              // backgroundColor: colorTheme == 'dark' ? "rgb(255, 255, 255, 0.8)" :" ",
                              objectfit: "contain",
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            flexWrap: "wrap",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            pt: 2,
                            display: {
                              xs: "flex",
                              sm: "flex",
                              md: "none",
                              lg: "none",
                            },
                          }}
                        >
                          {imageUrls.map((imageUrl, index) => (
                            <Box key={index}>
                              <Box
                                component="img"
                                onClick={() => setSelectedImageIndex(index)}
                                sx={{
                                  border: "1px solid white",
                                  cursor: "pointer",
                                  // marginRight:"1rem",
                                  backgroundColor:
                                    selectedImageIndex === index
                                      ? "#006D82"
                                      : "white",
                                  padding: "0 1.5rem",
                                  borderRadius: "0.5rem",
                                  height: {
                                    xs: "7rem",
                                    sm: "10rem",
                                    md: "7rem",
                                  },
                                  marginBottom: "0.5rem",
                                }}
                                src={imageUrl ? imageUrl : "./All.png"}
                                alt="Image"
                              />
                            </Box>
                          ))}
                        </Box>
                      </Container>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      sm={12}
                      lg={6}
                      sx={{
                        borderStyle: "solid",
                        borderColor: "#006D82",
                        mt: { xs: 0, sm: 0, md: 3, lg: 5 },
                        mb: { xs: 0, sm: 0, md: 3, lg: 3 },
                        mr: { xs: 2, sm: 0, md: 0, lg: 0 },
                        ml: { xs: 2, sm: 0, md: 0, lg: 0 },
                        // height: "25rem",
                        borderWidth: {
                          xs: "2px 0 0 0",
                          sm: "2px 0 0 0",
                          md: "0 0 0 2px",
                          lg: "0 0 0 2px",
                        },
                      }}
                    >
                      <Container sx={{ paddingTop: "1rem" }}>
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: { lg: 20, sm: 18, xs: 18 },
                            marginLeft: "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {productInfo?.title}
                        </Typography>
                        <Typography
                          sx={{
                            color: "white",
                            marginLeft: "1rem",
                            marginTop: "0.5rem",
                          }}
                        >
                          {productInfo?.productType} ||{" "}
                          {productInfo?.options[2]?.values} ||{" "}
                          {productInfo?.gender?.value}
                        </Typography>
                        <Divider sx={{ bgcolor: "gray", mt: 2 }} />
                        <Typography
                          sx={{
                            marginLeft: "1rem",
                            color: "white",
                            marginTop: "0.6rem",
                            marginBottom: "0.5rem",
                            display: "inline",
                          }}
                        >
                          Product Price -{" "}
                          <CurrencyRupeeIcon sx={{ fontSize: 16, mb: -0.2 }} />
                          {/* {Math.round(Number(VarientPrice))} */}
                          {parseInt(
                            productInfo?.variants?.nodes[0]?.compareAtPrice
                              .amount,
                            10
                          )}
                        </Typography>
                        <Typography
                          variant="subtitle"
                          sx={{
                            color: "lightblue",
                            ml: 2,
                            textDecoration: "line-through",
                            fontSize: 16,
                            mb: -0.2,
                          }}
                        >
                          ₹{Math.round(Number(VarientPrice))}{" "}
                        </Typography>
                        <Divider sx={{ bgcolor: "gray", mb: 4 }} />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Box sx={{ width: { lg: 350, xs: 230 } }}>
                            <Sliders
                              defaultValue={
                                varientIndex
                                  ? varientIndex
                                  : productVarientIndex
                              }
                              step={1}
                              min={1}
                              max={productInfo?.variants?.nodes?.length}
                              marks={cat == "Top" ? marks : marksBottom}
                              onChange={changeSizeValue}

                              // valueLabelDisplay="on"
                            />
                          </Box>
                          <Box sx={{ mt: { xs: -1.5, sm: -2, lg: -3 } }}>
                            <Typography
                              sx={{
                                color: "white",
                                textAlign: "right",
                                mr: 3.5,
                                fontSize: 13,
                              }}
                            >
                              Size
                            </Typography>
                            <Box
                              sx={{
                                height: "2rem",
                                width: "4rem",
                                bgcolor: "white",
                                borderRadius: "5px",
                                ml: 1,
                              }}
                            >
                              <Typography sx={{ textAlign: "center", pt: 0.5 }}>
                                {sizeValue}
                              </Typography>
                            </Box>
                            <Button
                              variant="text"
                              onClick={handleSizeChart}
                              sx={{
                                textTransform: "none",
                                color: "white",
                                textDecoration: "underline",
                                fontSize: 13,
                              }}
                            >
                              {" "}
                              Size Chart
                            </Button>
                          </Box>
                        </Box>

                        <div
                          style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              flexWrap: "wrap",
                            }}
                          >
                            {productInfo?.availableForSale === true &&
                            !cartItems?.some((e) => e.id == cartDetail.id) ? (
                              <Box sx={{}}>
                                <Button
                                  onClick={buyNowBag}
                                  variant="contained"
                                  type="submit"
                                  sx={{
                                    width: "12rem",
                                    // height: "4.3vh",
                                    height: {
                                      lg: "4.3vh",
                                      xs: "4.3vh",
                                      sm: "3vh",
                                    },
                                    fontSize: 13,
                                    mt: { lg: "2rem", sm: "1rem", xs: "1rem" },
                                    border: "1px solid #006D82",
                                    p: 0.2,
                                    borderRadius: "40px",
                                    backgroundColor: "transparent",
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                    },
                                    textTransform: "none",
                                  }}
                                >
                                  Buy Now{" "}
                                </Button>
                              </Box>
                            ) : (
                              ""
                            )}
                            <div style={{ marginBottom: "2rem" }}>
                              {productInfo?.availableForSale === true ? (
                                cartItems?.some(
                                  (e) => e.merchandiseId == productVarientId
                                ) ? (
                                  <Button
                                    onClick={(e) => hadleRemove(e)}
                                    sx={{
                                      width: {
                                        lg: "12rem",
                                        xs: "12rem",
                                        sm: "12rem",
                                      },
                                      height: {
                                        lg: "4.3vh",
                                        xs: "4.3vh",
                                        sm: "3vh",
                                      },
                                      fontSize: 13,
                                      borderRadius: "40px",
                                      background:
                                        "linear-gradient(to right, #006D82, #006D82)",
                                      textTransform: "none",
                                      mt: { lg: "2rem", xs: "1rem" },
                                    }}
                                    variant="contained"
                                  >
                                    Remove from Bag
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={AddToBag}
                                    sx={{
                                      width: "12rem",
                                      // height: "4.3vh",
                                      height: {
                                        lg: "4.3vh",
                                        xs: "4.3vh",
                                        sm: "3vh",
                                      },
                                      fontSize: 13,
                                      borderRadius: "40px",
                                      background:
                                        "linear-gradient(to right, #006D82, #006D82)",
                                      textTransform: "none",
                                      mt: {
                                        lg: "2rem",
                                        sm: "1rem",
                                        xs: "1rem",
                                      },
                                    }}
                                    variant="contained"
                                  >
                                    Add to My Bag
                                  </Button>
                                )
                              ) : (
                                <Button
                                  style={{
                                    backgroundColor: "grey",
                                    color: "white",
                                    width: "12rem",
                                    // height: "4.3vh",
                                    height: {
                                      lg: "4.3vh",
                                      xs: "4.3vh",
                                      sm: "3vh",
                                    },
                                    fontSize: 13,
                                    border: "1px solid #006D82",
                                    marginTop: "30px",
                                    borderRadius: "40px",
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                    },
                                    textTransform: "none",
                                  }}
                                  disabled
                                >
                                  Sold Out
                                </Button>
                              )}
                            </div>
                          </Box>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            sx={{ padding: "2%" }}
                          >
                            <div className={classes.container}>
                              <TextField
                                placeholder="Enter Pincode"
                                variant="outlined"
                                value={pincode}
                                fullWidth
                                className={classes.textField}
                                inputProps={{ minLength: 6, maxLength: 6 }}
                                disabled={isValidPincode ? true : false}
                                InputLabelProps={{
                                  style: { color: "white" }, // Placeholder text color
                                }}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                onChange={(e) => {
                                  setPincode(e.target.value);
                                  setInValidpinCode("");
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <>
                                      {isValidPincode && (
                                        <CheckIcon
                                          className={classes.checkIcon}
                                        />
                                      )}
                                    </>
                                  ),
                                }}
                              />
                              {!isValidPincode ? (
                                <Button
                                  variant="contained"
                                  className={classes.button}
                                  sx={{
                                    background: "#006D82",
                                    borderRadius: "40px",
                                    "&:hover": {
                                      backgroundColor: "#006D82",
                                    },
                                  }}
                                  onClick={() => handlePincodeValidate()}
                                >
                                  Check
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  className={classes.button}
                                  sx={{
                                    background: "#006D82",
                                    borderRadius: "40px",
                                    "&:hover": {
                                      backgroundColor: "#006D82",
                                    },
                                  }}
                                  onClick={() => handleChangeButton()}
                                >
                                  Change
                                </Button>
                              )}
                              {/* {isValid && <CheckIcon className={classes.checkIcon} />} */}
                            </div>
                            {!isValidPincode && inValidPinCode != "" && (
                              <Typography
                                variant="body2"
                                className={classes.errorText}
                                style={{ marginTop: "1%" }}
                              >
                                Sorry, we do not ship to this pincode. Try
                                anther one
                              </Typography>
                            )}
                            {pincode === "" && (
                              <Typography
                                color="while"
                                sx={{
                                  color: "white",
                                  marginTop: "2%",
                                  fontSize: "14px",
                                }}
                              >
                                Enter the pin code for delivery availability
                              </Typography>
                            )}
                            {isValidPincode && (
                              <div
                                className={classes.message}
                                style={{ marginTop: "2%" }}
                              >
                                <LocalShippingIcon />
                                <span
                                  style={{
                                    marginLeft: "8px",
                                    fontSize: "14px",
                                  }}
                                >
                                  delivery available
                                </span>
                              </div>
                            )}
                          </Grid>

                          <Box
                            sx={{
                              color: "white",
                              display: "flex",
                              justifyContent: "space-between",
                              mt: 4,
                            }}
                          >
                            <Typography>Product Description</Typography>
                            <IconButton onClick={toggleDescription}>
                              {isDescription ? (
                                <ExpandMoreIcon sx={{ color: "white" }} />
                              ) : (
                                <ExpandLess sx={{ color: "white" }} />
                              )}
                            </IconButton>
                          </Box>
                          {!isDescription && (
                            <Typography sx={{ color: "white" }}>
                              {productInfo?.description}
                            </Typography>
                          )}
                          <Divider sx={{ bgcolor: "gray", mt: 2 }} />
                          <Box
                            sx={{
                              color: "white",
                              display: "flex",
                              justifyContent: "space-between",
                              mt: 4,
                            }}
                          >
                            <Typography>Product Details</Typography>
                            <IconButton onClick={toggleDetails}>
                              {isDetails ? (
                                <ExpandMoreIcon sx={{ color: "white" }} />
                              ) : (
                                <ExpandLess sx={{ color: "white" }} />
                              )}
                            </IconButton>
                          </Box>
                          {!isDetails && (
                            <Typography>
                              {filteredKeyValuePairs.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    style={{
                                      color: "white",
                                      borderBottom: "1px solid gray",
                                    }}
                                  >
                                    {item.key}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: "white",
                                      borderBottom: "1px solid gray",
                                    }}
                                  >
                                    {item.value}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </Typography>
                          )}

                          <Divider sx={{ bgcolor: "gray", mt: 2 }} />
                          {/* <Accordion
                          defaultExpanded={true}
                          sx={{
                            backgroundColor: "#18161D",
                            color: "white",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ bgcolor: "white" }} />
                            }
                          >
                            <Typography>Product Description</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              {productInfo?.description}
                            </Typography>
                          </AccordionDetails>
                        </Accordion> */}
                          {/* <Divider sx={{ bgcolor: "gray" }} /> */}

                          {/* <Divider sx={{ bgcolor: "white" }} /> */}
                          {/* <Accordion
                          defaultExpanded={true}
                          sx={{
                            backgroundColor: "#18161D",
                            color: "white",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ bgcolor: "white" }} />
                            }
                          >
                            <Typography>Product Details</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              {filteredKeyValuePairs.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    style={{
                                      color: "white",
                                      borderBottom: "1px solid gray",
                                    }}
                                  >
                                    {item.key}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: "white",
                                      borderBottom: "1px solid gray",
                                    }}
                                  >
                                    {item.value}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </Typography>
                          </AccordionDetails>
                        </Accordion> */}
                          {/* <Divider sx={{ bgcolor: "gray", mb: 4 }} /> */}
                        </div>
                      </Container>
                    </Grid>
                    <Box sx={{ padding: { lg: 2 }, mb: { xs: -22 } }}>
                      <Grid item lg={12}>
                        <Typography
                          sx={{
                            color: "#ffffff",
                            fontSize: 20,
                            fontFamily: "Railway",
                            ml: 2,
                            mt: { lg: 1, xs: 4 },
                            mb: { xs: 25 },
                          }}
                        >
                          You might like these
                        </Typography>
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        paddingLeft: { lg: "1vw", xs: "1.3vw" },

                        //   paddingRight: {lg:"14vw", xs:"5vw"},
                        paddingBottom: { lg: "1vh", xs: "69px" },
                        //   paddingTop: "3vh",
                        //   display: "flex",
                      }}
                    >
                      <Grid
                        container
                        spacing={{ lg: 3, md: 8, sm: 3, xs: 1 }}
                        sx={{
                          pr: { lg: "1vw", xs: "1vw" },
                          marginBottom: "0.4rem",
                        }}
                      >
                        {sortedRecommendations?.map((e) => {
                          return (
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={4}
                              lg={3}
                              sx={{ mb: 1 }}
                            >
                              <CommonCard
                                props={e}
                                key={e?.id}
                                titile={e?.title}
                                catTitle="All"
                                productVariantPrice={
                                  e?.variants?.nodes[0]?.price?.amount
                                }
                                productVariantCompareAtPrice={
                                  e?.variants?.nodes[0]?.compareAtPrice?.amount
                                }
                                imgUrl={e?.images?.edges[0]?.node?.url}
                                imgUrlTwo={e?.images?.edges[1]?.node?.url}
                                discription={e?.description}
                                // discription={
                                //   e?.description.length > 60
                                //     ? `${e.description.substring(0, 150)}...`
                                //     : e.description
                                // }
                                availableForSale={
                                  e?.variants?.nodes[0]?.availableForSale
                                }
                                productVariantId={e?.variants?.nodes[0]?.id}
                                productVariants={e?.variants?.nodes}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                </Card>
              </Box>
            </Box>
            <SizeChartPage
              opensizepage={opensizepage}
              setOpenSizepage={setOpenSizepage}
              cat={cat}
            />
            <ZoomView
              open={dialogOpen}
              images={imageUrls}
              selectedImageIndex={selectedImageIndex}
              onClose={closeDialog}
            />
            <LoginPage
              openLog={openLog}
              setOpenLog={setOpenLog}
              openForgotPw={openForgotPw}
              setOpenForgotPw={setOpenForgotPw}
              openSingUp={openSingUp}
              setOpenSingUp={setOpenSingUp}
              showNewPass={showNewPass}
              setshowNewPass={setshowNewPass}
            />
          </Box>
        </Box>
      )}
      </Box>

      {/* for mobile view  */}
      <Box  sx={{ display: { xs: 'block', sm: 'none',md:"none",lg:"none" }}}>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1000 }}
        open={loadingg}
      >
        <CircularProgress sx={{color:"gray"}} />
      </Backdrop>
    
       
          <Box
            sx={{
              mt: { lg: "12vh", xs: "7vh", md: "12vh" },backgroundColor:"#F1F1F1",
            }}
          >
           
            
             {loadingg ? (
            // Show the skeleton when loading
            <Skeleton variant="rectangular" width={600} height={200} sx={{ marginBottom: '1rem' }} />
          ) : (
        
            <ReactSlickSlider {...settings} >
              {imageUrls?.map((item, index) => (
                <Box
                  sx={{objectFit: "contain",bgcolor:"#FCFCFC", 
                  height: { lg: "23rem", md: "24rem", sm: "20rem", xs: "28rem" },}}
                  component="img"
                  key={index}
                  onClick={openDialog}
                  src={item}
                  alt="img"
                  loading="lazy"
                />
              ))}
            </ReactSlickSlider>
          
          
          )}
           
             
                <Grid  sx={{marginTop:"0.6rem",}} container spacing={2}>
                  
                   <Grid xs={10}>
          {loadingg ? (
            // Show the product title skeleton when loading
            <Skeleton variant="text" width={200} height={30} sx={{ marginRight: '2rem' }} />
          ) : (
            // Show the actual product title when not loading
            <Typography sx={{ color: '#707070',marginLeft:"2.5rem",fontFamily:"",fontSize:"13px" }}>{productInfo?.title.toUpperCase()}</Typography>
          )}
          </Grid>
          <Grid  xs={2}>
          {loadingg ? (
            // Show the product price skeleton when loading
            <Skeleton variant="text" width={100} height={30} />
          ) : (
            // Show the actual product price when not loading
            <Typography sx={{ fontSize:"13px",color: '#707070',marginLeft:"0.6rem",fontFamily:"bold" }}>₹{parseInt(
              productInfo?.variants?.nodes[0]?.compareAtPrice
                .amount,
              10
            )}</Typography>
          )}
          </Grid>
     
        </Grid>
        <Box sx={{}}>
            {loadingg?( <Skeleton variant="text" width={100} height={30} />):(
              <Box sx={{display:"flex",gap:"",marginTop:"0.5rem"}}>
              <FormControl  sx={{ m: 1, minWidth: 230 ,backgroundColor:"#E5E5E5",borderRadius:"10px",marginLeft:"1.2rem",display: 'flex', justifyContent: 'center'}}>
        {/* <InputLabel  sx={{fontWeight:'600', color:"#3C3C3C",fontSize:"1.1rem",fontFamily:"",marginLeft:"2.5rem",marginTop:"-0.2rem", "& .MuiSelect-icon": { marginTop:"0.8rem" }}}id="demo-simple-select-filled-label">SELECT SIZE</InputLabel> */}
        {sizeChnage ? null : (
    <InputLabel sx={{display: 'flex', justifyContent: 'center',marginLeft:"2.4rem",fontWeight: '600', color: "#3C3C3C", fontSize: "1.1rem",marginTop:"-0.3rem","&.Mui-focused": {
      color: "#3C3C3C", fontSize: "1rem"
    },}} id="demo-simple-select-filled-label">SELECT SIZE</InputLabel>
  )}
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={sizeChnage}
            // MenuProps={MenuProps}
          onChange={handleChange}
          IconComponent={KeyboardArrowDownOutlinedIcon}
          // classes={{icon:classes.icon, iconOpen:classes.iconOpen,select:classes.selectSelect}}
         sx={{ fontSize: '1rem', alignItems:"center",alignContent:"center",boxShadow: 'none','.MuiOutlinedInput-notchedOutline': { border: 0 ,borderStyle:"none"} ,textAlign:'center','&:focus': {
          backgroundColor: 'transparent', 
          color:"transparent",
          borderColor: 'your-desired-border-color', 
        },}}
         
      
        >
          {
            productInfo?.variants?.nodes.map((e)=>(
              <MenuItem 
              value={e.id} 
              sx={{ display: 'flex', justifyContent: 'center' ,}}
              >
              {e.title.split("/")[0]}
              </MenuItem>
            ))
          }
         
          
        </Select>
      </FormControl>
              
              <Box onClick={handleSizeChart}
              sx={{marginLeft:"0.2rem",marginTop:"0.5rem", shadow:"none",borderRadius: "10px",float:"",backgroundColor:"#ffffff",width:"6rem",height:"3.5rem", display: "flex", alignItems: "center", justifyContent: "center",
             
            }}>
                <Typography sx={{color:"black",fontSize:"14px",fontWeight:"600",fontFamily:""}}>SIZE CHART</Typography>
              </Box>
              </Box>
  )}
  <Box>
    <Typography sx={{marginLeft:"1.2rem",color:"#767676",fontSize:"10px"}}>Tax included.Shipping calculated at checkout</Typography>
  </Box>
  <Box sx={{color:"#767676",fontSize:"20px",marginLeft:"1.2rem",marginTop:"0.6rem"}} ><Typography sx={{fontSize:"0.8rem"}}>Quantity</Typography></Box>
   <Box sx={{width:"10rem",height:"2.5rem",border:"0.6px solid #767676",borderRadius:"10px",marginLeft:"1rem",marginTop:"0.5rem"}}>
<Box sx={{display:"flex",gap:"2rem", paddingTop:"0.4rem"}}>
   <Box sx={{marginLeft:"0.9rem",cursor:"pointer",marginTop:"0.3rem"}}onClick={decreaseQuantity}>
    <RemoveOutlinedIcon  sx={{fontSize:"medium"}}/>
   </Box>
            <div style={{marginLeft:"0.6rem",marginTop:"0.1rem"}}>
              {quantity}
            </div>
            <Box sx={{marginLeft:"1rem",marginTop:"0.3rem"}} onClick={increaseQuantity}><AddOutlinedIcon sx={{fontSize:"medium"}}/></Box>
            </Box>
   </Box>
  
   <Box sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            flexWrap: "wrap",
                            marginTop:"2rem",
                          }}>
                <div style={{ marginBottom: "2rem",marginTop:"1rem" }}>
                            {productInfo?.availableForSale === true ? (
                              cartItems?.some(
                                (e) =>
                                  e.merchandiseId == productVarientId
                              ) ? (
                                <Button
                                  onClick={(e) => hadleRemove(e)}
                                  variant="contained"
                                type="submit"
                                sx={{
                                  width: "22rem",
                                  // height: "4.3vh",
                                  height: {
                                    lg: "4.3vh",
                                    xs: "6vh",
                                    sm: "3vh",
                                  },
                                  fontSize: 13,
                                  mt: { lg: "2rem", sm: "1rem", xs: "1rem" },
                                  border: "1px solid black",
                                  color:"black",
                                  p: 0.2,
                                  borderRadius: "10px",
                                  backgroundColor: "transparent",
                                  "&:hover": { backgroundColor: "transparent" },
                                  textTransform: "none",
                                  // marginLeft:"1.2rem"
                                }}
                              >
                                  Remove from Bag
                                </Button>
                              ) : (
                                <Button
                                  onClick={AddToBagMobileView}
                                  sx={{
                                    width: {
                                      xs: "22rem", 
                                        
                                    },
                                  
                                     
                                      
                                    height: {
                                      lg: "4.3vh",
                                      xs: "6vh",
                                      sm: "3vh",
                                    },
                                    fontSize: 13,
                                    borderRadius: "10px",
                                    background:
                                      "black",
                                    textTransform: "none",
                                    "&:hover": { backgroundColor: "black" },
                                   
                                    //  ml: {xs:"1.8rem"},
                                    p: 0.2,
                                  }}
                                  variant="contained"
                                >
                                  ADD TO CART
                                </Button>
                              
                              )
                            ) : (
                              ""
                            )}
                           
                          </div>
  </Box>
                          <div style={{ marginLeft: "1rem", marginTop: "0.5rem" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            flexWrap: "wrap",
                            marginTop:"-2rem",
                          }}
                        >
                          {productInfo?.availableForSale === true &&
                            !cartItems?.some((e) => e.id == cartDetail.id) ? (
                            <Box sx={{}}>
                              <Button
                                onClick={buyNowBagMobileView}
                                variant="contained"
                                type="submit"
                                sx={{
                                  width:{ xs:"22rem"},
                                  // height: "4.3vh",
                                  height: {
                                    lg: "4.3vh",
                                    xs: "6vh",
                                    sm: "3vh",
                                  },
                                  fontSize: 13,
                                  mt: { lg: "2rem", sm: "1rem", xs: "1rem" },
                                  mr: {xs:"1.4rem"},
                                  boxShadow:'none',
                                  border: "1px solid black",
                                  color:"black",
                                
                                  borderRadius: "10px",
                                  backgroundColor: "transparent",
                                  "&:hover": { backgroundColor: "transparent" },
                                  textTransform: "none",
                                }}
                              >
                                BUY IT NOW
                              </Button>
                            </Box>
                          ) : (
                            ""
                          )}
                          </Box>
                          </div>
                        
                          
                          <Box sx={{marginLeft:"1rem",color:"black", display:"flex" ,justifyContent:"space-between",mt:4}}>
                          <Typography sx={{fontWeight:"600",fontSize:"20px",fontFamily:"bold",marginTop:"0.5rem",color:"#767676",marginLeft:"0.5rem"}}>Product Description</Typography>
                          <IconButton onClick={toggleDescription}>{isDescription ? <AddIcon sx={{ bgcolor: "",fontSize:"1.8rem",marginRight:"0.5rem" }} /> : <ExpandLess sx={{ bgcolor: "" }} />}</IconButton>
                        </Box>
                        <Box sx={{alignItems:"center",margin:"0 auto",}}>
                        {!isDescription && (
                            <Typography sx={{fontFamily:"TeoriaMF Light",color:"#4b4b4b", display: "flex", mb: 2,padding:"20px",marginLeft:2}}>
                              {productInfo?.description}
                            </Typography>
                          )}
                        </Box>
                          <Divider sx={{width:"22.5rem",marginLeft:"1rem", bgcolor: "#d3d3d3" ,mt:1}} />
                          <Box sx={{marginLeft:"1rem",color:"", display:"flex" ,justifyContent:"space-between", mt:4}}>
                          <Typography sx={{fontWeight:"600",fontSize:"20px",fontFamily:"bold",marginTop:"0.5rem",color:"#767676",marginLeft:"0.5rem"}}>Product Details</Typography>
                          <IconButton onClick={toggleDetails}>{isDetails ? <AddIcon sx={{ bgcolor: "",fontSize:"1.8rem",marginRight:"0.5rem" }} /> : <ExpandLess sx={{ bgcolor: "" }} />}</IconButton>
                        </Box>
                        {!isDetails && (
                            <Typography sx={{fontFamily:"TeoriaMF Light",color:"#4b4b4b", mb: 2,padding:"20px",marginLeft:0.5}}>
                              {filteredKeyValuePairs.map((item, index) => (
                                <TableRow  key={index}>
                                  <TableCell
                                    style={{
                                      color: "#4b4b4b",
                                      borderBottom: "1px solid gray",
                                    }}
                                  >
                                    {item.key}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: "#4b4b4b",
                                      borderBottom: "1px solid gray",
                        
                                    }}
                                  >
                                    {item.value}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </Typography>
                          )}
                          {/* <Divider sx={{width:"22.5rem",marginLeft:"1rem", bgcolor: "#d3d3d3" ,mt:1}} /> */}
                           <Box sx={{marginLeft:"1rem",color:"", display:"flex" ,justifyContent:"space-between", mt:4}}>
                        </Box>
                           <Divider sx={{width:"22.5rem",marginLeft:"1rem", bgcolor: "#d3d3d3" ,mt:1}} />
                           </Box>
                           <Typography
                        sx={{margin:"1.6rem",fontFamily:"bold",fontSize:"1.2rem",color:"#767676",fontWeight:"600",marginTop:"2.5rem"}}
                          
                      >
                        YOU MAY ALSO LIKE
                      </Typography>
                            <Box sx={{ padding: { lg: 2,xs:1 }, mb: { xs: -22 } }}>
                  
                     
                      <Grid
                      container
                      spacing={{ lg: 8, md: 8, sm: 3, xs: 1 }}
                      sx={{ pr: { lg: "1vw", xs: "1.4vw" }}}
                    >
                      {sortedRecommendations?.map((e) => {
                          return (
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={4}
                              lg={3}
                              sx={{  }}
                            >
                              <CommonCard
                                props={e}
                                key={e?.id}
                                titile={e?.title}
                                catTitle="All"
                                productVariantPrice={
                                  e?.variants?.nodes[0]?.price?.amount
                                }
                                productVariantCompareAtPrice={
                                  e?.variants?.nodes[0]?.compareAtPrice?.amount
                                }
                                imgUrl={e?.images?.edges[0]?.node?.url}
                                imgUrlTwo={e?.images?.edges[1]?.node?.url}
                                discription={e?.description}
                                // discription={
                                //   e?.description.length > 60
                                //     ? `${e.description.substring(0, 150)}...`
                                //     : e.description
                                // }
                                availableForSale={
                                  e?.variants?.nodes[0]?.availableForSale
                                }
                                productVariantId={e?.variants?.nodes[0]?.id}
                                productVariants={e?.variants?.nodes}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                
                
            </Box>
          </Box>
          <SizeChartPage
            opensizepage={opensizepage}
            setOpenSizepage={setOpenSizepage}
            cat={cat}
          />
          <ZoomView
            open={dialogOpen}
            images={imageUrls}
            selectedImageIndex={selectedImageIndex}
            onClose={closeDialog}
          />
          <LoginPage
            openLog={openLog}
            setOpenLog={setOpenLog}
            openForgotPw={openForgotPw}
            setOpenForgotPw={setOpenForgotPw}
            openSingUp={openSingUp}
            setOpenSingUp={setOpenSingUp}
            showNewPass={showNewPass}
            setshowNewPass={setshowNewPass}
          />
        </Box>
    
      
    </>
  );
}

export default ItemDetail;
