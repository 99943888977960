import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Button,
  Typography,
  Backdrop,
  Grid,
  RadioGroup,
  Radio,
  Card,
  Divider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Zoom from "@mui/material/Zoom";
import { useForm } from "react-hook-form";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import { addressDetails } from "../../api/Queries";
import {
  useMutation,
  useQuery,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  ApolloLink,
} from "@apollo/client";
import { defaultAddress, deleteAddress } from "../../api/Mutations";
import { setAddressID, setTempAddress } from "../../Redux/UserSlice";
import {
  successToastMessage,
  failureToastMessage,
  ErrorText,
} from "../../common/Utils";
import { getCustomerDetails } from "../../api/Api";
import Checkbox from "@mui/material/Checkbox";
;
const AddressPopup = ({ addressPopup, setAddressPopup,setDefAddress,defAddress,radioValue, setRadioValue}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const addressID = useSelector((state) => state?.user?.addressID);
  const tempAddress = useSelector((state) => state?.user?.tempAddress);
  const dispatch = useDispatch();
  const [sendAddId, setSendAddId] = useState("");
  const [allAddress, setAllAddress] = useState([]);
  const [makeId, setMakeId] = useState("");
  const [defAddressID, setDefAddressID] = useState("");
  const [loading, setLoading] = useState(false);
  const yourBagDetail = location?.state?.yourBagId;
  const [check, setCheck] = useState(false);
  const [valueaddress,setValueaddress] = useState(defAddress.id || "");
  
  const [selectedOption, setSelectedOption] = useState("false");

  


  useEffect(() => {

    setLoading(true)
    getCustomerDetails(accessToken).then((res) => {
      setLoading(false)
      let customer = res.data;
      let addressInArray = customer?.data?.customer?.addresses?.edges;
      setDefAddress(customer?.data?.customer.defaultAddress)
      setAllAddress(addressInArray);
      setDefAddressID(customer?.data?.customer?.defaultAddress?.id);
    
    }).catch((e) => {
      setLoading(false)
    })
  }, [sendAddId])


  // Add Default Address
  const [setDefaultAddress] = useMutation(defaultAddress);

  // Delete Address
  const [removeAddress] = useMutation(deleteAddress);

  const hanldeMakeDefault = (node,e) => {
    setValueaddress(node.id)
    setRadioValue(node.id)
    dispatch(setTempAddress(node))
     setAddressPopup(false);
   
  }
  const hanldeRemoveAddress = (node) => {
    removeAddress({
      variables: {
        customerAccessToken: accessToken,
        addressId: node,
      },
    }).then((res) => {
      setSendAddId(res);
      successToastMessage("Your Address Deleted!!");
    });
  };

  const hanldeAddressDefault = (address) => {
    setMakeId(address?.id)
    navigate("/editaddress", { state: { Id: address, yourBagId: 1 } })
  }
  const handleClose = () => {
    setAddressPopup(false);
  };

  const onSelfServiceChange = (e) => {
    setCheck(!check);
  };


  

  const handleOptionToggle = () => {
    const newOption = selectedOption === "true" ? "false" : "true";
    setSelectedOption(newOption);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };
  
  return (
    <>
      <Dialog
        open={addressPopup}
        // TransitionComponent={Transition}
      >
    
       {loading ?(
         <Backdrop sx={{ color: 'white', zIndex: 1000 }} open={loading}>
         <CircularProgress color="inherit" />
       </Backdrop>
   ) : (
        <Box>
          <form autoComplete="off">
            <Card sx={{ overflowY: "auto", height: "auto", width: { sm: "35vw", xs: "80vw" } }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", bgcolor: "", color: "black", }}>
                <Typography sx={{ fontSize: 20, fontFamily: "Raleway", ml: 6,marginTop:"0.5rem" }}>
                  Change Delivery Address
                </Typography>
                <IconButton sx={{ float: 'right', color: "" }}>
                  <CloseIcon onClick={handleClose} style={{ fontSize: 27 }} />
                </IconButton>
              </Box>
              <Box>
               
              </Box>

              <Grid sx={{ mt: "" }} container spacing={2}>

                  {allAddress.map((text, index) =>
                   (
                    
  <Grid item lg={12}>
    <Box sx={{ m: "1rem", p: 2, border: "", borderRadius: "10px", bgcolor: "" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box >
          <FormControl>
            <RadioGroup  onChange={(e) => hanldeMakeDefault(text.node,e)}
            >
              <FormControlLabel
                   control={
                  <Radio
                   checked={String(text?.node.id)?.split("?")[0]==String(radioValue)?.split("?")[0]}
                    // size="large"
                    sx={{
                      marginBottom:"9rem",
                      color: "#006D82",
                      "&.Mui-checked": {
                        color: "#006D82",
                      },
                    }}
                  />
                }
                label=""
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box sx={{ marginLeft: "1rem",marginTop:"-4rem" }}>
          <Box sx={{display:"flex"}}>
         
          <Typography><b>{text?.node?.firstName}</b></Typography>
          {/* {defAddress.id !== text.node.id && <Typography sx={{marginLeft:"0.5rem",fontSize:"0.8rem",color:"#B4B4B4",marginTop:'0.2rem'}}> (Default)</Typography>} */}
          {defAddress.id!=text.node.id? " ":<Typography sx={{marginLeft:"0.5rem",fontSize:"0.8rem",color:"#B4B4B4",marginTop:'0.2rem'}}> (Default)</Typography>}
          </Box>
          <Typography sx={{ mt: 2 }}>{text?.node?.address1}, {text?.node?.address2}</Typography>
          <Typography>{text?.node?.city}, {text?.node?.province}, {text?.node?.country}, {text?.node?.zip}</Typography>
          <Typography><b>{text?.node?.phone}</b></Typography>
        </Box>  
      </Box>
    </Box>
    <Divider
                  sx={{
                    background: "black",
                    // width: "11rem",
                    //  marginLeft:{lg:"4%",sm:"5.7%",xs:"12%"},
                      marginTop: {lg:"-5rem"},
                  }}
                />
  </Grid>   
                ))}

              </Grid>
            </Card>
          </form>
        </Box>
  )}
      </Dialog>
    </>
  );
};
export default AddressPopup;
