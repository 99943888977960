import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import { ErrorMessage } from "@hookform/error-message";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setEditProfileDetails } from "../../Redux/UserSlice";
import {
  successToastMessage
} from "../../common/Utils";
import {
  updateCustomerInfoDetails
} from "./../../api/Mutations";

const EditProfile = () => {
  const ErrorText = styled("p")`
    color: red;
    font-size: 0.9rem;
    position: absolute;
    top: 2em;
  `;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userName1 = location?.state;

  const userDetails = useSelector((state) => state?.user?.userDetails);
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const [updateUser] = useMutation(updateCustomerInfoDetails);
  const [firstName, setFirstName] = useState("");
  const userName = userDetails.firstName;
  const userEmail = userDetails.email;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setFirstName(data?.firstName);
    updateUser({
      variables: {
        customerAccessToken: accessToken,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
      },
    }).then((res) => {
      let data1 = res;
      let firstUserName = data1?.data?.customerUpdate?.customer?.firstName;
      successToastMessage("Change Your Name Successful");
      navigate("/profile", { state: { firstName: firstUserName } });
      dispatch(
        setEditProfileDetails(data1?.data?.customerUpdate?.customer?.firstName)
      );
    });
  };

  return (
    <>
      <Container sx={{ paddingTop: { lg: "17vh", sm: "13vh", xs: "12vh" } }}>
        <Box>
          <Card sx={{pb:"5vh",backgroundColor: "#F0EAFE" }}>
            <Typography sx={{ fontSize: 30, fontFamily: "Raleway", ml: 6 }}>
              Edit Profile
            </Typography>
            <Box>
              <Button
                sx={{ color: "black", mt: 3, ml: 4 }}
                onClick={() => navigate("/profile")}
              >
                <ArrowBackIosIcon sx={{ fontSize: "small" }} />
                Back to My Account
              </Button>
              <Divider
                sx={{
                  background: "black",
                  width: { lg: "10rem", xs: "10.6rem" },
                  marginLeft: { lg: "4.7%", xs: "14%", sm: "5.4%" },
                  marginTop: "-0.5%",
                }}
              />
            </Box>

            <br></br>
            <Box>
              <Divider style={{}} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ minHeight: "", mt: 2 }}
                  spacing={2}
                >
                  <Grid item lg={12} xs={12} sm={12}>
                    <Box>
                      <FormControl
                        fullWidth
                        sx={{
                          width: { lg: "50ch" },
                          pl: { xs: 1, sm: 1.5, md: 2, lg: 0 },
                          pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                        }}
                        variant="outlined"
                        size="small"
                      >
                        <Controller
                          control={control}
                          name="firstName"
                          render={({ field: { name, onChange } }) => (
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              defaultValue={userName1?.firstName}
                              size="small"
                              placeholder="Name"
                              fullWidth={true}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonIcon />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={onChange}
                              {...register(name, {
                                required: {
                                  value: true,
                                  message: "Profile name is required",
                                },
                              })}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="firstName"
                          render={({ message }) => (
                            <ErrorText>{message}</ErrorText>
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <Box sx={{ mt: "1rem" }}>
                      <FormControl
                        fullWidth
                        sx={{
                          width: { lg: "50ch" },
                          pl: { xs: 1, sm: 1.5, md: 2, lg: 0.0 },
                          pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                        }}
                        variant="outlined"
                        size="small"
                      >
                        <Controller
                          control={control}
                          name="email"
                          render={({ field: { name, onChange } }) => (
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              defaultValue={userName1?.email}
                              disabled
                              size="small"
                              placeholder="Email"
                              // sx={{ width:{lg:"28rem",xs:"14.5rem",sm:"14.5rem",md:"28rem"} }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EmailIcon />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={onChange}
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Grid>

                  <Box>
                    <Grid item lg={12}>
                      <Box>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            mt: 3,
                            // mr: { lg: 28, md: 28 },
                            width: "180px",
                            borderRadius: "23px",
                            color: "white",
                            // fontSize: "16px",
                            // fontFamily: "Raleway",
                            backgroundColor: "#006D82",
                            "&:hover": { backgroundColor: "#006D82" },
                            textTransform: "none",
                          }}
                        >
                          Save Change
                        </Button>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </form>
            </Box>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default EditProfile;
