import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SortIcon from "@mui/icons-material/Sort";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import "./TrackOrder.css";

function GetUsers() {
  const cartDetails = [
    {
      cart_image: "./cat2.png",
      pro_name: "Pixelated Printed Tee ",
      Delivered: "24/04/2023",
      Returnable: "Apr 29",
    },
    {
      cart_image: "./cat1.png",
      pro_name: "Into the Metaverse",
      Delivered: "25/04/2023",
      Returnable: "Apr 30",
    },
    {
      cart_image: "./cat2.png",
      pro_name: "Pixelated Printed Tee ",
      Delivered: "24/04/2023",
      Returnable: "Apr 29",
    },
    {
      cart_image: "./cat1.png",
      pro_name: "Into the Metaverse",
      Delivered: "25/04/2023",
      Returnable: "Apr 30",
    },
  ];

  return (
    <>
      <Box
        sx={{
          // backgroundImage: `url("EarthLogo.png")`,
          backgroundSize: {
            xs: "50% 100%",
            sm: "40% 100%",
            md: "30% 100%",
            lg: "23% 100%",
          },
          backgroundRepeat: "no-repeat",
          overflow: "hidden",
          backgroundAttachment: "fixed",
        }}
      >
        <Box
          sx={{
            mt: { xs: 8, sm: 10, md: 11, lg: 11 },
            height: "calc(100vh - 12vh)",
            overflowY: "auto",
          }}
        >
          <Typography
            color="#FFFFFF"
            sx={{
              fontWeight: 15,
              fontSize: "1.5rem",
              mb: 1,
              ml: { xs: "2vw", sm: "10vw", md: "16vw", lg: "16vw" },
            }}
          >
            Track Your Order
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Box
              sx={{
                bgcolor: "white",
                width: { xs: "95vw", sm: "80vw", md: "70vw", lg: "70vw" },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box sx={{ display: "flex" }}>
                    <TextField
                      fullWidth
                      sx={{ bgcolor: "#595A79", m: "1.5rem" }}
                      id="coupenCode"
                      height="10px"
                      type="text"
                      size="small"
                      variant="outlined"
                      placeholder="Tracking Number"
                      inputProps={{
                        maxLength: 50,
                        style: {
                          height: "2vh",
                          color: "white",
                          // width: "25vw"
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box
                    sx={{
                      m: { xs: "-3rem 1rem", sm: "-3rem 1rem", md: 0, lg: 0 },
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        width: "7rem",
                        height: "4.3vh",
                        fontSize: 13,
                        borderRadius: "40px",
                        background:
                          "linear-gradient(to right, #006D82, #006D82)",
                        textTransform: "none",
                        mt: "1.5rem",
                      }}
                    >
                      Track Order
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      m: 2,
                    }}
                  >
                    <Box
                      sx={{
                        height: "2.5rem",
                        width: "2.5rem",
                        bgcolor: "#EA9A23",
                        mr: 2,
                        borderRadius: "8px",
                      }}
                    >
                      <IconButton>
                        {" "}
                        <SortIcon sx={{ color: "white", fontSize: 30 }} />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        height: "2.5rem",
                        width: "2.5rem",
                        bgcolor: "#EA9A23",
                        borderRadius: "8px",
                      }}
                    >
                      <IconButton>
                        <FilterAltOutlinedIcon
                          sx={{ color: "white", fontSize: 30 }}
                        />{" "}
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              m: 3,
              ml: { xs: 1, sm: 10, md: 22, lg: 28 },
            }}
          >
            <Box className="arrow-pointer">
              {" "}
              <Typography sx={{ color: "white", pl: 2, pt: 1 }}>
                April
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: 1, sm: "56vw", md: "50vw", lg: "60vw" },
                height: "10px",
                marginTop: ".78rem",
                background: "#006d82",
              }}
            ></Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 5,
            }}
          >
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: -53, lg: -53 }}
              sx={{}}
            >
              {cartDetails.map((e) => (
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mb: 3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card
                      sx={{
                        width: {
                          xs: "90vw",
                          sm: "80vw",
                          md: "34vw",
                          lg: "34vw",
                        },
                        borderRadius: "10px",
                        bgcolor: "rgba(255, 255, 255, 0.5)",
                        p: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "35vh",
                        }}
                      >
                        <img src={e.cart_image} alt="" />
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Box>
                          <Typography sx={{ fontSize: 17, fontWeight: "bold" }}>
                            {e.pro_name}
                          </Typography>
                          <Typography sx={{ fontSize: 14 }}>
                            Delivered on {e.Delivered}
                          </Typography>
                          <Button
                            variant="text"
                            sx={{
                              textTransform: "none",
                              color: "black",
                              mr: 1.5,
                            }}
                          >
                            {" "}
                            Download Invoice <ExpandMoreIcon />
                          </Button>
                        </Box>
                        <Box
                          sx={{
                            ml: {
                              xs: "16vw",
                              sm: "25vw",
                              md: "1vw",
                              lg: "11vw",
                            },
                          }}
                        >
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              width: "7rem",
                              height: "4.3vh",
                              borderRadius: "40px",
                              background: "#EA9A23",
                              textTransform: "none",
                              mt: "1.5rem",
                            }}
                          >
                            Return
                          </Button>
                          <Typography sx={{ fontSize: 13, m: ".5rem 1rem" }}>
                            {" "}
                            Returnable till {e.Returnable}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default GetUsers;
