import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCommonWishList, setWishList } from "../../Redux/CartSlice";
import { findAllWishlistByCustomerId } from "../../api/Api";
import { productByProductId1 } from "../../api/Queries";
import { client } from "../../common/Utils";
import CommonCard from "../Categories/CommonCard";

const Wishlist = () => {
  const dispatch = useDispatch();
  const wishList = useSelector((state) => state?.cart?.wishList);
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const commonWishList = useSelector((state) => state?.cart?.commonWishList);
  const isFavorite = useSelector((state) => state?.user?.isFavorite);
  let navigate = useNavigate();
  const [wishListCard, setWishListCard] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    findAllWishlistByCustomerId(userDetails?.id).then((res) => {
      let wishListId = res.data;
      let productvarientIds = wishListId.map((e) => e.productVariantId);
      if (res?.data?.length == 0) {
        setLoading(false);
        dispatch(setCommonWishList([]));
        dispatch(setWishList([]));
      } else {
        setTimeout(() => {
          client
            .query({
              query: productByProductId1,
              variables: { ids: productvarientIds },
            })
            .then((res) => {
              dispatch(setWishList(productvarientIds));
              dispatch(setCommonWishList(res.data.nodes));
              setLoading(false);
            });
        }, 1000);
      }
    });
  }, [isFavorite]);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {wishList?.length == 0 ? (
        <div style={{ textAlign: "center", marginTop: "15rem" }}>
          <Typography variant="h5" fontWeight="600" color="#EA9A23">
            Your Wish List is empty
          </Typography>
          <Box>
            <img src="/Cart Empty Image.png" alt="" />
          </Box>
          <Button
            sx={{
              borderRadius: 20,
              padding: "0.2rem 3rem",
              backgroundColor: "#006D82",
              color: "white",
              textTransform: "none",
            }}
            variant="contained"
            onClick={() => {
              let path = `/cat`;
              navigate(path);
            }}
          >
            Add Items
          </Button>
        </div>
      ) : (
        <>
          <Typography
            color="#006D82"
            sx={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              marginBottom: "1rem",
              paddingLeft: { lg: "16vw", xs: "5vw" },
              paddingRight: { lg: "16vw", xs: "5vw" },
              paddingBottom: { lg: "3vh", xs: "20px" },
              paddingTop: "3vh",
              display: "flex",
              mt: { xs: "8vh", md: "12vh", sm: "8vh", lg: "15vh" },
            }}
          >
            {" "}
            <ArrowBackIcon
              onClick={() => navigate("/")}
              sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }}
            />
            Back
          </Typography>
          <Box
            sx={{
              paddingLeft: { lg: "14vw", xs: "5vw" },
              paddingRight: { lg: "14vw", xs: "5vw" },
              paddingBottom: { lg: "3vh", xs: "69px" },
              // paddingTop: "3vh",
              display: "flex",
              // mt: {xs:"8vh",md:"12vh",sm:"8vh"},
            }}
          >
            <Grid container spacing={{ lg: 6, md: 8, sm: 3, xs: 3 }}>
              {commonWishList?.map((e) => {
                return (
                  <Grid item xs={6} sm={6} md={4} lg={3}>
                    <CommonCard
                      key={e?.id}
                      props={e}
                      page={"wishlist"}
                      wishListCard={wishListCard}
                      setWishListCard={setWishListCard}
                      productId={e?.id}
                      imgUrl={e?.images?.edges[0]?.node.url}
                      imgUrlTwo={e?.images.edges[1].node.url}
                      productVariantId={e?.variants?.nodes[0].id}
                      productVariants={e?.variants?.nodes}
                      discription={e?.description}
                      // discription={e?.description.length>60?`${e.description.substring(0, 150)}...`:e.description}
                      titile={e?.title}
                      availableForSale={e?.availableForSale}
                      priceRange={e?.priceRange?.maxVariantPrice?.amount}
                      productVariantPrice={e?.variants?.nodes[0]?.price?.amount}
                      productVariantCompareAtPrice={
                        e?.variants?.nodes[0]?.compareAtPrice?.amount
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default Wishlist;
