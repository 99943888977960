import { gql } from "@apollo/client";

export const loginDetailes = gql`
  query GetCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      email
      firstName
      id
      phone
    }
  }
`;

export const marqureeGetResourceId = gql`
  query {
    menu(handle: "MarqueeMenu") {
      items {
        id
        tags
        title
        resourceId
      }
    }
  }
`;

export const marqureeDetail = gql`
  query getMarqureeDetail($id: ID!) {
    collection(id: $id) {
      title
      image {
        altText
        height
        id
        originalSrc
        src
        transformedSrc
        url
      }
      products(first: 100) {
        edges {
          node {
            id
            title
            availableForSale
            productType
            description
            variants(first: 5) {
              nodes {
                id
                title
                quantityAvailable
                availableForSale
                weightUnit
                weight
                sku
                price {
                  amount
                  currencyCode
                }
              }
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            options(first: 10) {
              name
              values
            }
            gender: metafield(key: "gender", namespace: "custom") {
              id
              namespace
              key
              value
            }
            fit: metafield(key: "fit", namespace: "custom") {
              key
              value
            }
            sleeve_length: metafield(
              key: "sleeve_length"
              namespace: "custom"
            ) {
              key
              value
            }
            cat: metafield(key: "cat", namespace: "custom") {
              key
              value
            }
            Style_code: metafield(key: "stylecode", namespace: "custom") {
              key
              value
              __typename
            }
            images(first: 6) {
              edges {
                node {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Track Your Order
export const trackYourOrder = gql`
  query getTrackYourOrderDetail($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      orders(first: 15, sortKey: PROCESSED_AT, reverse: true) {
        edges {
          node {
            id
            orderNumber
            processedAt
            financialStatus
            fulfillmentStatus
            lineItems(first: 15) {
              edges {
                node {
                  title
                  quantity
                  variant {
                    sku
                    priceV2 {
                      amount
                      currencyCode
                    }
                    id
                    image {
                      src
                      url
                    }
                    title
                    quantityAvailable
                  }
                }
              }
            }
            subtotalPrice {
              amount
              currencyCode
            }
            successfulFulfillments {
              trackingInfo {
                url
                number
              }
            }
            totalPrice {
              amount
              currencyCode
            }
            totalShippingPrice {
              amount
              currencyCode
            }
            totalTax {
              amount
              currencyCode
            }
            canceledAt
            cancelReason
            shippingAddress {
              address1
              address2
              city
              country
              firstName
              id
              lastName
              name
              phone
              province
              provinceCode
              zip
            }
          }
        }
      }
    }
  }
`;

export const addressDetails = gql`
  query GetAddress($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      createdAt
      email
      phone
      firstName
      displayName
      lastName
      defaultAddress {
        phone
        address1
        address2
        city
        company
        province
        country
        zip
        countryCode
        firstName
        id
      }
      addresses(first: 100) {
        edges {
          node {
            address1
            address2
            city
            company
            province
            country
            zip
            countryCode
            firstName
            phone
            id
          }
          cursor
        }
      }
    }
  }
`;

// Banner On Home Page
export const bannerImageId = gql`
  query GetCollection($collectionId: ID!) {
    collection(id: $collectionId) {
      title
      description
      image {
        height
        url
      }
    }
  }
`;

export const bannerImgHome = gql`
  query {
    menu(handle: "Home-Banner") {
      handle
      id
      itemsCount
      title
      items {
        id
        resourceId
        tags
        title
        type
        url
      }
    }
  }
`;

// Marquee Image on Home Page
export const bannerMarqueeId = gql`
  query GetCollection($collectionId: ID!) {
    collection(id: $collectionId) {
      title
      description
      image {
        height
        url
      }
    }
  }
`;

export const bannerMarqueeImg = gql`
  query {
    menu(handle: "Marquee-Design-Image") {
      handle
      id
      itemsCount
      title
      items {
        id
        resourceId
        tags
        title
        type
        url
      }
    }
  }
`;

// Marquee Image for Banner
export const bannerImgMarqueeId = gql`
  query GetCollection($collectionId: ID!) {
    collection(id: $collectionId) {
      title
      description
      image {
        height
        url
      }
    }
  }
`;

export const bannerMarquee = gql`
  query {
    menu(handle: "Marquree-Banner-Image") {
      handle
      id
      itemsCount
      title
      items {
        id
        resourceId
        tags
        title
        type
        url
      }
    }
  }
`;

export const slickImageId = gql`
  query GetCollection($collectionId: ID!) {
    collection(id: $collectionId) {
      title
      image {
        height
        url
      }
    }
  }
`;

export const slickImagePart1 = gql`
  query {
    menu(handle: "Home-Screen") {
      handle
      id
      itemsCount
      title
      items {
        id
        resourceId
        tags
        title
        type
        url
      }
    }
  }
`;
export const slickImagePart2 = gql`
  query GetCollection($collectionId: ID!) {
    collection(id: $collectionId) {
      title
      image {
        id
        height
        url
      }
    }
  }
`;

// usequery for geting collection id for product
export const getCollectionId = gql`
  query {
    menu(handle: "Collections-Menu") {
      items {
        id
        tags
        title
        resourceId
      }
    }
  }
`;

// usequery for geting collection id for product
export const getProductCollectionId = gql`
  query {
    menu(handle: "Home-Product") {
      items {
        id
        tags
        title
        resourceId
      }
    }
  }
`;

// usequery for product Collection:-
export const knitProductCollectionId = gql`
  query GetCollection($id: ID!) {
    collection(id: $id) {
      title
      image {
        altText
        height
        id
        originalSrc
        src
        transformedSrc
        url
      }
      products(first: 15) {
        edges {
          node {
            id
            title
            productType
            description
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            images(first: 13) {
              edges {
                node {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ProductCollectionId = gql`
  query GetCollection($id: ID!) {
    collection(id: $id) {
      title
      image {
        altText
        height
        id
        originalSrc
        src
        transformedSrc
        url
      }
      products(first: 200) {
        edges {
          node {
            id
            title
            availableForSale

            productType
            description
            variants(first: 10) {
              nodes {
                id
                title
                quantityAvailable
                availableForSale
                currentlyNotInStock
                weightUnit
                weight
                sku
                compareAtPrice {
                  amount
                  currencyCode
                }
                price {
                  amount
                  currencyCode
                }
              }
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            options(first: 10) {
              name
              values
            }
            gender: metafield(key: "gender", namespace: "custom") {
              id
              namespace
              key
              value
            }
            fit: metafield(key: "fit", namespace: "custom") {
              key
              value
            }
            sleeve_length: metafield(
              key: "sleeve_length"
              namespace: "custom"
            ) {
              key
              value
            }
            cat: metafield(key: "cat", namespace: "custom") {
              key
              value
            }
            Style_code: metafield(key: "stylecode", namespace: "custom") {
              key
              value
              __typename
            }
            images(first: 6) {
              edges {
                node {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ProductGetCollectionId = gql`
  query GetCollection($id: ID!) {
    collection(id: $id) {
      title
      image {
        altText
        height
        id
        originalSrc
        src
        transformedSrc
        url
      }
      products(first: 200) {
        edges {
          node {
            id
            title
            availableForSale

            productType
            description
            variants(first: 10) {
              nodes {
                id
                title
                quantityAvailable
                availableForSale
                currentlyNotInStock
                weightUnit
                weight
                sku
                compareAtPrice {
                  amount
                  currencyCode
                }
                price {
                  amount
                  currencyCode
                }
              }
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            options(first: 10) {
              name
              values
            }
            gender: metafield(key: "gender", namespace: "custom") {
              id
              namespace
              key
              value
            }
            fit: metafield(key: "fit", namespace: "custom") {
              key
              value
            }
            sleeve_length: metafield(
              key: "sleeve_length"
              namespace: "custom"
            ) {
              key
              value
            }
            cat: metafield(key: "cat", namespace: "custom") {
              key
              value
            }
            Style_code: metafield(key: "stylecode", namespace: "custom") {
              key
              value
              __typename
            }
            images(first: 6) {
              edges {
                node {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const allProductCollectionId = gql`
  query GetCollection($id: ID!) {
    collection(id: $id) {
      title
      image {
        altText
        height
        id
        originalSrc
        src
        transformedSrc
        url
      }
      products(first: 200) {
        edges {
          node {
            id
            title
            availableForSale
            productType
            description
            variants(first: 10) {
              nodes {
                id
                title
                quantityAvailable
                availableForSale
                weightUnit
                weight
                sku
                compareAtPrice {
                  amount
                  currencyCode
                }
                price {
                  amount
                  currencyCode
                }
              }
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            options(first: 10) {
              name
              values
            }
            gender: metafield(key: "gender", namespace: "custom") {
              id
              namespace
              key
              value
            }
            fit: metafield(key: "fit", namespace: "custom") {
              key
              value
            }
            sleeve_length: metafield(
              key: "sleeve_length"
              namespace: "custom"
            ) {
              key
              value
            }
            cat: metafield(key: "cat", namespace: "custom") {
              key
              value
            }
            Style_code: metafield(key: "stylecode", namespace: "custom") {
              key
              value
              __typename
            }
            images(first: 6) {
              edges {
                node {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

// productRecommendationsQuery

export const productRecommendationsQuery = gql`
  query ProductRecommendations($productId: ID!) {
    productRecommendations(productId: $productId, intent: RELATED) {
      title
      availableForSale
      productType
      description
      handle
      id
      images(first: 6) {
        edges {
          node {
            id
            originalSrc
            altText
            url
          }
        }
      }
      priceRange {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      variants(first: 5) {
        nodes {
          id
          title
          availableForSale
          quantityAvailable
          sku
          compareAtPrice {
            amount
            currencyCode
          }
          price {
            amount
            currencyCode
          }
        }
      }
      options(first: 10) {
        name
        values
      }
      gender: metafield(key: "gender", namespace: "custom") {
        id
        namespace
        key
        value
      }
      fit: metafield(key: "fit", namespace: "custom") {
        key
        value
      }
      sleeve_length: metafield(key: "sleeve_length", namespace: "custom") {
        key
        value
      }
      cat: metafield(key: "cat", namespace: "custom") {
        key
        value
      }
      Style_code: metafield(key: "stylecode", namespace: "custom") {
        key
        value
        __typename
      }
    }
  }
`;

export const productByProductId = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      title
      id
      availableForSale
      productType
      description
      images(first: 10) {
        nodes {
          altText

          url
        }
      }
      variants(first: 200) {
        edges {
          node {
            id
            title
          }
        }
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
    }
  }
`;

export const productByProductId1 = gql`
  query GetProducts($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Product {
        id
        availableForSale
        title
        description
        productType
        priceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        totalInventory
        variants(first: 10) {
          nodes {
            availableForSale
            id
            title
            currentlyNotInStock
            image {
              src
              url
            }
            compareAtPrice {
              amount
              currencyCode
            }
            price {
              amount
              currencyCode
            }
            quantityAvailable
            requiresShipping
            sku
          }
        }
        options(first: 10) {
          name
          values
          __typename
        }
        gender: metafield(key: "gender", namespace: "custom") {
          id
          namespace
          key
          value
          __typename
        }
        fit: metafield(key: "fit", namespace: "custom") {
          key
          value
          __typename
        }
        sleeve_length: metafield(key: "sleeve_length", namespace: "custom") {
          key
          value
          __typename
        }
        cat: metafield(key: "cat", namespace: "custom") {
          key
          value
          __typename
        }
        Style_code: metafield(key: "stylecode", namespace: "custom") {
          key
          value
          __typename
        }
        images(first: 6) {
          edges {
            node {
              altText
              url
            }
          }
        }
      }
    }
  }
`;

export const productByProductIdPay = gql`
  query GetProducts($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on ProductVariant {
        id

        title

        image {
          url

          src

          __typename
        }

        sku

        weight

        weightUnit

        product {
          id

          title

          handle

          __typename
        }
        compareAtPrice {
          amount
          currencyCode
        }
        price {
          amount

          currencyCode

          __typename
        }

        availableForSale

        __typename
      }

      __typename
    }
  }
`;
// search query for api
export const SEARCH_PRODUCT_QUERY = gql`
  query GetProducts($query: String!, $first: Int!) {
    products(query: $query, first: $first) {
      edges {
        node {
          id
          title
          availableForSale
          productType
          description
          variants(first: 10) {
            nodes {
              id
              title
              quantityAvailable
              availableForSale
              currentlyNotInStock
              weightUnit
              weight
              sku
              compareAtPrice {
                amount
                currencyCode
              }
              price {
                amount
                currencyCode
                __typename
              }
              __typename
            }
            __typename
          }
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
              __typename
            }
            minVariantPrice {
              amount
              currencyCode
              __typename
            }
            __typename
          }
          options(first: 10) {
            name
            values
            __typename
          }
          gender: metafield(key: "gender", namespace: "custom") {
            id
            namespace
            key
            value
            __typename
          }
          fit: metafield(key: "fit", namespace: "custom") {
            key
            value
            __typename
          }
          sleeve_length: metafield(key: "sleeve_length", namespace: "custom") {
            key
            value
            __typename
          }
          cat: metafield(key: "cat", namespace: "custom") {
            key
            value
            __typename
          }
          Style_code: metafield(key: "stylecode", namespace: "custom") {
            key
            value
            __typename
          }
          images(first: 6) {
            edges {
              node {
                url
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  query GetProductInfo($id: ID!) {
    node(id: $id) {
      __typename
      ... on Product {
        id
        title
        description
        availableForSale
        productType
        images(first: 10) {
          __typename
          edges {
            __typename
            node {
              __typename
              url

              src
            }
          }
        }
        priceRange {
          __typename
          maxVariantPrice {
            __typename
            amount
          }
          minVariantPrice {
            __typename
            amount
          }
        }

        variants(first: 10) {
          nodes {
            id
            title
            quantityAvailable
            availableForSale
            weightUnit
            weight
            sku
            compareAtPrice {
              amount
              currencyCode
            }
            price {
              amount
              currencyCode
            }
          }
        }
        options(first: 10) {
          name
          values
          __typename
        }
        gender: metafield(key: "gender", namespace: "custom") {
          id
          namespace
          key
          value
        }
        fit: metafield(key: "fit", namespace: "custom") {
          key
          value
        }
        sleeve_length: metafield(key: "sleeve_length", namespace: "custom") {
          key
          value
        }
        cat: metafield(key: "cat", namespace: "custom") {
          key
          value
        }
        Style_code: metafield(key: "stylecode", namespace: "custom") {
          key
          value
          __typename
        }
      }
    }
  }
`;
// All product query
export const GET_All_PRODUCTS = gql`
  query AllCategoryItems {
    products(first: 200) {
      edges {
        node {
          id
          title
          availableForSale
          productType
          description
          variants(first: 10) {
            nodes {
              id
              title
              quantityAvailable
              availableForSale
              weightUnit
              weight
              sku
              compareAtPrice {
                amount
                currencyCode
              }
              price {
                amount
                currencyCode
              }
            }
          }
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          options(first: 10) {
            name
            values
          }
          gender: metafield(key: "gender", namespace: "custom") {
            id
            namespace
            key
            value
          }
          fit: metafield(key: "fit", namespace: "custom") {
            key
            value
          }
          sleeve_length: metafield(key: "sleeve_length", namespace: "custom") {
            key
            value
          }
          cat: metafield(key: "cat", namespace: "custom") {
            key
            value
          }
          Style_code: metafield(key: "stylecode", namespace: "custom") {
            key
            value
            __typename
          }
          images(first: 6) {
            edges {
              node {
                url
              }
            }
          }
        }
      }
    }
  }
`;

// usequery for geting collection id for product
export const getAllProductCollectionId = gql`
  query {
    menu(handle: "All-Products") {
      items {
        id
        tags
        title
        resourceId
      }
    }
  }
`;

export const ProductAllCollection = gql`
  query GetCollection($id: ID!) {
    collection(id: $id) {
      title
      image {
        altText
        height
        id
        originalSrc
        src
        transformedSrc
        url
      }
      products(first: 200) {
        edges {
          node {
            id
            title
            availableForSale
            productType
            description
            variants(first: 10) {
              nodes {
                id
                title
                quantityAvailable
                availableForSale
                weightUnit
                weight
                sku
                compareAtPrice {
                  amount
                  currencyCode
                }
                price {
                  amount
                  currencyCode
                }
              }
            }
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            options(first: 10) {
              name
              values
            }
            gender: metafield(key: "gender", namespace: "custom") {
              id
              namespace
              key
              value
            }
            fit: metafield(key: "fit", namespace: "custom") {
              key
              value
            }
            sleeve_length: metafield(
              key: "sleeve_length"
              namespace: "custom"
            ) {
              key
              value
            }
            cat: metafield(key: "cat", namespace: "custom") {
              key
              value
            }
            Style_code: metafield(key: "stylecode", namespace: "custom") {
              key
              value
              __typename
            }
            images(first: 6) {
              edges {
                node {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
