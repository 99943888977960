import { useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import StepContent from "@mui/material/StepContent";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cancelOrder } from "../../api/Api";
import { trackYourOrder } from "../../api/Queries";
import { client } from "../../common/Utils";
import { fontSizeDownText } from "../Cart/CartItem";
import { fontSizedUpperText } from "./../Cart/CartItem";

const useStyles = makeStyles((theme) => ({
  "& .MuiStepLabel-label": {
    color: "white",
  },

  root: {
    "& .MuiStepIcon-active": { color: "red" },
    "& .MuiStepIcon-completed": { color: "red" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "#006D82" },
  },
}));

const steps = [
  {
    label: "Order Placed",
    description: `Order placed on 11/08/2023`,
  },
  {
    label: "Order Confirmed",
    description: "Order confirmed on 11/08/2023",
  },
  {
    label: "Shipped",
    description: `Order Shipped on 11/08/2023`,
  },
  {
    label: "Delivered ",
    description: `Order Delivered on 11/08/2023`,
  },
];

function TrackOrderScreen() {
  const orderData = [
    {
      img: "image9.png",
      title: "Pixeled Prinde Tee",
      des: "Introducing our stylish dark blue denim jeans with patchwork details from NoBiY! These jeans are perfect for any occasion and will make you stand out from the crowd. Get yours today!",
      size: "38",
      qty: "4",
      price: "1499",
    },
    {
      img: "image10.png",
      title: "Into the Metaverse",
      des: "Introducing our stylish dark blue denim jeans with patchwork details from NoBiY! These jeans are perfect for any occasion and will make you stand out from the crowd. Get yours today!",
      size: "XS",
      qty: "2",
      price: "2499",
    },
  ];

  // const createCardValues = useSelector((state) => state?.cart?.createCardValue);

  const [orderDetails, setOrderDetails] = useState([]);
  const accessToken = useSelector((state) => state?.user?.accessToken);

  let navigate = useNavigate();

  const classes = useStyles();

  const { data } = useQuery(trackYourOrder, {
    variables: { customerAccessToken: accessToken },
  });

  let customer = data;
  useEffect(() => {
    const handleData = async () => {
      const responseData = await client
        .query({
          query: trackYourOrder,
          variables: { customerAccessToken: accessToken },
        })
        .then((res) => {
          let customer = res;
          setOrderDetails(customer?.data?.customer?.orders?.edges);
        });
    };
    handleData();
  }, []);

  //  Stepper
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Box
        sx={{
          mt: { xs: "13vh", sm: "6vh", md: "12vh" },
          height: { xs: "calc(87vh)", sm: "calc(90vh)", md: "calc(87vh)" },
          overflowY: "auto",
        }}
      >
        <Container sx={{ pt: { lg: "15px", xs: "1px" } }}>
          <Typography
            color="#FFFFFF"
            sx={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              marginBottom: "1rem",
            }}
          >
            <ArrowBackIcon
              onClick={() => navigate("/cat")}
              sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }}
            />
            Back
          </Typography>
          <Card
            sx={{
              borderRadius: "20px",
              backgroundColor: "#18161D",
              boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.5)",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box
                  sx={{
                    overflowY: "auto",
                    height: { xs: "30vh", md: "62vh" },
                    margin: { lg: "1.5rem 0" },
                    padding: {
                      lg: "0.5rem 05rem 0rem 3rem",
                      md: "4rem 4rem 0rem 4rem",
                      sm: "4rem 9rem 2rem 9rem",
                      xs: "2rem 1rem 2rem 1rem",
                    },
                  }}
                >
                  {orderData?.map((e, index) => {
                    return (
                      <Box sx={{ mt: ".5rem" }}>
                        <Box sx={{ display: "flex" }}>
                          <Box
                            component="img"
                            sx={
                              {
                                // height: "9.5rem",
                              }
                            }
                            alt="CartImage"
                            src={e.img}
                          />
                          <Box sx={{ display: "flex", ml: "1rem" }}>
                            <Box>
                              <Typography
                                variant="subtitle1"
                                color="#FFFFFF"
                                sx={fontSizedUpperText}
                              >
                                {e.title}
                              </Typography>
                              <Typography
                                variant="subtitle"
                                color="#FFFFFF"
                                style={{
                                  maxHeight: "3.6em", // Adjust this to control the maximum height (2 lines)
                                  cursor: "pointer",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 2, // Limit to 2 lines
                                  lineHeight: "1.5em",
                                }}
                                sx={fontSizeDownText}
                              >
                                {e.des}
                              </Typography>

                              <Box
                                sx={{
                                  display: "flex",
                                  mt: "1rem",
                                  marginLeft: "2rem",
                                  color: "white",
                                }}
                              >
                                <Typography
                                  style={{ flex: 1 }}
                                  sx={fontSizeDownText}
                                >
                                  size: {e.size}
                                </Typography>
                                <Typography
                                  style={{ flex: 1 }}
                                  sx={fontSizeDownText}
                                >
                                  Qty: {e.qty}
                                </Typography>
                              </Box>
                              <Box sx={{ display: "flex", mt: "1rem" }}>
                                <Typography
                                  variant="subtitle1"
                                  color="#FFFFFF"
                                  sx={fontSizedUpperText}
                                >
                                  MRP : ₹ {e.price}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              borderRadius: "40px",
                              height: "4.3vh",
                              background:
                                "linear-gradient(to right, #006D82, #006D82)",
                              textTransform: "none",
                            }}
                          >
                            Cancel Order
                          </Button>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>

              <Grid
                item
                md={6}
                xs={12}
                sm={12}
                lg={6}
                sx={{
                  borderStyle: "solid",
                  borderColor: "#EA9A23",
                  mt: { xs: 0, sm: 0, md: 3, lg: 3 },
                  mb: { xs: 0, sm: 0, md: 3, lg: 3 },
                  mr: { xs: 2, sm: 2, md: 0, lg: 0 },
                  ml: { xs: 2, sm: 2, md: 0, lg: 0 },
                  height: "62vh",
                  borderWidth: {
                    xs: "2px 0 0 0",
                    sm: "2px 0 0 0",
                    md: "0 0 0 2px",
                    lg: "0 0 0 2px",
                  },
                }}
              >
                <Box
                  sx={{
                    margin: { lg: "1.5rem 0" },
                    padding: {
                      lg: "0.5rem 05rem 0rem 3rem",
                      md: "4rem 4rem 0rem 4rem",
                      sm: "4rem 9rem 2rem 9rem",
                      xs: "2rem 1rem 2rem 1rem",
                    },
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: "#93C69B",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        transform: "scaleX(-1)",
                        height: "4rem",
                        p: ".5rem",
                      }}
                      alt="CartImage"
                      src="deliveryCar.png"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="subtitle1" sx={fontSizedUpperText}>
                        <b>In Process</b>
                      </Typography>
                      <Typography variant="subtitle" sx={fontSizeDownText}>
                        Expected delivery on <b>15/09/2023</b>
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ overflowY: "auto" }}>
                    <Box sx={{ maxWidth: 400 }}>
                      <Stepper
                        activeStep={activeStep}
                        orientation="vertical"
                        className={classes.root}
                      >
                        {steps.map((step, index) => (
                          <Step key={step.label}>
                            <StepLabel
                              style={{ color: "#fffff" }}
                              optional={
                                index === 3 ? (
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "white" }}
                                  >
                                    Last step
                                  </Typography>
                                ) : null
                              }
                            >
                              {step.label}
                            </StepLabel>
                            <StepContent>
                              <Typography color={"white"} sx={fontSizeDownText}>
                                {step.description}
                              </Typography>
                              <Box sx={{ height: ".5rem" }}>
                                <div>
                                  <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ mr: 1, height: "1.5rem" }}
                                  >
                                    {index === steps.length - 1
                                      ? "Finish"
                                      : "Continue"}
                                  </Button>
                                  <Button
                                    disabled={index === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1, height: "1.5rem" }}
                                  >
                                    Back
                                  </Button>
                                </div>
                              </Box>
                            </StepContent>
                          </Step>
                        ))}
                      </Stepper>
                      <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        Reset
                      </Button>
                    </Box>
                    <Typography variant="subtitle1" sx={{ color: "white" }}>
                      <b>Total Item Price</b>
                    </Typography>
                    <Box sx={{ color: "white" }}>
                      <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                        <Typography style={{ flex: 1 }} sx={fontSizeDownText}>
                          Cart Total :
                        </Typography>
                        <Typography style={{ flex: 1 }} sx={fontSizeDownText}>
                          ₹2499
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                        <Typography style={{ flex: 1 }} sx={fontSizeDownText}>
                          GST :
                        </Typography>
                        <Typography style={{ flex: 1 }} sx={fontSizeDownText}>
                          ₹235
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Box>
    </>
  );
}

export default TrackOrderScreen;
