import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setselectCoupanCode } from "../../Redux/CartSlice";
import { setCoupneCode } from "../../Redux/UserSlice";
import { findAllDiscountCoupan } from "../../api/Api";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    // backgroundColor: 'black',
    // color: 'white',
  },
  section: {
    marginBottom: "16px",
  },
  divider: {
    backgroundColor: "white",
    marginBottom: "15px",
    width: "104%",
  },
  text: {
    color: "red",
    fontFamily: "Raleway",
    fontSize: "10px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "16px",
  },
}));
const CouponCode = ({
  opencoupancode,
  setopenCoupancode,
  setdiscountcodeMessage,
  handleRemoveDiscountCoupan,
}) => {
  const [coupanDiscountValue, setcoupanDiscountValue] = useState();
  const coupanCodeValue = useSelector((state) => state.cart.selectCoupanCode);
  const [selectedValue, setSelectedValue] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    findAllDiscountCoupan().then((res) => {
      setcoupanDiscountValue(res);
      dispatch(setCoupneCode(res.data.price_rules.map((e) => e.title)));
    });
  }, []);

  const getCoupanTitle = coupanDiscountValue?.data?.price_rules?.map(
    (e) => e.starts_at
  );

  const classes = useStyles();
  const handleClose = () => {
    setopenCoupancode(false);
  };
  const handleItem = (value) => {
    if (coupanCodeValue) {
      handleRemoveDiscountCoupan();
    }
    dispatch(setselectCoupanCode(value));
    setdiscountcodeMessage("");
    setSelectedValue(value);
    setopenCoupancode(false);
  };

  return (
    <Dialog
      sx={{}}
      open={opencoupancode}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: 20, backgroundColor: "#1E1E1E" },
      }}
      // classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle
        style={{
          backgroundColor: "#1E1E1E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: 600,
        }}
      >
        My Coupons
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon onClick={handleClose} />
        </IconButton>
      </DialogTitle>
      <Divider variant="fullWidth" style={{ backgroundColor: "gray" }} />
      {/* <DialogContent sx={{ backgroundColor: "#1E1E1E", color: "#FFFFFF",backgroundImage: `url("coupon_background.png")`, backgroundRepeat:"no-repeat",width:{lg:"36rem"} }}>
      No Coupnes Available
       </DialogContent> */}
      {coupanDiscountValue?.data?.price_rules?.length == 0 ? (
        <DialogContent
          sx={{
            backgroundColor: "#1E1E1E",
            color: "#FFFFFF",
            backgroundImage: `url("coupon_background.png")`,
            backgroundRepeat: "no-repeat",
            width: { lg: "33rem" },
          }}
        >
          No Coupnes Available
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            overflowX: "hidden",
            backgroundColor: "#1E1E1E",
            color: "#FFFFFF",
            backgroundImage: `url("coupon_background.png")`,
            backgroundRepeat: "no-repeat",
            width: { lg: "33rem" },
          }}
        >
          {coupanDiscountValue?.data?.price_rules
            ?.slice(-1)
            .filter((g, index) => {
              const currentDate = new Date();
              const formattedEndDate = new Date(g.ends_at);
              return formattedEndDate >= currentDate;
            })

            .map((g, index) => {
              const startDate = new Date(g.starts_at);
              const endDate = new Date(g.ends_at);

              const formattedEndDate = endDate.toLocaleString(undefined, {
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              });

              const currnetDate = new Date();
              const isExpired = currnetDate > endDate;

              return (
                <div>Please fill in the coupon code manually.</div>
              );
            })}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default CouponCode;
