import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { fontSize, htag, mHead } from "./ReturnPolicy";

function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <>
      {/* <Box sx={{ backgroundImage: `url("Frame 201.png")`,backgroundSize:"40%",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}> */}

      <Box
        sx={{
          mt: "13vh",
          pr: { xs: 2, sm: 10, md: 25 },
          pl: { xs: 2, sm: 10, md: 25 },
          pb: 5,
        }}
      >
        <Typography sx={mHead}>
          <b>NOBIY: PRIVACY POLICY</b>
        </Typography>
        <Typography sx={htag}>
          <b>1. INTRODUCTION</b>
        </Typography>
        <Typography sx={fontSize}>
          Neometa Lifestyle Studio Private Limited lifestyle studio Pvt Ltd and
          its affiliates (collectively "the Company, we, our, us") takes the
          security of our systems and data privacy very seriously. We constantly
          strive to make our systems safe for our customers to use. This Privacy
          Policy describes how Neometa Lifestyle Studio Private Limited
          lifestyle studio Pvt Ltd (NoBiY & Gloria Vanderbilt Jeans) and its
          affiliates (collectively "Company, we, our, us") collect, use, share,
          protect or otherwise process your personal information through the
          Company’s official website{" "}
          <a
            href="http://www.nobiylifestyle.com/"
            target="_blank"
            style={{ color: "#EA9A23" }}
          >
            www.nobiylifestyle.com
          </a>{" "}
          (‘Website’). By mere use of the Website, you expressly consent to our
          use and disclosure of your personal information in accordance with
          this Privacy Policy. This Privacy Policy is incorporated into and
          subject to the Terms of Use.
        </Typography>
        <Typography sx={htag}>
          <b>2. TERMS OF USE </b>
        </Typography>
        <Typography sx={fontSize}>
          The Company has posted Terms and Conditions of Use that set forth the
          terms and conditions under which we provide products, services and
          info to you from the Websites. Please review the Terms and Conditions
          governing your use and access. It is your responsibility to read,
          understand and comply with the terms and conditions set forth therein.
        </Typography>
        <Typography sx={htag}>
          <b>
            3. COLLECTION OF PERSONALLY IDENTIFIABLE INFORMATION AND OTHER
            INFORMATION
          </b>
        </Typography>
        <Typography sx={fontSize}>
          When you use our Website, we collect and store your Personal
          Information which is provided by you from time to time. Our primary
          goal in doing so is to provide you a safe, efficient, smooth and
          customized experience. This allows us to provide services and features
          that most likely meet your needs, and to customize our Website to make
          your experience safer and easier.{" "}
        </Typography>
        <Typography sx={fontSize}>
          Personal Information means any information about you which is
          personally identifiable, including but not limited to your name,
          address, telephone number, email address, financial details,
          registration name, password and other information from which you can
          be identified and which you disclose to us at any time. If you
          transact with us, we collect some additional information, such as a
          billing address, a credit / debit card number and a credit / debit
          card expiration date and/ or other payment instrument details.
        </Typography>
        <Typography sx={fontSize}>
          We may collect your Personal Information when:
        </Typography>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}>
              {" "}
              You create an online account.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}> You place an order.</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}>
              {" "}
              You make a complaint or enquiry or provide other feedback to us
              and.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}>
              {" "}
              You disclose your personal data to us through the website or at
              any other point including our Exclusive/Franchised/Large Format
              Stores(LFS)/Retail stores (‘Stores’).
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}>
              {" "}
              You disclose your personal data by participating in surveys or
              through questionnaire.
            </Typography>
          </Box>
        </Box>
        <Typography sx={htag}>
          <b>4. COOKIES</b>
        </Typography>
        <Typography sx={fontSize}>
          We use data collection devices such as "cookies" on certain pages of
          the Website to help analyse our web page flow, measure promotional
          effectiveness, and promote trust and safety. "Cookies" are small files
          placed on your hard drive that assist us in providing our services. We
          offer certain features that are only available through the use of a
          "cookie". We also use cookies to allow you to enter your password less
          frequently during a session. Cookies can also help us provide
          information that is targeted to your interests. You are always free to
          decline our cookies if your browser permits, although in that case you
          may not be able to use certain features on the Website and you may be
          required to re-enter your password more frequently during a session.
        </Typography>
        <Typography sx={fontSize}>
          We utilize a variety of methods, such as cookies and pixel tags, to
          collect this data, which may include your: (a) IP-address; (b) unique
          cookie identifier, cookie data and data on whether your device has
          software to access particular features; (c) unique device identifier
          and device type; (d) domain, browser type, country and language; (e)
          operating system and system settings; (f) country and time zone; (g)
          previously visited websites; (h) data regarding your interaction with
          the Sites such as click behaviour, purchases and preferences; and (i)
          access times and referring URLs. This enables us to provide services
          to our customers and improve their online experience. We also utilize
          cookies and pixel tags to acquire aggregate information regarding site
          traffic and site interaction, to identify trends and acquire
          statistics so that we can improve the Sites.
        </Typography>
        <Typography sx={fontSize}>
          Third parties may also collect data via the Sites through cookies,
          third party plug-ins and widgets. These third parties collect
          information directly from your web browser and the processing of this
          information is subject to such third parties’ privacy policies.
        </Typography>
        <Typography sx={htag}>
          <b>5. SHARING OF PERSONAL INFORMATION</b>
        </Typography>
        <Typography sx={fontSize}>
          We may use your information to prevent, detect, or investigate fraud,
          abuse, illegal use, violations of our Terms and Conditions of Use, and
          to comply with court orders, governmental requests or applicable law.
          We may share your Personal Information with other third parties to the
          extent necessary to: (a) comply with a government request, court order
          or applicable law; (b) prevent illegal uses of the Sites or violations
          of the Sites’ Terms and Conditions of Use and our policies; (c) defend
          ourselves against claims; and (d) assist in fraud prevention or
          investigation (for example, counterfeiting).
        </Typography>
        <Typography sx={fontSize}>
          We may share personal information with any member of our group, which
          means our subsidiaries; our ultimate holding company; its
          subsidiaries; and certain of our business partners which help us in
          providing your order, delivering your goods, taking your payment and
          analysing your use of the website in order to improve the efficiency
          of the Website. These entities and affiliates may market to you as a
          result of such sharing unless you explicitly opt-out.
        </Typography>
        <Typography sx={fontSize}>
          We may disclose personal information to third parties. This disclosure
          may be required for us to provide you access to our Services, for
          fulfilment of your orders, or for enhancing your experience, or to
          comply with our legal obligations, to enforce our User Agreement, to
          facilitate our marketing and advertising activities, or to prevent,
          detect, mitigate, and investigate fraudulent or illegal activities
          related to our Services. We do not disclose your personal information
          to third parties for their marketing and advertising purposes without
          your explicit consent.
        </Typography>
        <Typography sx={fontSize}>
          In the event we sell or transfer all or a portion of our business or
          assets (including in the event of a reorganization, spin-off,
          dissolution or liquidation), your information may be shared with a
          third party following completion of such transaction and/or during the
          assessment process pending transfer.
        </Typography>
        <Typography sx={fontSize}>
          For any other purposes, we will provide specific notice at the time of
          collection and request your consent where necessary.
        </Typography>
        <Typography sx={htag}>
          <b>6. SECURITY PRECAUTIONS</b>
        </Typography>
        <Typography sx={fontSize}>
          Our Website has stringent security measures in place to protect the
          loss, misuse, and alteration of the information under our control.
          Whenever you change or access your account information, we offer the
          use of a secure server. Once your information is in our possession we
          adhere to strict security guidelines, protecting it against
          unauthorized access.
        </Typography>
        <Typography sx={fontSize}>
          Despite our efforts, no website, mobile application, database or
          system is completely secure or “hacker proof.” Consequently, we cannot
          ensure or warrant the security of any information you transmit to us
          and you understand that any information that you transfer to us is
          done at your own risk. If we learn of a security systems breach we may
          attempt to notify you electronically so that you can take appropriate
          protective steps. By using the Sites or providing Personal Information
          to us, you agree that we can communicate with you electronically
          regarding security, privacy and administrative issues relating to your
          use of the Neometa Lifestyle studio Pvt Ltd Interactions. We may post
          a notice via the Sites if a security breach occurs. We may also send
          an email to you at the email address you have provided to us in these
          circumstances. Depending on where you live, you may have a legal right
          to receive notice of a security breach in writing. You can help keep
          your Personal Information safe by taking reasonable steps to protect
          your Personal Information against unauthorized disclosure or misuse,
          such as appropriately selecting and protecting your password and/or
          other sign-on mechanism, limiting access to your computer or device
          and browser, and signing off after you have finished accessing the
          Sites.
        </Typography>
        <Typography sx={fontSize}>
          We retain your Personal Information for as long as necessary to
          fulfill the purposes described in this Privacy Policy, unless
          otherwise required by law.{" "}
        </Typography>
        <Typography sx={htag}>
          <b>7. CHANGES TO THIS PRIVACY POLICY</b>
        </Typography>
        <Typography sx={fontSize}>
          Please check our Privacy Policy periodically for changes. We may
          update this Privacy Policy to reflect changes to our information
          practices. We will alert you to significant changes by posting the
          date our Privacy Policy got last updated, placing a notice on our
          Platform, or by sending you an email when we are required to do so by
          applicable law.
        </Typography>
        <Typography sx={htag}>
          <b>8. LINKS TO OTHER SITES</b>
        </Typography>
        <Typography sx={fontSize}>
          Our Website platform links to other websites that may collect Personal
          Information about you. Neometa Lifestyle Studio Private Limited
          lifestyle studio Pvt Ltd is not responsible for the privacy practices
          or the content of those linked websites.
        </Typography>
        <Typography sx={htag}>
          <b>9. YOUR RIGHTS</b>
        </Typography>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}>
              {" "}
              <b>The right to know:</b> You may request information about the
              categories and specific pieces of Personal Information we have
              collected about you, as well as the categories of sources from
              which such information is collected, the purpose for collecting
              such information, the sale or disclosure for business purposes of
              your Personal Information to third parties, and the categories of
              third parties with whom this information was shared.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}>
              {" "}
              <b>The right to choice/opt out:</b> We provide all users with the
              opportunity to opt-out of receiving non-essential (promotional,
              marketing-related) communications from us after setting up an
              account. If you do not wish to receive promotional communications
              from us, then please unsubscribe by clicking on the unsubscribe
              link in the e-mail.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}>
              {" "}
              <b>The right to request deletion </b>of your Personal Information,
              subject to certain legal exemptions.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}>
              {" "}
              <b>The right to not be discriminated against </b>for exercising
              any of these rights.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          {" "}
          <Box sx={{ marginTop: "20px" }}>
            <img
              src="MaskImg.png"
              height={"12px"}
              style={{ marginRight: "10px" }}
              alt=""
            />
          </Box>{" "}
          <Box>
            {" "}
            <Typography sx={fontSize}>
              {" "}
              <b>The right to your consent:</b> By using the Website and/ or by
              providing your information, you consent to the collection and use
              of the information you disclose on the Website in accordance with
              this Privacy Policy, including but not limited to your consent for
              sharing your information as per this Privacy Policy.
            </Typography>
          </Box>
        </Box>

      </Box>
      <Box sx={{ position: "fixed", bottom: 0 }}>
        <Typography
          color="#EA9A23"
          sx={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            left: 0,
            p: "10px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          <ArrowBackIcon
            sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }}
          />
          Back
        </Typography>
      </Box>
      {/* </Box> */}
    </>
  );
}

export default PrivacyPolicy;
