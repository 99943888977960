import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import { Box, Badge,useMediaQuery,useTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { List } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate,useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import LoginPage from "../page/Login/LoginPage";
import { makeStyles } from "@mui/styles";
import ForgotPassword from "../page/Login/ForgotPassword";
import SingUp from "../page/Singup/SingUp";
import Grid from "@mui/material/Grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ProfilePop from "./ProfilePop";
import OtpPopUp from "../page/Singup/OtpPopUp";
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import NewPassword from "../page/Singup/NewPassword";
import CloseIcon from '@mui/icons-material/Close';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import SearchBar from "../Search/SearchBar";
import Shop from "../page/Shop/Shop";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchIcon from '@mui/icons-material/Search';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DrawerBackground from "../images/DrawerBackground.png";



const useStyles = makeStyles((theme) => ({
  NobiyImg: {
    height: "60px",
    cursor: "pointer",
    "@media (max-width: 900px)": {
      height: "30px",
      marginTop:"0.7rem",
      marginRight:"11rem"
    }
  },
  NobiyImg1: {
    height: "50px",
   
    "@media (max-width: 900px)": {
      height: "42px",
      marginTop:".4rem"
    }
  },
  customBadge: {
    backgroundColor: "#006D82",
    color: "white",
    marginTop:"-5px",
  },
  customBadgeMobile: {
    backgroundColor: "#006D82",
    color: "white",
    marginTop:"-.4rem",
    marginRight:".6rem"
  },

  demo: {
   "& .css-qi8p6p.Mui-selected":{
    backgroundColor:"transparent"
   }
  },

}));


const navItems=[
  {
    id:1,
    img:null,
    name:'Home',
    moduleName:"home",
    link:"/"
    },
    {
    id:7,
    img:null,
    name: 'Shop',
    moduleName: "shop",
    link: "/cat",
    },
    {
    id:6,
    img:null,
    name: 'Search',
    moduleName: "search",
    },
    {
    id:2,
    img:null,
    name: 'About Us',
    moduleName: "aboutUs",
    link: "/aboutUs"
    },
    {
    id:4,
    img:null,
    name: 'Your Bag',
    moduleName: "yourBag",
    link: "/cart"
    },
     
    {
    id:5,
    img: '/LogIconImg1.png',
    name: 'Log In',
    moduleName: "logIn",
    },
    
  
  ]

const navItems1=[
  {
  id:1,
  img:null,
  name:'Home',
  moduleName:"home",
  link:"/"
  },
  {
  id:7,
  img:null,
  name: 'Shop',
  moduleName: "shop",
  link: "/cat",
  },
  {
  id:6,
  img:null,
  name: 'Search',
  moduleName: "search",
  },
  {
    id: 8,
    img: null,
    name: null,
    moduleName: "/newlogo.png",
    // link: "/"
  },
  {
  id:2,
  img:null,
  name: 'About Us',
  moduleName: "aboutUs",
  link: "/aboutUs"
  },
  {
  id:4,
  img:null,
  name: 'Your Bag',
  moduleName: "yourBag",
  link: "/cart"
  },
   
  {
  id:5,
  img: '/LogIconImg1.png',
  name: 'Log In',
  moduleName: "logIn",
  },
  
  ]

function Headerbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = useSelector((state) => state?.user?.isAuth);
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const editProfileDetails = useSelector((state) => state?.user?.editProfileDetails);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [openLog, setOpenLog] = useState(false);
  const [userName, setUserName] = useState("")
  const [openForgotPw, setOpenForgotPw] = useState(false);
  const [openSingUp, setOpenSingUp] = useState(false);
  const [showNewPass, setshowNewPass] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openShop, setOpenShop] = useState(false);
  const classes = useStyles();
  const cartItems = useSelector((state) => state?.cart?.cart);
  const [openpopUp, setOpenPopUp] = useState(false);
  const handleClickOpenPopUp = () => {
    setOpenPopUp(true);
  };
  const handleOpenShop = ()  =>{
    setOpenShop(true)
  }
  const handleOpenSearch = ()  =>{
    setOpenSearch(true)
  }

  const handleProfileIcon = () =>{
    isAuth?navigate("/profile"): setOpenLog(true)
  }

  const theme = useTheme();
 const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  let navData = isMobileView ? navItems : navItems1;
  const idToRemove = 4;
  let removeYourBagMobile = navData.filter((item) => item.id !== idToRemove);


  useEffect(() => {
    if (editProfileDetails === "") {
      if (userDetails && Object.keys(userDetails).length > 0) {
        let name = userDetails.firstName.split(" ")
        setUserName(name[0])
      }
    }
    else if(!editProfileDetails) {
      setUserName("")
    }else{
      let name = editProfileDetails?.split(" ")
      setUserName(name[0])
    }
  }, [userDetails, editProfileDetails])
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleListItemClick = (event, index) => {
    // console.log(index,"index")
    setSelectedIndex(index);
  };

  const handleRoute = (path) => {
    navigate(path);
  };

  const handleClick = (event) => {
    console.log(event.target);

    console.log("Image clicked");
  };

  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        pl: {xs:"30vw",sm:"40vw"},backgroundImage: `url(${DrawerBackground}) `, backgroundRepeat: "no-repeat", backgroundSize: "100vw 85vh", color: "#FFFFFF",
        // pl: "30vw",backgroundColor:"black", backgroundRepeat: "no-repeat", backgroundSize: "100vw 85vh", color: "#FFFFFF",
        display: { xs: "block", sm: "block", md: "none" }, height: "85vh"

      }}
      role="presentation"
    >
      <Divider />
      <IconButton sx={{ float: 'right', }}>
          <CloseIcon onClick={toggleDrawer(anchor, false)} style={{ color: "white", fontSize: 27 }} />
      </IconButton>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent:"center",
          paddingBottom: "0rem",
          paddingTop: {xs:"2rem",sm:"3rem"},
          marginRight: "8rem",
        }}
      >
        <Box
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            mt: 1,
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
          }}
        >
        </Box>
        {removeYourBagMobile.map((text, index) => (
          <ListItem
            key={text?.id}
            onClick={(event) => handleRoute(text?.link)}
            sx={{ width: "9rem", pt: {xs:"3rem",sm:"5rem"} }}
          >
            <ListItemButton
              selected={location.pathname === text.link}
              onClick={toggleDrawer(anchor, false)}
              sx={{ width: "100px", height: "30px",
              }}
            >
             {text?.moduleName === "/newlogo.png" ? (
              <img
                onClick={() => navigate("/")}
                src={text.moduleName}
                alt="logo"
                style={{height:"40px"}}
              />) : "" }
              {!text?.img ? (
                <ListItemText
                
                  primary={text.name}
                  primaryTypographyProps={{ fontSize: "13px",paddingTop:0,
                            paddingBottom:0,backgroundColor: location.pathname === text.link ? "transparent" : "initial" }}
                  sx={{
                    borderBottom:
                    location.pathname === text.link? "2px solid #006D82" : "",
                    textAlign: "center", "& .css-9cg7ln-MuiButtonBase-root-MuiListItemButton-root.Mui-selected":{backgroundColor:"red"}
                  }}
                  onClick ={() => text?.id == 6 ? handleOpenSearch():text?.id == 7 ? handleOpenShop():""}
                />
              ) :
               isAuth ? (
                <Typography
                  sx={{
                    fontWeight: "bolder",
                    backgroundColor: "#006D82",
                    p: "0.5rem", pr: "1rem",
                    borderRadius: ".7rem",
                    textTransform: 'capitalize',
                  }}
                  onClick={handleClickOpenPopUp} 
                >
                  {editProfileDetails == "" ? userName : editProfileDetails}
                  <ArrowDropDownIcon sx={{ position: "fixed", ml: -0.6, mb: -3 }}
                   />
                </Typography>
              ) : (
                <Box sx={{marginLeft:"-10px"}}>
                <img
                  src='/LogInImg.png'
                  height="45px"
                  onClick={() => {
                    setOpenLog(true);
                  }}
                />
                </Box>
              )
              }
            </ListItemButton>

          </ListItem>
        ))}

      </List>
    </Box>
  );

  return (
    <Box>
      <AppBar
        position="fixed"
        style={{
           boxShadow: "none",
          background: "#F1F1F1",
           borderBottom: "0.1px solid #D3D3D3",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: { xs: "block", sm: "block", md: "none" }, }}>
          </Box>
           <Box
            variant="h6"
            component="div"
            sx={{
              // flexGrow: 1,
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
            }}
          >
            <img
              onClick={() => navigate("/")}
              src="/newlogo.png"
              alt="logo"
              className={classes.NobiyImg}
            />
          </Box> 
          
         
          <Box sx={{display:"flex",ml:{xs:"-2rem",sm:"22rem"},flexWrap:"wrap"}}>
           <IconButton onClick={() => handleOpenSearch()}
           sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" },mt:"0.3rem"}}>
              <SearchIcon sx={{color:"#767676" ,}} />
          </IconButton>
          <IconButton  sx={{
              display: { xs: "block", sm: "block", md: "none", lg: "none" },ml:"",
            }}>
          <Badge badgeContent={cartItems?.length} classes={{ badge: classes.customBadgeMobile }}
          sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" },}}>
              <ListItemText
                primaryTypographyProps={{ fontSize: "4px",paddingTop:0,
                            paddingBottom:0 }}
              /></Badge>
          <LocalGroceryStoreOutlinedIcon onClick={() => navigate("/cart")}  
              sx={{ color: "#006D82", cursor:"pointer", }} />
          </IconButton>
          
          </Box>
          <Box sx={{display:{xs:"block",md:"none",lg:'none',sx:"none"}}}>
           <IconButton
              color="inherit"
              onClick={handleDrawerToggle}
              sx={{width:"2rem",marginLeft:"",justifyContent:'',}}
              // onClick={toggleDrawer(true)}
              className={classes.hamburBtn}
            >
              {['top'].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Button onClick={toggleDrawer(anchor, true)}><MenuIcon sx={{ fontSize: 30, color: "#767676",marginRight:"0.5rem" }} /></Button>
                  <Drawer
                    PaperProps={{ sx: { backgroundColor: "transparent",border:"none",height:"100vh" } }}
                    transitionDuration={500}
                    className={classes.demo}
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
              
            </IconButton>
            </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
          }}
        >
          <List
            sx={{
              display: "flex",
              flexDirection: "row",
              marginLeft:"6rem"
            }}
          >
            {navData.map((text, index) => (
              <ListItem
                key={text?.id}
                onClick={(event) => handleRoute(text?.link)}
                sx={{ color:"#006D82", cursor:"pointer",fontWeight:"bold",paddingTop:0,
                transition: "background-color 0.3s, color 0.3s",
                            paddingBottom:0 ,
                            "&:hover": {
                            
                              // color: "#4f97a3", 
                             
                            },
                          }}
              > 
                <ListItemText
                  onClick={(event) => handleListItemClick(event, text?.id)}
                  selected={location.pathname == text.link}
                  sx={{paddingTop:0,
                            paddingBottom:0}}
                >
              {text?.moduleName == "/newlogo.png" ? (<img
                onClick={() => navigate("/")}
                src={text.moduleName}
                alt="logo"
                style={{height:"55px",marginRight:"3rem" }}
              />):""}
                
              
                  {!text?.img ? (
                    text?.moduleName == "yourBag"  ?
                      <Badge badgeContent={cartItems?.length} classes={{ badge: classes.customBadge }}>
                        <ListItemText
                          primary={text.name}
                          primaryTypographyProps={{ fontWeight:"bold",fontSize:20 }}
                          sx={{
                            borderBottom:
                            location.pathname === text.link? "2px solid #006D82" : "",
                            textAlign: "center",
                            paddingTop:0,
                            paddingBottom:0
                          }}
                        /></Badge> :
                      <ListItemText
                        primary={text.name}
                        primaryTypographyProps={{ fontWeight:"bold",fontSize:20 }}
                        sx={{
                          borderBottom:
                          location.pathname === text.link? "2px solid #006D82" : "",
                          textAlign: "center",
                          maxWidth:"7.6rem",
                          paddingTop:0,
                            paddingBottom:0
                        }}
                         onClick ={() => text?.id == 6 ? handleOpenSearch():""}
                        // onClick ={() => text?.id == 6?handleOpenSearch():text?.id == 7?handleOpenShop():""}
                      />
                  ) : 
                  isAuth ? (
                    <Typography
                      sx={{
                        fontWeight: "bolder",
                        backgroundColor: "white",
                        padding: "0.5rem 1.5rem",
                        borderRadius: "1rem",
                        cursor:"pointer",
                        fontSize:20,
                        textTransform: 'capitalize',
                      }}
                      onClick={()=>handleClickOpenPopUp()}
                    >
                      {userName}<ArrowDropDownIcon sx={{ position: "absolute",mt:.5, ml: { lg: 0, sm: -0.2 } }} onClick={handleClickOpenPopUp} />
                    </Typography>
                  ) : (
                    <Typography variant="h6"  sx={{fontWeight:"bold"}} onClick={() => {
                          setOpenLog(true);
                        }}>Log In</Typography>
                  )
                  }
                </ListItemText>
              </ListItem>
            ))}
          <Box
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
              }}
            >
              <img
                // onClick={() => navigate("/")}
                src="/NOBIY LogoNew.png"
                alt="logo"
                className={classes.NobiyImg1}
                style={{marginRight:"4rem", marginTop:"0.75rem"}}
              />
            </Box>
          </List>
        </Box>
        {/* </Box> */}
      </AppBar>

      {openLog && <LoginPage
        openLog={openLog}
        setOpenLog={setOpenLog}
        openForgotPw={openForgotPw}
        setOpenForgotPw={setOpenForgotPw}
        openSingUp={openSingUp}
        setOpenSingUp={setOpenSingUp}
        showNewPass={showNewPass}
        setshowNewPass={setshowNewPass}
      />}

          <SearchBar
            openSearch={openSearch}
            setOpenSearch={setOpenSearch}
          />
          {/* <Shop
            openShop={openShop}
            setOpenShop={setOpenShop}
          /> */}

      {openForgotPw && <OtpPopUp
        openLog={openLog}
        setOpenLog={setOpenLog}
        openForgotPw={openForgotPw}
        setOpenForgotPw={setOpenForgotPw}
        openSingUp={openSingUp}
        setOpenSingUp={setOpenSingUp}
        showNewPass={showNewPass}
        setshowNewPass={setshowNewPass}
      />}
      {openSingUp && <SingUp
        openLog={openLog}
        setOpenLog={setOpenLog}
        openForgotPw={openForgotPw}
        setOpenForgotPw={setOpenForgotPw}
        openSingUp={openSingUp}
        setOpenSingUp={setOpenSingUp}
      />}

      {showNewPass && <NewPassword
        openLog={openLog}
        setOpenLog={setOpenLog}
        openForgotPw={openForgotPw}
        setOpenForgotPw={setOpenForgotPw}
        openSingUp={openSingUp}
        setOpenSingUp={setOpenSingUp}
        showNewPass={showNewPass}
        setshowNewPass={setshowNewPass}
      />}
      <ProfilePop openpopUp={openpopUp} setOpenPopUp={setOpenPopUp} />
    </Box>
  );
}

export default Headerbar;
