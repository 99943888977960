import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function OderPlace() {
  let navigate = useNavigate();
  const checkoutDetailes = useSelector(
    (state) => state?.cart?.checkoutDetailes
  );
  const checkoutVariants = useSelector(
    (state) => state?.cart?.checkoutVariants
  );
  const totalPrice = useSelector((state) => state?.cart?.totalPrice);
  const createCardValues = useSelector((state) => state?.cart?.createCardValue);
  const coupanValue = useSelector((state) => state?.user?.coupanValue);

  const dicountPre =
    (20 / 100) * createCardValues?.estimatedCost?.totalAmount.amount;
    const dicountTotalPrice =
    parseFloat(
      (20 / 100) * createCardValues?.estimatedCost?.totalAmount.amount
    ) + parseFloat(coupanValue);
  const compareTotalPrice =
    parseFloat(createCardValues?.estimatedCost?.totalAmount.amount) -
    dicountTotalPrice;

  return (
    <>
      <Container
        sx={{
          pt: { lg: "20vh", sm: "15vh", xs: "10vh" },
          pb: "5vh",
          width: { xs: "100vw", sm: "90vw", md: "60vw", lg: "80vw" },
        }}
      >
        <Box
          sx={{
            backgroundImage: `url("bgImgCircle.png")`,
            backgroundSize: "50%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Card
            sx={{
              borderRadius: "20px",
              backgroundColor: "#18161D",
              backdropFilter: "blur(10px)",
              boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.5)",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Container
                  sx={{
                    p: { lg: "3rem", md: "3rem", xs: "1rem", sm: "2rem" },
                    pt: { lg: "5rem", md: "5rem", xs: "1rem", sm: "1rem" },
                  }}
                >
                  <Box
                    sx={{
                      color: "white",
                      overflowY: "auto",
                      height: { xs: "50vh", md: "50vh" },
                    }}
                  >
                    {checkoutVariants?.map((e) => {
                      const quantityDetail = checkoutDetailes.find(
                        (detail) => detail.title === e.product.title
                      );
                      const quantityD = quantityDetail
                        ? quantityDetail.quantity
                        : 0;
                      return (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              borderRadius: "0.5rem",
                              boxShadow: "0px 0px 6px rgba(255, 255, 255, 0.5)",
                              paddingBottom: "0.8rem",
                              marginBottom: "1rem",
                            }}
                          >
                            <Box sx={{ textAlign: "center" }}>
                              <Box
                                component="img"
                                sx={{
                                  height: {
                                    xs: "7rem",
                                    sm: "8rem",
                                    md: "9rem",
                                    lg: "10rem",
                                  },
                                }}
                                alt="CartImage"
                                src={e.image.url}
                              />
                            </Box>

                            <Box sx={{}}>
                              <Typography
                                sx={{
                                  color: "white",
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  width: {
                                    xs: "42vw",
                                    sm: "30vw",
                                    md: "20vw",
                                    lg: "15vw",
                                  },
                                  mt: 1,
                                }}
                              >
                                {e.product.title}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "white",
                                  fontSize: 14,
                                  width: {
                                    xs: "40vw",
                                    sm: "30vw",
                                    md: "20vw",
                                    lg: "15vw",
                                  },
                                }}
                              >
                                {e.product.description}
                              </Typography>
                              <Box sx={{ display: "flex", m: 1 }}>
                                <Typography
                                  sx={{
                                    color: "white",
                                    fontSize: 14,
                                    width: {
                                      xs: "20vw",
                                      sm: "15vw",
                                      md: "10vw",
                                      lg: "8vw",
                                    },
                                  }}
                                >
                                  Size:{String(e.title).split("/")[0]}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "white",
                                    fontSize: 14,
                                    width: {
                                      xs: "20vw",
                                      sm: "15vw",
                                      md: "10vw",
                                      lg: "8vw",
                                    },
                                  }}
                                >
                                  Qty:{quantityD}
                                </Typography>
                              </Box>
                              {/* <Typography
                                sx={{
                                  color: "white",
                                  fontSize: 14,
                                  width: "12vw",
                                  m: 1,
                                }}
                              >
                                {compareTotalPrice.toFixed(2)}
                              </Typography> */}
                              <Box
                                  sx={{
                                    display: "flex",
                                    mt: "0.5rem",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    color="#FFFFFF"
                                    sx={{fontSize: 14}}
                                  >
                                    MRP : ₹{e?.compareAtPrice?.amount}
                                  </Typography>
                                  <Typography  variant='subtitle' sx={{ color: "gray", textDecoration:"line-through", fontSize:14,ml:"1rem",mt:".1rem" }}>
                                  ₹{Math.round(Number(e?.price?.amount))}/-</Typography>
                                </Box>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </Container>
              </Grid>
              <Grid item md={12} xs={12} sm={12} lg={6}>
                <Container
                  sx={{
                    padding: { lg: "4rem 6rem", xs: "2rem 3rem" },
                    mt: { xs: -7, sm: -8, md: -10, lg: 16 },
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {/* <img src="/SuccessfullyIcon.png" alt="" /> */}
                    <Box
                      component="img"
                      sx={{
                        height: { lg: "5rem", xs: "2rem" },
                        marginTop: { lg: "0", xs: "1rem" },
                      }}
                      alt="CartImage"
                      src="/SuccessfullyIcon.png"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "white",
                        fontSize: 14,
                        mt: 2,
                      }}
                    >
                      Order Successfully Placed
                    </Typography>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        color: "#FFFFFF",
                        marginBottom: "1rem",
                      }}
                    >
                      Total amount ₹ {compareTotalPrice.toFixed(2)}
                    </Typography>
                  </Box>
                </Container>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
                mt: { xs: 0, sm: -1, md: -2, lg: -2 },
              }}
            >
              <Button
                sx={{
                  bgcolor: "#006D82",
                  color: "#FFFFFF",
                  textTransform: "none",
                  height: "2.2rem",
                  width: "13rem",
                  fontSize: { xs: 11, sm: 12, md: 13, lg: 14 },
                }}
                variant="contained"
                onClick={() => navigate("/")}
              >
                Go back to Shopping
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
    </>
  );
}

export default OderPlace;
