import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, Typography, Zoom, Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { client } from ".././common/Utils";
import { setsearchProductValue } from "../Redux/CartSlice";
import { SEARCH_PRODUCT_QUERY } from "../api/Queries";
import { fontSize } from "../common/Footer";
const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: "#ffffff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ffffff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#006D82",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#ffffff",
    },
  },
}));

export const fontColor = {
  color: "#ffffff",
  cursor: "pointer",
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});

function SearchBar({ openSearch, setOpenSearch }) {
  const searchOptionValue = [
    { title: "Tees", id: 1 },
    { title: "Hoodie", id: 2 },
    { title: "Jacket", id: 3 },
    { title: "Denim Pants", id: 4 },
    { title: "Polo", id: 5 },
    { title: "Shorts", id: 6 },
    { title: "Denim Jacket", id: 7 },
    { title: "Sweatshirt", id: 8 },
    { title: "Jogger", id: 9 },
    { title: "Woven Pants", id: 10 },
  ];

  const navigate = useNavigate();
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpenSearch(true);
  };

  const handleClose = () => {
    setOpenSearch(false);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  // const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchTextValue = (value) => {
    client
      .query({
        query: SEARCH_PRODUCT_QUERY,
        variables: {
          query: value,
          first: 50,
        },
      })
      .then((res) => {
        dispatch(setsearchProductValue([res.data.products]));
        // navigate("/cat")
        // navigate("/searchValue")
        handleClose();
      });
    setSearchTerm(value);
  };

  const handleSug = (e) => {
    navigate("/searchValue");
    const value = e.target.getAttribute("value");
    client
      .query({
        query: SEARCH_PRODUCT_QUERY,
        variables: {
          query: value,
          first: 80,
        },
      })
      .then((res) => {
        dispatch(setsearchProductValue([res.data.products]));
        handleClose();
      });
    setSearchTerm(value);
  };

  return (
    <>
      <Dialog
        open={openSearch}
        TransitionComponent={Transition}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              // height: "100%",
              // maxWidth: { xs: "95vw", sm: "50vw", md: "65vw", lg: "65vw" }, 
              minHeight: { xs: "30vh", sm: "10vh", md: "10vh", lg: "10vh" },
            },
          },
          mb: { xs: "60%", md: "25%", lg: "14%" },
        }}
      >
        <DialogContent sx={{ fontFamily: "Raleway", backgroundColor: "black" }}>
          <Box sx={{ padding: "20px", backgroundColor: "black" }}>
            <Autocomplete
              className={classes.inputText}
              freeSolo
              id="free-solo-2-demo"
              size="small"
              disableClearable
              options={searchOptionValue.map((option) => option.title)}
              // open={openAutocomplete}
              // onInputChange={handleInputChange}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.toLowerCase().includes(state.inputValue.toLowerCase())
                );
              }}
              //   openOnFocus
              onChange={(event, newValue) => {
                searchTextValue(newValue);
                navigate("/searchValue");
              }}
              renderInput={(params) => (
                <TextField
                  className={classes.textField}
                  sx={{ input: { color: "#ffffff" } }}
                  {...params}
                  label="Search input"
                  onClick={() => searchTextValue(...params.InputProps)}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#ffffff" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Typography sx={{ color: "#E6A82E", mt: "1.5rem" }}>
              Suggestion Searches
            </Typography>
            <Grid container spacing={{ xs: 0, sm: 2 }} >
              <Grid item xs={6} sm={3} md={3}>
                <Typography
                  value="Tees"
                  onClick={handleSug}
                  style={{ color: "#ffffff", cursor: "pointer" }}
                  sx={fontSize}
                >
                  Tees
                </Typography>
                <Typography
                  value="Polo"
                  onClick={handleSug}
                  style={{ color: "#ffffff", cursor: "pointer" }}
                  sx={fontSize}
                >
                  Polo
                </Typography>
                <Typography
                  value="Sweatshirt"
                  onClick={handleSug}
                  style={{ color: "#ffffff", cursor: "pointer" }}
                  sx={fontSize}
                >
                  Sweatshirt
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Typography
                  style={fontColor}
                  sx={fontSize}
                  value="Hoodie"
                  onClick={handleSug}
                >
                  Hoodie
                </Typography>
                <Typography
                  style={fontColor}
                  sx={fontSize}
                  value="Shorts"
                  onClick={handleSug}
                >
                  Shorts
                </Typography>
                <Typography
                  style={fontColor}
                  sx={fontSize}
                  value="Jogger"
                  onClick={handleSug}
                >
                  Jogger
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Typography
                  style={fontColor}
                  sx={fontSize}
                  value="Jacket"
                  onClick={handleSug}
                >
                  Jacket
                </Typography>
                {/* <Typography style={fontColor} sx={fontSize} value="Shirts" onClick={handleSug}>
                  Shirt
                </Typography> */}
                <Typography
                  style={{ ...fontColor, whiteSpace: 'nowrap' }}
                  sx={fontSize}
                  value="Woven Pants"
                  onClick={handleSug}
                >
                  Woven Pants
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Typography
                  style={{ ...fontColor, whiteSpace: 'nowrap' }}
                  sx={fontSize}
                  value="Denim Pants"
                  onClick={handleSug}
                >
                  Denim Pants
                </Typography>
                <Typography
                  style={{ ...fontColor, whiteSpace: 'nowrap' }}
                  sx={fontSize}
                  value="Denim Jacket"
                  onClick={handleSug}
                >
                  Denim Jacket
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SearchBar;
