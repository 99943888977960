import { useMutation } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setName } from "../../Redux/UserSlice";
import LoginPage from "../Login/LoginPage";
import OtpPopUp from "../Singup/OtpPopUp";
import SingUp from "../Singup/SingUp";
import CartItem from "./CartItem";
import "./cart.css";
// import ChangePassword from '../Profile/ChangePassword';
import {
  setUpdateCart,
  setcreateCardValue,
  setproductVariantSize,
} from "../../Redux/CartSlice";
import { CREATE_CART_MUTATION } from "../../api/Mutations";
import NewPassword from "../Singup/NewPassword";

const Cart = () => {
  const dispatch = useDispatch();
  dispatch(setName("abc"));
  let navigate = useNavigate();

  const [openLog, setOpenLog] = useState(false);
  const [openForgotPw, setOpenForgotPw] = useState(false);
  const [openSingUp, setOpenSingUp] = useState(false);
  const [showNewPass, setshowNewPass] = useState(false);
  const [checkoutNavigate, setCheckoutNavigate] = useState(false);
  const cartItems = useSelector((state) => state?.cart?.cart);
  const [showAlternateImage, setShowAlternateImage] = useState(false);
  const isAuth = useSelector((state) => state?.user?.isAuth);
  // const showproductSize = useSelector((state) => state?.cart?.productVariantSize);
  const createCardValues = useSelector((state) => state?.cart?.createCardValue);

  useEffect(() => {
    // Show the alternate image for 2 seconds
    setShowAlternateImage(true);
    const timeout = setTimeout(() => {
      setShowAlternateImage(false);
    }, 1300);

    // Clean up the timeout when the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  const checkoutBtmfn = () => {
    if (isAuth === true) {
      navigate("/yourBag");
    } else {
      setOpenLog(true);
    }
  };
  const handleCartSizeQuantityChange = (size, quantity, productId) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.id === productId) {
        return { ...item, quantity: quantity, merchandiseId: size };
      }
      return item;
    });
    dispatch(setUpdateCart(updatedCartItems));
  };
  const [createCart, { data }] = useMutation(CREATE_CART_MUTATION);
  const handleRemove = async (productVariants) => {
    const lineItems = cartItems.map(({ quantity, merchandiseId }) => ({
      quantity,
      merchandiseId,
    }));
    try {
      const response = await createCart({
        variables: {
          cartInput: {
            lines: lineItems,
          },
        },
      });
      dispatch(setproductVariantSize(productVariants));
      dispatch(setcreateCardValue(response.data.cartCreate.cart));
    } catch (error) {
      console.error(error);
    }
  };

  const dicountTotalPrice =
    (20 / 100) * createCardValues?.estimatedCost?.totalAmount.amount;
  const compareTotalPrice =
    parseFloat(createCardValues?.estimatedCost?.totalAmount.amount) -
    dicountTotalPrice;

  return (
    <>
      {cartItems?.length === 0 ? (
        <div style={{ textAlign: "center", marginTop: "15rem" }}>
          <Typography variant="h5" fontWeight="600" color="#EA9A23">
            Your cart is empty
          </Typography>
          <Button
            sx={{
              borderRadius: 20,
              padding: "0.2rem 3rem",
              backgroundColor: "#006D82",
              color: "white",
              textTransform: "none",
              marginTop: "1rem",
            }}
            variant="contained"
            onClick={() => {
              navigate("/cat");
            }}
          >
            Add Items
          </Button>
        </div>
      ) : (
        <Box sx={{ mb: { lg: -3 }, mt: { xs: "8vh", sm: "6vh", md: "12vh" } }}>
          <Box
            sx={{
              position: "relative",
              zIndex: 1,
              // Add any desired styles for the content container
            }}
          >
            <Container sx={{ pt: { lg: "15px", xs: "1px" } }}>
              <Typography
                color="#006D82"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  marginBottom: "1rem",
                  mt: "1rem",
                }}
                onClick={() => navigate("/cat")}
              >
                <ArrowBackIcon
                  sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }}
                />
                Your Bag
              </Typography>
              <Card
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#18161D",
                  boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.5)",
                }}
              >
                <Grid container>
                  <Grid item md={6} xs={12} sm={12} lg={6}>
                    <Container
                      sx={{
                        overflowY: "auto",
                         height:"auto",
                         height: { xs: "40vh", md: "62vh" },
                        margin: { lg: "1.5rem 0" },
                        padding: {
                          lg: "0.5rem 05rem 0rem 3rem",
                          md: "4rem 4rem 0rem 4rem",
                          sm: "4rem 9rem 2rem 9rem",
                          xs: "2rem 1rem 0.1rem 1rem",
                        },
                      }}
                    >
                      {cartItems?.map((e, index) => {
                        return (
                          <CartItem
                            keyIndex={index}
                            Detail={e}
                            uniqueKey={index}
                            showproductSize={e?.quantityAvailable}
                            handleCartSizeQuantityChange={
                              handleCartSizeQuantityChange
                            }
                            handleRemove={handleRemove}
                          />
                        );
                      })}
                    </Container>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={12}
                    lg={6}
                    sx={{
                      borderStyle: "solid",
                      borderColor: "#006D82",
                      mt: { xs: 3, sm: 0, md: 3, lg: 3 },
                      mb: { xs: 3, sm: 3, md: 3, lg: 3 },
                      mr: { xs: 2, sm: 2, md: 0, lg: 0 },
                      ml: { xs: 2, sm: 2, md: 0, lg: 0 },
                      // height: "62vh",
                      borderWidth: {
                        xs: "2px 0 0 0",
                        sm: "2px 0 0 0",
                        md: "0 0 0 2px",
                        lg: "0 0 0 2px",
                      },
                    }}
                  >
                    <Container
                      sx={{
                        padding: {
                          lg: "2rem 5rem",
                          md: "5rem 5rem",
                          xs: "1rem 0.5rem",
                        },
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          ></div>
                        </div>
                      </div>
                      <Divider />
                      {/* </div> */}

                      <Divider
                        sx={{
                          bgcolor: "#F2F2F2",
                          marginTop: "2rem",
                          marginBottom: "1rem",
                        }}
                      />
                      <Box>
                        {/* createCardValues?.estimatedCost?.subtotalAmount?.amount */}
                        <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                          <Typography
                            sx={{
                              color: "white",
                              flex: 2,
                              fontSize: { lg: 18, sm: 12 },
                            }}
                          >
                            Total MRP :
                          </Typography>
                          <Typography style={{ color: "white", flex: 1 }}>
                            ₹{" "}
                            {
                              createCardValues?.estimatedCost?.totalAmount
                                .amount
                            }
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                          <Typography
                            sx={{
                              color: "white",
                              flex: 2,
                              fontSize: { lg: 18, sm: 12 },
                            }}
                          >
                            Discount on MRP :
                          </Typography>
                          <Typography
                            style={{
                              color: "white",
                              flex: 1,
                              // marginRight: "1rem",
                            }}
                          >
                            -₹ {dicountTotalPrice.toFixed(2)}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                          <Typography
                            sx={{
                              color: "white",
                              fontSize: { lg: 18, sm: 12 },
                              flex: 2,
                            }}
                          >
                            Shipping Charges :
                          </Typography>
                          <Typography
                            sx={{
                              color: "white",
                              flex: 1,
                              fontSize: { lg: 18, sm: 14 },
                            }}
                          >
                            Free
                          </Typography>
                        </Box>
                      </Box>

                      <Divider
                        sx={{
                          bgcolor: "#F2F2F2",
                          marginTop: "1rem",
                          marginBottom: "0.5rem",
                        }}
                      />
                      <Box sx={{ display: "flex", marginLeft: "2rem" }}>
                        <Typography
                          sx={{
                            color: "white",
                             // flex: 1,
                            flex: 2,
                            fontSize: { lg: 18, sm: 14 },
                          }}
                        >
                          Total Amount :
                        </Typography>
                        <Typography style={{ color: "white", flex: 1 }}>
                          ₹ {compareTotalPrice.toFixed(2)}
                        </Typography>
                      </Box>
                      <Divider
                        sx={{
                          bgcolor: "#F2F2F2",
                          marginTop: "0.5rem",
                          marginBottom: { lg: "4rem", sm: "2rem" },
                        }}
                      />
                    </Container>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        mt: { lg: -4, xs: "-2" },
                      }}
                    >
                      <Box sx={{}}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "11rem",
                            height: "4.3vh",
                            mb: { xs: 2 },
                            ml: { xs: 2 },
                            border: "1px solid #006D82",
                            borderRadius: "40px",
                            backgroundColor: "transparent",
                            "&:hover": { backgroundColor: "transparent" },
                            textTransform: "none",
                            mr: 2,
                          }}
                          onClick={() => navigate("/cat")}
                        >
                          Continue Shopping
                        </Button>
                      </Box>
                      <Box sx={{}}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "11rem",
                            height: "4.3vh",
                            borderRadius: "40px",
                            backgroundColor: "#006D82",
                            textTransform: "none",
                          }}
                          // onClick={() => navigate('/yourBag')}>Checkout</Button>
                          onClick={checkoutBtmfn}
                        >
                          Checkout
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </Box>
          <LoginPage
            checkoutNavigate={checkoutNavigate}
            setCheckoutNavigate={setCheckoutNavigate}
            openLog={openLog}
            setOpenLog={setOpenLog}
            openForgotPw={openForgotPw}
            setOpenForgotPw={setOpenForgotPw}
            openSingUp={openSingUp}
            setOpenSingUp={setOpenSingUp}
            showNewPass={showNewPass}
            setshowNewPass={setshowNewPass}
          />
          {openForgotPw && (
            <OtpPopUp
              openLog={openLog}
              setOpenLog={setOpenLog}
              openForgotPw={openForgotPw}
              setOpenForgotPw={setOpenForgotPw}
              openSingUp={openSingUp}
              setOpenSingUp={setOpenSingUp}
              showNewPass={showNewPass}
              setshowNewPass={setshowNewPass}
            />
          )}
          {openSingUp && (
            <SingUp
              openLog={openLog}
              setOpenLog={setOpenLog}
              openForgotPw={openForgotPw}
              setOpenForgotPw={setOpenForgotPw}
              openSingUp={openSingUp}
              setOpenSingUp={setOpenSingUp}
            />
          )}

          {showNewPass && (
            <NewPassword
              openLog={openLog}
              setOpenLog={setOpenLog}
              openForgotPw={openForgotPw}
              setOpenForgotPw={setOpenForgotPw}
              openSingUp={openSingUp}
              setOpenSingUp={setOpenSingUp}
              showNewPass={showNewPass}
              setshowNewPass={setshowNewPass}
            />
          )}
        </Box>
      )}
      ;
    </>
  );
};

export default Cart;
