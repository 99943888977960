import { FormControl } from "@mui/base";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { returnRequest, saveReturnId } from "../../api/Api";
import { failureToastMessage, successToastMessage } from "../../common/Utils";

const dialogStyles = {
  maxWidth: "80vw",
  width: { lg: "70vw", md: "70vw", sm: "70vw", xs: "80vw" },
  maxHeight: "80vh",
  height: "auto",
  backgroundImage: `url('./BackgroundImageweb.png')`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};
const radioLabelStyles = {
  fontSize: { lg: 16, md: 16, sm: 16, xs: 13 },
  marginBottom: "8px",
  color: "white",
};

const radioStyles = {
  "& .MuiSvgIcon-root": {
    fontSize: "24px",
    color: "white",
  },
};
const buttonStyles = {
  display: "flex",
  justifyContent: "center", // Center the button horizontally
  marginTop: "0.5rem",
  marginBottom: "1rem",
  fontSize: { lg: 16, md: 16, sm: 16, xs: 12 },
};

const titleStyles = {
  textAlign: "center", // Center the title text
  color: "white",
  fontSize: { lg: 18, md: 18, sm: 16, xs: 16 },
};
function ReturnOrderDialog({
  orderId,
  openDialog,
  handleDialogClose,
  returnOrderRequest,
  varientId,
}) {
  const [reason, setReason] = useState("");
  const [customerNote, setCustomerNote] = useState("");
  const accessToken = useSelector((state) => state?.user?.accessToken);

  const handleClose = () => {
    setReason("");
    handleDialogClose();
  };
  console.log(returnOrderRequest, "returnOrderRequest");
  const handleConfirm = () => {
    let data = {
      returnRequestInput: {
        orderId: returnOrderRequest.returnRequestInput.orderId,
        returnLineItems: [
          {
            fulfillmentLineItemId:
              returnOrderRequest.returnRequestInput.returnLineItems[0]
                .fulfillmentLineItemId,
            quantity:
              returnOrderRequest.returnRequestInput.returnLineItems[0].quantity,
            returnReason: reason,
            customerNote: customerNote,
          },
        ],
      },
    };
    //Implement your cancel order logic here.
    // console.log("Order Cancelled. Reason:", reason, data);
    returnRequest(data)
      .then((res) => {
        if (res.responseCode == 200) {
          successToastMessage(`Return Request Successfully submitted`);
          let data = {
            id: 0,
            customerIdShopify: accessToken,
            orderId: returnOrderRequest?.returnRequestInput?.orderId,
            returnRequestId: res.data?.data?.returnRequest?.return?.id,
            productVariantId: varientId,
            createdAt: "2023-10-02T17:48:51.558Z",
            updatedAt: "2023-10-02T17:48:51.558Z",
          };
          saveReturnId(data).then((res) => {
            //  console.log(res,"resresresres22")
          });
        } else {
          failureToastMessage("Return request Failed");
        }
      })
      .catch((e) => {
        console.log(e);
      });
    handleClose();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{ style: dialogStyles }}
    >
      <DialogTitle sx={titleStyles}>Reason for Return</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="cancel-reason"
            name="cancel-reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          >
            <FormControlLabel
              value="SIZE_TOO_LARGE"
              control={<Radio sx={radioStyles} />}
              label="Size or fit issues"
              sx={radioLabelStyles}
            />
            <FormControlLabel
              value="WRONG_ITEM"
              control={<Radio sx={radioStyles} />}
              label="Recieved wrong or defective product"
              sx={radioLabelStyles}
            />
            <FormControlLabel
              value="UNWANTED"
              control={<Radio sx={radioStyles} />}
              label="Image shown did not match the actual product"
              sx={radioLabelStyles}
            />
            <FormControlLabel
              value="DEFECTIVE"
              control={<Radio sx={radioStyles} />}
              sx={radioLabelStyles}
              label="Quality issues"
            />
            <FormControlLabel
              value="OTHER"
              control={<Radio sx={radioStyles} />}
              sx={radioLabelStyles}
              label="Other"
            />
          </RadioGroup>
        </FormControl>
        <TextField
          label="Customer Note"
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={customerNote}
          onChange={(e) => setCustomerNote(e.target.value)}
          sx={{ mt: 1, background: "white", mb: "2rem" }}
        />
      </DialogContent>
      <DialogActions sx={buttonStyles}>
        <Button
          variant="contained"
          type="submit"
          sx={{
            width: "9rem",
            height: "4.3vh",
            fontSize: 13,
            borderRadius: "10px",
            background: "linear-gradient(to right, #006D82, #006D82)",
            textTransform: "none",
            mt: "-2.8rem",
            marginRight: "3%",
          }}
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          sx={{
            width: "9rem",
            height: "4.3vh",
            fontSize: 13,
            borderRadius: "10px",
            background: "linear-gradient(to right, #006D82, #006D82)",
            textTransform: "none",
            mt: "-2.8rem",
            marginRight: "3%",
          }}
          onClick={handleConfirm}
          color="primary"
          disabled={!reason}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReturnOrderDialog;
