import {
  useMutation,
  useQuery
} from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setDefaultUserAddress
} from "../../Redux/UserSlice";
import { getCustomerDetails } from "../../api/Api";
import { addressDetails } from "../../api/Queries";
import AlertPopup from "../../common/AlertPopup";
import {
  defaultAddress
} from "./../../api/Mutations";
import AddIcon from '@mui/icons-material/Add';


const Profile = () => {
  const [open, setOpen] = useState(false);
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const defualtUserAddress = useSelector(
    (state) => state?.user?.defualtUserAddress
  );
  const addressID = useSelector((state) => state?.user?.addressID);
  const goLoginKey = useSelector((state) => state?.user?.goLoginKey);
  const [valifyAddressId, setValifyAddressId] = useState({});

  useEffect(() => {
    setValifyAddressId(addressID);
  }, []);

  const dispatch = useDispatch();

  const { data } = useQuery(addressDetails, {
    variables: { customerAccessToken: accessToken },
  });
  let customer = data;
  const userFirstName = customer?.customer?.firstName;
  const userEmail = customer?.customer?.email;
  const userPhone = customer?.customer?.phone;

  const handleLogout = () => {
    setOpen(true);
  };
  const {
    register,
    handleSubmit,
    formState: { error },
  } = useForm();
  const navigate = useNavigate();


  useEffect(() => {
    getCustomerDetails(accessToken)
      .then((res) => {
        let customer = res.data;
        dispatch(
          setDefaultUserAddress(customer?.data?.customer?.defaultAddress)
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <Box sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            // mt: { lg: "9vh", xs: "1vh", sm: "7vh", md: "9vh" },
            mt: {sm:"7vh",xs:"2vh"},
            height: { lg: "0", xs: "calc(90vh)" },
            overflowY: "auto",
          }}
        >
          <Container sx={{ paddingTop: { lg: "3vh", xs: "1vh" } }}>
            <Typography
              color="#FFFFFF"
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              <ArrowBackIcon
                onClick={() => {
                  navigate("/");
                }}
                sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }}
              />
              Back
            </Typography>
            <Box>
              <Card
                sx={{
                  backgroundColor: "#F0EAFE",
                  pb: 3, pt: 3
                }}
              >
                <Typography sx={{ fontSize: 30, fontFamily: "Raleway", ml: 5.3 }}>
                  My Account
                </Typography>
                <Box>
                </Box>
                <Box sx={{display:{xs:"flex", sm:"flex",md:"block"}, justifyContent:'end'}}>
                <Button
                  sx={{
                    color: "black",
                    boxShadow: '1px 2px 2px gray',
                    bgcolor: "#d1d1e0",
                    "&:hover": {
                      bgcolor: "#006D82",
                      color: "white"
                    },
                    mt: 2, mr:"1rem", ml:8,
                    fontWeight: "bold",
                  }}
                  onClick={handleLogout}
                >
                    Log Out
                </Button>
                </Box>
                <br/>
                <Divider />
                <Box display="flex">
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Box display="flex" justifyContent={"space-between"}>
                        <Box sx={{ display: "flex" }}>
                          <Box
                            component="img"
                            sx={{
                              width: "1.3rem",
                              height: "3.5vh",
                              mt: { lg: 4, xs: 4.3 },
                              ml: { lg: 6, xs: 1 },
                            }}
                            src="profileIcon.png"
                          />
                          <Typography
                            sx={{
                              mt: { lg: 4, xs: 4 },
                              ml: { lg: 6, xs: 1.8 },
                              fontFamily: "Raleway",
                              fontWeight: "bold",
                            }}
                          >
                            Profile
                          </Typography>
                        </Box>
                        <Box>
                          <Button
                            sx={{
                              boxShadow: '1px 2px 2px gray',
                              color: "black",
                              fontWeight: "bold",
                              bgcolor: "#d1d1e0",
                              ml: { lg: 30, xs: 10 },
                              mr: "1rem",
                              mt: { lg: 3, xs: 3.3 },
                              "&:hover": {
                                bgcolor: "#006D82",
                                color: "white"
                              },
                            }}
                            onClick={() =>
                              navigate("/editProfile", {
                                state: {
                                  firstName: userFirstName,
                                  email: userEmail,
                                },
                              })
                            }
                          >
                            Edit Profile
                          </Button>
                        </Box>
                      </Box>

                      <Divider style={{ marginTop: "8px" }} />
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            mt: "1rem",
                            ml: { xs: "4rem", md: "7.5rem" },
                          }}
                        >
                          <Typography style={{}}>Name : {userFirstName} </Typography>
                          {/* <Typography style={{ flex: 1, textAlign: "start" }}>
                            {userFirstName}{" "}
                          </Typography> */}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            mt: "1rem",
                            ml: { xs: "4rem", md: "7.5rem" },
                          }}
                        >
                        <Typography sx={{overflow:"hidden", whiteSpace:"nowrap",textOverflow:"ellipsis"}}>
                        Email : {userEmail}</Typography>
                        </Box>
                      </Box>
                      {goLoginKey == "" ? (
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Box display="flex">
                            <Box
                              component="img"
                              sx={{
                                width: "1.3rem",
                                height: "2.8vh",
                                mt: 4,
                                ml: { lg: 6, xs: 1 },
                              }}
                              src="passwordIcon.png"
                            />
                            <Typography
                              sx={{
                                mt: 4,
                                ml: { lg: 6, xs: 1.8 },
                                fontFamily: "Raleway",
                                fontWeight: "bold",
                              }}
                            >
                              Password
                            </Typography>
                          </Box>
                          <Button
                            sx={{
                              color: "black",
                              boxShadow: '1px 2px 2px gray',
                              bgcolor: "#d1d1e0",
                              "&:hover": {
                                bgcolor: "#006D82",
                                color: "white"
                              },
                              ml: { lg: 24, xs: 2 },
                              mt: 3, mr: "1rem",
                              fontWeight: "bold",
                            }}
                            onClick={() => navigate("/changepassword")}
                          >
                            Change password
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}
                      <Divider style={{ marginTop: "8px" }} />
                      {goLoginKey == "" ? (
                        <Box display="flex">
                          <Box
                            component="img"
                            sx={{
                              width: "1rem",
                              height: "2.7vh",
                              mt: { lg: 4, xs: 4.5 },
                              ml: { lg: 6, xs: 1.4 },
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            src="phoneIcon.png"
                          />
                          <Typography
                            sx={{
                              mt: 4,
                              ml: { lg: 6.5, xs: 2 },
                              fontFamily: "Raleway",
                            }}
                          >
                            <b>Phone Number :</b>  {userPhone}
                          </Typography>
                          {/* <Typography sx={{ mt: 4, ml: { lg: 20, xs: 3 } }}>
                            {userPhone}
                          </Typography> */}
                        </Box>
                      ) : (
                        ""
                      )}
                      {goLoginKey == "" ? (
                        <Divider style={{ marginTop: "8px" }} />
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box display="flex">
                          <Box
                            component="img"
                            sx={{
                              width: "1.3rem",
                              height: "3.5vh",
                              mt: 4,
                              ml: { xs: 1.4 },
                            }}
                            src="AddressIcon.png"
                          />
                          <Typography
                              sx={{
                                mt: 4,
                                ml: { lg: 6, xs: 1.5 },
                                fontFamily: "Raleway",
                                fontWeight: "bold",
                              }}
                            >
                              Address
                            </Typography>
                        </Box>
                        {defualtUserAddress == null ? (
                          ""
                        ) : (
                          <Button
                            sx={{
                              color: "black",
                              boxShadow: '1px 2px 2px gray',
                              bgcolor: "#d1d1e0",
                              "&:hover": {
                                bgcolor: "#006D82",
                                color: "white"
                              },
                              fontWeight: "bold",
                              mr: "1rem",
                              ml: { lg: 30, xs: 5 },
                              mt: { lg: 3, xs: 3.3 },
                            }}
                            onClick={() => navigate("/myaddress")}
                          >
                            View All
                          </Button>
                        )}
                      </Box>
                      <Divider style={{ marginTop: "8px" }} />
                      <Box sx={{ ml: { xs: "4rem", md: "7.5rem" }}}>
                        <Typography
                          sx={{
                            // ml: { xs: 3 },
                            fontFamily: "Raleway",
                            // fontSize: 2,
                          }}
                        >
                          <b>{defualtUserAddress == null ? "" : "Default"}</b>
                          {/* Default */}
                        </Typography>
                        {/* <Typography sx={{ fontFamily: "Raleway" }}>{addr?allAddress.firstName:addressID.firstName}</Typography> */}
                        <Typography sx={{ mt: 0.5,  }}>
                          {addressID && Object.keys(addressID).length == 0
                            ? defualtUserAddress?.address1
                            : addressID?.address1} {addressID && Object.keys(addressID).length == 0
                              ? defualtUserAddress?.address2
                              : addressID?.address2}
                        </Typography>
                        <Typography sx={{ mt: 0.3,}}>
                          {addressID && Object.keys(addressID).length == 0
                            ? defualtUserAddress?.city
                            : addressID?.city} {addressID && 
                                Object.keys(addressID).length == 0
                            ? defualtUserAddress?.province
                            : addressID?.province} {addressID && Object.keys(addressID).length == 0
                              ? defualtUserAddress?.country
                              : addressID?.country} {addressID && Object.keys(addressID).length == 0
                                ? defualtUserAddress?.zip
                                : addressID?.zip}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                        <Button
                          onClick={() => navigate("/editaddress")}
                          variant="contained"
                          sx={{
                            mb: 1,
                            width: "200px",
                            borderRadius: "22px",
                            color: "white",
                            // fontSize: "16px",
                            marginTop:
                              defualtUserAddress == null ? "20%" : "0rem",
                            backgroundColor: "#006D82",
                            "&:hover": { backgroundColor: "#006D82" },
                            textTransform: "none",
                          }}
                        >
                          <AddIcon /> Add New
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {/* )
            })} */}
              </Card>
            </Box>
          </Container>
          <AlertPopup
            open={open}
            setOpen={setOpen}
            title={"logout"}
            displayTitle={"Are you sure you want to log out?"}
            buttonType1={true}
            buttonType2={true}
          ></AlertPopup>
        </Box>
      </Box>
    </>
  );
};

export default Profile;
