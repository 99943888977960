import { useMutation } from "@apollo/client";
import { ErrorMessage } from "@hookform/error-message";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  TextField,
  Typography
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setAccessToken } from "../../Redux/UserSlice";
import { changePassword } from "../../api/Api";
import {
  customerAccessTokenCreateMutation,
  updateCustomerInfoMutation,
} from "../../api/Mutations";
import {
  ErrorText,
  failureToastMessage,
  successToastMessage,
} from "../../common/Utils";

toast.configure();
const useStyles = makeStyles((theme) => ({
  paper: { borderRadius: 15 },
  errorP: {
    color: "#bf1650",
    marginLeft: "11.8rem",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));
const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const emailValue = useSelector((state) => state?.user?.emailValue);
  const emailMobile = useSelector((state) => state?.user?.emailMobile);
  const isPhone = useSelector((state) => state?.user?.isPhone);
  const isEmail = useSelector((state) => state?.user?.isEmail);
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const oldPasswordEnter = useSelector(
    (state) => state?.user?.oldPasswordEnter
  );
  const goLoginKey = useSelector((state) => state?.user?.goLoginKey);
  const emaiValueIncrep = window.btoa(emailValue);
  const mobileValueIncrep = window.btoa(emailMobile);
  const defaultValues = {
    email: isPhone == true ? mobileValueIncrep : emaiValueIncrep,
    isOldPasswordRequired: false,
    newPassword: "",
  };

  const { control, handleSubmit, formState, register } = useForm({
    defaultValues,
  });
  const { errors } = formState;
  const classes = useStyles();
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passValue, setPassValue] = useState("");
  // const [accessToken, setAccessToken] = useState();

  const [changepasswordToken, { error }] = useMutation(
    customerAccessTokenCreateMutation
  );
  const [changepasswordMutation] = useMutation(updateCustomerInfoMutation);
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [valuesCN, setValuesCN] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPasswordCN = () => {
    setValuesCN({ ...valuesCN, showPassword: !valuesCN.showPassword });
  };

  const handlePasswordChangeCN = (prop) => (event) => {
    setValuesCN({ ...valuesCN, [prop]: event.target.value });
  };

  const [valueOldPass, setValueOldPass] = useState({
    password: "",
    showPassword: false,
  });

  let valueOldPassword = valueOldPass.password;
  const handleClickShowOldPass = () => {
    setValueOldPass({
      ...valueOldPass,
      showPassword: !valueOldPass.showPassword,
    });
  };

  const handleChangeOldPass = (prop) => (event) => {
    setValueOldPass({ ...valueOldPass, [prop]: event.target.value });
  };

  const onSubmit = (data) => {
    if (valueOldPassword == oldPasswordEnter) {
      if (data.newPassword == data.confirmPassword) {
        changePassword(data)
          .then((res) => {
            console.log(res, "res");
            if (res.responseCode == 200) {
              const decryptedPassword = window.atob(res.data.oldPassword);
              changepasswordToken({
                variables: {
                  email: res.data.email,
                  password: decryptedPassword,
                },
              }).then((res) => {
                // setAccessToken(res?.data?.customerAccessTokenCreate?.customerAccessToken?.accessToken);
                changepasswordMutation({
                  variables: {
                    customerAccessToken:
                      res?.data?.customerAccessTokenCreate?.customerAccessToken
                        ?.accessToken,
                    password: data.newPassword,
                  },
                }).then((res) => {
                  dispatch(
                    setAccessToken(
                      res?.data?.customerUpdate?.customerAccessToken
                        ?.accessToken
                    )
                  );
                });
              });
              successToastMessage("Password Change Successfully");
              navigate("/profile");
            } else {
              failureToastMessage(res.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        data.newPassword = values.password;
        delete data.confirmPassword;
      } else {
        failureToastMessage("Confirm Password Not Match");
      }
    } else failureToastMessage("Old Password Not Matched");
  };

  const handleClose = () => {
    // setshowNewPass(false);
  };
  useEffect(() => {
    setPassValue(values.password);
  });

  return (
    <>
      <Container sx={{ paddingTop: { lg: "15vh", sm: "7vh", xs: "12vh" } }}>
        <Box>
          <Card sx={{ height: "80vh", backgroundColor: "#F0EAFE" }}>
            <Typography
              sx={{
                fontSize: { lg: 30, xs: 25 },
                fontFamily: "Raleway",
                ml: 6,
              }}
            >
              Saved Password
            </Typography>
            <Box>
              <Button
                sx={{ color: "black", mt: 3, ml: 4 }}
                onClick={() => navigate("/profile")}
              >
                <ArrowBackIosIcon sx={{ fontSize: "small" }} />
                Back to My Account
              </Button>
              <Divider
                sx={{
                  background: "black",
                  width: { lg: "10rem", xs: "11rem" },
                  marginLeft: { lg: "4.7%", xs: "14%", sm: "5.4%" },
                  marginTop: "-0.5%",
                }}
              />
            </Box>

            <br></br>
            <Box p={3} sx={{}}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Box
                  sx={{
                    width: { xs: "80vw", sm: "40vw", md: "25vw" },
                    pr: 5,
                    ml: { xs: "0%", sm: "33%" },
                  }}
                >
                  {goLoginKey == "" ? (
                    <Box sx={{ mt: 5 }}>
                      <FormControl fullWidth>
                        <Controller
                          name="oldPassword"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              sx={{ borderRadius: "5px" }}
                              id="oldPassword"
                              size="small"
                              placeholder="Old Password"
                              type={
                                valueOldPass.showPassword ? "text" : "password"
                              }
                              onInput={handleChangeOldPass("password")}
                              value={valueOldPass.password}
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                sx: {
                                  height: "30px",
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleClickShowOldPass}
                                    >
                                      {valueOldPass.showPassword ? (
                                        <VisibilityIcon />
                                      ) : (
                                        <VisibilityOffIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                autocomplete: "old-password",
                                form: {
                                  autocomplete: "off",
                                },
                              }}
                              {...register("oldPassword", {
                                required: "Password is mandatory",
                              })}
                            />
                          )}
                          control={control}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="oldPassword"
                          render={({ message }) => (
                            <ErrorText>{message}</ErrorText>
                          )}
                        />
                      </FormControl>
                    </Box>
                  ) : (
                    ""
                  )}

                  <Box sx={{ mt: 5 }}>
                    <FormControl fullWidth>
                      <Controller
                        name="newPassword"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ borderRadius: "5px" }}
                            id="newPassword"
                            size="small"
                            placeholder="Enter 8-16 digit password"
                            type={values.showPassword ? "text" : "password"}
                            onInput={handlePasswordChange("password")}
                            value={values.password}
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              maxLength: 16,
                              minLength: 6,
                            }}
                            InputProps={{
                              sx: {
                                height: "30px",
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleClickShowPassword}>
                                    {values.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            {...register("newPassword", {
                              required: "New Password is mandatory",
                              minLength: {
                                value: 6,
                                message: "Minimum 6 character long.",
                              },
                              maxLength: {
                                value: 16,
                                message: "Maximum 16 character long.",
                              },
                            })}
                          />
                        )}
                        control={control}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="newPassword"
                        render={({ message }) => (
                          <ErrorText>{message}</ErrorText>
                        )}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ mt: 5 }}>
                    <FormControl fullWidth>
                      <Controller
                        name="confirmPassword"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ borderRadius: "5px" }}
                            id="confirmPassword"
                            size="small"
                            placeholder="Re-enter password"
                            type={valuesCN.showPassword ? "text" : "password"}
                            onInput={handlePasswordChangeCN("password")}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                            value={valuesCN.password}
                            variant="outlined"
                            inputProps={{
                              maxLength: 16,
                              minLength: 6,
                            }}
                            InputProps={{
                              sx: {
                                height: "30px",
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowPasswordCN}
                                  >
                                    {valuesCN.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            {...register("confirmPassword", {
                              required: "Confirm Password is mandatory",
                            })}
                          />
                        )}
                        control={control}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="confirmPassword"
                        render={({ message }) => (
                          <ErrorText>{message}</ErrorText>
                        )}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      fontSize: 13,
                      borderRadius: "40px",
                      bgcolor: "#006D82",
                      textTransform: "none",
                    }}
                  >
                    Save Change
                  </Button>
                </Box>
              </form>
            </Box>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default ChangePassword;
