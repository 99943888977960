import React from "react";
import {
  TextField,
  Grid,
  Box,
  Card,
  FormControl,
  Button,
  Typography,
  Divider,
  Paper,
  InputAdornment,
  IconButton,
  Input,
  Dialog,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

function ForgotPassword({
  setOpenForgotPw,
  openForgotPw,
  setOpenSingUp,
  setOpenLog,
}) {
  const defaultValues = {
    email: "",
  };
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    console.log(data.email, "email");
  };

  let navigate = useNavigate();
  const navigateToSignUp = () => {
    // navigate("/singUp");
    // setOpenLog(false);
    // setOpenForgotPw(false);
    // setOpenSingUp(true);
  };

  const handleClose = () => {
    // setOpenForgotPw(false);
  };

  return (
    <>
      <Box>
        <Dialog onClose={handleClose}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Card
              sx={{
                boxShadow: "0px 0px 32px ",
                backgroundColor: "#1E1E1E",
                width: { xs: "80vw", sm: "45vw", md: "30vw", lg: "25vw" },
                height: { xs: "62vh", sm: "65vh", md: "75vh", lg: "75vh" },
                p: 1,
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <IconButton sx={{ float: "right" }}>
                      <CloseIcon
                        onClick={handleClose}
                        style={{ color: "white", fontSize: 27 }}
                      />
                    </IconButton>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 4,
                        ml: 7,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: 14, sm: 19, md: 22, lg: 22 },
                        }}
                      >
                        Create an Account
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        pt: 2,
                      }}
                    >
                      <Box>
                        <img src="/LoginLog.png" alt="" height="130vh" />
                      </Box>
                      <Box sx={{ mb: -1 }}>
                        <img src="/LoginLog1.png" alt="" height="30vh" />
                      </Box>
                      <Box sx={{}}>
                        <img src="/LoginLog2.png" alt="" height="12vh" />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item md={12} xs={12} sm={12} lg={12} sx={{}}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <FormControl
                        fullWidth
                        sx={{
                          pl: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                          pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                        }}
                      >
                        <Controller
                          control={control}
                          name="email"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              sx={{ bgcolor: "#595A79", borderRadius: "5px" }}
                              id="email"
                              height="10px"
                              type="text"
                              size="small"
                              variant="outlined"
                              placeholder="E-mail"
                              InputProps={{
                                maxLength: 50,
                                sx: {
                                  height: "40px",
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EmailIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box
                      sx={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 4,
                        mb: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          width: "12rem",
                          height: "4.3vh",
                          fontSize: 13,
                          borderRadius: "40px",
                          background:
                            "linear-gradient(to right, #006D82, #006D82)",
                          textTransform: "none",
                        }}
                      >
                        Send Link
                      </Button>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: { xs: 12, sm: 12, md: 13, lg: 13 },
                        }}
                      >
                        Back to
                        <Button
                          variant="text"
                          sx={{
                            textTransform: "none",
                            color: "#89CFF0",
                            fontSize: { xs: 12, sm: 12, md: 13, lg: 13 },
                            mt: "-3px",
                            ml: -0.7,
                            textDecoration: "underline",
                          }}
                          onClick={navigateToSignUp}
                        >
                          {" "}
                          Sign In
                        </Button>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Box>
        </Dialog>
      </Box>
    </>
  );
}

export default ForgotPassword;
