import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
  showVideo: true,
  isAuth: false,
  userGoogleData: "",
  emailValue: "",
  allProductsValue: [],
  products: [],
  accessToken: "",
  isFavorite: false,
  addressID: {},
  oldPasswordEnter: "",
  name: "",
  defualtUserAddress: {},
  goLoginKey: "",
  editProfileDetails: "",
  isPhone: false,
  isEmail: false,
  emailMobile: "",
  removeWishList: false,
  createAddressId: "",
  isLogOut: false,
  orderDetailsss: [],
  fullfillmentIndex: 0,
  coupanValue: "",
  tempAddress: {},
  coupneCode: "",
  checkScroll: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    setName: (state, { payload }) => {
      state.name = payload;
    },
    loginSuccess: (state, { payload }) => {
      state.isAuth = payload;
    },
    setShowVideo: (state, { payload }) => {
      if (payload === null) state = initialState;
      state.showVideo = payload;
    },
    setUserGoogleData: (state, { payload }) => {
      state.userGoogleData = payload;
    },

    setEmailValue: (state, { payload }) => {
      state.emailValue = payload;
    },
    setTempAddress: (state, { payload }) => {
      state.tempAddress = payload;
    },
    setCreateAddressId: (state, { payload }) => {
      state.createAddressId = payload;
    },
    setIsPhone: (state, { payload }) => {
      state.isPhone = payload;
    },
    setIsEmail: (state, { payload }) => {
      state.isEmail = payload;
    },
    setCoupanValue: (state, { payload }) => {
      state.coupanValue = payload;
    },
    setEmailMobile: (state, { payload }) => {
      state.emailMobile = payload;
    },
    setAccessToken: (state, { payload }) => {
      state.accessToken = payload;
    },
    setEditProfileDetails: (state, { payload }) => {
      state.editProfileDetails = payload;
    },
    setIsFavorite: (state, { payload }) => {
      state.isFavorite = payload;
    },
    setAddressID: (state, { payload }) => {
      state.addressID = payload;
    },
    setGoLoginKey: (state, { payload }) => {
      state.goLoginKey = payload;
    },

    setAllProductsValue: (state, { payload }) => {
      state.allProductsValue = payload;
    },
    setDefaultUserAddress: (state, { payload }) => {
      state.defualtUserAddress = payload;
    },
    setOldPasswordEnter: (state, { payload }) => {
      state.oldPasswordEnter = payload;
    },
    setCoupneCode: (state, { payload }) => {
      state.coupneCode = payload;
    },
    setRemoveWishList: (state, { payload }) => {
      state.removeWishList = payload;
    },
    setIsLogOut: (state, { payload }) => {
      state.isLogOut = payload;
    },
    setOrderDetailsss: (state, { payload }) => {
      state.orderDetailsss = payload;
    },
    setfullfilmentsIndex: (state, { payload }) => {
      state.fullfillmentIndex = payload;
    },
    setProducts: (state, { payload }) => {
      if (payload === null) {
        state.products = initialState;
      } else {
        state.products = payload;
      }
    },
    setCheckScroll: (state, { payload }) => {
      state.checkScroll = payload;
    },
  },
});

export const {
  setName,
  setUserDetails,
  loginSuccess,
  setShowVideo,
  setUserGoogleData,
  setEmailValue,
  setAccessToken,
  setIsFavorite,
  setAddressID,
  setAllProductsValue,
  setProducts,
  setOldPasswordEnter,
  setDefaultUserAddress,
  setGoLoginKey,
  setEditProfileDetails,
  setIsPhone,
  setCoupneCode,
  setTempAddress,
  setCoupanValue,
  setIsEmail,
  setCreateAddressId,
  setEmailMobile,
  setRemoveWishList,
  setIsLogOut,
  setOrderDetailsss,
  setfullfilmentsIndex,
  setCheckScroll,
} = userSlice.actions;
export default userSlice.reducer;
