import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
// import { fontSize } from './ReturnPolicy';
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import { makeStyles } from "@mui/styles";

export const fontSize = {
  fontSize: { xs: 12, sm: 14, md: 16, lg: 16 },
  mt: 1,
  color: "black",
  // fontFamily: "cursive"
};

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: "#0096FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0096FF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#0096FF",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: "18px", // Change this to your desired height
    },
    "& .MuiInputLabel-root": {
      fontSize: "18px", // Change this to your desired font size
      color: "#EA9A23",
      paddingLeft: "15px",
    },
  },
}));
const ContactUs = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <div>
      {/* <Box sx={{ backgroundImage: `url("Frame 201.png")`, backgroundSize: "38%", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}> */}

      <Box
        sx={{
          backgroundColor: "transparent",
          mt: "16vh",
          textAlign: "center",
          pr: { xs: 2, sm: 10, md: 25 },
          pl: { xs: 2, sm: 10, md: 25 },
          alignItems: "center",
        }}
      >
        <h2
          style={{
            fontSize: "25px",
            textAlign: "center",
            color: "#0D8180",
            borderBottom: "3px solid #EA9A23",
            // fontFamily: "cursive",
          }}
        >
          CONTACT US
        </h2>

        {/* <Typography sx={{color:"white"}}><b>E-mail : </b><a href="mailto:info@neometalifestyle.com" target="_blank" style={{ color: "#EA9A23" }}>info@neometalifestyle.com</a></Typography>

          <Typography sx={fontSize} ><b>Phone : </b> +91-803-121-0094</Typography>
          <Typography sx={fontSize} > <b>Contact Timing : </b>10:00AM to 6:00PM , Monday to Friday</Typography>

          <Typography sx={fontSize}><b>Address : </b> 918, 3rd Cross Rd, HRBR Layout 1st Block,<br /> HRBR Layout, Kalyan Nagar,Bengaluru,<br /> Karnataka 560043</Typography> */}

        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Avatar sx={{ height: "50px", width: "50px" }}>
                <FmdGoodIcon fontSize="large" sx={{ color: "#0D8180" }} />
              </Avatar>
            </Box>
            <Typography sx={fontSize} style={{ color: "#EA9A23" }}>
              <b>Address : </b>{" "}
            </Typography>
            <Typography sx={fontSize}>
              918, 3rd Cross Rd,
              <br /> HRBR Layout 1st Block,
              <br /> HRBR Layout, Kalyan Nagar,
              <br />
              Bengaluru,Karnataka 560043
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Avatar sx={{ height: "50px", width: "50px" }}>
                <LocalPhoneIcon fontSize="large" sx={{ color: "#0D8180" }} />
              </Avatar>
            </Box>
            <Typography sx={fontSize} style={{ color: "#EA9A23" }}>
              <b>Phone : </b>{" "}
            </Typography>
            <Typography sx={fontSize}>+91-748-394-9248</Typography>
            <Typography sx={fontSize} style={{ color: "#EA9A23" }}>
              <b>Contact Timing : </b>{" "}
            </Typography>
            <Typography sx={fontSize}>
              10:00 AM to 6:00 PM, Mon - Fri
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Avatar sx={{ height: "50px", width: "50px" }}>
                <MailIcon fontSize="large" sx={{ color: "#0D8180" }} />
              </Avatar>
            </Box>
            <Typography sx={fontSize} style={{ color: "#EA9A23" }}>
              <b>E-mail : </b>{" "}
            </Typography>
            <Typography sx={fontSize}>
              <a
                href="mailto:info@neometalifestyle.com"
                target="_blank"
                style={{ color: "black" }}
              >
                info@neometalifestyle.com
              </a>
            </Typography>
          </Grid>
        </Grid>
        {/* <Typography color="#EA9A23" sx={{ fontWeight: 'bold',  fontSize: '1.5rem', cursor: "pointer",position: "absolute",bottom:0,left:0,p:"10px"}} onClick={() => navigate("/home")}>
        <ArrowBackIcon sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }} />Back</Typography>
        */}
      </Box>
        <Box sx={{ position: "fixed", bottom: 0 }}>
        <Typography
          color="#EA9A23"
          sx={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            left: 0,
            p: "10px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          <ArrowBackIcon
            sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }}
          />
          Back
        </Typography>
      </Box>

      {/* </Box> */}
    </div>
  );
};

export default ContactUs;
