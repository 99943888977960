import { Box, Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setName } from "../../Redux/UserSlice";
import CommonCard from "./CommonCard";
const OutwearSeries = () => {
  const dispatch = useDispatch();
  dispatch(setName("abc"));
  const outer = useSelector((state) => state?.cart?.denimSeries);
  const seriesValue = outer?.map((e) => e?.data?.collection?.title);
  const outerAllValues = outer?.filter(
    (e) => e?.data?.collection?.title == "OUTERWEAR"
  );
  const ourterValue = outerAllValues[0]?.data?.collection?.products?.edges;

  const sortedProducts = [...ourterValue].sort((a, b) => {
    const priceA = b?.node.variants?.nodes[0]?.price?.amount;
    const priceB = a?.node.variants?.nodes[0]?.price?.amount;
    // return priceA - priceB;
    return priceB - priceA;
  });

  return (
    <>
      <Box
        sx={{
          paddingLeft: { lg: "2vw", xs: "3vw" },
          paddingRight: { lg: "2vw", xs: "5vw" },
          paddingBottom: { lg: "2vh", xs: "69px" },
          paddingTop: "1vh",
          display: "flex",
          mt: { lg: 20, xs: 12 },
        }}
      >
        <Grid
          container
          columnSpacing={{ lg: 1, md: 8, sm: 3, xs: 3 }}
          rowSpacing={3}
          sx={{ mt: ".3rem" }}
        >
          {ourterValue && ourterValue.length > 0 ? (
            sortedProducts.map((e) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={2.4}
                key={e?.id}
                sx={{ mt: "-1.2rem" }}
              >
                <CommonCard
                  zoomScreenDetails={e}
                  imgUrl={e.node.images.edges[0].node.url}
                  imgUrlTwo={e?.node?.images?.edges[1]?.node?.url}
                  props={e?.node}
                  discription={e?.node?.description}
                  titile={e.node.title}
                  catTitle="OUTWEAR"
                  availableForSale={e.node.availableForSale}
                  priceRange={e.node.priceRange.maxVariantPrice.amount}
                  productVariantPrice={e.node.variants.nodes[0].price.amount}
                  // productVariantCompareAtPrice={e?.variants?.nodes[0]?.compareAtPrice?.amount}
                  productVariantId={e.node.variants.nodes[0].id}
                  productVariants={e.node.variants.nodes}
                />
              </Grid>
            ))
          ) : (
            <div style={{ textAlign: "center", alignItems: "center" }}>
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: { lg: "50%", sm: "100%" },
                  objectFit: "contain",
                  marginTop: { lg: 0, xs: "4rem" },
                  // display: "block",
                  // marginTop:"-4rem"
                }}
                alt="CartImage"
                src="./abc.png"
              />
            </div>
          )}
        </Grid>
      </Box>

      {/* <Box sx={{ mt: 3 }}>
          <Footer position="static" />
        </Box> */}
    </>
  );
};

export default OutwearSeries;
