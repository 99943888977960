import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Avatar, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";

const ZoomView = ({ open, images, selectedImageIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(selectedImageIndex);

  useEffect(() => {
    setCurrentIndex(selectedImageIndex);
  }, [selectedImageIndex]);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(images.length - 1);
    }
  };

  // const handleNext = () => {
  // if (currentIndex < images.length - 1) {
  //   setCurrentIndex(currentIndex + 1);
  //   }
  // };
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
    >
      <Box
        sx={{
          // position: 'relative',
          textAlign: "center",
        }}
      >
        <Box
          component="img"
          sx={{
            objectFit: "contain",
            width: { lg: "50rem", md: "50rem", sm: "50rem", xs: "21rem" },
            height: { lg: "100rem", md: "100rem", sm: "100rem", xs: "41rem" },
          }}
          src={images[currentIndex]}
          alt={`Image ${currentIndex}`}
        />
      </Box>

      <Box
        sx={{
          position: "fixed",
          zIndex: 1,
          zoom: 1,
          bottom: { xs: "1vw", sm: "1vw" },
          left: { xs: "26vw", sm: "42vw" },
          display: "flex",
        }}
      >
        <Avatar
          sx={{
            mr: 4,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.6)", // Zoom in on hover
            },
            "@media (max-width: 768px)": {
              "&:hover": {
                transform: "scale(1)", // No zoom on mobile screens (adjust the max-width as needed)
              },
            },
          }}
        >
          <IconButton onClick={handlePrev}>
            <NavigateBeforeIcon fontSize="large" sx={{ color: "#006D82" }} />
          </IconButton>
        </Avatar>
        <Avatar
          sx={{
            mr: 4,
            mt: -1,
            width: "55px",
            height: "55px",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.6)", // Zoom in on hover
            },
            "@media (max-width: 768px)": {
              "&:hover": {
                transform: "scale(1)", // No zoom on mobile screens (adjust the max-width as needed)
              },
            },
          }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="large" sx={{ color: "#006D82" }} />
          </IconButton>
        </Avatar>
        <Avatar
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.6)", // Zoom in on hover
            },
            "@media (max-width: 768px)": {
              "&:hover": {
                transform: "scale(1)", // No zoom on mobile screens (adjust the max-width as needed)
              },
            },
          }}
        >
          <IconButton onClick={handleNext}>
            <NavigateNextIcon fontSize="large" sx={{ color: "#006D82" }} />
          </IconButton>
        </Avatar>
      </Box>
    </Dialog>
  );
};

export default ZoomView;
