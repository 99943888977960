import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Typography,Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fontSize, htag, mHead } from "./ReturnPolicy";
import { Link as RouterLink } from "react-router-dom";

function ShippingPolicy() {
    const navigate = useNavigate();
    return (
        <>
            <Box
                sx={{
                    overflow: "hidden",
                    mt: "13vh",
                    pr: { xs: 2, sm: 10, md: 25 },
                    pl: { xs: 2, sm: 10, md: 25 },
                    pb: 5,
                }}
            >
                <Typography sx={mHead}>
                    <b>NOBIY: Shipping Policy</b>
                </Typography>
                {/* <Typography sx={{ fontSize: 15, color: "white", mt: "1rem" }}>
          Last Updated: August 04, 2023{" "}
        </Typography> */}
                <Typography sx={htag}>
                    <b> 1. Delivery Timeframe: </b>
                </Typography>
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ marginTop: "20px" }}>
                        <img
                            src="MaskImg.png"
                            height={"12px"}
                            style={{ marginRight: "10px" }}
                            alt=""
                        />
                    </Box>
                    <Box>
                        <Typography sx={fontSize}>
                            <b> Minimum Delivery Time:</b> Orders will be delivered within a minimum of 2 day from the date of
                            order confirmation. Orders placed before 01:00 PM on a business day
                            will be processed and shipped on the same day.
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ marginTop: "20px" }}>
                        <img
                            src="MaskImg.png"
                            height={"12px"}
                            style={{ marginRight: "10px" }}
                            alt=""
                        />
                    </Box>
                    <Box>
                        <Typography sx={fontSize}>
                            <b> Maximum Delivery Time:</b> Our maximum delivery time is 5 days from the date of order confirmation.
                            Please note that delivery times may vary based on the shipping destination
                            and any unforeseen circumstances.
                        </Typography>
                    </Box>
                </Box>
                <Typography sx={htag}>
                    <b>2. Standard Shipping: </b>
                </Typography>
                <Box sx={{ display: "flex" }}>
                    {" "}
                    <Box sx={{ marginTop: "20px" }}>
                        <img
                            src="MaskImg.png"
                            height={"12px"}
                            style={{ marginRight: "10px" }}
                            alt=""
                        />
                    </Box>
                    <Box>
                        <Typography sx={fontSize}>
                            Estimated delivery time within 3-5 business days.
                        </Typography>
                    </Box>
                </Box>
                <Typography sx={htag}>
                    <b>3. Order Tracking: </b>
                </Typography>
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ marginTop: "20px" }}>
                        <img
                            src="MaskImg.png"
                            height={"12px"}
                            style={{ marginRight: "10px" }}
                            alt=""
                        />
                    </Box>
                    <Box>
                        <Typography sx={fontSize}>
                            Once your order is shipped, a confirmation email with a tracking number will be sent to you.
                            Use this tracking number to monitor the shipment status.
                        </Typography>
                    </Box>
                </Box>
                <Typography sx={htag}>
                    <b>4. Shipping Costs: </b>
                </Typography>
                <Box sx={{ display: "flex" }}>
                    {" "}
                    <Box sx={{ marginTop: "20px" }}>
                        <img
                            src="MaskImg.png"
                            height={"12px"}
                            style={{ marginRight: "10px" }}
                            alt=""
                        />
                    </Box>
                    <Box>
                        <Typography sx={fontSize}>
                            Shipping costs are calculated based on the  selected shipping method,
                            and your location. The total shipping cost will be displayed during
                            the checkout process.
                        </Typography>
                    </Box>
                </Box>
                <Typography sx={htag}>
                    <b>5. Delivery Address:  </b>
                </Typography>
                <Box sx={{ display: "flex" }}>
                    {" "}
                    <Box sx={{ marginTop: "20px" }}>
                        <img
                            src="MaskImg.png"
                            height={"12px"}
                            style={{ marginRight: "10px" }}
                            alt=""
                        />
                    </Box>
                    <Box>
                        <Typography sx={fontSize}>
                            Ensure the delivery address provided during checkout is accurate and complete.
                            We are not responsible for orders shipped to incorrectly provided addresses.
                        </Typography>
                    </Box>
                </Box>
                <Typography sx={htag}>
                    <b>6. Contact Information:  </b>
                </Typography>
                <Box sx={{ display: "flex" }}>
                    {" "}
                    <Box sx={{ marginTop: "20px" }}>
                        <img
                            src="MaskImg.png"
                            height={"12px"}
                            style={{ marginRight: "10px" }}
                            alt=""
                        />
                    </Box>
                    <Box>
                        <Typography sx={fontSize}>
                            For inquiries about your order or shipping process, please contact our customer service team 
                            at <Link component={RouterLink} target="_blank" to="https://neometalifestyle.com/contact-us">info@neometalifestyle.com</Link> or 
                            Contact us at +91-748-394-9248
                        </Typography>
                    </Box>
                </Box>
                <Typography sx={fontSize} style={{textAlign:"center"}}><b>Thank you for choosing  NEOMETA LIFESTYLE STUDIO PRIVATE LIMITED </b> </Typography>
            </Box>
            <Box sx={{ position: "fixed", bottom: 0 }}>
                <Typography
                    color="#EA9A23"
                    sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        left: 0,
                        p: "10px",
                        cursor: "pointer",
                    }}
                    onClick={() => navigate("/")}
                >
                    <ArrowBackIcon
                        sx={{ fontSize: 30, mr: 2, mb: -1, cursor: "pointer" }}
                    />
                    Back
                </Typography>
            </Box>
        </>
    )
}

export default ShippingPolicy
