import { Box, Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertPopup from "./AlertPopup";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LogoutIcon from '@mui/icons-material/Logout';
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={400} />;
});

const ProfilePop = ({ openpopUp, setOpenPopUp }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const defualtUserAddress = useSelector((state) => state?.user?.defualtUserAddress);

  const handleClickOpen = () => {
    setOpenPopUp(true);
  };

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const dispatch = useDispatch();
  const navigate1 = useNavigate();

  // useEffect(() => {
  //   dispatch(logout())
  //     .then(() => {
  //       navigate1("/", { replace: true });
  //     });

  // }, []);

  const handleLogout = () => {
    setOpen(true);
  };

  const handleMyAddress = () => {
    navigate(defualtUserAddress == null ? "/profile" : "/myaddress");
    handleClose();
  }

  useEffect(() => { });
  return (
    <>
      <Box>
        <Dialog
        TransitionComponent={Transition}
          sx={{ mb: { lg: 50, sm: 95 }, ml: { lg: "80vw",md:"70vw", sm: "58vw",xs:"10vw" }, mt: 4, width: "20rem", height: "20rem", }}
          open={openpopUp}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            sx={{ fontFamily: "Raleway", backgroundColor: "#FFFFFF" }}
          >
            <Box sx={{ fontFamily: "Raleway", }}>
              <Box sx={{ display:"flex", cursor:"pointer",textAlign:"start",
              pr:"3rem",pl:".5rem",pt:".3rem",pb:".3rem",
                '&:hover': {
                  backgroundColor: '#E4F4F3',
                  border:"2px solid gray",
                  borderRadius:"5px"
                },
              }} onClick={() => {
                    navigate("/profile");
                    handleClose();
                  }}>
                <AccountCircleIcon style={{ marginBottom: "-9px" }} />
                <Typography
                  sx={{ color: "black",pl:2 }}
                >
                  My Profile
                </Typography>
              </Box>
              {/* <Divider /> */}
              <Box sx={{ display:"flex", cursor:"pointer",textAlign:"start",
              pr:"3rem",pl:".5rem",pt:".3rem",pb:".3rem",
                '&:hover': {
                  backgroundColor: '#E4F4F3',
                  border:"2px solid gray",
                  borderRadius:"5px"
                },
              }}  onClick={() => { handleMyAddress() }}>
              <HomeIcon style={{ marginBottom: "-9px" }} />
              <Typography sx={{ color: "black",pl:2 }}>
                My Address
              </Typography>
              </Box>
              {/* <Divider /> */}
              <Box sx={{ display:"flex", cursor:"pointer",textAlign:"start",
              pr:"3rem",pl:".5rem",pt:".3rem",pb:".3rem",
                '&:hover': {
                  backgroundColor: '#E4F4F3',
                  border:"2px solid gray",
                  borderRadius:"5px"
                },
              }}  onClick={() => {
                  navigate("/wishlist");
                  handleClose();
                }}>
              <FavoriteBorderIcon style={{ marginBottom: "-9px" }} />
              <Typography sx={{ color: "black",pl:2 }}>
                My Wishlist
              </Typography>
              </Box>
              {/* <Divider /> */}
              <Box sx={{ display:"flex", cursor:"pointer",textAlign:"start",
              pr:"3rem",pl:".5rem",pt:".3rem",pb:".3rem",
                '&:hover': {
                  backgroundColor: '#E4F4F3',
                  border:"2px solid gray",
                  borderRadius:"5px"
                },
              }}  onClick={() => {
                  navigate("/trackActiveOrder");
                  handleClose();
                }}>
              <LocalMallIcon style={{ marginBottom: "-9px" }} />
              <Typography sx={{ color: "black",pl:2 }}>
                My Orders
              </Typography>
              </Box>
              {/* <Divider /> */}
              <Box sx={{ display:"flex", color:"red", cursor:"pointer",textAlign:"start",
              pr:"3rem",pl:".5rem",pt:".3rem",pb:".3rem",
                '&:hover': {
                  backgroundColor: '#E4F4F3',
                  border:"2px solid gray",
                  borderRadius:"5px"
                },
              }}  onClick={() => {
                  handleLogout();
                  handleClose();
                }}>
              <LogoutIcon style={{ marginBottom: "-9px" }} />
              <Typography sx={{pl:2 }}>
                Logout
              </Typography>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
      <AlertPopup
        open={open}
        setOpen={setOpen}
        title={"logout"}
        displayTitle={"Are you sure you want to log out?"}
        buttonType1={true}
        buttonType2={true}
      ></AlertPopup>
    </>
  );
};

export default ProfilePop;
