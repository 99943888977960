import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getFullfilmentItemId,
  getOrderByOrderId,
  getReturnId,
  getReturnStatus,
  getTrakingDetails,
  createTrackNumber
} from "../../api/Api";
import TATneometa from "../../api/TATneometa.json";
import { fontSizeDownText } from "../Cart/CartItem";
import { fontSizedUpperText } from "./../Cart/CartItem";
import ReturnOrderDialog from "./ReturnOrderDialog";
import TrackDetails from "./TrackDetails";
import InvoicePage from "./InvoicePage";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom'
function PreviousOrder(props) {
  const orderDetails = props?.location?.state?. orderDetails;
  console.log(orderDetails,"sumitkumar")
  const orderDetail = useSelector((state) => state?.user?.orderDetailsss);
  const fullfillIndex = useSelector((state) => state.user.fullfillmentIndex);
  const [openDialog, setOpenDialog] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [returnDate, setReturnDate] = useState("");
  const [cancelDate, setCancelDate] = useState("");
  const [confirmDate, setConfirmDate] = useState("");
  const [shippedDate, setShippedDate] = useState("");
  const [OfddDate, setOfdDate] = useState("");
  const [DeleveredDate, setDeleveredDate] = useState("");
  const [deleveredDateWithYear, setDeleveredDateWithYear] = useState(
    new Date()
  );
  const [expectedDate, setExpectedDate] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  const [orderCanceled, setOrderCanceled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [returnOrderRequest, setReturnOrderRequest] = useState({});
  const [returnRequestSent, SetReturnRequestSent] = useState(false);
  const [trackDetailsOpen, setTrackDetailsOpen] = useState(false);
  const [trackingDetails, setTrackingDetails] = useState([]);
  const [undelivered, setUndelivered] = useState(false);
  const [undeliveredDate, setUndeliveredDate] = useState("");
  const [subTotal, SetSubTotal] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [discountprice, setDiscountPrice] = useState("");
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const invoiceDetails = useSelector((state) => state?.user?.invoiceDetails);
  const orderDetailvalue = useSelector((state) => state?.user?.orderDetailsss);
  const emailValue1 = useSelector((state) => state?.user?.emailValue);
  let navigate = useNavigate();

  const useStyles = makeStyles((theme) => ({
    "& .MuiStepLabel-label": {
      color: "white",
    },
    label: {
      "& .MuiStepLabel-label": {
        color: "white",
      },
      "& .MuiStepLabel-labelContainer": {
        color: "white",
      },
    },

    root: {
      "& .MuiStepIcon-active": { color: "white" },
      "& .MuiStepIcon-completed": { color: "white" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "" },
      "& .css-4ff9q7.Mui-completed": {
        color: returnRequestSent ? "#f1c40f" : "",
      },
      "& .css-4ff9q7.Mui-active": {
        color: returnRequestSent ? "#f1c40f" : "",
      },
    },
  }));
  const classes = useStyles();

  //  Dialog Box Of Track Details
  const handleOpenDialog = () => {
    setTrackDetailsOpen(true);
  };

  useEffect(() => {
    // Calculate the current date
    const currentDate = new Date();

    // Calculate the date 15 days from the created date
    const fifteenDaysLater = new Date(deleveredDateWithYear);
    fifteenDaysLater.setDate(fifteenDaysLater.getDate() + 15);
    let day = String(fifteenDaysLater).split(" ")[0];
    let date = String(fifteenDaysLater).split(" ")[2];
    let month = String(fifteenDaysLater).split(" ")[1];
    let rdate = `${day}, ${date} ${month}`;
    setReturnDate(rdate);
    // Check if the current date is greater than or equal to 15 days later
    if (currentDate >= fifteenDaysLater) {
      setButtonDisabled(true);
    }
  }, [DeleveredDate]);

  const dateTimeFormater = (date, time) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const dateParts = date?.split("-");
    const year = dateParts[0];
    const monthIndex = parseInt(dateParts[1]) - 1;
    const day = parseInt(dateParts[2]);

    const timeParts = String(time).split("");
    const hours = parseInt(timeParts[0] + timeParts[1]);
    const minutes = parseInt(timeParts[2] + timeParts[3]);

    const dateObj = new Date(year, monthIndex, day, hours, minutes);

    const dayOfWeek = dateObj?.getDay();
    const dayName = dayNames[dayOfWeek];
    const monthName = monthNames[monthIndex];

    const formattedDateTime =
      (dayName ? dayName : "") +
      (dayName ? "," : "") +
      dateParts[0] +
      " " +
      monthName +
      " " +
      `${hours}:${minutes}`;

    return formattedDateTime;
  };
  const dateTimeFormaterWithYear = (date, time) => {
    const dateParts = date?.split("-");
    const year = dateParts[0];
    const monthIndex = parseInt(dateParts[1]) - 1;
    const day = parseInt(dateParts[2]);

    const timeParts = String(time).split("");
    const hours = parseInt(timeParts[0] + timeParts[1]);
    const minutes = parseInt(timeParts[2] + timeParts[3]);

    const dateObj = new Date(year, monthIndex, day, hours, minutes);

    return dateObj;
  };
  useEffect(() => {
    setLoading(true);
    const regex = /\/Order\/(\d+)\?/;
    const match = orderDetail.id.match(regex);

    // Check if a match was found and extract the order number
    if (match) {
      const orderNumber = match[1];
      getOrderByOrderId(orderNumber)
        .then((res) => {
          setTotalAmount(res.data?.order.total_price);

          SetSubTotal(res.data?.order?.total_line_items_price);
          setDiscountPrice(res.data?.order.total_discounts);
          getReturnId(
            accessToken,
            res.data?.order?.admin_graphql_api_id,
            orderDetail?.lineItems?.edges[0].node?.variant?.id
          ).then((res) => {
            if (res.data.length > 0) {
              getReturnStatus(res.data[0].returnRequestId).then((response) => {
                if (response.data.data.return.status == "REQUESTED") {
                  setActiveStep(1);
                }
                if (response.data.data.return.status == "OPEN") {
                  setActiveStep(2);
                }
              });
            }
          });
          if (res.data.order?.cancelled_at == null) {
            setOrderCanceled(false);
          } else {
            setOrderCanceled(true);
          }
          if (orderDetail?.canceledAt != null) {
            let time = String(res.data?.order?.cancelled_at).split("T");
            let time1 = String(time[1]?.split(":")[0]);
            let time2 = String(time[1]?.split(":")[1]);
            const fifteenDaysLater = new Date(res.data.order?.cancelled_at);
            fifteenDaysLater.setDate(fifteenDaysLater.getDate());
            let day = String(fifteenDaysLater)?.split(" ")[0];
            let date = String(fifteenDaysLater)?.split(" ")[2];
            let month = String(fifteenDaysLater)?.split(" ")[1];
            let rdate = `${day},${date} ${month} ${time1}:${time2}`;
            setCancelDate(rdate);
          }
          let time = String(res?.data?.order?.created_at).split("T");
          let time1 = String(time[1].split(":")[0]);
          let time2 = String(time[1].split(":")[1]);
          const fifteenDaysLater = new Date(res.data.order.created_at);
          fifteenDaysLater?.setDate(fifteenDaysLater.getDate());
          let day = String(fifteenDaysLater)?.split(" ")[0];
          let date = String(fifteenDaysLater)?.split(" ")[2];
          let month = String(fifteenDaysLater)?.split(" ")[1];
          let rdate = `${day},${date} ${month} ${time1}:${time2}`;
          setConfirmDate(rdate);

          let isDeliveredZipcode = TATneometa?.filter(
            (e) => e.Pincode == orderDetail?.shippingAddress?.zip
          );
          if (isDeliveredZipcode.length != 0) {
            const fifteenDaysLater = new Date(res.data.order.created_at);
            fifteenDaysLater?.setDate(
              fifteenDaysLater?.getDate() + isDeliveredZipcode[0].TAT
            );
            let day = String(fifteenDaysLater)?.split(" ")[0];
            let date = String(fifteenDaysLater)?.split(" ")[2];
            let month = String(fifteenDaysLater)?.split(" ")[1];
            let expdate = `${day}, ${date} ${month}`;
            setExpectedDate(expdate);
          }
          if (
            orderDetail?.canceledAt == null &&
            orderDetail?.fulfillmentStatus == "FULFILLED"
          ) {
            let reqData = {
              orderId: res.data?.order?.admin_graphql_api_id,
              first: 10,
            };
            getFullfilmentItemId(reqData).then((response) => {
              if (
                response.data.data?.returnableFulfillments?.edges.length > 0
              ) {
                let returnrequest = {
                  returnRequestInput: {
                    orderId: res.data?.order?.admin_graphql_api_id,
                    returnLineItems: [
                      {
                        fulfillmentLineItemId:
                          response.data?.data?.returnableFulfillments?.edges[0]
                            ?.node?.returnableFulfillmentLineItems?.edges[0]
                            ?.node?.fulfillmentLineItem?.id,
                        quantity:
                          response.data?.data?.returnableFulfillments?.edges[0]
                            ?.node?.returnableFulfillmentLineItems?.edges[0]
                            ?.node?.quantity,
                        returnReason: "",
                        customerNote: "",
                      },
                    ],
                  },
                };
                setReturnOrderRequest(returnrequest);
                setLoading(false);
              } else {
                SetReturnRequestSent(true);
                setLoading(false);
              }
            });
          } else {
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    } else {
      // console.log("Order number not found in the input string.");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const channelId = 33858;
      const orderReferences = ["5520045441320"];
  
      try {
        const response = await createTrackNumber(channelId, orderReferences);
        console.log('API Response:', response);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    if (returnRequestSent) {
      fetchData();
      SetReturnRequestSent(false);
    }
  }, [returnRequestSent]);
  
  const generateInvoiceHTML = (invoiceDetails, orderDetailvalue, emailValue1,index) => {
    return (
      <div>
        <InvoicePage invoiceDetails={invoiceDetails} orderDetail={orderDetailvalue} emailValue={emailValue1} index={index} />
      </div>
    );
  };
  const handleDownloadInvoice = (index) => {
    const pdf = new jsPDF('portrait', 'px', 'a2');
    // pdf.text('', 10, 10);
    const invoiceHTML = generateInvoiceHTML(invoiceDetails, orderDetailvalue, emailValue1,index);
    const containerDiv = document.createElement('div');
    document.body.appendChild(containerDiv);
    ReactDOM.render(invoiceHTML, containerDiv, () => {
      html2canvas(containerDiv).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 15, 20);
        pdf.save('invoice.pdf');
        document.body.removeChild(containerDiv);
      });
    });
  };

  useEffect(() => {
    if (!returnRequestSent) {
      if (orderDetail.successfulFulfillments.length != 0) {
        getTrakingDetails(
          orderDetail?.successfulFulfillments[0]?.trackingInfo[0].number
        ).then((res) => {
          setTrackingDetails(res.data);
          res.data.map((e) => {
            if (e?.statusCode == "PKD") {
              setActiveStep(2);
              let shipingDate = dateTimeFormater(e?.statusDate, e?.statusTime);
              setShippedDate(shipingDate);
            }
            if (e?.statusCode == "OFD") {
              setActiveStep(3);
              let ofDate = dateTimeFormater(e?.statusDate, e?.statusTime);
              setOfdDate(ofDate);
            }
            if (e?.statusCode == "DLVD") {
              setActiveStep(4);
              let DLVDDate = dateTimeFormater(e?.statusDate, e?.statusTime);
              setDeleveredDate(DLVDDate);
              let DeliverDate = dateTimeFormaterWithYear(
                e?.statusDate,
                e?.statusTime
              );
              setDeleveredDateWithYear(DeliverDate);
            }
            if (e?.statusCode == "UD") {
              setUndelivered(true);
              setActiveStep(5);
              let UdDate = dateTimeFormater(e?.statusDate, e?.statusTime);
              setUndeliveredDate(UdDate);
            }
          });
        });
      }
    }
  }, []);
  const handleReturnOrder = (data) => {
    let orderId = data;
    const regex = /\/Order\/(\d+)\?/;
    const match = orderId?.match(regex);

    // Check if a match was found and extract the order number
    if (match) {
      const orderNumber = match[1]; // Output: 5479163625768
      setOrderId(orderNumber);
      setOpenDialog(true);
    } else {
      console.log("Order number not found in the input string.");
    }
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const steps = [
    {
      label: "Confirmed",
      date: confirmDate,
    },
    {
      label: "Shipped",
      date: shippedDate,
    },
    {
      label: "Out of delivery",
      date: OfddDate,
    },
    {
      label: undelivered ? "Refused To Accept" : "Delivered ",
      date: undelivered ? undeliveredDate : DeleveredDate,
    },
  ];

  const stepsFailed = [
    {
      label: "Confirmed",
      date: confirmDate,
    },
    {
      label: "canceled",
      date: cancelDate,
    },
    {
      label:
        orderDetail?.financialStatus == "PAID" ? "Refund" : "N/A for Refund",
      date: "",
    },
  ];
  const ReturnSteps = [
    {
      label: "Return Requested",
      date: "",
    },
    {
      label: "Request Approved",
      date: "",
    },
    {
      label: "Pickup Arranged",
      date: "",
    },
    {
      label: "Pickup successfully",
      date: "",
    },
    {
      label:
        orderDetail?.financialStatus == "PAID" ? "Refund" : "N/A for Refund",
      date: "",
    },
  ];

   const getRemarkValue = trackingDetails.map((e)=>e?.remarks)
   const isDelivered = getRemarkValue.includes('Delivered');
  return (
    <>
      {loading ? (
        <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box
          sx={{
            mt: { xs: "9vh", sm: "9vh", md: "13vh" },
          }}
        >
          <Container sx={{ pt: { lg: "18px", xs: "2px" } }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                marginBottom: "1rem",
                color: "#006D82",
              }}
            >
              <ArrowBackIcon
                onClick={() => {
                  navigate("/trackActiveOrder");
                }}
                sx={{
                  fontSize: 30,
                  mr: 2,
                  mb: -1,
                  cursor: "pointer",
                  color: "#006D82",
                }}
              />
              Back
            </Typography>
            <Card
              sx={{
                borderRadius: "20px",
                backgroundColor: "#18161D",
                boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.5)",
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    sx={{
                      overflowY: "auto",
                      height: "auto",
                      
                      margin: { lg: "1.5rem 0" },
                      padding: {
                        lg: "0.5rem 05rem 0rem 3rem",
                        md: "4rem 4rem 0rem 4rem",
                        sm: "4rem 9rem 2rem 9rem",
                        xs: "2rem 1rem 2rem 1rem",
                      },
                    }}
                  >
                    {orderDetail?.lineItems?.edges?.map((elm, index) => {
                      return (
                        <div
                          style={{
                            borderRadius: "0.5rem",
                            boxShadow: "0px 0px 6px rgba(255, 255, 255, 0.5)",
                            paddingBottom: "0.5rem",
                            marginBottom: "2rem",
                            marginTop: "1rem",
                          }}
                        >
                          <Box sx={{ mt: ".5rem" }}>
                            <Box sx={{ display: "flex", marginLeft: "1.5%" }}>
                              <Box
                                component="img"
                                sx={{
                                  height: {
                                    xs: "7rem",
                                    sm: "8rem",
                                    md: "9rem",
                                    lg: "9rem",
                                  },
                                  mt: 1,
                                  cursor: "pointer",
                                }}
                                alt="CartImage"
                                src={elm?.node?.variant?.image?.url}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  ml: "2rem",
                                  mt: "1rem",
                                }}
                              >
                                <Box>
                                  <Typography
                                    variant="subtitle1"
                                    color="#FFFFFF"
                                    fontSize={16}
                                    sx={fontSizedUpperText}
                                  >
                                    {elm?.node?.title}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      mt: "1rem",
                                      color: "white",
                                    }}
                                  >
                                    <Typography
                                      style={{ flex: 0.5 }}
                                      sx={{ fontSize: 14 }}
                                    >
                                      size:{" "}
                                      {
                                        String(elm?.node?.variant?.title).split(
                                          "/"
                                        )[0]
                                      }
                                    </Typography>
                                    <Typography
                                      style={{ flex: 0.5 }}
                                      sx={{ fontSize: 14 }}
                                    >
                                      Qty: {elm?.node?.quantity}
                                    </Typography>
                                  </Box>
                                  <Box
                                  sx={{
                                    display: "flex",
                                    mt: "0.5rem",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    color="#FFFFFF"
                                    sx={{fontSize: 14}}
                                  >
                                    MRP : ₹{elm?.node?.variant?.compareAtPrice?.amount}
                                  </Typography>
                                  <Typography  variant='subtitle' sx={{ color: "gray", textDecoration:"line-through", fontSize:14,ml:"1rem",mt:".1rem" }}>₹{Math.round(Number(elm.node.variant.priceV2.amount))}/-</Typography>
                                </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      mt: "0.5rem",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      color="#FFFFFF"
                                      sx={{ fontSize: 14 }}
                                    >
                                      {isButtonDisabled
                                        ? "Return/Exchange window closed"
                                        : returnRequestSent
                                        ? "Return Request Sent Successfully"
                                        : !orderCanceled
                                        ? activeStep == 4
                                          ? `Return/Exchange window closed on ${returnDate}`
                                          : ""
                                        : "order has been canceled"}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            {/* <Box sx={{ color: "white", display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                    <Button variant="contained" type="submit"
                                                        sx={{ borderRadius: "20px", height: "4.3vh", background: "linear-gradient(to right, #006D82, #006D82)", textTransform: "none" }}
                                                    >Return</Button>
                                                     <Button variant="contained" type="submit"
                                                        sx={{ borderRadius: "20px", height: "4.3vh", background: "linear-gradient(to right, #006D82, #006D82)", textTransform: "none" }}
                                                    >Exchange</Button>
                                                </Box> */}
                            {activeStep == 4 &&
                              !returnRequestSent &&
                              !isButtonDisabled && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                      display:'flex'
                                    }}
                                  >
                                    <Button
                                      onClick={() =>
                                        handleReturnOrder(orderDetail?.id)
                                      }
                                      sx={{
                                        background: "rgba(255, 255, 255, 0.05)",
                                        color: "#FFFFFF",
                                        width: { xs: "8rem" },
                                        textTransform: "none",
                                      }}
                                      // disabled={orderDetail.canceledAt==null?isButtonDisabled:true}
                                      variant="contained"
                                    >
                                      Return
                                    </Button>
                                    <div>
    {isDelivered && (
      <Button onClick={() => handleDownloadInvoice(index)} sx={{
        marginLeft: "1%",
        fontSize: { lg: 13, md: 18, xs: 13 },
      }}>
        Invoice
      </Button>
    )}
  </div>

                                  </Box>
                                  {/* <Button
                              // onClick={() => handleClick()}
                              sx={{
                                background: "rgba(255, 255, 255, 0.05)",
                                color: "#FFFFFF",
                                width: { xs: "30%" },
                                textTransform: "none",
                              }}
                              // disabled={orderDetail.canceledAt==null?isButtonDisabled:true}
                              variant="contained"
                            >
                              Exchange
                            </Button> */}
                                </Box>
                              )}
                          </Box>
                        </div>
                      );
                    })}
                  </Box>
                </Grid>

                <Grid
                  item
                  md={6}
                  xs={12}
                  sm={12}
                  lg={6}
                  sx={{
                    borderStyle: "solid",
                    borderColor: "#EA9A23",
                    mt: { xs: 0, sm: 0, md: 3, lg: 3 },
                    mb: { xs: 0, sm: 0, md: 3, lg: 3 },
                    mr: { xs: 2, sm: 2, md: 0, lg: 0 },
                    ml: { xs: 2, sm: 2, md: 0, lg: 0 },
                    // height: "65vh",
                    borderWidth: {
                      xs: "2px 0 0 0",
                      sm: "2px 0 0 0",
                      md: "0 0 0 2px",
                      lg: "0 0 0 2px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      margin: { lg: ".5rem 0",xs:"-1.2rem" },
                      height: {
                        xs: "100%",
                        sm: "52vh",
                        md: "75vh",
                        lg: "70vh",
                      },
                      padding: {
                        lg: "0.5rem 05rem 0rem 3rem",
                        md: "4rem 4rem 0rem 4rem",
                        sm: "4rem 9rem 2rem 9rem",
                        xs: "2rem 1rem 2rem 1rem",
                      },
                      overflowY: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor:
                          !orderCanceled && !undelivered
                            ? "#93C69B"
                            : "#fa8072",
                        display: "flex",
                        justifyContent: "space-around",
                        overflowY: "auto",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          transform: "scaleX(-1)",
                          height: "4rem",
                          p: ".5rem",
                        }}
                        alt="CartImage"
                        src="deliveryCar.png"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="subtitle1" sx={fontSizedUpperText}>
                          <b>
                            {returnRequestSent
                              ? "Return Request sent Successfully"
                              : !orderCanceled
                              ? activeStep == 4
                                ? "Delivered Succesfully"
                                : undelivered
                                ? "Refused to Accept"
                                : "In Process"
                              : "Canceled Succesfully"}
                          </b>
                        </Typography>
                        {returnRequestSent ? (
                          ""
                        ) : undelivered ? (
                          ""
                        ) : !orderCanceled ? (
                          activeStep == 4 ? (
                            !(
                              <Typography
                                variant="subtitle"
                                sx={fontSizeDownText}
                              >
                                on {DeleveredDate}
                              </Typography>
                            )
                          ) : (
                            <Typography
                              variant="subtitle"
                              sx={fontSizeDownText}
                            >
                              Expected delivery on {expectedDate}
                            </Typography>
                          )
                        ) : (
                          <Typography variant="subtitle" sx={fontSizeDownText}>
                            on {cancelDate}
                          </Typography>
                        )}
                      </Box>
                    </Box>

                    <Box sx={{ overflowY: "auto" }}>
                      <Box sx={{ width: "100%", display: "flex", flexWrap:'wrap' }}>
                        <Stepper
                          sx={{ color: "white" }}
                          activeStep={
                            returnRequestSent
                              ? activeStep
                              : !orderCanceled
                              ? activeStep
                              : 2
                          }
                          orientation="vertical"
                          className={classes.root}
                        >
                          {(!orderCanceled
                            ? !returnRequestSent
                              ? steps
                              : ReturnSteps
                            : stepsFailed
                          ).map((step, index) => (
                            <Step
                              key={step.label}
                              className={classes.label}
                              style={{ color: "white" }}
                            >
                              <StepLabel className={classes.label}>
                                <Typography color="white" fontSize="13px">
                                  {step.label}
                                  <br />
                                  {step.date}
                                </Typography>
                              </StepLabel>
                              {/* <StepContent>
                              <Typography color={"white"} sx={fontSizeDownText}>
                                {step.description}
                              </Typography>
                            </StepContent> */}
                            </Step>
                          ))}
                        </Stepper>
                        <div
                          style={{
                            borderRadius: "0.5rem",
                            boxShadow: "0px 0px 2px rgba(255, 255, 255, 0.5)",
                            paddingBottom: "0.2rem",
                            marginBottom: "2rem",
                            marginTop: "1rem",
                            marginLeft: "10%",
                            width: "15rem",
                          }}
                        >
                          <Box
                            sx={{
                              color: "white",
                              width: "90%",
                              marginLeft: "5%",
                            }}
                          >
                            Deliver to
                            <br />
                            <Typography
                              variant="subtitle2"
                              sx={{ marginTop: "4%" }}
                            >
                              {" "}
                              {String(
                                orderDetail?.shippingAddress?.firstName
                              ).toLowerCase()}
                            </Typography>
                            <Typography variant="subtitle2">
                              {" "}
                              {String(
                                orderDetail?.shippingAddress?.address1
                              ).toLowerCase()}
                            </Typography>
                            <Typography variant="subtitle2">
                              {" "}
                              {String(
                                orderDetail?.shippingAddress?.city
                              ).toLowerCase()}
                              ,{orderDetail?.shippingAddress?.province}
                            </Typography>
                            <Typography variant="subtitle2">
                              {" "}
                              {orderDetail?.shippingAddress?.country +
                                " - " +
                                orderDetail?.shippingAddress?.zip}
                            </Typography>
                            <Typography variant="subtitle2">
                              {" "}
                              Phone - {orderDetail?.shippingAddress?.phone}
                            </Typography>
                          </Box>
                        </div>
                      </Box>
                      {trackingDetails.length > 0 && (
                        <Button
                          style={{ color: "#f1c40f" }}
                          onClick={() => handleOpenDialog()}
                        >
                          Track
                        </Button>
                      )}
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        <b>Order Payment Details</b>
                      </Typography>
                      <Box sx={{ color: "white" }}>
                        <Box>
                          <Divider sx={{ bgcolor: "#333333" }} />
                          <Box sx={{}}>
                            <Box sx={{ display: "flex", m: 0.5 }}>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "38vw",
                                    sm: "17vw",
                                    md: "12vw",
                                    lg: "10vw",
                                  },
                                  ml: -0.4,
                                }}
                              >
                                Payment Mode
                              </Typography>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "29vw",
                                    sm: "15vw",
                                    md: "10vw",
                                    lg: "8vw",
                                  },
                                  ml: 8,
                                }}
                              >
                                {orderDetail?.financialStatus == "PAID"
                                  ? "Prepaid"
                                  : "COD"}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", m: 0.5 }}>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "38vw",
                                    sm: "17vw",
                                    md: "12vw",
                                    lg: "10vw",
                                  },
                                  ml: -0.4,
                                }}
                              >
                                Cart Total
                              </Typography>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "29vw",
                                    sm: "15vw",
                                    md: "10vw",
                                    lg: "8vw",
                                  },
                                  ml: 8,
                                }}
                              >
                                <CurrencyRupeeIcon
                                  sx={{ fontSize: 16, mb: -0.2 }}
                                />
                                {subTotal}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", m: 0.3 }}>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "38vw",
                                    sm: "17vw",
                                    md: "12vw",
                                    lg: "10vw",
                                  },
                                }}
                              >
                                Discount
                              </Typography>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "29vw",
                                    sm: "15vw",
                                    md: "10vw",
                                    lg: "8vw",
                                  },
                                  ml: 8,
                                }}
                              >
                                <CurrencyRupeeIcon
                                  sx={{ fontSize: 16, mb: -0.2 }}
                                />
                                {Number(discountprice).toFixed(2)}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", m: 0.3 }}>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "38vw",
                                    sm: "17vw",
                                    md: "12vw",
                                    lg: "10vw",
                                  },
                                }}
                              >
                                GST{" "}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "29vw",
                                    sm: "15vw",
                                    md: "10vw",
                                    lg: "8vw",
                                  },
                                  ml: 8,
                                }}
                              >
                                <CurrencyRupeeIcon
                                  sx={{ fontSize: 16, mb: -0.2 }}
                                />
                                {orderDetail?.totalTax?.amount}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", m: 0.3 }}>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "38vw",
                                    sm: "17vw",
                                    md: "12vw",
                                    lg: "10vw",
                                  },
                                }}
                              >
                                Shipping Charges
                              </Typography>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "29vw",
                                    sm: "15vw",
                                    md: "10vw",
                                    lg: "8vw",
                                  },
                                  ml: 8,
                                }}
                              >
                                <CurrencyRupeeIcon
                                  sx={{ fontSize: 16, mb: -0.2 }}
                                />
                                0
                              </Typography>
                            </Box>
                            <Divider sx={{ bgcolor: "#333333", mt: 1 }} />
                            <Box sx={{ display: "flex", m: 1 }}>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "38vw",
                                    sm: "17vw",
                                    md: "12vw",
                                    lg: "10vw",
                                  },
                                  ml: -0.5,
                                }}
                              >
                                Total Amount
                              </Typography>
                              <Typography
                                sx={{
                                  color: "white",
                                  width: {
                                    xs: "29vw",
                                    sm: "15vw",
                                    md: "10vw",
                                    lg: "8vw",
                                  },
                                  ml: 8,
                                }}
                              >
                                <CurrencyRupeeIcon
                                  sx={{ fontSize: 16, mb: -0.2 }}
                                />
                                {Number(totalAmount).toFixed(2)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {trackDetailsOpen && (
                <TrackDetails
                  trackDetailsOpen={trackDetailsOpen}
                  setTrackDetailsOpen={setTrackDetailsOpen}
                  trackingNo={
                    orderDetail?.successfulFulfillments[0]?.trackingInfo[0]
                      ?.number
                  }
                  varientId={orderDetail?.lineItems?.edges[0].node?.variant?.id}
                  trackingDetails={trackingDetails}
                />
              )}
            </Card>
          </Container>
          <ReturnOrderDialog
            orderId={orderId}
            openDialog={openDialog}
            handleDialogClose={handleDialogClose}
            returnOrderRequest={returnOrderRequest}
            varientId={orderDetail?.lineItems?.edges[0].node?.variant?.id}
          />
        </Box>
      )}
    </>
  );
}

export default PreviousOrder;
