import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createAddress,
  defaultAddress,
  updateAddress,
} from "../../api/Mutations";
import {
  failureToastMessage,
  successToastMessage
} from "../../common/Utils";
// import { addressDetails } from "../../api/Queries";
import { setAddressID, setCreateAddressId,setTempAddress } from "../../Redux/UserSlice";
import { getCustomerDetails } from "../../api/Api";
import TATneometa from "../../api/TATneometa.json";

const EditAddress = () => {
  const ErrorText = styled("p")`
    color: red;
    font-size: 0.9rem;
    position: absolute;
    top: 1em;
  `;

  // console.log(TATneometa.filter((e=>e.Pincode==140109)),"iiiiiiiiiiiiiiii")
  const location = useLocation();
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [addAddress, { error }] = useMutation(createAddress);
  const [userUpdateAddress] = useMutation(updateAddress);
  const [check, setCheck] = useState(false);
  const addressID = useSelector((state) => state?.user?.addressID);
  const [zipName, setZipName] = useState("");
  const [zipPhone, setZipPhone] = useState("");
  const [zipAddress1, setZipAddress1] = useState("");
  const [zipAddress2, setZipAddress2] = useState("");
  const [zipCity, setZipCity] = useState("");
  const [zipProvince, setZipProvince] = useState("");
  const [zipCountry, setZipCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  // const [createAddressId, setCreateAddressId] = useState("");
  const [verifyAdd, setVerifyAdd] = useState({});
  const [checkBoxDefAdd, setCheckBoxDefAdd] = useState("");
  const [checkBoxDefAdd1, setCheckBoxDefAdd1] = useState("");
  const userAddressAllDetails = location?.state?.Id;

  // Get for get All Address Details
  // const { data } = useQuery(addressDetails,
  //   {
  //     variables: { customerAccessToken: accessToken }
  //   }
  // );
  useEffect(() => {
    // const handleData = async () => {
    //   const responseData = await client
    //     .query({
    //       query: addressDetails,
    //       variables: { customerAccessToken: accessToken },
    //     })
    //     .then((res) => {
    //       setVerifyAdd(res?.data?.customer?.addresses?.edges[0]?.node?.id);
    //       setCheckBoxDefAdd(res?.data?.customer?.defaultAddress?.firstName == userAddressAllDetails?.firstName);
    //       setCheckBoxDefAdd1(res?.data?.customer?.defaultAddress?.phone == userAddressAllDetails?.phone);
    //     })
    // }
    // handleData();
    getCustomerDetails(accessToken)
      .then((res) => {
        let customer = res.data;
        setVerifyAdd(customer?.data?.customer?.addresses?.edges[0]?.node?.id);
        setCheckBoxDefAdd(
          customer?.data?.customer?.defaultAddress?.firstName ==
            userAddressAllDetails?.firstName
        );
        setCheckBoxDefAdd1(
          customer?.data?.customer?.defaultAddress?.phone ==
            userAddressAllDetails?.phone
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const dispatch = useDispatch();
  const yourBagDetail = location?.state?.yourBagId;
  const defaultAddresHide = location?.state?.defaultAddId;

  useEffect(() => {
    setZipName(userAddressAllDetails?.firstName);
    setZipPhone(userAddressAllDetails?.phone);
    setZipAddress1(userAddressAllDetails?.address1);
    setZipAddress2(userAddressAllDetails?.address2);
    setZipCity(userAddressAllDetails?.city);
    setZipProvince(userAddressAllDetails?.province);
    setZipCountry(userAddressAllDetails?.country);
    setZipCode(userAddressAllDetails?.zip);
  }, [userAddressAllDetails]);

  // Validation
  const [addres, setAddres] = useState("");
  const [addres2, setAddres2] = useState("");
  const [cityVali, setCityVali] = useState("");
  const [stateVali, setStateVali] = useState("");
  const [countryVali, setCountryVali] = useState("");
  const [zipVali, setZipVali] = useState("");
  const [nameVali, setNameVali] = useState("");
  const [phoneVali, setPhoneVali] = useState("");

  const onSubmit = (data) => {
    if (
      !zipAddress1 ||
      !zipAddress2 ||
      !zipCity ||
      !zipProvince ||
      !zipCode ||
      !zipCountry ||
      !zipPhone ||
      !zipName
    ) {
      if (!zipAddress1) {
        setAddres("Address1 is required.");
      }
      if (!zipAddress2) {
        setAddres2("Address2 is required.");
      }
      if (!zipCity) {
        setCityVali("City is required.");
      }
      if (!zipProvince) {
        setStateVali("State is required.");
      }
      if (!zipCountry) {
        setCountryVali("Country is required.");
      }
      if (!zipCode) {
        setZipVali("Zip-code is required/6-dig.");
      }
      if (!zipName) {
        setNameVali("Name is required.");
      }
      if (!zipPhone) {
        setPhoneVali("Phone is required/10-dig.");
      }
      if (zipCode && zipCode?.length < 6) {
        setZipVali("Zip-code is required/6-dig.");
      }
    } else if (zipCode && zipCode?.length < 6) {
      setZipVali("Zip-code is required/6-dig.");
    } else {
      let isDeliveredZipcode = TATneometa.filter((e) => e.Pincode == zipCode);
      if (isDeliveredZipcode.length != 0) {
        if (userAddressAllDetails == null) {
          addAddress({
            variables: {
              customerAccessToken: accessToken,
              firstName: zipName,
              // phone:"+91"+zipPhone,
              phone: zipPhone,
              address1: zipAddress1,
              address2: zipAddress2,
              country: zipCountry,
              province: zipProvince,
              city: zipCity,
              zip: zipCode,
            },
          }).then((res) => {
            // console.log(res.data.customerAddressCreate.customerUserErrors[0].message,"454545");
            if (
              check == true &&
              res?.data?.customerAddressCreate?.customerUserErrors[0]
                ?.message != "Address already exists for customer"
            ) {
              setDefaultAddress({
                variables: {
                  customerAccessToken: accessToken,
                  addressId:
                    res?.data?.customerAddressCreate?.customerAddress?.id,
                },
              }).then((res) => {
                let defaultAddressData =
                  res?.data?.customerDefaultAddressUpdate?.customer
                    ?.defaultAddress;
                let defaultAddressData1 = res?.data;
                let defaultAddressId =
                  res?.data?.customerDefaultAddressUpdate?.customer
                    ?.defaultAddress?.id;
                dispatch(setAddressID(defaultAddressData));
                successToastMessage(
                  "Address added successfully and set as deafault"
                );
              });
            }

            if (
              res?.data?.customerAddressCreate?.customerAddress?.__typename ==
              "MailingAddress"
            ) {
              dispatch(
                setCreateAddressId(
                  res?.data?.customerAddressCreate?.customerAddress?.id
                )
              );
              if (check != true) {
                successToastMessage("Address Added Successfully");
              }
              yourBagDetail == 1
                ? navigate("/yourBag")
                : navigate("/myaddress");
            } else if (
              res?.data?.customerAddressCreate?.customerUserErrors?.length > 0
            ) {
              failureToastMessage(
                res?.data?.customerAddressCreate?.customerUserErrors[0].message
              );
              // console.log(res.data.customerAddressCreate.customerUserErrors[0].message, "kkkkkk");
            }
          });
        } else {
          userUpdateAddress({
            variables: {
              addressId: userAddressAllDetails?.id,
              customerAccessToken: accessToken,
              firstName: zipName,
              phone: zipPhone,
              address1: zipAddress1,
              address2: zipAddress2,
              country: zipCountry,
              province: zipProvince,
              city: zipCity,
              zip: zipCode,
            },
          }).then((res) => {
            dispatch(setTempAddress(res.data.customerAddressUpdate.customerAddress))
            if (check == true) {
              setDefaultAddress({
                variables: {
                  customerAccessToken: accessToken,
                  addressId: userAddressAllDetails?.id,
                },
              }).then((res) => {
                let defaultAddressData =
                  res?.data?.customerDefaultAddressUpdate?.customer
                    ?.defaultAddress;
                let defaultAddressData1 = res?.data;
                let defaultAddressId =
                  res?.data?.customerDefaultAddressUpdate?.customer
                    ?.defaultAddress?.id;
                dispatch(setAddressID(defaultAddressData));
                if (yourBagDetail == 1) {
                  successToastMessage("Address updated successfully");
                } else {
                  successToastMessage(
                    "Address updated successfully and set as deafault"
                  );
                }
              });
            }

            if (
              res?.data?.customerAddressUpdate?.customerAddress?.__typename ==
              "MailingAddress"
            ) {
              if (check != true && yourBagDetail != 1) {
                successToastMessage("Address updated successfully");
              }
              yourBagDetail == 1
                ? navigate("/yourBag")
                : navigate("/myaddress");
            } else if (
              res?.data?.customerAddressUpdate?.customerUserErrors?.length > 0
            ) {
              failureToastMessage(
                res?.data?.customerAddressUpdate?.customerUserErrors[0].message
              );
              // console.log(res.data.customerAddressCreate.customerUserErrors[0].message, "kkkkkk");
            }
          });
        }
      } else {
        failureToastMessage("Currently we are not delivering at this location");
      }
    }
  };
  const [setDefaultAddress] = useMutation(defaultAddress);

  const onSelfServiceChange = (e) => {
    setCheck(!check);
  };
  // const handleDefaltValue = () => {
  //   window.location.reload(false);
  // }
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");


  const currentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          axios
            .get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyApTADZTcQTHE42exK2V-8lNT7q5J09B5M`
            )
            .then((res) => {
              console.log(res.data, "Address information");
              let lastIndex = res?.data?.results[1]?.address_components;
              let getAddress1 =
                      res ?.data?.results[1]?.address_components[lastIndex.length - 8]
                          ?.long_name;
              let getAddress2 =
                      res ?.data?.results[1]?.address_components[lastIndex.length - 7]
                          ?.long_name;
                    let getZipCode =
                      res?.data?.results[1]?.address_components[lastIndex.length - 1]
                        ?.long_name;
                    let getCountry =
                      res?.data?.results[1]?.address_components[lastIndex.length - 2]
                        ?.long_name;
                    let getState =
                      res?.data?.results[1]?.address_components[lastIndex.length - 3]
                        ?.long_name;
                    let getCity =
                      res?.data?.results[1]?.address_components[lastIndex.length - 5]
                        ?.long_name;
                    setZipAddress1(getAddress1)
                    setZipAddress2(getAddress2)
                    setZipCity(getCity);
                    setZipProvince(getState);
                    setZipCountry(getCountry);
                    setZipCode(getZipCode);
            })
            .catch((error) => {
              console.error("Error fetching address:", error);
            });
        },
        (error) => {
          console.error("Error getting location:", error);
          failureToastMessage("Please turn on your location");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      failureToastMessage("Location is not supported by this browser.");
    }
  };

  // console.log(Object.keys(verifyAdd).length == null,"verifyAdd");
  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value;

    // Check if the input is a valid 10-digit phone number
    if (/^\d{10}$/.test(phoneNumber)) {
      setZipPhone(phoneNumber);
      setPhoneVali("");
    } else {
      setZipPhone(phoneNumber);
      setPhoneVali("Please enter a valid 10-digit phone number.");
    }
  };

  const handleDiscardClick = (e) => {
    setZipName(userAddressAllDetails?.firstName);
    setZipAddress1(userAddressAllDetails?.address1);
    setZipAddress2(userAddressAllDetails?.address2);
    setZipCity(userAddressAllDetails?.city);
    setZipProvince(userAddressAllDetails?.province);
    setZipCountry(userAddressAllDetails?.country);
    setZipCode(userAddressAllDetails?.zip);
    setZipPhone(userAddressAllDetails?.phone);
  };

  const [addData, setAddData] = useState(null);
  useEffect(() => {
    if (zipCode && String(zipCode).length == 6) {
      axios
        .get(`https://api.postalpincode.in/pincode/${zipCode}`)
        .then((res) => {
          if (res.data[0].Status == "Success") {
            setAddData(res?.data[0]?.PostOffice[0]);
          } else {
            failureToastMessage("Wrong Zip Code");
          }
        });
    }
  }, [zipCode]);
  useEffect(() => {
    if (addData != null) {
      setZipCity(addData.District);
      setZipProvince(addData.State);
      setZipCountry(addData.Country);
    }
  }, [addData]);

  return (
    <Container
      sx={{
        mt: { xs: "12vh", md: "15vh", sm: "9vh" },
        height: { xs: "calc(85vh)", md: "calc(85vh)" },
        overflowY: "auto",
      }}
    >
      <Box>
        <Card sx={{ backgroundColor: "#F0EAFE", pb: 2 }}>
          <Typography sx={{ fontSize: 30, fontFamily: "Raleway", ml: 6 }}>
            {userAddressAllDetails != null ? "Edit Address:" : "New Address:"}
          </Typography>
          <Box>
            {verifyAdd == null ? (
              <Button
                sx={{ color: "black", mt: 1, ml: 4 }}
                onClick={() => navigate("/profile")}
              >
                <ArrowBackIosIcon sx={{ fontSize: "small" }} />
                Back Profile
              </Button>
            ) : (
              <Button
                sx={{ color: "black", mt: 1, ml: 4 }}
                onClick={() =>
                  yourBagDetail == 1
                    ? navigate("/yourBag")
                    : navigate("/myaddress")
                }
              >
                <ArrowBackIosIcon sx={{ fontSize: "small" }} />
                Back to My Address
              </Button>
            )}
            <Divider
              sx={{
                background: "black",
                width: "10rem",
                marginLeft: { lg: "4%", xs: "15%", sm: "7%" },
                marginTop: "-0.5%",
              }}
            />
          </Box>
          <Box>
            <Divider sx={{ mt: 1 }} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: "", mt: 1 }}
                spacing={2}
              >
                <Grid item lg={12}>
                  <Box sx={{ mr: { lg: 2 } }}>
                    <Button
                      onClick={currentLocation}
                      variant="outlined"
                      sx={{
                        width: { xs: 220, sm: 400 },
                        textTransform: "none",
                        borderColor: "#006D82",
                        borderRadius: "23px",
                        color: "#006D82",
                        fontSize: "14px",
                      }}
                    >
                      <img
                        src="./gis_location.png"
                        height={"16px"}
                        style={{ marginRight: "15px" }}
                      />
                      Detect my location
                    </Button>
                  </Box>
                </Grid>
                <Grid item lg={12}>
                  <Box>
                    <FormControl
                      sx={{
                        mt: 1,
                        width: { xs: "30ch", sm: "50ch" },
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 0 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="firstName"
                            defaultValue={userAddressAllDetails?.firstName}
                            variant="outlined"
                            value={zipName}
                            size="small"
                            type="text"
                            placeholder="Name"
                            fullWidth={true}
                            // onChange={onChange}
                            onChange={(e) => {
                              setZipName(e.target.value);
                            }}
                            height="10px"
                            InputProps={{ sx: { height: "30px" } }}
                          />
                        )}
                      />
                      {zipName == null || zipName == "" ? (
                        <Typography sx={{ color: "red", mb: -3, fontSize: 13 }}>
                          {nameVali}
                        </Typography>
                      ) : (
                        <Typography sx={{ mb: "-15px" }}></Typography>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item lg={12}>
                  <Box>
                    {/* <FormControl
                      sx={{
                        mt: 1,
                        width: { xs: "30ch", sm: "50ch" },
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 0 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <Controller
                        control={control}
                        name="mobile"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="mobile"
                            defaultValue={userAddressAllDetails?.phone}
                            variant="outlined"
                            value={zipPhone}
                            size="small"
                            type="text"
                            placeholder="Mobile/Phone"
                            fullWidth={true}
                            // onChange={onChange}
                            onChange={(e) => { setZipPhone(e.target.value) }}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9,+]/g, '') }}
                            height="10px"
                            inputProps={{ minLength: 10, maxLength: 10, }}
                            InputProps={{ sx: { height: "30px" } }}
                          />
                        )}
                      />
                      {(zipPhone == null || zipPhone == "" || (zipPhone && zipPhone?.length < 10) ?
                        <Typography sx={{ color: "red", mb: -3, fontSize: 13 }}>{phoneVali}
                        </Typography> : <Typography sx={{ mb: "-15px" }}></Typography>)}
                    </FormControl> */}
                    <FormControl
                      sx={{
                        mt: 1,
                        width: { xs: "30ch", sm: "50ch" },
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 0 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <Controller
                        control={control}
                        name="mobile"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="mobile"
                            defaultValue={userAddressAllDetails?.phone}
                            variant="outlined"
                            value={zipPhone}
                            size="small"
                            type="num"
                            placeholder="Mobile/Phone"
                            fullWidth={true}
                            height="10px"
                            InputProps={{ sx: { height: "30px" } }}
                            onChange={handlePhoneChange}
                            onKeyPress={(e) => {
                              if (e.key.match(/[^0-9]/)) {
                                e.preventDefault();
                              }
                            }}
                            inputProps={{ maxLength: 10 }}
                          />
                        )}
                      />
                      {/* {phoneVali && (
                        <Typography sx={{ color: 'red', mb: -3, fontSize: 13 }}>
                          {phoneVali}
                        </Typography>
                      )} */}
                      {phoneVali ? (
                        <Typography sx={{ color: "red", mb: -3, fontSize: 13 }}>
                          {phoneVali}
                        </Typography>
                      ) : (
                        <Typography sx={{ mb: "-15px" }}></Typography>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item lg={12}>
                  <Box>
                    <FormControl
                      sx={{
                        mt: 1,
                        width: { xs: "30ch", sm: "50ch" },
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 0 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <Controller
                        control={control}
                        name="address1"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="address1"
                            defaultValue={userAddressAllDetails?.address1}
                            variant="outlined"
                            value={zipAddress1}
                            size="small"
                            type="text"
                            placeholder="Address"
                            fullWidth={true}
                            // onChange={onChange}
                            onChange={(e) => {
                              setZipAddress1(e.target.value);
                            }}
                            height="10px"
                            InputProps={{ sx: { height: "30px" } }}
                          />
                        )}
                      />
                      {zipAddress1 == null || zipAddress1 == "" ? (
                        <Typography sx={{ color: "red", mb: -3, fontSize: 13 }}>
                          {addres}
                        </Typography>
                      ) : (
                        <Typography sx={{ mb: "-15px" }}></Typography>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item lg={12}>
                  <Box>
                    <FormControl
                      sx={{
                        mt: 1,
                        width: { xs: "30ch", sm: "50ch" },
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 0 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <Controller
                        control={control}
                        name="address2"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="address2"
                            // required={false}
                            defaultValue={userAddressAllDetails?.address2}
                            variant="outlined"
                            value={zipAddress2}
                            size="small"
                            type="text"
                            height="10px"
                            InputProps={{ sx: { height: "30px" } }}
                            placeholder="Street Land Mark"
                            fullWidth={true}
                            // onChange={onChange}
                            onChange={(e) => {
                              setZipAddress2(e.target.value);
                            }}
                          />
                        )}
                      />
                      {zipAddress2 == null || zipAddress2 == "" ? (
                        <Typography sx={{ color: "red", mb: -3, fontSize: 13 }}>
                          {addres2}
                        </Typography>
                      ) : (
                        <Typography sx={{ mb: "-15px" }}></Typography>
                      )}
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item lg={12}>
                  <Box>
                    <FormControl
                      sx={{
                        mt: 1,
                        width: { xs: "30ch", sm: "50ch" },
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 0 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <Controller
                        control={control}
                        name="zip"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="zip"
                            defaultValue={userAddressAllDetails?.zip}
                            variant="outlined"
                            type="text"
                            placeholder="Zip"
                            height="10px"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            inputProps={{ minLength: 6, maxLength: 6 }}
                            InputProps={{ sx: { height: "30px" } }}
                            value={zipCode}
                            onChange={(e) => {
                              setZipCode(e.target.value);
                            }}
                            size="small"
                            fullWidth={true}
                          />
                        )}
                      />
                      {zipCode == null ||
                      zipCode == "" ||
                      (zipCode && zipCode?.length < 6) ? (
                        <Typography sx={{ color: "red", mb: -3, fontSize: 13 }}>
                          {zipVali}
                        </Typography>
                      ) : (
                        <Typography sx={{ mb: "-15px" }}></Typography>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item lg={12}>
                  <Box>
                    <FormControl
                      sx={{
                        mt: 1,
                        width: { xs: "30ch", sm: "50ch" },
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 0 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <Controller
                        control={control}
                        name="city"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="city"
                            defaultValue={userAddressAllDetails?.city}
                            variant="outlined"
                            type="text"
                            size="small"
                            placeholder="City"
                            height="10px"
                            InputProps={{ sx: { height: "30px" } }}
                            value={zipCity}
                            fullWidth={true}
                            onChange={(e) => {
                              setZipCity(e.target.value);
                            }}
                          />
                        )}
                      />
                      {zipCity == null || zipCity == "" ? (
                        <Typography sx={{ color: "red", mb: -3, fontSize: 13 }}>
                          {cityVali}
                        </Typography>
                      ) : (
                        <Typography sx={{ mb: "-15px" }}></Typography>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={6}
                  sx={{
                    gap: "2PX",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <FormControl
                      sx={{
                        mt: 1,
                        width: { xs: "30ch", sm: "24.4ch" },
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 0 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 1 },
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <Controller
                        control={control}
                        name="province"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="province"
                            defaultValue={userAddressAllDetails?.province}
                            variant="outlined"
                            type="text"
                            size="small"
                            value={zipProvince}
                            placeholder="State"
                            height="10px"
                            InputProps={{ sx: { height: "30px" } }}
                            fullWidth={true}
                            // onChange={onChange}
                            onChange={(e) => {
                              setZipProvince(e.target.value);
                            }}
                          />
                        )}
                      />
                      {zipProvince == null || zipProvince == "" ? (
                        <Typography sx={{ color: "red", mb: -3, fontSize: 13 }}>
                          {stateVali}
                        </Typography>
                      ) : (
                        <Typography sx={{ mb: "-15px" }}></Typography>
                      )}
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl
                      sx={{
                        mt: { xs: 2.5, sm: 1 },
                        width: { xs: "30ch", sm: "24.4ch" },
                        pl: { xs: 1, sm: 1.5, md: 2, lg: 0 },
                        pr: { xs: 1, sm: 1.5, md: 2, lg: 2.5 },
                      }}
                      variant="outlined"
                      size="small"
                    >
                      <Controller
                        control={control}
                        name="country"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="country"
                            defaultValue={userAddressAllDetails?.country}
                            placeholder="Country"
                            type="text"
                            size="small"
                            height="10px"
                            InputProps={{ sx: { height: "30px" } }}
                            value={zipCountry}
                            fullWidth={true}
                            onChange={(e) => {
                              setZipCountry(e.target.value);
                            }}
                          />
                        )}
                      />
                      {zipCountry == null || zipCountry == "" ? (
                        <Typography sx={{ color: "red", mb: -3, fontSize: 13 }}>
                          {countryVali}
                        </Typography>
                      ) : (
                        <Typography sx={{ mb: "-15px" }}></Typography>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                {defaultAddresHide == 2 ? (
                  <p></p>
                ) : (
                  <Grid item lg={12}>
                    <Box>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                checkBoxDefAdd && checkBoxDefAdd1 == true
                                  ? true
                                  : check
                              }
                              onChange={onSelfServiceChange}
                              name="check"
                            />
                          }
                          label="Make this default"
                        />
                      </FormGroup>
                    </Box>
                  </Grid>
                )}

                <Box
                  sx={{
                    display: "flex",
                    gap: "15px",
                    flexWrap: "wrap",
                    ml: 2,
                    justifyContent: "center",
                  }}
                >
                  {/* <Grid item xs={12} lg={6}> */}
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        width: "180px",
                        borderRadius: "23px",
                        color: "white",
                        fontSize: "14px",
                        backgroundColor: "#006D82",
                        "&:hover": { backgroundColor: "#006D82" },
                        textTransform: "none",
                      }}
                    >
                      {userAddressAllDetails != null
                        ? "Save Changes"
                        : "Save Address"}
                    </Button>
                  </Box>
                  {userAddressAllDetails != null ? (
                    <Box>
                      <Button
                        onClick={handleDiscardClick}
                        variant="outlined"
                        sx={{
                          width: "180px",
                          borderColor: "#006D82",
                          borderRadius: "23px",
                          color: "#006D82",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        Discard Changes
                      </Button>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            </form>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default EditAddress;
